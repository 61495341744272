import React from 'react';
import { asyncComponent } from '@peloton/code-splitting';
import { Links } from '@peloton/links';
import { Route as FeatureRoute, FEATURES } from '@account/optimizely';

const BASE_MEMBERSHIP_REACTIVATE_ROUTE = Links.MYMEMBERSHIP_ROUTE + '/reactivate';
export const MEMBERSHIP_REACTIVATE_SUCCESS_ROUTE =
  BASE_MEMBERSHIP_REACTIVATE_ROUTE + '/success';

const Routes = [
  <FeatureRoute
    feature={FEATURES.IsPersonalizedWinbackEnabled}
    key={MEMBERSHIP_REACTIVATE_SUCCESS_ROUTE}
    path={MEMBERSHIP_REACTIVATE_SUCCESS_ROUTE}
    component={asyncComponent(
      () =>
        import('./SuccessPage' /* webpackChunkName: "MembershipReactivateSuccessPage" */),
    )}
  />,
  <FeatureRoute
    feature={FEATURES.IsPersonalizedWinbackEnabled}
    key={BASE_MEMBERSHIP_REACTIVATE_ROUTE}
    path={BASE_MEMBERSHIP_REACTIVATE_ROUTE}
    component={asyncComponent(
      () => import('./Page' /* webpackChunkName: "MembershipReactivatePage" */),
    )}
  />,
];

export default Routes;
