import { race, take, call, put } from 'redux-saga/effects';
import { isDefined } from '@peloton/types';
import type {
  reactivateFailure,
  reactivateSuccess,
  Subscription,
} from '@engage/subscriptions';
import {
  reactivateSubscription,
  SubscriptionUpdatesActionType,
} from '@engage/subscriptions';
import { loadTransactions } from '../redux';

export enum Actions {
  Reactivate = 'pelo/@account/pg-preferences/subscriptionDetails/REACTIVATE',
}

export const reactivateSub = (
  sub: Subscription,
  onSuccess: () => void,
  onError: () => void,
) => ({
  type: Actions.Reactivate,
  payload: { sub, callbacks: { onSuccess, onError } },
});

export const reactivateSaga = function* (action: ReturnType<typeof reactivateSub>) {
  const { sub, callbacks } = action.payload;
  yield put(reactivateSubscription(sub));

  const {
    success,
    failure,
  }: {
    success: ReturnType<typeof reactivateSuccess>;
    failure: ReturnType<typeof reactivateFailure>;
  } = yield race({
    success: take(SubscriptionUpdatesActionType.ReactivateSuccess),
    failure: take(SubscriptionUpdatesActionType.ReactivateFailure),
  });

  if (isDefined(success)) {
    yield call(callbacks.onSuccess);
    yield put(loadTransactions(sub.id));
  } else if (isDefined(failure)) {
    yield call(callbacks.onError);
  }
};
