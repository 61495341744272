const getAccessTokenWithBackoff = (
  getAccessTokenSilently: (options?: any | undefined) => Promise<any>,
  backoffTime: number = 0,
) =>
  new Promise((resolve, reject) => {
    setTimeout(async () => {
      getAccessTokenSilently()
        .then(resolve)
        .catch(e => {
          reject(e);
        });
    }, backoffTime * 1000);
  });

export default getAccessTokenWithBackoff;
