import { useFeature } from '@optimizely/react-sdk';
import { useQueryToggleValue } from '@account/feature-toggles/QueryToggleContext';
import type { FEATURES } from '../enums';

export const useFeatureWithOverrides = (featureName: FEATURES): boolean => {
  const override = useQueryToggleValue(featureName);
  const [isEnabled] = useFeature(featureName);
  if (override !== undefined) {
    return override;
  } else {
    return isEnabled;
  }
};
