import { put, call } from 'redux-saga/effects';
import { reportError } from '@peloton/error-reporting';
import { history } from '@peloton/redux';
import type { AddOrderPaymentMethodAction } from '../redux/addOrderPaymentMethod';
import {
  addOrderPaymentError,
  addOrderPaymentLoading,
} from '../redux/addOrderPaymentMethod';

export default function* (action: AddOrderPaymentMethodAction) {
  yield put(addOrderPaymentLoading(true));
  try {
    const {
      payload: { setPaymentMethod, orderId, nextStep },
    } = action;
    const mutationOptions = {
      variables: {
        billingPartnerToken: action.payload.paymentMethodId,
        orderId: orderId,
      },
    };
    // This ts-ignore is due to Apollo 3 upgrades
    // eslint-disable-next-line @typescript-eslint/prefer-ts-expect-error
    // @ts-ignore
    yield call(setPaymentMethod, mutationOptions);
    yield put(addOrderPaymentLoading(false));
    yield history.push(nextStep!.url);
  } catch (error) {
    yield put(addOrderPaymentError(error));
    yield put(addOrderPaymentLoading(false));
    yield put(reportError({ error }));
  }
}
