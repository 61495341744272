import type { Reducer } from 'redux';
import type { PendingResult } from '@engage/result';
import { pending, ok, error as engageError } from '@engage/result';

const updateReducer = (
  requestAction: string,
  successAction: string,
  errorAction: string,
  clearAction?: string,
): Reducer<PendingResult<null, null>> => (state = pending, action) => {
  switch (action.type) {
    case requestAction:
      return pending;
    case successAction:
      return ok(null);
    case errorAction:
      return engageError(null);
    default:
      if (clearAction && action.type === clearAction) {
        return pending;
      }
      return state;
  }
};

export default updateReducer;
