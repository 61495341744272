import React from 'react';
import { useTracking } from 'react-tracking';
import { useBaseTrackingProperties } from './hooks/useBaseTrackingProperties';

type Props = {
  orderId: string;
  isLoggedIn: boolean;
};

const BaseTrackingPropertiesProvider: React.FC<React.PropsWithChildren<Props>> = ({
  orderId,
  isLoggedIn,
  children,
}) => {
  const baseTrackingProperties = useBaseTrackingProperties(orderId, isLoggedIn);

  const { Track } = useTracking({
    ...baseTrackingProperties,
  });

  return <Track>{children}</Track>;
};

export default BaseTrackingPropertiesProvider;
