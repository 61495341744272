import { useFeature } from '@optimizely/react-sdk';
import { useEffect, useState } from 'react';
import { FEATURES } from '@account/optimizely';
import useLegacySession from './useLegacySession';

export const TIMEOUT = 60000;

const useCheckSession = (onUnAuthenticated: () => void = () => {}) => {
  const [legacySessionEnabled] = useFeature(FEATURES.IsLegacySessionEnabled);
  const [isCheckingLegacySession, setIsCheckingLegacySession] = useState(true);
  const { isAuthedSession } = useLegacySession();

  // Check for valid legacy session
  useEffect(() => {
    (async () => {
      if (legacySessionEnabled) {
        const isAuthed = await isAuthedSession();
        if (isAuthed) {
          setIsCheckingLegacySession(false);
          return;
        }
        onUnAuthenticated();
      } else {
        setIsCheckingLegacySession(false);
      }
    })();
  }, [legacySessionEnabled]);

  return [isCheckingLegacySession];
};

export default useCheckSession;
