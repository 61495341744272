import type { Client } from '@peloton/api';
import { pipeData, toSkipErrorHandlingConfig } from '@peloton/api';
import { toMaybeTime, toTime } from '@peloton/time';
import type {
  AttachedDevice,
  Subscription,
  DeviceSubscription,
  Device,
  DigitalSubscription,
  Status,
  SubUser,
  TierType,
  BillingFrequency,
  Platform,
} from '../Subscription';
import {
  BillingType,
  DeviceType,
  toPrimarySub,
  StatusType,
  SubscriptionKind,
  SubscriptionType,
} from '../Subscription';

const fetchSubscriptionUrlByKind = (kind: SubscriptionKind, subId: string) => {
  switch (kind) {
    case SubscriptionKind.Digital: {
      return `api/subscription/digital/${subId}`;
    }
    case SubscriptionKind.LegacyiOS: {
      return `api/ios_subscription/${subId}`;
    }
    default: {
      return `api/subscription/${subId}`;
    }
  }
};

const getSubscriptionMapper = (kind: SubscriptionKind, userId: string) => {
  switch (kind) {
    case SubscriptionKind.Digital: {
      return toDigitalSubscription(userId);
    }
    case SubscriptionKind.LegacyiOS: {
      return toIosSubscription(userId);
    }
    default: {
      return toDeviceSubscription(userId);
    }
  }
};

export const fetchSubscription = (
  api: Client,
  userId: string,
  subId: string,
  kind: SubscriptionKind,
) =>
  api
    .get(fetchSubscriptionUrlByKind(kind, subId))
    .then(
      pipeData<
        ApiDigitalSub | ApiIosSub | ApiDeviceSub,
        DigitalSubscription | DeviceSubscription
      >(getSubscriptionMapper(kind, userId)),
    );

const ALL_SUBSCRIPTIONS_URL = 'api/v2/user/subscriptions';

export const fetchSubscriptions = (
  api: Client,
  userId: string,
): Promise<Subscription[]> =>
  api
    .get(ALL_SUBSCRIPTIONS_URL, toSkipErrorHandlingConfig())
    .then(pipeData(toAllSubscriptions(userId)));

export const fetchPrimarySubscription = (api: Client, userId: string) =>
  fetchSubscriptions(api, userId).then(toPrimarySub);

export const toAllSubscriptions = (userId: string) => (
  response: ApiSubscriptionsResponse,
): Subscription[] => [
  ...response.subscriptions.map(toDeviceSubscription(userId)),
  ...response.sharedSubscriptions.map(toDeviceSubscription(userId)),
  ...response.digitalSubscriptions.map(toDigitalSubscription(userId)),
  ...response.sharedDigitalSubscriptions.map(toDigitalSubscription(userId)),
  ...response.iosSubscriptions.map(toIosSubscription(userId)),
  ...response.sharedIosSubscriptions.map(toIosSubscription(userId)),
];

const subscription_types = {
  consumer: SubscriptionType.Consumer,
  commercial: SubscriptionType.Commercial,
  guide: SubscriptionType.Guide,
};

export const toDeviceSubscription = (userId: string) => (
  apiSub: ApiDeviceSub,
): DeviceSubscription => ({
  ...toCommonSubProps(userId, apiSub),
  kind: SubscriptionKind.Device,
  type: subscription_types[apiSub.subscriptionType],
  attachedDevices: toAttachedDevices(apiSub),
  firstActivatedAt: toMaybeTime(apiSub.firstActivatedOn),
  commitment: toMonthsCount(0, 0),
  currency: apiSub.currency,
  deviceLastAttachedAt: toMaybeTime(apiSub.deviceLastAttachedAt),
  prepaidCredits: toMonthsCount(apiSub.prepaidCredits, apiSub.prepaidCreditsUsed),
  maxSharedUsers: apiSub.maxSharedUsers,
  name: apiSub.name,
  nextBillingAt: toMaybeTime(apiSub.nextBillingDate),
  purchasedAt: toMaybeTime(apiSub.orderDate),
  order: {
    id: apiSub.orderId,
    confirmationCode: apiSub.orderConfirmationCode,
  },
  status: {
    type: apiSub.status,
    text: apiSub.statusText,
  },
  waivers: toMonthsCount(apiSub.waivers, apiSub.waiversUsed),
  activationCode: apiSub.formalActivationCode,
  canPause: apiSub.canPause,
  canUnpause: apiSub.canUnpause,
  pausedOn: toMaybeTime(apiSub.pausedOn),
  unpausedOn: toMaybeTime(apiSub.unpausedOn),
  cancelledOn: toMaybeTime(apiSub.cancelledOn),
  hasEquipmentLease: apiSub.hasEquipmentLease,
});

export const toDigitalSubscription = (userId: string) => (
  apiSub: ApiDigitalSub,
): DigitalSubscription => ({
  ...toCommonSubProps(userId, apiSub),
  ...(apiSub.currencyCode ? { currency: apiSub.currencyCode } : undefined),
  name: apiSub.name,
  billingType: apiSub.billingType,
  doesRollover: apiSub.doesRollover || false,
  requireAccessCode: apiSub.requireAccessCode || false,
  kind:
    apiSub.billingType === BillingType.AppleMonthly
      ? SubscriptionKind.LegacyiOS
      : SubscriptionKind.Digital,
  status: toStatusFromDigital(apiSub),
  nextBillingAt: toTime(apiSub.expiresAt),
  purchasedAt: toTime(apiSub.originalPurchaseDate),
  tierId: apiSub.tierId,
  tierType: apiSub.tierType,
  billingFrequency: apiSub.billingFrequency,
  platform: apiSub.platform,
  rolloverCostInCents: apiSub.rolloverCostInCents,
  lastSuccessfulCharge: apiSub.lastSuccessfulCharge
    ? toTime(apiSub.lastSuccessfulCharge)
    : undefined,
});

export const toIosSubscription = (userId: string) => (
  apiSub: ApiIosSub,
): DigitalSubscription => ({
  ...toCommonSubProps(userId, apiSub),
  doesRollover: apiSub.doesRollover || false,
  name: toIosSubName(apiSub),
  kind: SubscriptionKind.LegacyiOS,
  status: toStatusFromIos(apiSub),
  purchasedAt: toTime(apiSub.createdAt),
  billingType: toBillingTypeFromIos(apiSub),
  nextBillingAt: toTime(apiSub.expireAt),
  requireAccessCode: apiSub.requireAccessCode,
  tierId: apiSub.tierId,
  tierType: apiSub.tierType,
  billingFrequency: apiSub.billingFrequency,
  platform: apiSub.platform,
  rolloverCostInCents: apiSub.rolloverCostInCents,
});

const toCommonSubProps = (
  userId: string,
  { owner, sharedUserSet, id, costInCents, isActive }: CommonSubProps,
) => ({
  owner,
  isOwner: userId === owner.id,
  sharedUserSet,
  id,
  costInCents,
  isActive,
});

const toMonthsCount = (totalMonths: number, usedMonths: number) => ({
  totalMonths,
  usedMonths,
});

const toDeviceNameFromDeviceGroup = (
  deviceGroup: AttachedDevice['deviceGroup'],
): string => {
  switch (deviceGroup) {
    case DeviceType.Bike:
      return 'Bike';
    case DeviceType.Tread:
      return 'Tread';
    case DeviceType.Guide:
      return 'Peloton Guide';
    default:
      return '';
  }
};

const toAttachedDevices = (apiSub: ApiDeviceSub): Device[] =>
  apiSub.attachedDevices
    .filter(({ deviceId }) => deviceId)
    .map(({ deviceGroup, deviceId, deviceName }) => ({
      kind: deviceGroup,
      id: deviceId,
      name: deviceName || toDeviceNameFromDeviceGroup(deviceGroup),
    }));

const toStatusFromDigital = (sub: ApiDigitalSub): Status => ({
  // TODO, this can be removed when api updates to add statusText are merged
  type: sub.status,
  text: sub.statusText ?? toStatusTextFromStatusType(sub.status),
});

// TODO: remove after api change to add status text is live
const toStatusTextFromStatusType = (statusType: StatusType) => {
  switch (statusType) {
    case StatusType.ActiveNormal:
    case StatusType.ActiveGracePeriod:
      return 'Active';
    case StatusType.ActiveAutoRenewOff:
      return 'Active - Will not renew';
    case StatusType.Cancelled:
    case StatusType.Expired:
    default:
      return 'Inactive';
  }
};

const toStatusFromIos = ({ isActive }: { isActive: boolean }): Status => ({
  type: isActive ? StatusType.ActiveNormal : StatusType.Inactive,
  text: isActive ? 'Active - Normal' : ' Inactive',
});

const toIosSubName = ({ subscriptionType }: { subscriptionType: string }) => {
  if (subscriptionType === 'free_trial') {
    return 'Digital Free Trial';
  } else if (subscriptionType === 'one_week_pass') {
    return 'Digital 1 Week';
  } else {
    return 'Digital Subscription';
  }
};

const toBillingTypeFromIos = ({ subscriptionType }: { subscriptionType: string }) => {
  if (subscriptionType === 'free_trial') {
    return BillingType.Trial;
  } else if (subscriptionType === 'one_week_pass') {
    return BillingType.AppleOneWeek;
  } else {
    return BillingType.AppleMonthly;
  }
};

// Types

export type ApiDeviceSub = CommonSubProps & {
  attachedDevices: AttachedDevice[];
  currency: string;
  name: string;
  deviceId?: string;
  deviceLastAttachedAt?: number;
  deviceName?: string;
  firstActivatedOn?: number;
  formalActivationCode: string;
  hasEquipmentLease: boolean;
  prepaidCredits: number;
  prepaidCreditsUsed: number;
  treadDeviceId?: string;
  treadDeviceName?: string;
  waivers: number;
  waiversUsed: number;
  orderId: string;
  orderConfirmationCode: string;
  orderDate: number;
  maxSharedUsers?: number;
  nextBillingDate?: number;
  status: StatusType;
  statusText: string;
  subscriptionType: string;
  canPause: boolean;
  canUnpause: boolean;
  pausedOn: number;
  unpausedOn: number;
  cancelledOn: number;
};

export type ApiDigitalSub = CommonSubProps & {
  currencyCode: string | null;
  doesRollover: boolean;
  name: string;
  billingType: BillingType;
  expiresAt: number;
  originalPurchaseDate: number;
  status: StatusType;
  statusText: string;
  tierId: string | null;
  tierType: TierType | null;
  billingFrequency: BillingFrequency | null;
  platform: Platform | null;
  rolloverCostInCents: number;
  lastSuccessfulCharge?: number;
};

type ApiIosSub = CommonSubProps & {
  doesRollover: boolean;
  expireAt: number;
  subscriptionType: string;
  createdAt: number;
  tierId: string | null;
  tierType: TierType | null;
  billingFrequency: BillingFrequency | null;
  platform: Platform | null;
  rolloverCostInCents: number;
};

type CommonSubProps = {
  id: string;
  owner: SubUser;
  sharedUserSet: SubUser[];
  costInCents: number;
  isActive: boolean;
  requireAccessCode: boolean;
};

type ApiSubscriptionsResponse = {
  subscriptions: ApiDeviceSub[];
  sharedSubscriptions: ApiDeviceSub[];
  iosSubscriptions: ApiIosSub[];
  sharedIosSubscriptions: ApiIosSub[];
  digitalSubscriptions: ApiDigitalSub[];
  sharedDigitalSubscriptions: ApiDigitalSub[];
};
