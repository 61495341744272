import React from 'react';
import { connect } from 'react-redux';
import type { UserSelectorState } from '@peloton/auth';
import { getIsUserLoading, getUser, isUserSignedIn } from '@peloton/auth';
import type {
  ExtLink,
  ExtLinkEnv,
  ExtLinkEnvState,
  PeloLink,
} from '@peloton/external-links';
import {
  REDIRECT_URL_QUERY_PARAM,
  REDIRECT_APP_QUERY_PARAM,
  getExtLinkEnv,
  toHref,
  toRedirectParams,
} from '@peloton/external-links';
import useStudioOAuth from './useStudioOAuth';

export const requiresAuth = (
  WrappedComponent: React.ComponentType<React.PropsWithChildren<unknown>>,
) => {
  const AuthenticatedComponent = ({
    extLinkEnv,
    isLoadingUser,
    isLoggedIn,
    successRedirectLink,
    userEntityExists,
    ...props
  }: AuthenticatedProps) => {
    const redirectLink = successRedirectLink?.path;
    const { loginWithRedirect } = useStudioOAuth(redirectLink);

    React.useEffect(() => {
      if (userEntityExists && !isLoggedIn && !isLoadingUser) {
        loginWithRedirect();
      }
    }, [isLoggedIn, isLoadingUser, userEntityExists, loginWithRedirect]);

    return isLoggedIn ? <WrappedComponent {...props} /> : null;
  };

  const mapStateToProps = (state: UserSelectorState & ExtLinkEnvState) => ({
    extLinkEnv: getExtLinkEnv(state),
    isLoggedIn: isUserSignedIn(state),
    isLoadingUser: getIsUserLoading(state),
    userEntityExists: Boolean(getUser(state)),
  });

  return connect(mapStateToProps)(AuthenticatedComponent);
};

export type AuthenticatedProps = StateProps & RedirectProps;

type StateProps = {
  extLinkEnv: ExtLinkEnv;
  isLoggedIn: boolean;
  isLoadingUser: boolean;
  userEntityExists: boolean;
};

type RedirectProps = {
  successRedirectLink?: PeloLink;
};

export const toHrefWithRedirect = (
  link: ExtLink,
  env: ExtLinkEnv,
  redirectLink: PeloLink,
) => {
  const redirectParams = toRedirectParams(redirectLink);

  const redirectQueryString = `?${REDIRECT_URL_QUERY_PARAM}=${btoa(
    redirectParams.redirectUrl,
  )}&${REDIRECT_APP_QUERY_PARAM}=${redirectParams.redirectApp}`;

  return `${toHref(link, env)}${redirectQueryString}`;
};
