import { browserProvider as newrelicProvider } from '@peloton/newrelic';
import type { ErrorProperties, ErrorReporterFactory } from '../ErrorReporter';

const newRelicErrorReporter: ErrorReporterFactory = (config: any) => {
  const nrReporter = newrelicProvider.getErrorReporter();

  return {
    reportError: (error: Error, properties: ErrorProperties) =>
      nrReporter(error, properties),
  };
};

export default newRelicErrorReporter;
