import { css } from 'styled-components';

export const light = css`
  font-weight: 300;
`;

export const reg = css`
  font-weight: 400;
`;

export const med = css`
  font-weight: 500;
`;

export const bold = css`
  font-weight: 700;
`;
