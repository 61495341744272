import type { SagaIterator } from 'redux-saga';
import { put, select } from 'redux-saga/effects';
import { getSubscriptions, loadSubscriptionsSuccess } from '../redux';
import type { Subscription } from '../Subscription';

const updateSubscriptions = function* (updatedSub: Subscription): SagaIterator {
  const allSubs = yield select(getSubscriptions);
  yield put(loadSubscriptionsSuccess(toUpdatedSubs(allSubs, updatedSub)));

  return allSubs;
};

const toUpdatedSubs = (currentSubs: Subscription[], updatedSub: Subscription) => {
  const indexOf = currentSubs.map((sub: Subscription) => sub.id).indexOf(updatedSub.id);

  return [
    ...currentSubs.slice(0, indexOf),
    updatedSub,
    ...currentSubs.slice(indexOf + 1),
  ];
};

export default updateSubscriptions;
