import React from 'react';
import { asyncComponent } from '@peloton/code-splitting';
import { Route } from '@account/router';
import { CALLBACK, LOGIN } from './urls';

const Routes = [
  <Route
    key={CALLBACK}
    path={CALLBACK}
    component={asyncComponent(
      () => import('./Callback' /* webpackChunkName: "AuthCallbackPage" */),
    )}
  />,
  <Route
    key={LOGIN}
    path={LOGIN}
    component={asyncComponent(
      () => import('./Login' /* webpackChunkName: "AuthLoginPage" */),
    )}
  />,
];

export default Routes;
