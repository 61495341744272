import { connect } from 'react-redux';
import type { TrackTraits } from '@peloton/analytics';

export const TrackActionType = 'pelo/engage/analytics/TRACK';

export function track<T extends Object = {}>(event: TrackTraits<T>) {
  return {
    type: TrackActionType,
    payload: event,
  };
}

export const trackEnhancer = connect(undefined, { track });
export type TrackingComponent<T extends Object = {}> = {
  track(event: TrackTraits<T>): void;
};
