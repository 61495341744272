import 'regenerator-runtime/runtime.js';
// eslint-disable-next-line import/order
import { GlobalStyles } from '@ecomm/styles'; // must import GlobalStyles first to ensure correct css order
import { ConnectedRouter as Router } from 'connected-react-router';
import type { ReactNode } from 'react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';
import { Switch } from 'react-router-dom';
import { reportFocusMethod } from '@peloton/accessibility';
import { load as loadAnalytics } from '@peloton/analytics';
import { makeAnalytics } from '@peloton/analytics/segment';
import { CLIENT_CONTEXT as API_CLIENT } from '@peloton/api';
import { USER_MAPPER_CONTEXT } from '@peloton/auth';
import LogoutCookieProvider from '@peloton/auth/LogoutCookieProvider';
import { asyncComponent } from '@peloton/code-splitting';
import {
  toAuthLinkEnv,
  toDigitalLinkEnv,
  toLinkEnv,
  toPreorderLinkEnv,
} from '@peloton/env';
import {
  configureErrorHandler,
  consoleErrorReporter,
  ERROR_REPORTING_CLIENT_CONTEXT,
  ErrorBoundary,
  ErrorReporterContext,
  newRelicErrorReporter,
} from '@peloton/error-reporting';
import { toExtLinkEnv } from '@peloton/external-links';
import { ExtLinkEnvProvider } from '@peloton/external-links/context/Provider';
import Internationalize, {
  toLocaleFromHostname,
  LocaleProvider,
} from '@peloton/internationalize';
import { OneTrustStyles } from '@peloton/onetrust/styles';
import { history, toStore } from '@peloton/redux';
import { injectFonts } from '@peloton/typography';
import { AppTrackingProvider } from '@account/analytics';
import { toClient, ClientProvider } from '@account/api';
import { AuthUser, mapper as userMapper, OauthProvider } from '@account/auth';
import {
  features,
  FeatureTogglePanel,
  toStoreReducers,
  toStoreSagas,
} from '@account/bootstrapping';
import { FeatureEnvironments } from '@account/bootstrapping/features/static';
import { accountEnvironmentFlags, toAccountLinkEnv, toAccountApiEnv } from '@account/env';
import { QueryToggleProvider } from '@account/feature-toggles/QueryToggleContext';
import { ApolloProviderV3 } from '@account/graphql';
import { NotFoundErrorPage } from '@account/layout';
import { Provider as OptimizelyProvider } from '@account/optimizely';
import { toReactClient } from '@account/optimizely/toReactClient';
import { initDatadogRum } from '@account/reporting/datadogRum';
import PanelManagerProvider from '@acme-ui/global/PanelManagerProvider';
import AsyncFallback from '@ecomm/async-fallback';
import { computeToggles } from '@ecomm/feature-toggle';
import { SimpleErrorPage } from '@ecomm/layout';
import { PageSpinner } from '@ecomm/spinner';
import { getKeys, Vendor } from '@ecomm/vendor-keys';
import {
  CLIENT_CONTEXT as ONEWELLNESS_API_CLIENT,
  OneWellnessClientProvider,
  toOneWellnessClient,
} from '@onewellness/api/client';
import { toLinkEnv as toStudioLinkEnv } from '@studio/env';
import { routes } from './routes';

const CopyXray = asyncComponent(
  () => import('@account/copy-xray/CopyXray' /* webpackChunkName: "CopyXray" */),
);
initDatadogRum();
injectFonts();

// accessibility tool for local development
// if (process.env.NODE_ENV === 'development') {
//   const axe = require('react-axe');
//   axe(React, ReactDOM, 1000);
// }
const APP = 'account';
const envFlags = accountEnvironmentFlags();
const locale = toLocaleFromHostname(window.location.hostname);

// when linking to another app with parallel envs, we use the same mapper as for self
// bc all envs should match except local, which should never be true
// this will default to uatqa if running locally
const nonLocalAppEnv = { ...envFlags, isLocal: false };
const keys = getKeys({ locale, ...envFlags });

const toErrorEnv = () => {
  if (envFlags.isProd) {
    return 'production';
  } else if (envFlags.isLocal) {
    return 'local';
  }

  return 'uat';
};

const errorHandler = configureErrorHandler(
  [
    ...(envFlags.isProd ? [] : [consoleErrorReporter]),
    ...(envFlags.isLocal ? [] : [newRelicErrorReporter]),
  ],
  { environment: toErrorEnv(), release: process.env.COMMIT_REF },
);

const apiEnv = toAccountApiEnv();
const env = {
  extLink: toExtLinkEnv({
    www: toLinkEnv(nonLocalAppEnv),
    ecomm: toLinkEnv(nonLocalAppEnv),
    digital: toDigitalLinkEnv(envFlags, apiEnv),
    api: apiEnv,
    preorder: toPreorderLinkEnv(nonLocalAppEnv),
    studio: toStudioLinkEnv(nonLocalAppEnv),
    account: toAccountLinkEnv(nonLocalAppEnv),
    auth: toAuthLinkEnv(nonLocalAppEnv),
  }),
};
const client = toClient(apiEnv);
const oneWellnessClient = toOneWellnessClient(apiEnv, envFlags);

loadAnalytics({ key: keys.segment }, makeAnalytics());

const isDark = !(envFlags.isProd || envFlags.isStaging);
const isLocal = envFlags.isLocal;

const computedToggles = computeToggles(features[locale], isDark, isLocal);
const optimizelyReactClient = toReactClient(
  isLocal
    ? FeatureEnvironments.Dev
    : isDark
    ? FeatureEnvironments.UAT
    : FeatureEnvironments.Production,
);
const reduxStore = toStore({
  initialState: {
    env: {
      flags: envFlags,
      locale,
      appName: APP,
    },
    extLinkEnv: env.extLink,
    vendorKeys: {
      [Vendor.Drift]: keys.drift,
      [Vendor.StripeApi]: keys.stripeApi,
      [Vendor.StripeAccount]: keys.stripeAccount,
      [Vendor.Recaptcha]: keys.recaptchaApi,
    },
    toggles: {
      toggles: {
        ...computedToggles,
      },
    },
  },
  reducers: toStoreReducers(),
  sagas: toStoreSagas(),
  context: {
    [API_CLIENT]: client,
    [ONEWELLNESS_API_CLIENT]: oneWellnessClient,
    [ERROR_REPORTING_CLIENT_CONTEXT]: errorHandler.reportError,
    [USER_MAPPER_CONTEXT]: userMapper,
  },
  onError: errorHandler.sagaOnError,
});

declare global {
  interface Window {
    CFXHOME: {
      getAccessToken: () => string;
    };
  }
  interface Crypto {
    randomUUID(): string;
  }
}
// force build account for apollo 3 again again
export const App: React.FC<React.PropsWithChildren<unknown>> = () => (
  <ErrorBoundary
    renderError={() => (
      <>
        <GlobalStyles />
        <SimpleErrorPage />
      </>
    )}
    reportError={errorHandler.reportError}
  >
    <ErrorReporterContext.Provider value={{ errorReporter: errorHandler }}>
      <AsyncFallback
        LoadingFallback={<PageSpinner />}
        ErrorFallback={<SimpleErrorPage />}
      >
        <GlobalStyles />
        <OneTrustStyles />
        <LocaleProvider envLocale={locale}>
          <Internationalize>
            <OptimizelyProvider optimizelyClient={optimizelyReactClient} locale={locale}>
              <QueryToggleProvider
                isLit={envFlags.isStaging}
                isProd={envFlags.isProd}
                browserLocationSearch={window.location.search}
              >
                <ReduxProvider store={reduxStore}>
                  <Router history={history}>
                    <ExtLinkEnvProvider extLinkEnv={env.extLink}>
                      <OauthProvider apiEnv={apiEnv}>
                        <ClientProvider client={client}>
                          <ApolloProviderV3 env={env.extLink} name="account">
                            <OneWellnessClientProvider client={oneWellnessClient}>
                              <AuthUser>
                                <AppTrackingProvider>
                                  <MaybePanelManagerProvider>
                                    <ErrorBoundary
                                      reportError={errorHandler.reportError}
                                      renderError={() => <SimpleErrorPage />}
                                    >
                                      {process.env.ALLOW_COPY_XRAY && <CopyXray />}
                                      <div id="post-order-banner"></div>
                                      <LogoutCookieProvider>
                                        <Switch>
                                          {...routes}
                                          <NotFoundErrorPage />
                                        </Switch>
                                      </LogoutCookieProvider>
                                    </ErrorBoundary>
                                    <FeatureTogglePanel />
                                  </MaybePanelManagerProvider>
                                </AppTrackingProvider>
                              </AuthUser>
                            </OneWellnessClientProvider>
                          </ApolloProviderV3>
                        </ClientProvider>
                      </OauthProvider>
                    </ExtLinkEnvProvider>
                  </Router>
                </ReduxProvider>
              </QueryToggleProvider>
            </OptimizelyProvider>
          </Internationalize>
        </LocaleProvider>
      </AsyncFallback>
    </ErrorReporterContext.Provider>
  </ErrorBoundary>
);

const MaybePanelManagerProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  if (!envFlags.isProd) {
    return <PanelManagerProvider>{children}</PanelManagerProvider>;
  }
  return <>{children}</>;
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

if (process.env.NODE_ENV !== 'test') {
  root.render(<App />);
}

reportFocusMethod(document);
