import { reportNewRelicEvent } from '@peloton/newrelic';
import type { AnalyticsEvent } from './events';
import { ErrorEvents, CancellationEvent, HappyPathEvents } from './events';

// Monitoring events
enum MonitoringEventName {
  OXEvent = 'OX-EVENT',
  OXError = 'OX-ERROR',
}

export interface ExperimentProperties {
  experimentName: string | undefined;
  variationName: string | undefined;
}

// TODO: Add full list of monitoring events once we confirm this works
const monitoringEvents: AnalyticsEvent[] = [
  CancellationEvent.OrderStatusViewed,
  ErrorEvents.AccountAppErrorComponentRendered,
  ErrorEvents.AccountAppNoSlotsErrorComponentRendered,
  ErrorEvents.AccountAppPPFErrorComponentRendered,
  ErrorEvents.AccountAppSubmitDeliveryPreferenceError,
  HappyPathEvents.OrderPlaced,
  HappyPathEvents.PurchaserViewedPPS,
  HappyPathEvents.PurchaserSubmittedPPS,
  HappyPathEvents.OrderTransactionCompleted,
  HappyPathEvents.AccountAppSkipScheduling,
  HappyPathEvents.ClickedReimagineTheWaitCTA,
  HappyPathEvents.AccountUpsellBannerViewed,
  HappyPathEvents.AccountUpsellBannerClicked,
  HappyPathEvents.ExperimentReceived,
];

export const monitorTrackingEvent = (event: AnalyticsEvent) => {
  let newRelicEventName: MonitoringEventName = MonitoringEventName.OXEvent;
  if (Object.values(ErrorEvents).includes(event as ErrorEvents)) {
    newRelicEventName = MonitoringEventName.OXError;
  }

  if (!monitoringEvents.includes(event)) {
    return;
  }

  const pageName = getPageName();
  const orderId = getOrderId();
  const timing = getTimings(event);

  const eventAttr = {
    hostname: window?.location?.hostname,
    description: event,
    orderId,
    pageName,
    ...timing,
  };

  try {
    reportNewRelicEvent(
      newRelicEventName,
      eventAttr as Record<string, string | number>,
      100,
    );
  } catch (e) {
    console.error(`Error sending New Relic event: ${event}`);
  }
};

export const monitorExperimentDistribution = (properties: ExperimentProperties) => {
  if (!properties.experimentName && !properties.variationName) {
    return;
  }

  const pageName = getPageName();

  const eventAttr = {
    description: `${HappyPathEvents.ExperimentReceived} for ${properties.experimentName}`,
    experimentName: properties.experimentName,
    variationName: properties.variationName,
    pageName,
  };

  try {
    reportNewRelicEvent(
      MonitoringEventName.OXEvent,
      eventAttr as Record<string, string | number>,
      100,
    );
  } catch (e) {
    console.error(`Error sending New Relic event: ${event}`);
  }
};

type AnalyticsPageName =
  | '/delivery/*/schedule'
  | '/delivery/*/reschedule'
  | '/delivery/*/status'
  | '/delivery/*/rebate'
  | '/delivery/*'
  | '/orderhistory'
  | '/orderhistory/*';

const getPageName = (): AnalyticsPageName | string => {
  const url = window?.location?.pathname;

  if (url.includes('delivery') && url.includes('schedule')) {
    return '/delivery/*/schedule';
  } else if (url.includes('delivery') && url.includes('reschedule')) {
    return '/delivery/*/reschedule';
  } else if (url.includes('delivery') && url.includes('status')) {
    return '/delivery/*/status';
  } else if (url.includes('delivery') && url.includes('rebate')) {
    return '/delivery/*/rebate';
  } else if (url.includes('delivery')) {
    return '/delivery/*';
  } else if (url.includes('orderhistory') && url.split('/')[2]) {
    return '/orderhistory/*';
  } else if (url.includes('orderhistory')) {
    return '/orderhistory';
  } else {
    return url;
  }
};

const getOrderId = (): string => {
  const url = window?.location?.pathname;

  if (!url) {
    return '';
  }

  return url.split('/')[2]?.replace('-', '');
};

type Timing = {
  ppfElapsed?: number;
};
const getTimings = (event: AnalyticsEvent): Timing | void => {
  if (sessionStorage.getItem(event)) {
    return;
  }

  sessionStorage.setItem(event, 'sent');

  const current = Date.now();
  if (event === HappyPathEvents.OrderPlaced) {
    sessionStorage.setItem('ppfStart', current.toString());
  }

  const ppfStartTimeStr = sessionStorage.getItem('ppfStart');

  if (!ppfStartTimeStr) {
    return;
  }

  const ppfStartTime = parseInt(ppfStartTimeStr);
  return {
    ppfElapsed: current - ppfStartTime,
  };
};
