export enum CoveredType {
  bike = 'bike',
  bikeRefurb = 'bike_refurb',
  bikePlus = 'bike_plus',
  bikePlusRefurb = 'bike_plus_refurb',
  device = 'device',
  digital = 'digital',
  digitalBasic = 'digital_basic',
  digitalPlus = 'digital_plus',
  // Fitness as a Service, i.e., One Peloton Club (OPC)
  faasDevice = 'faas-device',
  // Guide Hardware
  guide = 'guide',
  // Guide subscription
  introductoryDevice = 'introductory-device',
  tread = 'tread',
  treadPlus = 'tread_plus',
  row = 'row',
  // Hardware Rentals a.k.a. 'FaaS'
  bikeFaas = 'faas-bike',
  bikePlusFaas = 'faas-bike-plus',
}

export enum BaseProduct {
  digital = 'digital',
  bike = 'bike',
  bikePlus = 'bike_plus',
  guide = 'guide',
  row = 'row',
  tread = 'tread',
}

export enum BenefitCategory {
  hardware = 'hardware',
  subscription = 'subscription',
  rental = 'rental',
  refurb = 'refurb',
}

export const coveredTypeIsDigital = (coveredType: CoveredType) => {
  const digitalTypes: Set<CoveredType> = new Set([
    CoveredType.digital,
    CoveredType.digitalBasic,
    CoveredType.digitalPlus,
  ]);

  return digitalTypes.has(coveredType);
};

export const isFullySubsidized = (programBenefit: ProgramBenefit): boolean =>
  programBenefit.userPays === 0;

export const programBenefitCoversAam = (programBenefit: ProgramBenefit): boolean =>
  programBenefit.subscriptionType === CoveredType.device;

type Program = {
  onboardingPathSegment: string;
  discountCode?: string;
};

type Benefit = {
  baseProduct: BaseProduct;
  category: BenefitCategory;
};

export type ProgramBenefit = {
  couponCode?: string;
  formattedRetailRate: string;
  formattedUserPays: string;
  retailRate: number;
  subscriptionType: CoveredType;
  userPays: number;
  program: Program;
  benefit: Benefit;
};

export type Benefits = { getBenefitsByMemberId: ProgramBenefit[] };
