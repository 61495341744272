import type { Country } from '@peloton/internationalize';
import { reportNewRelicEvent } from '@peloton/newrelic';

export enum UpdatePaymentStage {
  Attempt = 'attempt',
  Success = 'success',
  Failure = 'failure',
}

type UpdatePaymentAttributes = {
  source: string;
  country: Country;
  stage: UpdatePaymentStage;
  errorMessage?: string;
  userId?: string;
  setupIntentId?: string;
};
export const reportUpdatePayment = (attr: UpdatePaymentAttributes) => {
  const eventAttr = {
    ...attr,
    hostname: window.location.hostname,
  };
  reportNewRelicEvent(
    'Update Payment Method',
    eventAttr as Record<string, string | number>,
    100,
  );
};
