import type { UpdateAddressErrorAction as TUpdateAddressErrorAction } from './address';
import {
  blurAddressField,
  toggleAddressAgreement,
  updateAddressField,
  resetAddressField,
  updateBillingAddress,
  updateShippingAddress,
} from './address';
import { attributionSet } from './attribution';
import { updateBillingInfo } from './billing';
import { updateSkipFreeTrialPath } from './digital';
import {
  freeTrialEligibilitySet,
  reset as resetFreeTrialEligibility,
} from './freeTrialEligibility';
import type {
  UpdatePaymentErrorAction as TUpdatePaymentErrorAction,
  SelectPaymentMethodAction as TSelectPaymentMethodAction,
} from './payment';
import {
  ActionTypes as PaymentActions,
  blurPaymentField,
  reset as resetPayment,
  selectPaymentMethod,
  updatePaymentError,
  updatePaymentField,
} from './payment';
import type { UpdatePromoFieldAction as TUpdatePromoFieldAction } from './promo';
import {
  ActionTypes as PromoActionTypes,
  applyPromo,
  applyPromoSuccess,
  applyPromoFailure,
  blurPromoField,
  updatePromoField,
} from './promo';
import {
  ActionTypes as ReferrerActionTypes,
  referrerRequest,
  referrerRequestSuccess,
  referrerRequestFailure,
} from './referrer';
import type { ReviewOrderPayload as TReviewOrderPayload } from './reviewOrder';
import {
  Actions as ReviewActions,
  exceptionSelector as reviewOrderExceptionSelector,
  loadingSelector as reviewOrderLoadingSelector,
  reviewOrder,
  reviewWalletOrder,
  reviewOrderFailure,
  reviewOrderSuccess,
  reviewOrderClearError,
} from './reviewOrder';
import type { ReducerState as TReducerState } from './rootReducer';
import reducer from './rootReducer';
import {
  getCheckoutCardName,
  getAcceptedTerms,
  getAcceptedLeaseAgreement,
  getAddressFieldError,
  getAttribution,
  getCheckoutAddress,
  getCheckoutAddressErrors,
  getCheckoutAddressField,
  getCheckoutUIState,
  getDoesUserExist,
  getFreeTrialEligibility,
  getMarketingPreference,
  getIsPromoApplied,
  getPaymentField,
  getPaymentFieldError,
  getPaymentTokenData,
  getPromoError,
  getPromoField,
  getSelectedPaymentMethod,
  getSelectedFinancingPartner,
  getShippingUIState,
  getSkipFreeTrialPath,
  getUserEmail,
  getUserField,
  getUserFieldError,
  getUser,
  getVerification,
  hasAcceptedTermsError,
  hasCheckoutErrors,
  hasCheckoutWithNoPaymentErrors,
  hasUserErrors,
  hasUserEmailErrors,
  matchCheckoutReviewRoute,
  matchCheckoutRoute,
  selectCheckout,
  selectCheckoutBilling,
  selectCheckoutReviewOrder,
  selectCheckoutSubmitOrder,
} from './selectors';
import {
  Actions as ShippingActions,
  updateShipping,
  updateShippingFailure,
  updateShippingInvalid,
  updateShippingSuccess,
} from './shipping';
import type { SubmitOrder as TSubmitOrder } from './submitOrder';
import {
  Actions as SubmitOrderActions,
  submitOrder,
  exceptionSelector as submitOrderExceptionSelector,
  loadingSelector as submitOrderLoadingSelector,
} from './submitOrder';
import type { CheckoutReadyAction as TCheckoutReadyAction } from './ui';
import {
  ActionType as UiActionType,
  checkoutLoadingFailed,
  checkoutReady,
  loadCheckout,
} from './ui';
import type {
  DoesNotExistAction as TDoesNotExistAction,
  EnteredEmailAction as TEnteredEmailAction,
  ExistsAction as TExistsAction,
} from './user';
import {
  ActionType as UserActions,
  blurUserField,
  enterEmail,
  resolveDefaultMarketingState,
  updateEmail,
  updateUserField,
} from './user';
import { verificationSetId, verificationSetSegment } from './verification';

export default reducer;

export {
  attributionSet,
  applyPromo,
  applyPromoSuccess,
  applyPromoFailure,
  blurAddressField,
  blurPaymentField,
  blurPromoField,
  blurUserField,
  enterEmail,
  checkoutLoadingFailed,
  checkoutReady,
  freeTrialEligibilitySet,
  getCheckoutCardName,
  getAcceptedTerms,
  getAcceptedLeaseAgreement,
  getAddressFieldError,
  getAttribution,
  getCheckoutAddress,
  getCheckoutAddressErrors,
  getCheckoutAddressField,
  getCheckoutUIState,
  getDoesUserExist,
  getFreeTrialEligibility,
  getIsPromoApplied,
  getMarketingPreference,
  getPaymentField,
  getPaymentFieldError,
  getPaymentTokenData,
  getPromoError,
  getPromoField,
  getSelectedPaymentMethod,
  getSelectedFinancingPartner,
  getShippingUIState,
  getSkipFreeTrialPath,
  getUserEmail,
  getUserField,
  getUserFieldError,
  getUser,
  getVerification,
  hasAcceptedTermsError,
  hasCheckoutErrors,
  hasCheckoutWithNoPaymentErrors,
  hasUserErrors,
  hasUserEmailErrors,
  loadCheckout,
  matchCheckoutReviewRoute,
  matchCheckoutRoute,
  PaymentActions,
  PromoActionTypes,
  resetFreeTrialEligibility,
  resetPayment,
  resolveDefaultMarketingState,
  ReviewActions,
  reviewOrder,
  reviewWalletOrder,
  reviewOrderExceptionSelector,
  reviewOrderFailure,
  reviewOrderLoadingSelector,
  reviewOrderSuccess,
  selectCheckout,
  selectCheckoutBilling,
  selectCheckoutReviewOrder,
  selectCheckoutSubmitOrder,
  selectPaymentMethod,
  ShippingActions,
  submitOrder,
  SubmitOrderActions,
  submitOrderExceptionSelector,
  submitOrderLoadingSelector,
  toggleAddressAgreement,
  UiActionType,
  updateAddressField,
  resetAddressField,
  updateBillingAddress,
  updateBillingInfo,
  updateEmail,
  updatePaymentError,
  updatePaymentField,
  updatePromoField,
  updateSkipFreeTrialPath,
  updateShipping,
  updateShippingAddress,
  updateShippingFailure,
  updateShippingInvalid,
  updateShippingSuccess,
  updateUserField,
  UserActions,
  verificationSetId,
  verificationSetSegment,
  ReferrerActionTypes,
  referrerRequest,
  referrerRequestSuccess,
  referrerRequestFailure,
  reviewOrderClearError,
};

export type CheckoutReadyAction = TCheckoutReadyAction;
export type DoesNotExistAction = TDoesNotExistAction;
export type EnteredEmailAction = TEnteredEmailAction;
export type ExistsAction = TExistsAction;
export type ReducerState = TReducerState;
export type ReviewOrderPayload = TReviewOrderPayload;
export type SelectPaymentMethodAction = TSelectPaymentMethodAction;
export type SubmitOrder = TSubmitOrder;
export type UpdateAddressErrorAction = TUpdateAddressErrorAction;
export type UpdatePaymentErrorAction = TUpdatePaymentErrorAction;
export type UpdatePromoFieldAction = TUpdatePromoFieldAction;
