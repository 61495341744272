import type { OauthProviderProps } from '@peloton/auth';
import { OauthEnvironment, apiEnvToOauthEnv } from '@peloton/env/oauthEnv';
import type { ApiEnvs } from '@peloton/external-links/models';
import { toOauthConfig } from './oauthEnv';

const Auth0EnvConfigs = Object.entries(apiEnvToOauthEnv).reduce(
  (auth0EnvConfig, [apiEnv, oauthEnv]) => ({
    ...auth0EnvConfig,
    [apiEnv]: toOauthConfig(oauthEnv),
  }),
  {} as Partial<Record<ApiEnvs, OauthProviderProps>>,
);

export const toAuth0Config = (
  apiEnv: ApiEnvs,
  oauthEnv?: OauthEnvironment,
): OauthProviderProps => {
  if (oauthEnv) {
    return toOauthConfig(oauthEnv);
  }

  return Auth0EnvConfigs[apiEnv] ?? toOauthConfig(OauthEnvironment.Stage);
};
