import { takeEvery } from 'redux-saga/effects';
import { CancellationPageEvent } from '@account/analytics';
import type { TrackOfferClickAction, TrackRefinementClickAction } from './redux';
import { CancellationActionType } from './redux';

export const offerClickSaga = function* (action: TrackOfferClickAction) {
  action.track({
    event: CancellationPageEvent.OfferClick,
    instanceProperties: action.payload,
  });
};

export const refinementClickSaga = function* (action: TrackRefinementClickAction) {
  action.track({
    event: CancellationPageEvent.RefinementClick,
    instanceProperties: action.payload,
  });
};

const cancellationSaga = function* () {
  yield takeEvery(CancellationActionType.TrackOfferClick, offerClickSaga);
  yield takeEvery(CancellationActionType.TrackRefinementClick, refinementClickSaga);
};

export default cancellationSaga;
