import type { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import React, { useEffect } from 'react';
import type { Locale } from '@peloton/internationalize';
import { toClient } from './client';
import { CopyQuery } from './documents.generated';
import { reportCopyFetch, CopyFetchStatus } from './reporting';

export const useCopyClient = (locale: Locale, isPreview = false) => {
  const [client, setClient] = React.useState<ApolloClient<NormalizedCacheObject>>();

  useEffect(() => {
    (async () => {
      const asyncClient = await toClient(isPreview, locale);
      // This ts-ignore is due to Apollo 3 upgrades
      // eslint-disable-next-line @typescript-eslint/prefer-ts-expect-error
      // @ts-ignore
      setClient(asyncClient);
    })();
  }, [isPreview, locale]);

  useEffect(() => {
    if (client && isPreview) {
      client
        .query({
          query: CopyQuery,
          variables: {
            locale,
            preview: isPreview,
          },
        })
        .catch((errorMessage: string) => {
          reportCopyFetch({
            status: CopyFetchStatus.Failure,
            fileName: `${locale}.generated.json`,
            errorMessage,
          });
        });
    }
  }, [client, locale, isPreview]);

  return client;
};
