import type { ApiMeUser } from '@peloton/auth';
import type { AccountUser } from './models';

const mapper = ({
  hasActiveDeviceSubscription,
  hasActiveDigitalSubscription,
}: ApiMeUser): AccountUser => ({
  hasDeviceSubscription: hasActiveDeviceSubscription,
  hasDigitalSubscription: hasActiveDigitalSubscription,
});

export default mapper;
