import React from 'react';
import { asyncComponent } from '@peloton/code-splitting';
import { FEATURES, RouteScopedToUser } from '@account/optimizely';
import { MYMEMBERSHIP_ROUTE } from './urls';

const Routes = [
  <RouteScopedToUser
    feature={FEATURES.IsMembershipsPageActive}
    key={MYMEMBERSHIP_ROUTE}
    path={MYMEMBERSHIP_ROUTE}
    component={asyncComponent(
      () => import('./Page' /* webpackChunkName: "MyMembershipPage" */),
    )}
  />,
];

export default Routes;
