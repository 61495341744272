import type { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';
import type { Client } from '@peloton/api';
import { toSkipErrorHandlingConfig } from '@peloton/api';
import { track } from '@engage/analytics';
import { toRemoveSharedUserEvent, toAddSharedUserEvent } from '../analytics';
import {
  getAddUserToSubscriptionApiBasedOnKind,
  getRemoveUserFromSubscriptionApiBasedOnKind,
} from '../api';
import type { addSharedUser, removeSharedUser } from '../redux';
import { addSharedUserSuccess, removeSharedUserSuccess } from '../redux';
import { getSubscription } from '../selectors';

export const addUserSaga = function* (
  client: Client,
  action: ReturnType<typeof addSharedUser>,
): SagaIterator {
  try {
    const addUserRequest = getAddUserToSubscriptionApiBasedOnKind(action.payload.subKind);
    const response = yield call(
      addUserRequest,
      client,
      action.payload.subId,
      action.payload.user,
      toSkipErrorHandlingConfig(),
    );
    yield put(addSharedUserSuccess(action.payload.subId, response.data.addedUser));
    action.payload.callbacks.onSuccess();
    const sub = yield select(getSubscription, action.payload.subId);
    yield put(track(toAddSharedUserEvent(response.data.addedUser.id, sub)));
  } catch (e) {
    action.payload.callbacks.onError(e);
  }
};

export const removeUserSaga = function* (
  client: Client,
  action: ReturnType<typeof removeSharedUser>,
): SagaIterator {
  try {
    yield call(
      getRemoveUserFromSubscriptionApiBasedOnKind(action.payload.subKind),
      client,
      action.payload,
    );
    yield put(removeSharedUserSuccess(action.payload.subId, action.payload.userId));
    action.payload.callbacks.onSuccess();
    const sub = yield select(getSubscription, action.payload.subId);
    yield put(track(toRemoveSharedUserEvent(action.payload.userId, sub)));
  } catch (e) {
    action.payload.callbacks.onError(e);
  }
};
