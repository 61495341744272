import type { SagaIterator } from 'redux-saga';
import { takeEvery, getContext, spawn, throttle, takeLeading } from 'redux-saga/effects';
import { CLIENT_CONTEXT } from '@peloton/api';
import {
  fetcherSaga as subscriptionPlansFetcherSaga,
  PlanType,
} from '@peloton/subscription-plans';
import { SubscriptionsActionType } from '@engage/subscriptions/redux';
import { SubscriptionUpdatesActionType } from '@engage/subscriptions/redux/updates';
import { addUserSaga, removeUserSaga } from '@engage/subscriptions/sagas';
import detachSubscription from '@engage/subscriptions/sagas/detachSubscription';
import { default as loadSubscription } from '@engage/subscriptions/sagas/loadSubscription';
import loadSubscriptions from '@engage/subscriptions/sagas/loadSubscriptions';
import reactivateSubscription from '@engage/subscriptions/sagas/reactivateSubscription';
import unpauseSubscription from '@engage/subscriptions/sagas/unpauseSubscription';
import { CardsActionType, TransactionsActionType } from '../redux';
import { BasicInfoActionType } from '../redux/basicInfo';
import { PasswordChangeActionType } from '../redux/passwordChange';
import cancelSubscription from './cancelSubscription';
import loadBasicInfo from './loadBasicInfo';
import loadCards from './loadCards';
import loadTransactions from './loadTransactions';
import { Actions as StatusActions, reactivateSaga } from './statusSagas';
import { subscriptionLoaded } from './subscriptionLoaded';
import updateBasicInfo from './updateBasicInfo';
import updatePassword from './updatePassword';

const myMembershipSaga = function* (): SagaIterator {
  const client = yield getContext(CLIENT_CONTEXT);

  // import { subscriptionSaga} from '@engage/subscriptions/sagas/index.ts';
  yield takeEvery(SubscriptionsActionType.AddSharedUserRequest, addUserSaga, client);
  yield takeEvery(
    SubscriptionsActionType.RemoveSharedUserRequest,
    removeUserSaga,
    client,
  );
  yield takeEvery(SubscriptionsActionType.DetachSubRequest, detachSubscription, client);
  yield takeEvery(SubscriptionsActionType.SubsRequest, loadSubscriptions, client);
  yield takeEvery(SubscriptionsActionType.SubDetailsRequest, loadSubscription, client);
  yield takeEvery(
    SubscriptionUpdatesActionType.ReactivateRequest,
    reactivateSubscription,
    client,
  );
  yield takeLeading(
    SubscriptionsActionType.UnpauseSubscriptionRequest,
    unpauseSubscription,
    client,
  );
  yield spawn(subscriptionPlansFetcherSaga, PlanType.Digital);
  yield takeEvery(BasicInfoActionType.BasicInfoRequest, loadBasicInfo, client);
  yield takeEvery(BasicInfoActionType.BasicInfoUpdate, updateBasicInfo, client);
  yield takeEvery(CardsActionType.Request, loadCards, client);
  yield takeEvery(PasswordChangeActionType.PasswordChange, updatePassword, client);
  yield takeEvery(TransactionsActionType.Request, loadTransactions, client);
  yield takeEvery(
    SubscriptionUpdatesActionType.SubsDigCancelRequest,
    cancelSubscription,
    client,
  );
  yield takeEvery(SubscriptionsActionType.SubsRequestSuccess, subscriptionLoaded);
  yield takeEvery(SubscriptionsActionType.SubsRequestFailure, subscriptionLoaded);
  // throttle 8 seconds bc stripe can take a while to charge
  // and if we reactivate again before it is done charging, it will charge twice
  yield throttle(8000, StatusActions.Reactivate, reactivateSaga);
};

export default myMembershipSaga;
