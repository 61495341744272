import { pipe, reject, filter, isNil, sort } from 'ramda';
import type { Client } from '@peloton/api';
import { pipeData, toSkipErrorHandlingConfig } from '@peloton/api';
import type { Currency } from '@peloton/internationalize/models/currency';
import { toMoney, toLocaleFromCurrency } from '@peloton/internationalize/models/currency';
import { toTime } from '@peloton/time';
import type {
  SimpleCard,
  StandardTransaction,
  Transaction,
} from '@account/pg-my-membership/models/Transaction';
import { subscriptionTransTaxTotal } from '@account/pg-my-membership/models/Transaction';

const fetchSubscriptionTransactions = (
  api: Client,
  userId: string,
  subId: string,
): Promise<Transaction[]> =>
  api
    .get(toUrl(userId, subId), toSkipErrorHandlingConfig())
    .then(pipeData(pipeData(toTransactions)));

const toUrl = (userId: string, subId: string, limit: number = 12) =>
  `/api/user/${userId}/subscription/${subId}/subscription_transactions?joins=transaction,transactions&limit=${limit}`;

const toTransaction = (data: ApiTransaction): Transaction | null => {
  const base = {
    id: data.id,
    createdAt: toTime(data.createdAt),
    monthsPaid: toMonthsPaid(data.billingPeriod),
  };
  if (data.transactions?.length) {
    const creditCardTransaction = data.transactions.find(trans => Boolean(trans.card));
    if (creditCardTransaction) {
      const allTransactions = data.transactions.map(trans => {
        return {
          subTotal: toMoney(trans.amountInCents, trans.currency),
          tax: toMoney(trans.taxInCents, trans.currency),
          billingPartner: trans.billingPartner,
        } as StandardTransaction;
      });
      const currency = creditCardTransaction.currency;
      return {
        ...base,
        subTotal: toMoney(data.amountInCents, currency),
        currency: currency,
        card: creditCardTransaction?.card,
        tax: subscriptionTransTaxTotal(allTransactions, currency),
        locale: toLocaleFromCurrency(currency),
        standardTransactions: allTransactions,
      } as Transaction;
    }
  } else if (data.transaction) {
    return {
      ...base,
      subTotal: toMoney(data.amountInCents, data.transaction.currency),
      currency: data.transaction.currency,
      card: data.transaction.card,
      tax: toMoney(data.transaction.taxInCents, data.transaction.currency),
      locale: toLocaleFromCurrency(data.transaction.currency!),
      standardTransactions: [],
    } as Transaction;
  }
  return null;
};

const toMonthsPaid = (period: number[]) =>
  !Array.isArray(period) || period.length < 2
    ? 1
    : Math.round(toTime(period[1]).diff(toTime(period[0]), 'months', true));

const onlySuccessful = (apiTransaction: ApiTransaction) => {
  const notFailed = apiTransaction.paymentStatus !== 'failed';
  const hasTransactionWithCard = !!apiTransaction.transaction?.card;
  const hasCorpWellnessTransaction =
    Boolean(apiTransaction.transactions?.length) &&
    Boolean(apiTransaction.transactions?.find(t => t.card));

  return notFailed && (hasTransactionWithCard || hasCorpWellnessTransaction);
};

// Shouldn't need to explicitly call `valueOf`. Unfortunately typescript only expects numbers or enum.
const byDate = (
  { createdAt: createdAtA }: Transaction,
  { createdAt: createdAtB }: Transaction,
) => Math.sign(createdAtB.valueOf() - createdAtA.valueOf());

const toTransactions = pipe<
  ApiTransaction[],
  ApiTransaction[],
  (Transaction | null)[],
  Transaction[],
  Transaction[]
>(filter(onlySuccessful), arr => arr.map(toTransaction), reject(isNil), sort(byDate));

type ApiTransaction = {
  amountInCents: number;
  id: string;
  billingPeriod: number[];
  createdAt: number;
  paymentStatus: string;
  transaction?: {
    amountInCents: number;
    card: SimpleCard;
    currency: Currency;
    taxInCents: number;
  };
  transactions?: {
    amountInCents: number;
    billingPartner: string;
    card?: SimpleCard;
    createdAt: number;
    currency: Currency;
    label?: string;
    taxInCents: number;
  }[];
};

export { fetchSubscriptionTransactions, toTransaction, toTransactions };
