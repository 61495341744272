import type { Client } from '@peloton/api';
import { toData } from '@peloton/api';
import type { ReportError } from '@peloton/error-reporting';
import { toCard } from '@account/api/cards';
import type { Card } from '@account/api/models/Card';
import { PaymentType } from '@account/api/models/PaymentMethod';
import { BillingPartner } from '@account/order/PaymentDetails/types';

const paymentUrl = '/ecomm/payment_methods';

export const addGiftCardPayment = async (
  client: Client,
  paymentToken: string,
  reportError: ReportError,
) => {
  try {
    const response = await client.post(paymentUrl, {
      paymentType: PaymentType.GiftCard,
      billingPartner: BillingPartner.Fiserv,
      paymentToken,
    });

    return toData(response);
  } catch (error) {
    reportError(new Error('Gift Card | Add payment method is not completed'));

    return error;
  }
};

export const deletePaymentMethod = async (
  apiClient: Client,
  token: string,
  reportError: ReportError,
) => {
  try {
    const response = await apiClient.delete(`${paymentUrl}/${token}`);

    return response;
  } catch (error) {
    reportError(new Error('Gift Card | Error Removing Gift Card'));

    return error;
  }
};

export const getPaymentMethods = async (
  client: Client,
  reportError?: ReportError,
): Promise<Card[]> => {
  try {
    const response = await client.get(paymentUrl);
    const paymentMethods = toData(response)?.paymentMethods;

    return paymentMethods?.map(toCard) || [];
  } catch (error) {
    if (reportError) {
      reportError(new Error('Gift Card | Get payment methods'));
    }

    throw error;
  }
};
