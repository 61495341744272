import type { AxiosError } from 'axios';
import type { Client } from '@peloton/api';
import { pipeError, throwError, pipeDataTwice } from '@peloton/api';
import { toStoreSlug } from '@peloton/stores';
import type { ErrorCode } from '@ecomm/checkout/models';
import { toErrorCode } from '@ecomm/checkout/models';
import type { SubscriptionChangeCheckoutData } from './models';

const identity = <T>(a: T) => a;

export const toSubscriptionTierCheckoutUrl = () =>
  `/ecomm/store/${toStoreSlug()}/subscription_change_checkout`;

export const subscriptionTierCheckout = (
  api: Client,
  data: SubscriptionChangeCheckoutData,
) =>
  api
    .post(toSubscriptionTierCheckoutUrl(), data)
    .then(pipeDataTwice(identity))
    .catch(pipeError(throwError<ErrorCode>(toErrorCode)));

export const responseToErrorCode = (response: AxiosError) =>
  pipeError(throwError<ErrorCode>(toErrorCode))(response);
