import { identity } from 'ramda';
import type { Client } from '@peloton/api';
import { pipeData, toSkipErrorHandlingConfig } from '@peloton/api';
import { isDefined } from '@peloton/types';
import type { Subscription, DeviceType } from '../Subscription';
import { Platform, isDeviceSub, isDigitalSub } from '../Subscription';
import { toDeviceSubscription, toDigitalSubscription } from './subscriptions';

// API requires that the platform that attached, detaches! To get around this on web,
// We need to act like we need to fake our request is from the same platform.
// TODO: Work with API to remove this restriction.

export const detachSubscription = (
  api: Client,
  userId: string,
  subId: string,
  deviceType: DeviceType,
) =>
  api
    .put(`api/user/${userId}/subscription/${subId}/detach_device`, undefined, {
      headers: {
        'Peloton-Platform': isDefined(deviceType)
          ? PelotonPlatform.Bike
          : PelotonPlatform.Tread,
      },
    })
    .then(pipeData(toDeviceSubscription(userId)));

export const reactivateSubscription = (
  api: Client,
  userId: string,
  sub: Subscription,
) => {
  if (isDeviceSub(sub)) {
    return reactivateDeviceSubscription(api, userId, sub.id);
  } else if (isDigitalSub(sub) && sub.platform === Platform.Peloton) {
    return reactivateDigitalSubscription(api, sub.id);
  } else {
    throw new Error('Cannot reactivate external subscription through Peloton');
  }
};

export const reactivateDeviceSubscription = (
  api: Client,
  userId: string,
  subId: string,
) =>
  api
    .post(`api/user/${userId}/subscription/${subId}/reactivate`)
    .then(pipeData(toDeviceSubscription));

export const cancelDigitalSubscription = (api: Client, subId: string) =>
  api
    .post(`api/subscription/digital/${subId}/cancel`, {}, toSkipErrorHandlingConfig())
    .then(pipeData(identity));

export const reactivateDigitalSubscription = (api: Client, subId: string) =>
  api
    .post(`api/subscription/digital/${subId}/reactivate`, {}, toSkipErrorHandlingConfig())
    .then(pipeData(toDigitalSubscription));

export const pauseSubscription = (
  api: Client,
  userId: string,
  subId: string,
  monthCount: number,
) =>
  api
    .post(
      `api/subscription/${subId}/pause`,
      { month_count: monthCount },
      toSkipErrorHandlingConfig(),
    )
    .then(pipeData(toDeviceSubscription(userId)));

export const extendPauseSubscription = (
  api: Client,
  userId: string,
  subId: string,
  monthCount: number,
) =>
  api
    .post(
      `api/subscription/${subId}/extend_pause`,
      { month_count: monthCount },
      toSkipErrorHandlingConfig(),
    )
    .then(pipeData(toDeviceSubscription(userId)));

export const unpauseSubscription = (api: Client, userId: string, subId: string) =>
  api
    .post(`api/subscription/${subId}/unpause`, {}, toSkipErrorHandlingConfig())
    .then(pipeData(toDeviceSubscription(userId)));

enum PelotonPlatform {
  Bike = 'home_bike',
  Tread = 'home_tread',
}
