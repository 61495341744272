import type { SagaIterator } from 'redux-saga';
import { call, select, put } from 'redux-saga/effects';
import type { Client } from '@peloton/api';
import { getSignedInUserId } from '@peloton/auth';
import { detachSubscription as detach } from '../api';
import type { DetachSubAction } from '../redux';
import { detachSubscriptionFailure } from '../redux';
import updateSubscriptions from './updateSubscriptions';

const detachSubscription = function* (
  client: Client,
  { payload: { subId, deviceType } }: DetachSubAction,
): SagaIterator {
  try {
    const userId = yield select(getSignedInUserId);
    if (!userId) {
      throw new Error("Can't detach subscription. User is not signed in.");
    }

    const updatedSub = yield call(detach, client, userId, subId, deviceType);
    yield call(updateSubscriptions, updatedSub);
  } catch (e) {
    yield put(detachSubscriptionFailure());
  }
};

export default detachSubscription;
