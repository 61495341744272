import type { AxiosResponse, AxiosRequestConfig } from 'axios';
import type { ClientProvider } from '@peloton/api';
import type { PropsOf } from '@peloton/react';
import getAccessTokenWithBackoff from './getAccessTokenWithBackoff';
import { useOauth } from './OauthProvider';

const isChangePasswordError = (error: any) =>
  error.response.config.url.indexOf('/change_password') !== -1;
const isLoginError = (error: any) => error.response.config.url.indexOf('/login') !== -1;

type Err = {
  response: AxiosResponse;
  config: AxiosRequestConfig & { retries: number };
};

type Client = PropsOf<typeof ClientProvider>['client'];

type ErrHandler = (e: Err) => Promise<Err>;

const backoffStackInSeconds = [1, 5, 30];
const NUMBER_OF_RETRIES = backoffStackInSeconds.length - 1;

let isPending = false;

const useOauthErrorHandler = (
  errorHandler: ErrHandler,
  client: Client,
  shouldEnable: boolean,
) => {
  const { getAccessTokenSilently } = useOauth();

  const getTokenWithRetry = async (
    accessTokenRetry: number,
    error: Err,
  ): Promise<Err | AxiosRequestConfig | undefined> => {
    try {
      // Fetch new acccess token
      await getAccessTokenWithBackoff(
        getAccessTokenSilently,
        backoffStackInSeconds[accessTokenRetry],
      );

      const originalRequestRetries = (error?.config?.retries ?? 0) + 1;

      if (originalRequestRetries > NUMBER_OF_RETRIES) {
        return errorHandler(error);
      }

      if (!!error?.config) {
        error.config.retries = originalRequestRetries;
      }

      isPending = false;

      // Retry the request that failed before with the new access token
      return client?.request(error?.config);
    } catch (e) {
      if (accessTokenRetry < NUMBER_OF_RETRIES) {
        return await getTokenWithRetry(accessTokenRetry + 1, error);
      }

      isPending = false;

      return errorHandler(error);
    }
  };

  return (error: Err) => {
    if (!isPending) {
      if (
        shouldEnable &&
        error?.response?.status === 401 &&
        !isChangePasswordError(error) &&
        !isLoginError(error)
      ) {
        isPending = true;
        // If we have an error with the oauth token
        // We should try to get a new token before logging the user out
        return getTokenWithRetry(0, error);
      }
      return errorHandler(error);
    }
    return Promise.reject();
  };
};

export default useOauthErrorHandler;
