import { toContentfulAccessToken } from '@ecomm/contentful';
import { toClientV3 } from './clientV3';
import { URL, QUERY_TYPENAME_MAP } from './config';
import { CopyQueryDocument } from './hooks.generated';
import data from './static';

export const toClient = async (isPreview: boolean, locale: string) => {
  const copy = await data[locale]();
  return toClientV3({
    token: toContentfulAccessToken('account'),
    url: URL,
    queryMap: QUERY_TYPENAME_MAP,
    queryDocument: CopyQueryDocument,
    queryVariables: {
      preview: isPreview,
      locale: locale,
    },
    hydrateOnLoad: isPreview,
  })({ data: copy.data });
};
