import React from 'react';
import { connect } from 'react-redux';
import type { ResponsiveSelectorState } from '@peloton/responsive';
import { getIsBreakpointAt, getIsBreakpointGreaterThan } from '@peloton/responsive';
import HeaderViewDesktop from './HeaderView.desktop';
import HeaderViewMobile from './HeaderView.mobile';
import type { Props as HeaderViewProps } from './HeaderViewProps';

const mapStateToProps = (state: ResponsiveSelectorState) => ({
  desktop:
    getIsBreakpointAt(state, 'desktop') || getIsBreakpointGreaterThan(state, 'desktop'),
});

export type Props = HeaderViewProps & {
  desktop: boolean;
};

export const ResponsiveHeaderView: React.FC<React.PropsWithChildren<Props>> = ({
  desktop,
  ...props
}) => (desktop ? <HeaderViewDesktop {...props} /> : <HeaderViewMobile {...props} />);

export const ResponsiveHeader = connect(mapStateToProps)(ResponsiveHeaderView);
