import deAT from './de-AT';
import deDE from './de-DE';
import enAU from './en-AU';
import enCA from './en-CA';
import enGB from './en-GB';
import enUS from './en-US';

export default {
  'en-US': enUS,
  'en-GB': enGB,
  'en-CA': enCA,
  'de-DE': deDE,
  'de-AT': deAT,
  'en-AU': enAU,
};
