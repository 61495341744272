import type { Client } from '@peloton/api';
import { pipeData } from '@peloton/api';
import { toTimeWith } from '@peloton/time';
import type { Card } from '@account/api/models/Card';
import type { PaymentType } from '@account/api/models/PaymentMethod';
import type { Currency, Money } from '@account/graphql/types.generated';

const CARD_URL = 'ecomm/card';

const fetchCards = (api: Client) =>
  api.get(CARD_URL).then(pipeData(pipeData((arr: any[]) => arr.map(toCard)))) as Promise<
    Card[]
  >;

const toCardBalance = (balance: ApiCard['balance']): Money | undefined =>
  balance && balance.amountInCents !== null && balance.currency !== null
    ? { amount: balance.amountInCents, currency: balance.currency as Currency }
    : undefined;

const toCard = (apiCard: ApiCard): Card => ({
  name: apiCard.name,
  postalCode: apiCard.addressZip,
  type: apiCard.cardType,
  paymentType: apiCard.paymentType,
  paymentMethodId: apiCard.paymentMethodId,
  fingerprint: apiCard.fingerprint,
  expiresAt: toTimeWith({ month: apiCard.expMonth - 1, year: apiCard.expYear }),
  isDefault: apiCard.isDefault,
  last4: apiCard.last4,
  balance: toCardBalance(apiCard.balance),
});

type ApiCard = {
  name: string;
  addressZip?: string;
  cardType: string;
  expMonth: number;
  expYear: number;
  isDefault?: boolean;
  last4: string;
  paymentType: PaymentType;
  paymentMethodId?: string;
  fingerprint?: string;
  balance?: {
    amountInCents: number | null;
    currency: string | null;
  };
};

export { fetchCards, toCard };
