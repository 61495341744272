import { brand, grey } from '@pelotoncycle/design-system';
import React, { useState } from 'react';
import styled from 'styled-components';
import { ContentSizedDiv } from '@peloton/content-sized-div/ContentSizedDiv';
import { defaultTransition, media, hover } from '@peloton/styles';
import { Chevron, Orientation } from '@ecomm/icons';
import { b3, reg } from '@ecomm/typography';

type FaqProps = {
  id: string;
  question: React.ReactNode;
  answer: React.ReactNode;
  className?: string;
  propagateOpenState?: (x: boolean) => void;
};

const ariaQuestionId = (id: string) => `${id}-question`;
const ariaAnswerId = (id: string) => `${id}-answer`;

const FaqView: React.FC<React.PropsWithChildren<FaqProps>> = ({
  id,
  question,
  answer,
  className,
  propagateOpenState = () => undefined,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const answerId = ariaAnswerId(id);
  const questionId = ariaQuestionId(id);

  return (
    <QuestionAnswerPair data-test-id="faq-question" className={className}>
      <HeightTransition role="list">
        <Question role="listitem">
          <QuestionButton
            aria-controls={answerId}
            aria-expanded={isOpen}
            id={questionId}
            onClick={() => {
              setIsOpen(!isOpen);
              propagateOpenState(!isOpen);
            }}
          >
            {question}
            <OpenIndicator
              role="presentation"
              orientation={isOpen ? Orientation.Up : Orientation.Down}
            />
          </QuestionButton>
        </Question>
        <AnswerHeight
          aria-labelledby={questionId}
          id={answerId}
          isOpen={isOpen}
          role="listitem"
        >
          <Answer data-test-id="faq-answer" aria-hidden={!isOpen} aria-expanded={isOpen}>
            {answer}
          </Answer>
        </AnswerHeight>
      </HeightTransition>
    </QuestionAnswerPair>
  );
};

export default FaqView;

const QuestionAnswerPair = styled.li`
  ${defaultTransition('background-color')}
  align-items: center;
  border-bottom: 1px solid ${grey[40]};
  cursor: pointer;
  width: 100%;

  ${hover`
    background-color: ${brand.light};
  `}
`;

const Question = styled.h3`
  ${b3}
  ${reg}
`;

export const QuestionButton = styled.button`
  width: 100%;
  padding: 20px 10px;
  margin-right: -20px;
  display: flex;
  justify-content: space-between;

  :focus {
    background-color: ${brand.light};
  }
`;

const HeightTransition = styled(ContentSizedDiv)`
  ${defaultTransition()}
`;

const AnswerHeight = styled.div`
  ${(props: { isOpen: boolean }) => (props.isOpen ? '' : 'height: 0;')}
`;

export const Answer = styled.div`
  ${b3}
  padding: 10px 10px 20px 10px;

  > span {
    display: block;

    :not(:last-child) {
      margin-bottom: 1em;
    }
  }
`;

export const OpenIndicator = styled(Chevron)`
  ${defaultTransition()}
  flex-shrink: 0;
  height: 12px;
  margin-left: 10px;
  width: 12px;

  ${media.tablet`
    margin-left: 20px;
  `}
`;
