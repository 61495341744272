// must match the list of apps actually being proxied https://github.com/pelotoncycle/chef-repo/blob/9893a57fb6dd7f13273ccb8289fe18a557086472/cookbooks/nginx-router-api/templates/default/nginx-router.conf.erb#L172

import { CCTLDS_FOR_PARSING_HOSTNAME_IN_PELOTON_ENV } from '../internationalize/consolidatedAppConfigs';

// update playground if there's changes https://regexr.com/4l3r8
const VALID_CHARACTERS = `([\\w-]+)`;

const PREFIX = `${VALID_CHARACTERS}--`;
const APPS = [
  'members',
  'members-next',
  'pos',
  'preorder',
  'studio',
  'uikit',
  'upnext',
  'www',
  'next-www',
  'account',
  'admin',
  'universal-logout',
  'cra-www', // to map as www
];
const LIT = '--lit';
const SUFFIX = `--${VALID_CHARACTERS}`;
const SUBDOMAINS = ['secret', 'test', 'uat', 'xtest', 'prod-test', 'test-vercel'];

// captures a regex optionally
const optional = (regex: string) => `(${regex})?`;

// captures one of the elements of a string array
const oneOf = (list: string[]) => `(${list.join('|')})`;

export const VALID_HOSTNAME = [
  optional(PREFIX),
  optional('vercel-'),
  oneOf(APPS),
  optional(LIT),
  optional(SUFFIX),
  '.',
  oneOf(SUBDOMAINS),
  '.onepeloton.',
  oneOf(CCTLDS_FOR_PARSING_HOSTNAME_IN_PELOTON_ENV),
]
  .join('')
  .replace(/\./g, '\\.'); // escape periods

const parseHostname = (hostname: string) => {
  const [, , , , app, isLit, , api, ,] = hostname.match(VALID_HOSTNAME) || Array();

  const handleAppName = (appName: string) => {
    switch (appName) {
      case 'cra-www':
        return 'www';
      case 'members-next':
        return 'members';
      default:
        return appName;
    }
  };

  return { app: handleAppName(app), isLit: Boolean(isLit), api };
};

export default parseHostname;
