import type { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import type { Client } from '@peloton/api';
import type { Card } from '@account/api/models/Card';
import { fetchCards } from '../../api/cards';
import { getPaymentMethods } from '../../gift-cards/api';
import { loadCardsSuccess, loadCardsFailure } from '../redux';

const loadCards = function* (client: Client, action: any): SagaIterator {
  const { isGiftCardMyMembershipEnabled } = action.payload;

  try {
    let payments: Card[];

    if (isGiftCardMyMembershipEnabled) {
      payments = yield call(getPaymentMethods, client);
    } else {
      payments = yield call(fetchCards, client);
    }
    yield put(loadCardsSuccess(payments));
  } catch (error: unknown) {
    yield put(loadCardsFailure(error));
  }
};

export default loadCards;
