import { reportNewRelicEvent } from '@peloton/newrelic';

export enum CopyFetchStatus {
  Success = 'success',
  Failure = 'failure',
}

type EventAttributes = {
  status: CopyFetchStatus;
  fileName: string;
  errorMessage?: string;
};

export const reportCopyFetch = ({ status, fileName, errorMessage }: EventAttributes) => {
  const eventAttr = {
    status,
    fileName,
    errorMessage,
    hostname: window.location.hostname,
  };
  reportNewRelicEvent(
    'Account Copy Fetch',
    eventAttr as Record<string, string | number>,
    100,
  );
};
