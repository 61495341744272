import { takeEvery } from 'redux-saga/effects';
import { ActionTypes as AddOrderPaymentMethodActions } from '../redux/addOrderPaymentMethod';
import { ActionTypes as AddRefundPaymentMethodActions } from '../redux/addRefundPaymentMethod';
import addOrderPaymentMethodSaga from './addOrderPaymentMethodSaga';
import addRefundPaymentMethodSaga from './addRefundPaymentMethodSaga';

export const refundPaymentMethodSagas = function* () {
  yield takeEvery(
    AddRefundPaymentMethodActions.AddRefundPaymentMethod,
    addRefundPaymentMethodSaga,
  );
};

export const orderPaymentMethodSagas = function* () {
  yield takeEvery(
    AddOrderPaymentMethodActions.AddOrderPaymentMethod,
    addOrderPaymentMethodSaga,
  );
};
