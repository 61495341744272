/**
 * @deprecated prefer import { spacing } from '@pelotoncycle/design-system';
 * Spacing values are limited to the most common spacing sizes used by design.
 * If you receive a design that deviates from these sizes,
 * please check with your designer to see if a spacing value can be used instead.
 * @example tiny = spacing[2]
 * @example xxxSmall = spacing[4]
 */
export enum spaces {
  tiny = 2,
  xxxxSmall = 3,
  xxxSmall = 4,
  xxSmall = 6,
  xSmall = 8,
  small = 12,
  medium = 16,
  large = 20,
  xLarge = 24,
  xxLarge = 28,
  xxxLarge = 32,
  huge = 40,
  xHuge = 48,
  xxHuge = 54,
  xxxHuge = 64,
  giant = 80,
  xGiant = 96,
  xxGiant = 102,
  xxxGiant = 128,
}
