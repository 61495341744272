import { COUNTRIES_ELIGIBLE_FOR_APP_CHECKOUT } from '@peloton/internationalize';
import type { Country } from '@peloton/internationalize/models/country';
import { toCountry } from '@peloton/internationalize/models/country';
import { TierType } from '@engage/subscriptions/Subscription';
import { TierDirection, Platform, BillingFrequency, CheckoutMethod } from './models';

export const isMonthly = (billingFrequency: BillingFrequency): boolean =>
  billingFrequency === BillingFrequency.Monthly;

export const isYearly = (billingFrequency: BillingFrequency): boolean =>
  billingFrequency === BillingFrequency.Annual;

export const isTierCheckoutMethod = (checkoutMethod: CheckoutMethod) =>
  checkoutMethod === CheckoutMethod.TierCheckout;

export const isLegacy = (tierType?: TierType): boolean => tierType === TierType.Legacy;

export const isFirstParty = (platform?: Platform): boolean =>
  !platform || platform === Platform.Peloton;

export const isThirdParty = (platform?: Platform): boolean =>
  Boolean(platform) && !isFirstParty(platform);

export const isDowngrade = (tierDirection: TierDirection) =>
  tierDirection === TierDirection.Downgrade;

export const isUpgrade = (tierDirection: TierDirection) =>
  tierDirection === TierDirection.Upgrade;

export const isUpgradeDowngrade = (
  tierDirection: TierDirection,
): tierDirection is TierDirection.Upgrade | TierDirection.Downgrade =>
  isUpgrade(tierDirection) || isDowngrade(tierDirection);

export const isFirstPartyLegacy = (platform?: Platform, tierType?: TierType) =>
  isFirstParty(platform) && isLegacy(tierType);

export const isEligibleForAppCheckout = (country: Country = toCountry()) =>
  COUNTRIES_ELIGIBLE_FOR_APP_CHECKOUT.includes(country);
