import { brand, spacing } from '@pelotoncycle/design-system';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import type { UserSelectorState } from '@peloton/auth';
import { getUserTrackingProperties } from '@ecomm/auth';
import { trackFaqOpenStateToggled } from '@ecomm/faqs/analytics';
import { hr4 } from '@ecomm/typography';
import Faq from './Faq';

export type FaqQuestion = {
  key: string;
  question: string;
  answer: string;
};

export interface FaqsCopyConfiguration {
  heading: React.ReactNode;
  questions: FaqQuestion[];
  formatQuestion: (
    question: string,
    values?: { [key: string]: React.ReactNode },
  ) => React.ReactNode;
}

class DefaultFaqsCopyConfiguration implements FaqsCopyConfiguration {
  heading = (<>FAQs</>);
  questions = [];
  formatQuestion(question: string, values?: { [key: string]: React.ReactNode }) {
    return question;
  }
}

export const FaqsCopyContext = React.createContext<FaqsCopyConfiguration>(
  new DefaultFaqsCopyConfiguration(),
);

const useFaqsCopyContext = () => {
  return React.useContext(FaqsCopyContext);
};

export type Props = React.HTMLProps<HTMLElement> & {
  id: string;
  values?: { [key: string]: React.ReactNode };
  userTrackingProperties: ReturnType<typeof getUserTrackingProperties>;
};

const Faqs: React.FC<React.PropsWithChildren<Props>> = ({
  id,
  values,
  userTrackingProperties,
}) => {
  const handleOpenStateToggle = React.useCallback(
    (faqItemKey: string) => () =>
      trackFaqOpenStateToggled({
        faqGroupKey: id,
        faqItemKey,
        userTrackingProperties,
      }),
    [id, userTrackingProperties],
  );

  const copyContext = useFaqsCopyContext();

  return (
    <section>
      <SectionContent>
        <Heading>{copyContext.heading}</Heading>
        <ul id={id}>
          {copyContext.questions.map(({ key, question, answer }) => (
            <StyledFaq
              values={values}
              key={key}
              id={key}
              question={copyContext.formatQuestion(question, values)}
              answer={answer}
              onOpenStateChanged={handleOpenStateToggle(key)}
            />
          ))}
        </ul>
      </SectionContent>
    </section>
  );
};

const mapStateToProps = (state: UserSelectorState) => ({
  userTrackingProperties: getUserTrackingProperties(state),
});

const ContentfulFaqs = connect(mapStateToProps, {})(Faqs);

export default ContentfulFaqs;

export const SectionContent = styled.div`
  margin: ${spacing[32]} 0;
`;

const Heading = styled.h2`
  ${hr4}
  border-bottom: 2px solid ${brand.darkest};
  padding: 0 10px 10px;
`;

const StyledFaq = styled(Faq)`
  ul {
    list-style-type: disc;
    margin-left: 25px;
  }
`;
