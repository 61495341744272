// NOTE:
// this implementation of withApollo comes from Josh Navi's closed PR attempt: https://github.com/pelotoncycle/jupiter/pull/29184/files
import type { ApolloClientOptions, NormalizedCacheObject } from '@apollo/client';
import { ApolloClient, InMemoryCache, ApolloLink, createHttpLink } from '@apollo/client';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { SHA256 } from 'crypto-js';
import { GRAPHQL_ENDPOINT } from '@peloton/app-config';
import { getAPIAuthToken } from '@peloton/auth/config';
import type { ExtLinkEnv } from '@peloton/external-links';
import { ApiEnvs, getApiCluster } from '@peloton/external-links';
import { toMatchingTld } from '@peloton/internationalize';

type Props = Partial<ApolloClientOptions<NormalizedCacheObject>> & {
  env: ExtLinkEnv;
  authorizationHeaderEnabled?: boolean;
  name?: string;
};

const sha256 = (input: string) => {
  return SHA256(input).toString();
};

export const toClient = ({ env, authorizationHeaderEnabled, ...props }: Props) =>
  new ApolloClient({
    link: toLink(toUri(env), authorizationHeaderEnabled),
    cache: new InMemoryCache(),
    ...props,
  });

export default toClient;

const toApolloLinkHeaders = (
  authorizationHeaderEnabled: boolean,
): Record<string, string> => {
  if (!authorizationHeaderEnabled) return {};
  const token = getAPIAuthToken();
  return token ? { 'X-Secrets-Authorization': `${token}` } : {};
};

export const toLink = (uri: string, authorizationHeaderEnabled = false) =>
  ApolloLink.split(
    ({ getContext }) => getContext().useApq,
    createPersistedQueryLink({ useGETForHashedQueries: true, sha256 }).concat(
      createHttpLink({
        uri,
        credentials: 'include',
        headers: toApolloLinkHeaders(authorizationHeaderEnabled),
      }),
    ),
    createHttpLink({
      uri,
      credentials: 'include',
      headers: toApolloLinkHeaders(authorizationHeaderEnabled),
    }),
  );

// GraphQL Gateways only exist on these three clusters
const GATEWAY_CLUSTERS = ['stage', 'test', 'prod'];
export const toUri = (env: ExtLinkEnv) => {
  if (GRAPHQL_ENDPOINT) {
    // use webpack proxy to connect to localhost
    return '/graphql';
  } else {
    const cluster = getApiCluster(env.api);
    const tld = toMatchingTld(env.hostname);
    if (!GATEWAY_CLUSTERS.includes(cluster)) {
      return `https://graph.stage.k8s.onepeloton${tld}/graphql`;
    }
    const namespace = getApiNamespace(env.api);
    return `https://graph${namespace}.${cluster}.k8s.onepeloton${tld}/graphql`;
  }
};

const getApiNamespace = (env: ApiEnvs) => {
  if (
    env === ApiEnvs.Prod ||
    env === ApiEnvs.Qa1 ||
    env === ApiEnvs.Qa2 ||
    env === ApiEnvs.Local
  ) {
    return '';
  } else {
    // ApiEnvs must be a k8s API string

    // when using `api-api-core-` prefix, there could be numbers
    if (env.startsWith('api-api-core')) {
      const [namespace] = env.split('.');
      return namespace.replace('api-api-core', '');
    }
    if (getApiCluster(env) === 'dev') {
      // graph only needs the namespace included when on dev
      const [, namespace] = env.split('.');
      return '-' + namespace;
    } else {
      return '';
    }
  }
};
