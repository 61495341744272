import { brand } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { AccountMarkdown } from '@account/copy';
import { spaces } from '@account/styles';
import { b3 } from '@acme-ui/typography';
import type { Props } from '../HeaderViewProps';
import StyledContainer from '../StyledContainer';
import StyledLink from '../StyledLink';
import PelotonLogo from './Logo';

const HeaderViewDesktop: React.FC<React.PropsWithChildren<Props>> = ({
  className,
  homeLink,
  hasHelpBlock = false,
}) => (
  <StyledContainer className={className}>
    <MaxWidthContainer>
      <StyledLink href={homeLink}>
        <LargeLogo aria-label="Peloton" />
      </StyledLink>
      {hasHelpBlock && (
        <HelpBlock>
          <AccountMarkdown id="header.helpBlock.desktop" />
        </HelpBlock>
      )}
    </MaxWidthContainer>
  </StyledContainer>
);

export default HeaderViewDesktop;

const MaxWidthContainer = styled.div<Pick<Props, 'hasHelpBlock'>>`
  align-items: center;
  display: flex;
  height: 80px;
  justify-content: space-between;
  margin: auto;
  max-width: 1224px;
  ${({ hasHelpBlock }) =>
    hasHelpBlock
      ? `
    padding-left: ${spaces.huge}px;
    padding-right: ${spaces.xxxLarge}px;
  `
      : `
    padding: 0 ${spaces.large}px;
  `}
`;

const LargeLogo = styled(PelotonLogo)`
  height: 46px;
  width: 156px;
`;

const HelpBlock = styled.div`
  ${b3}
  color: ${brand.darkest};
`;
