'use client';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getIsUserLoading, getUser, loadAuthUser } from '@peloton/auth';

const AuthUser: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const isLoading = useSelector(getIsUserLoading);

  React.useEffect(() => {
    if (!user && !isLoading) {
      dispatch(loadAuthUser());
    }
  }, [dispatch, isLoading, user]);

  return <>{children}</>;
};

export default AuthUser;
