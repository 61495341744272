'use client';
/** @TODO Remove from SonarQube analysis exclusions in runSonarAnalysis.js if making any changes. */
import type { ExtLink } from '@peloton/external-links';
import { toPeloLink, toSupportLink, toWWWLink } from '@peloton/external-links';
import {
  toLocaleFromHostname,
  theUserIsInGermany,
  theUserIsInTheUnitedStates,
} from '@peloton/internationalize';
import type { Locale } from '@peloton/internationalize';
import { maybeHostname } from '@peloton/internationalize/models/locale';

import type { AccountKeyValue } from '@account/copy';

type Link = {
  title: AccountKeyValue;
  href: ExtLink;
};

export type FooterProps = {
  links: Link[][];
};

const toWarrantyLink = () => {
  /** Clumsy naming in place so we can reason about how intl configs are used from a central place */
  const locale: Locale = toLocaleFromHostname(maybeHostname());

  if (theUserIsInGermany(locale)) {
    return toPeloLink('support', '/hc/de/articles/203020505');
  } else {
    return toSupportLink('/articles/203020505-Peloton-Extended-Warranty');
  }
};

const californiaPrivacyData: [Link] = [
  {
    title: 'footer_links_caPrivacyNotice',
    href: toWWWLink('/california-privacy-notice'),
  },
];

const CorporateWellnessFooterData: FooterProps = {
  links: [
    [
      {
        title: 'footer_links_corporateWellnessTerms',
        href: toWWWLink('/benefits/terms-of-service'),
      },
      {
        title: 'footer_links_termsOfService',
        href: toWWWLink('/terms-of-service'),
      },
    ],
    [
      {
        title: 'footer_links_privacyPolicy',
        href: toWWWLink('/privacy-policy'),
      },
      {
        title: 'footer_links_membershipTerms',
        href: toWWWLink('/membership-terms'),
      },
    ],
    [
      {
        title: 'footer_links_ipPolicy',
        href: toWWWLink('/ip-policy'),
      },
    ],
  ],
};

const FooterData: FooterProps = {
  links: [
    [
      {
        title: 'footer_links_returnPolicy',
        href: toSupportLink(
          '/articles/201108256-What-is-the-return-policy-on-the-Peloton-Bike-',
        ),
      },
      {
        title: 'footer_links_genericWarranty',
        href: toWarrantyLink(),
      },
    ],
    [
      {
        title: 'footer_links_shipping',
        href: toSupportLink(
          '/articles/203078315-What-to-expect-from-the-in-home-delivery-and-set-up-of-your-Peloton-bike',
        ),
      },
      {
        title: 'footer_links_privacyPolicy',
        href: toWWWLink('/privacy-policy'),
      },
    ],
  ],
};
/** Clumsy naming in place so we can reason about how intl configs are used from a central place */
const locale = toLocaleFromHostname(maybeHostname());
if (theUserIsInTheUnitedStates(locale)) {
  FooterData.links.push(californiaPrivacyData);
  CorporateWellnessFooterData.links[CorporateWellnessFooterData.links.length - 1].push(
    californiaPrivacyData[0],
  );
}

export { FooterData, CorporateWellnessFooterData };
