import { Country } from '@peloton/internationalize/models';

enum ActionTypes {
  UpdateCountry = 'account/payment_info/UPDATE_COUNTRY',
}

export type CountryState = {
  country: Country;
};

type UpdateCountryAction = {
  type: ActionTypes.UpdateCountry;
  payload: Country;
};

export const updateCountry = (payload: Country): UpdateCountryAction => ({
  type: ActionTypes.UpdateCountry,
  payload,
});

export const COUNTRY_NAMESPACE = 'country';

export const getCountry = (state: CountryState) => state.country;

export default (state: Country = Country.Default, action: UpdateCountryAction) => {
  switch (action.type) {
    case ActionTypes.UpdateCountry:
      return action.payload;
    default:
      return state;
  }
};
