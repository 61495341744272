import React from 'react';
import { oneLine } from '@peloton/text';
import type { IconProps } from './types';

const ChatIcon: React.FC<React.PropsWithChildren<IconProps>> = props => (
  <svg
    role="presentation"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18 16"
    {...props}
  >
    <path
      d={oneLine(`
          M8.526 0c4.708 0 8.527 3.07 8.527 6.854 0 3.784-3.82 6.854-8.527 6.854-.45 0-.895-.028-1.328-.084-1.834
          1.814-3.947 2.138-6.061 2.188v-.445c1.14-.554 2.06-1.56 2.06-2.714 0-.162-.014-.318-.035-.473C1.232 10.924
          0 9.004 0 6.854 0 3.071 3.82 0 8.526 0z
        `)}
    />
  </svg>
);

export default ChatIcon;
