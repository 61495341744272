import React from 'react';
import { useSelector } from 'react-redux';
import type { RouteComponentProps } from 'react-router';
import { nest } from 'recompose';

import { getExtLinkEnv, toHref, toWWWLink } from '@peloton/external-links';
import { redirect } from '@peloton/navigation';
import { useTrackCallback, HappyPathEvents } from '@account/analytics';

import { DomainBasedCopyProvider } from '@account/copy';
import { MetadataTags } from '@account/metadata';

const PRERENDER_USER_AGENT = RegExp(/Prerender/);

export const PreviewPage: React.FC<React.PropsWithChildren<RouteComponentProps>> = ({
  location,
}) => {
  const { track } = useTrackCallback();
  const queryParams = new URLSearchParams(location.search);
  const shareSource = queryParams.get('source') || undefined;
  const referralCode = queryParams.get('code') || '';
  const extLinkEnv = useSelector(getExtLinkEnv);

  if (!window.navigator.userAgent.match(PRERENDER_USER_AGENT)) {
    track({
      event: HappyPathEvents.AccessedHomepageFromReferralShare,
      instanceProperties: {
        referralCode,
        shareSource,
      },
    });

    setTimeout(() => redirect(toHref(toWWWLink(`/`), extLinkEnv)), 300);
  }

  return (
    <>
      <MetadataTags id={'pgReferrals.metadata'} />
    </>
  );
};

export default nest(DomainBasedCopyProvider, PreviewPage);
