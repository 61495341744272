import type { SuccessCallbackFn } from '@account/checkout/models';
import type { ID } from '@ecomm/models';
import type { PaymentGateway } from '@ecomm/payment';
import type { SubscriptionChangeData } from '../models';

export enum ActionTypes {
  SubscriptionTierCheckoutRequested = 'membership/checkout/SUBSCRIPTION_TIER_CHECKOUT_REQUESTED',
}

type ExistingPaymentData = {
  postalCode: string;
};

export type SubscriptionTierCheckoutRequestAction = {
  type: ActionTypes.SubscriptionTierCheckoutRequested;
  payload: {
    product: ID;
    paymentGateway: PaymentGateway;
    recaptchaToken?: string;
    existingPayment?: ExistingPaymentData;
    successCallbackFn: SuccessCallbackFn;
    tierData: SubscriptionChangeData;
  };
};

export const requestSubscriptionTierCheckout = (
  paymentGateway: PaymentGateway,
  product: ID,
  tierData: SubscriptionChangeData,
  successCallbackFn: SuccessCallbackFn,
  recaptchaToken?: string,
  existingPayment?: ExistingPaymentData,
): SubscriptionTierCheckoutRequestAction => ({
  type: ActionTypes.SubscriptionTierCheckoutRequested,
  payload: {
    paymentGateway,
    product,
    successCallbackFn,
    tierData,
    recaptchaToken,
    existingPayment,
  },
});
