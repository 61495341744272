import type { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';
import type { Client } from '@peloton/api';
import { getUserId } from '@peloton/auth';
import { unpauseSubscription as unpauseDeviceSubscription } from '../api/manageSubscriptions';
import type { PauseSubscriptionAction } from '../redux/subscriptions';
import { updateSubscription } from '../redux/subscriptions';
import type { Subscription } from '../Subscription';

const pauseSubscription = function* (
  client: Client,
  { payload: { subscriptionId }, onSuccess, onError }: PauseSubscriptionAction,
): SagaIterator {
  try {
    const userId = yield select(getUserId);
    if (!userId) {
      throw new Error();
    }
    const subscription: Subscription = yield call(
      unpauseDeviceSubscription,
      client,
      userId,
      subscriptionId,
    );
    yield put(updateSubscription(subscription));
    yield call(onSuccess);
  } catch (e) {
    yield call(onError, e);
  }
};

export default pauseSubscription;
