import type { Reducer } from 'redux';
import type { UserReducerState } from '@peloton/auth';
import { USER_NAMESPACE, userReducer } from '@peloton/auth';
import { reducer as chatReducer } from '@peloton/drift';
import type { ExtLinkEnvState } from '@peloton/external-links';
import { extLinkEnvReducer } from '@peloton/external-links';
import { fetchReducer } from '@peloton/redux-fetch';
import { reducer as plansReducer } from '@peloton/subscription-plans';
import type { State as EnvState } from '@account/env';
import { envReducer } from '@account/env';
import type {
  LoadingReducerState,
  UpdatePaymentState,
  CountryState,
} from '@account/payment';
import {
  loadingReducer,
  LOADING_REDUCER_NAMESPACE,
  updatePaymentReducer,
  UPDATE_PAYMENT_NAMESPACE,
  countryReducer,
  COUNTRY_NAMESPACE,
} from '@account/payment';
import {
  SUB_TRANSACTION_NAMESPACE,
  transactionsReducer,
  cardsReducer,
} from '@account/pg-my-membership/redux';
import type { AddRefundPaymentMethodReducerState } from '@account/pg-post-purchase-flow/PaymentMethod/redux/addRefundPaymentMethodReducer';
import addRefundPaymentMethodReducer from '@account/pg-post-purchase-flow/PaymentMethod/redux/addRefundPaymentMethodReducer';
import { checkoutReducer } from '@ecomm/checkout';
import type { ReducerState as ToggleReducerState } from '@ecomm/feature-toggle/redux';
import { reducer as toggles } from '@ecomm/feature-toggle/redux';
import { reducer as quickCheckout } from '@ecomm/quick-checkout';
import type { VendorKeysState } from '@ecomm/vendor-keys';
import vendorKeysReducer from '@ecomm/vendor-keys';
import { subscriptionReducer } from '@engage/subscriptions/redux';
import oneWellnessReducers from '@onewellness/redux/account';

export const toStoreReducers = (): Record<string, Reducer> => ({
  chat: chatReducer,
  env: envReducer,
  extLinkEnv: extLinkEnvReducer,
  vendorKeys: vendorKeysReducer,
  [USER_NAMESPACE]: userReducer,
  [LOADING_REDUCER_NAMESPACE]: loadingReducer,
  [COUNTRY_NAMESPACE]: countryReducer,
  [UPDATE_PAYMENT_NAMESPACE]: updatePaymentReducer,
  toggles,
  addRefundPayment: addRefundPaymentMethodReducer,
  subscriptions: subscriptionReducer,
  [SUB_TRANSACTION_NAMESPACE]: transactionsReducer,
  cards: cardsReducer,
  fetched: fetchReducer,
  subscriptionPlans: plansReducer,
  quickCheckout,
  checkout: checkoutReducer,
  ...oneWellnessReducers,
});

type AccountUserState = {
  [USER_NAMESPACE]: UserReducerState;
};

export type AddRefundState = {
  addRefundPayment: AddRefundPaymentMethodReducerState;
};

export type GlobalState = EnvState &
  VendorKeysState &
  AccountUserState &
  LoadingReducerState &
  CountryState &
  UpdatePaymentState &
  ToggleReducerState &
  ExtLinkEnvState &
  AddRefundState;
