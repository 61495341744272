import React from 'react';
import { asyncComponent } from '@peloton/code-splitting';
import { Route } from '@account/router';
import { BENEFIT_PRICING_PATH } from '@onewellness/routes';

const route = [
  <Route
    key={BENEFIT_PRICING_PATH}
    path={BENEFIT_PRICING_PATH}
    component={asyncComponent(
      () => import('./Page' /* webpackChunkName: "BenefitsApplyBenefit" */),
    )}
  />,
];

export default route;
