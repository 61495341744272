import React from 'react';
import { asyncComponent } from '@peloton/code-splitting';
import { Route } from '@account/router';

export const BENEFIT_RENEWAL_PATH = `/benefit-renewal`;

const Routes = [
  <Route
    key={BENEFIT_RENEWAL_PATH}
    path={BENEFIT_RENEWAL_PATH}
    component={asyncComponent(
      () => import('./Page' /* webpackChunkName: "BenefitRenewalPage" */),
    )}
  />,
];

export default Routes;
