import { identity, pipe } from 'ramda';
import type { Client, ClientRequestConfig, ClientError } from '@peloton/api';
import { isHttpError, throwError } from '@peloton/api';
import { SubscriptionKind } from '../Subscription';

// Add User
export const getAddUserToSubscriptionApiBasedOnKind = (
  subscriptionKind: SubscriptionKind,
) => {
  switch (subscriptionKind) {
    case SubscriptionKind.LegacyiOS: {
      return addUserToIosSubscription;
    }
    case SubscriptionKind.Digital: {
      return addUserToDigitalSubscription;
    }
    default: {
      return addUserToHardwareSubscription;
    }
  }
};

export type UserKey =
  | {
      usernameOrEmail: string;
    }
  | {
      userId: string;
    };

export const addUserToDigitalSubscription = (
  api: Client,
  subId: string,
  user: UserKey,
  config?: ClientRequestConfig,
) =>
  api
    .post(addUserToDigitalSubscriptionUrl(subId), user, config)
    .then(identity)
    .catch(pipe(throwError<ErrorCode>(toErrorCode)));

export const addUserToHardwareSubscription = (
  api: Client,
  subId: string,
  user: UserKey,
  config?: ClientRequestConfig,
) =>
  api
    .post(addUserToHardwareSubscriptionUrl(subId), user, config)
    .then(identity)
    .catch(pipe(throwError<ErrorCode>(toErrorCode)));

export const addUserToIosSubscription = (
  api: Client,
  subId: string,
  user: UserKey,
  config?: ClientRequestConfig,
) =>
  api
    .post(addUserToIosSubscriptionUrl(subId), user, config)
    .then(identity)
    .catch(pipe(throwError<ErrorCode>(toErrorCode)));

export enum ErrorCode {
  CannotAddUserToAccount = 'cannotAddUserToAccount',
  AccountNotFound = 'accountNotFound',
  Default = 'default',
}

const apiErrorMap = {
  150: ErrorCode.CannotAddUserToAccount,
  110: ErrorCode.AccountNotFound,
};

const toErrorCode = (error: ClientError): ErrorCode => {
  const apiErrorCode = isHttpError(error) ? error.responseBody.errorCode : undefined;

  return apiErrorMap[apiErrorCode] ?? ErrorCode.Default;
};

// Remove user
export const getRemoveUserFromSubscriptionApiBasedOnKind = (
  subscriptionKind: SubscriptionKind,
) => {
  switch (subscriptionKind) {
    case SubscriptionKind.LegacyiOS: {
      return removeUserFromIosSubscription;
    }
    case SubscriptionKind.Digital: {
      return removeUserFromDigitalSubscription;
    }
    default: {
      return removeUserFromHardwareSubscription;
    }
  }
};

export const removeUserFromDigitalSubscription = (
  api: Client,
  params: RemoveUserFromSubApiParams,
) =>
  api
    .delete(removeUserDigitalSubscriptionUrl(params.subId, params.userId))
    .then(identity);

export const removeUserFromHardwareSubscription = (
  api: Client,
  params: RemoveUserFromSubApiParams,
) =>
  api
    .delete(removeUserHardwareSubscriptionUrl(params.subId, params.userId))
    .then(identity);

export const removeUserFromIosSubscription = (
  api: Client,
  params: RemoveUserFromSubApiParams,
) => api.delete(removeUserIosSubscriptionUrl(params.subId, params.userId)).then(identity);

// URLs
const addUserToDigitalSubscriptionUrl = (subId: string) =>
  `/api/subscription/digital/${subId}/shared_users`;

const addUserToHardwareSubscriptionUrl = (subId: string) =>
  `/api/subscription/${subId}/shared_users`;

const addUserToIosSubscriptionUrl = (subId: string) =>
  `/api/ios_subscription/${subId}/shared_users`;

const removeUserDigitalSubscriptionUrl = (subId: string, userId: string) =>
  `/api/subscription/digital/${subId}/shared_users/${userId}`;

const removeUserHardwareSubscriptionUrl = (subId: string, userId: string) =>
  `/api/subscription/${subId}/shared_users/${userId}`;

const removeUserIosSubscriptionUrl = (subId: string, userId: string) =>
  `/api/ios_subscription/${subId}/shared_users/${userId}`;

type RemoveUserFromSubApiParams = {
  subKind: SubscriptionKind;
  subId: string;
  userId: string;
};
