import React from 'react';
import styled from 'styled-components';
import { media } from '@peloton/styles';

const SVG: React.FC<React.PropsWithChildren<React.SVGProps<SVGSVGElement>>> = props => (
  <svg viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.80957 16.9775C0.80957 8.1398 7.97328 0.976562 16.8084 0.976562C25.6459 0.976562 32.8096 8.1398 32.8096 16.9775C32.8096 25.8139 25.6459 32.9766 16.8084 32.9766C7.97328 32.9766 0.80957 25.8139 0.80957 16.9775ZM25.1934 24.8938C26.2027 24.8938 27.3649 24.3895 27.9809 24.0806L27.8364 24.3367C26.551 26.2666 24.9058 27.2881 23.0784 27.2881C22.2773 27.2881 21.4307 27.0932 20.5647 26.7097C19.6471 26.3031 18.8411 25.559 18.2937 24.6128C17.7457 23.6673 17.5297 22.6495 17.6862 21.7343C17.8717 20.9383 18.2882 19.8598 18.7699 18.6119L18.7859 18.5705C20.061 15.2769 21.8046 10.7682 20.2971 8.56216C19.6623 7.63118 18.5213 7.17661 16.8062 7.17054C15.0942 7.17661 13.9508 7.63178 13.316 8.56216C11.8097 10.7682 13.5533 15.2769 14.8296 18.576L14.8544 18.6403C15.3319 19.8772 15.7444 20.9458 15.9293 21.7434C16.0834 22.6495 15.8674 23.6673 15.3224 24.6128C14.775 25.559 13.9684 26.3031 13.0508 26.7091C12.1848 27.0932 11.3388 27.2881 10.5377 27.2881C8.71036 27.2881 7.06569 26.2666 5.78516 24.3458L5.63525 24.0806C6.25246 24.3895 7.41526 24.8938 8.42269 24.8938C8.92762 24.8938 9.39857 24.7433 9.86587 24.4302C12.4567 22.7066 11.5712 20.6401 9.80458 17.4405L9.79402 17.4213C9.05142 16.0739 8.28332 14.6802 7.94386 13.2438C7.57244 11.6907 7.23744 9.42821 8.58352 7.71554C9.21589 6.91261 10.1796 6.29296 11.445 5.87359C12.7601 5.43845 14.4546 5.20297 16.4797 5.17627L16.995 5.17202L17.1346 5.17627C19.161 5.20297 20.8542 5.43845 22.1711 5.8742C23.4347 6.29296 24.3984 6.91321 25.0308 7.71614C26.3775 9.42699 26.0419 11.6907 25.6735 13.2438C25.3321 14.6781 24.5669 16.0669 23.8235 17.4161L23.8097 17.4411C22.0437 20.6401 21.1582 22.7066 23.7472 24.4302C24.2164 24.7433 24.6897 24.8938 25.1934 24.8938Z"
      fill="white"
    />
  </svg>
);

const LululemonLogo = styled(SVG)`
  height: 20px;
  width: auto;

  ${media.desktop`
    height: 33px;
  `}
`;

export default LululemonLogo;
