import { brand } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { Links } from '@peloton/links';
import { media } from '@peloton/styles';
import { useTrackCallback, ErrorEvents } from '@account/analytics';
import { AccountCopy } from '@account/copy';
import type { AccountKeyValue } from '@account/copy/models.generated';
import { hr3, b3, light } from '@acme-ui/typography';
import type { LinkProps } from '@ecomm/links';
import { Link, l3Black } from '@ecomm/links';

type Props = {
  titleId?: AccountKeyValue;
  subtitleId?: AccountKeyValue;
};

const ErrorComponent: React.FC<React.PropsWithChildren<Props>> = ({
  titleId = 'errors_default_title',
  subtitleId = 'errors_default_subtitle',
}) => {
  const { track } = useTrackCallback();
  React.useEffect(() => {
    track({
      event: ErrorEvents.AccountAppErrorComponentRendered,
    });
  }, []);

  return (
    <Content>
      <Title>
        <AccountCopy id={titleId} />
      </Title>
      <Subtitle>
        <AccountCopy id={subtitleId} values={toSubtitleValues()} />
      </Subtitle>
    </Content>
  );
};

export default ErrorComponent;

const toSubtitleValues = () => ({
  phone: (
    <PhoneLink to={Links.peloton}>
      <AccountCopy id={'footer_supportPhoneNumber'} />
    </PhoneLink>
  ),
});

export const Content: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <ContentContainer>
    <InnerContent>{children}</InnerContent>
  </ContentContainer>
);

const ContentContainer = styled.div`
  flex: 1;
  text-align: center;
  padding: 20px;
  background-color: ${brand.light};
`;

const InnerContent = styled.div`
  max-width: 500px;
  min-height: 200px;
  margin: 120px auto 120px auto;
  flex: 1 0 auto;
  ${media.tablet`
    max-width: 680px;
  `}
`;

export const Title = styled.h1`
  ${hr3}
  margin: 0 auto;
`;

export const Subtitle = styled.p`
  ${b3}
  ${light}
  max-width: 530px;
  margin: 32px auto 0 auto;
  color: ${brand.darkest};
`;

const PhoneLink = (styled(Link)`
  ${l3Black()}
` as any) as React.ComponentType<React.PropsWithChildren<LinkProps>>;
