import type { Subscription } from '@engage/subscriptions';

export enum Actions {
  Reactivate = 'pelo/@onewellness/pg-preferences/subscriptionDetails/REACTIVATE',
}

export const reactivateSub = (
  sub: Subscription,
  onSuccess: () => void,
  onError: () => void,
) => ({
  type: Actions.Reactivate,
  payload: { sub, callbacks: { onSuccess, onError } },
});
