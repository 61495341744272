import { pathOr, propOr } from 'ramda';
import type { SagaIterator } from 'redux-saga';
import { select, call, put } from 'redux-saga/effects';
import { redirect } from '@peloton/navigation';
import { track } from '@engage/analytics';
import type {
  loadSubscriptionsSuccess,
  loadSubscriptionsFailure,
  Subscription,
} from '@engage/subscriptions';
import { getSubscription } from '@engage/subscriptions';
import { MYMEMBERSHIP_ROUTE } from '../urls';

const toViewedSubscriptionDetailsEvent = (sub: Subscription) => ({
  event: 'Viewed Subscription Details',
  numberOfSharedUsers: sub.sharedUserSet.length,
  subscriptionStatus: pathOr(undefined, ['status', 'type'], sub),
  subscriptionType: propOr(undefined, 'kind', sub),
});

export const subscriptionLoaded = function* (
  action:
    | ReturnType<typeof loadSubscriptionsSuccess>
    | ReturnType<typeof loadSubscriptionsFailure>,
): SagaIterator {
  // The success/failures actions are shared between loadSubscription and loadSubscriptions.
  // We use an optional id to differentiate between singular and plural
  if (action.payload.id) {
    const sub = yield select(getSubscription, action.payload.id);

    if (!sub) {
      yield call(redirect, MYMEMBERSHIP_ROUTE);
    } else {
      yield put(track(toViewedSubscriptionDetailsEvent(sub)));
    }
  }
};
