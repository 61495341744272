import { css } from 'styled-components';
import { media } from '@peloton/styles';
import { headingFamily, secondaryHeadingFamily } from './families';
import { bold } from './weights';

export const heading = css`
  ${bold}
  font-family: ${headingFamily};
  letter-spacing: 0.1em;
  line-height: 1.375em;
  text-transform: uppercase;
`;

export const secondaryHeading = css`
  font-family: ${secondaryHeadingFamily};
  font-weight: 300;
  letter-spacing: 0;
  line-height: 1.375em;
`;

export const hb3 = css`
  ${heading}
  font-size: 18px;
  ${media.tablet`
    font-size: 24px;
  `}
`;

export const hb4 = css`
  ${heading}
  font-size: 18px;

  ${media.tablet`
    font-size: 20px;
  `}
`;

export const hb5 = css`
  ${heading}
  font-size: 16px;
`;

export const hb6 = css`
  ${heading}
  font-size: 14px;
`;

export const hb7 = css`
  ${heading}
  font-size: 13px;
`;

export const hb8 = css`
  ${heading}
  font-size: 12px;
`;

export const hr3 = css`
  ${secondaryHeading}
  font-size: 24px;

  ${media.tablet`
    font-size: 32px;
  `}
  ${media.desktop`
    font-size: 36px;
  `}
`;

export const hr4 = css`
  ${secondaryHeading}
  font-size: 20px;

  ${media.tablet`
    font-size: 28px;
  `}
`;

export const hr6 = css`
  ${secondaryHeading}
  font-size: 16px;
`;
