import { FEATURES } from '@account/optimizely/enums';

export type ToggleNames = FEATURES;
export type ToggleValues = Record<ToggleNames, boolean>;
const validToggleNames = Object.values(FEATURES) as string[];

export const getToggleOverrideValue = (
  toggleName: string,
  toggleOverrides: ToggleValues,
) => {
  return toggleOverrides[toggleName];
};

export const getQueryToggles = (
  isLit: boolean,
  isProd: boolean,
  search: string,
): ToggleValues => {
  if (isLit || isProd) {
    return {} as ToggleValues;
  }

  const queryParams = new URLSearchParams(search);
  const toggleOn = queryParams.getAll('toggleOn');
  const toggleOff = queryParams.getAll('toggleOff');

  return {
    ...toQueryToggle(toggleOn.filter(isValidToggleName), true),
    ...toQueryToggle(toggleOff.filter(isValidToggleName), false),
  };
};

const isValidToggleName = (name: string): name is ToggleNames => {
  return validToggleNames.includes(name);
};

const toQueryToggle = (toggles: ToggleNames[], value: boolean): ToggleValues => {
  return toggles.reduce(
    (values: ToggleValues, toggleName: ToggleNames) => ({
      ...values,
      [toggleName]: value,
    }),
    {} as ToggleValues,
  );
};
