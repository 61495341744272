import type { SagaIterator } from 'redux-saga';
import { put, call, getContext } from 'redux-saga/effects';
import type { Client } from '@peloton/api';
import { CLIENT_CONTEXT, pipeError, throwError } from '@peloton/api';
import type { ErrorCode } from '@ecomm/payment/models';
import { toErrorCode } from '@ecomm/payment/models';
import { updateVendorKey, Vendor } from '@ecomm/vendor-keys';
import {
  fetchStripeAccountFailure,
  fetchStripeAccountRequested,
  fetchStripeAccountSuccess,
  updateCountry,
} from '../redux';

export default function* (): SagaIterator {
  yield put(fetchStripeAccountRequested());

  const client = yield getContext(CLIENT_CONTEXT);
  try {
    const data = yield call(fetchStripeAccount, client);
    if (data.stripeAccount !== null) {
      yield put(updateVendorKey(Vendor.StripeAccount, data.stripeAccount));
    }
    yield put(updateCountry(data.country.toLowerCase()));
    yield put(fetchStripeAccountSuccess());
  } catch (error) {
    yield put(fetchStripeAccountFailure(error));
  }
}

const FETCH_STRIPE_ACCOUNT_ENDPOINT = '/ecomm/stripe/account';
export const fetchStripeAccount = (api: Client) =>
  api
    .get(FETCH_STRIPE_ACCOUNT_ENDPOINT)
    .then(resp => resp.data)
    .catch(pipeError(throwError<ErrorCode>(toErrorCode)));
