import React from 'react';
import { Route } from 'react-router-dom';
import { asyncComponent } from '@peloton/code-splitting';
import { UPDATE_CARD_ROUTE } from './urls';

const Routes = [
  <Route
    key={UPDATE_CARD_ROUTE}
    path={UPDATE_CARD_ROUTE}
    component={asyncComponent(() => import('./CreditCard/Page'))}
  />,
];

export default Routes;
