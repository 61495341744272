import { gql } from '@apollo/client';
import { toData } from '@onewellness/api';
import type { OneWellnessClient } from '@onewellness/api/client';

export const registerForHyattRewards = (
  client: OneWellnessClient,
  memberId: string,
  token: string,
  locale: string,
) =>
  client
    .mutate({
      mutation: REGISTER_FOR_HYATT_REWARDS,
      variables: { memberId, token, locale },
    })
    .then(toData);

const REGISTER_FOR_HYATT_REWARDS = gql`
  mutation registerForHyattRewards(
    $memberId: String!
    $token: String!
    $locale: String!
  ) {
    registerForHyattRewards(memberId: $memberId, token: $token, locale: $locale) {
      success
    }
  }
`;
