import React from 'react';
import { asyncComponent } from '@peloton/code-splitting';
import { Route } from '@account/router';
import { HYATT_OFFER_CONNECT, HYATT_OFFER_CONNECT_CONFIRM } from '../routes';

const route = [
  <Route
    key={HYATT_OFFER_CONNECT}
    path={HYATT_OFFER_CONNECT}
    component={asyncComponent(
      () => import('./Page' /* webpackChunkName: "HyattConnect" */),
    )}
    exact
  />,
  <Route
    key={HYATT_OFFER_CONNECT_CONFIRM}
    path={HYATT_OFFER_CONNECT_CONFIRM}
    component={asyncComponent(
      () => import('./ConfirmPage' /* webpackChunkName: "HyattConnect" */),
    )}
    exact
  />,
];

export default route;
