import { accountEnvironmentFlags } from '@account/env';

const envFlags = accountEnvironmentFlags();
const isTest = envFlags.isTest;
const isProd = envFlags.isProd;
const isProdTest = envFlags.isProdTest;
const isStaging = envFlags.isStaging;
const isPreview = !isProd && !isProdTest && !isStaging && !isTest;

export default isPreview;
