import {
  MYMEMBERSHIP_ROUTE as PELOTON_MEMBERSHIP_ROUTE,
  SUBS_ROUTE,
  toSubDetailsUrl,
} from '@peloton/links/account';

export const MYMEMBERSHIP_ROUTE = PELOTON_MEMBERSHIP_ROUTE;
export const ADD_PAYMENT_PROGRAM_ROUTE = `${MYMEMBERSHIP_ROUTE}/addpaymentprogram`;
export const ADD_GIFT_CODE_ROUTE = `${MYMEMBERSHIP_ROUTE}/redeemgiftcode`;
export const CHECKOUT_ROUTE = `${MYMEMBERSHIP_ROUTE}/checkout`;
export const ADD_GIFT_CARD_ROUTE = `${MYMEMBERSHIP_ROUTE}/addgiftcard`;

export const PREFS_ROUTE = '/preferences';

export const toSubPauseUrl = (id: string = ':id') => `${toSubDetailsUrl(id)}/pause`;
export const toSubExtendPauseUrl = (id: string = ':id') =>
  `${toSubDetailsUrl(id)}/pause-extend`;
export const toSubPauseResumeUrl = (id: string = ':id') =>
  `${toSubDetailsUrl(id)}/resume`;
export const toSubPauseSuccessUrl = (id: string = ':id') =>
  `${toSubPauseUrl(id)}/success`;
export const toSubCancelSuccessUrl = (id: string = ':id') =>
  `${toSubDetailsUrl(id)}/cancel-success`;

export const toTransactionUrl = (
  subscriptionId: string = ':id',
  transactionId: string = ':transactionId',
) => `${toSubDetailsUrl(subscriptionId)}/transactions/${transactionId}`;

export const toPreferencesSubscriptionsUrl = () => PREFS_ROUTE + SUBS_ROUTE;
