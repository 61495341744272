import React from 'react';
import { connect } from 'react-redux';
import type { RouteProps } from 'react-router';
import { Route } from 'react-router';
import type { ReducerState as State } from '@account/feature-toggles';
import { NotFoundErrorPage } from '@account/layout';
import type { PageViewPayload } from '@ecomm/analytics';
import { trackPageView } from '@ecomm/analytics';
import type { Toggle } from './models';
import toAreTogglesActive from './toAreTogglesActive';

export type StateProps = {
  isActive: boolean;
};

export type DispatchProps = {
  trackRoute: (payload: PageViewPayload) => void;
};

export type OwnProps = RouteProps & {
  shouldTrack?: boolean;
  toggleId?: Toggle | Toggle[];
};

type Props = OwnProps & StateProps & DispatchProps;

const AccountRoute: React.FC<React.PropsWithChildren<Props>> = ({
  component,
  isActive,
  location,
  path,
  shouldTrack,
  trackRoute,
}) => {
  React.useEffect(() => {
    shouldTrack && location && trackRoute({ pathname: location.pathname });
  }, [location, shouldTrack, trackRoute]);
  return isActive ? (
    <Route path={path} component={component} />
  ) : (
    <Route component={NotFoundErrorPage} />
  );
};

const mapStateToProps = (state: State, { toggleId }: OwnProps): StateProps => ({
  isActive: toAreTogglesActive<State>(toggleId)(state),
});

const mapDispatchToProps: DispatchProps = {
  trackRoute: trackPageView,
};

export default connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps,
)(AccountRoute);
