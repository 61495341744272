import type { SagaIterator } from 'redux-saga';
import { select, put, call } from 'redux-saga/effects';
import {
  getDoesUserExist,
  hasAcceptedTermsError,
  updateBillingAddress,
} from '@ecomm/checkout/redux';
import type { ExistingPaymentCheckoutRequestAction } from '@ecomm/quick-checkout/redux';
import { failCheckout } from '@ecomm/quick-checkout/redux';
import { checkoutSaga } from '@ecomm/quick-checkout/sagas/checkoutSaga';

export default function* (action: ExistingPaymentCheckoutRequestAction): SagaIterator {
  const userShouldLogin = yield select(getDoesUserExist);
  const hasNotAcceptedTerms = yield select(hasAcceptedTermsError);
  const NULL_PAYMENT = 'null_payment';

  if (hasNotAcceptedTerms) {
    yield put(failCheckout('errors.incomplete'));
  } else if (userShouldLogin) {
    yield put(failCheckout('errors.needsLogin'));
  } else {
    yield put(updateBillingAddress('postalCode', action.payload.postalCode));
    yield call(
      checkoutSaga,
      action.payload.checkoutType,
      {
        kind: NULL_PAYMENT,
        id: NULL_PAYMENT,
      },
      action.payload.product,
      action.payload.paymentsOverride,
      action.payload.promotion,
      action.payload.successRedirectOverride,
    );
  }
}
