import type { LogoutOptions } from '@auth0/auth0-spa-js';
import { useCallback } from 'react';
import { toClientDetails } from '@peloton/analytics';
import { useClient } from '@peloton/api';
import type { LogoutOptions as PLogoutOptions } from '@peloton/auth';
import { useOauth } from '@peloton/auth';
import { logOut as legacyLogout } from '@peloton/auth/api';
import { CHECKOUT_ACCESS_TOKEN_STORAGE_KEY } from '@peloton/auth/constants';
import useLocalStorage from '@ecomm/hooks/useLocalStorage';
import { useLoginUrl } from './OauthProvider';

const useLogout = () => {
  const client = useClient();
  const { logout } = useOauth();
  const loginUrl = useLoginUrl();
  const [, , removeToken] = useLocalStorage(CHECKOUT_ACCESS_TOKEN_STORAGE_KEY, '');

  return useCallback(
    async (
      options: PLogoutOptions = {},
      oauthLogoutOptions: LogoutOptions = {},
      shouldLogoutLegacy: boolean = true,
    ) => {
      if (shouldLogoutLegacy) {
        try {
          const clientDetails = options.source ? toClientDetails({}, options.source) : {};
          await legacyLogout(client, clientDetails);
        } catch (e) {
          // if we fail to logout of legacy, continue to oauth logout anyways
        }
      }
      removeToken();

      const { logoutParams, ...logoutOptions } = oauthLogoutOptions;
      logout(
        {
          logoutParams: {
            returnTo: loginUrl(window.location.href),
            ...(!!logoutParams ? logoutParams : {}),
          },
          ...(!!logoutOptions ? logoutOptions : {}),
        },
        true,
      );
    },
    [client, logout, loginUrl],
  );
};

export default useLogout;
