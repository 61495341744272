import React from 'react';
import { Route as ReactRoute } from 'react-router-dom';
import { asyncComponent } from '@peloton/code-splitting';

const url = '/annual-billing-upgrade';

const Route = (
  <ReactRoute
    key={url}
    path={url}
    component={asyncComponent(() => import('./Page' /* webpackChunkName: "Page" */))}
  />
);

export default Route;
