import type { SagaIterator } from 'redux-saga';
import { call, select } from 'redux-saga/effects';
import { getExtLinkEnv, toAccountLink } from '@peloton/external-links';
import { Links } from '@peloton/links';
import { redirect } from '@peloton/navigation';
import { browserProvider } from '@peloton/newrelic/browser-provider';
import { getLocation } from '@peloton/redux';
import { toHrefWithRedirect } from '@studio/auth';

export const loginRedirectSaga = function* (): SagaIterator {
  const extLinkEnv = yield select(getExtLinkEnv);
  const location = yield select(getLocation);
  const loginHref = toHrefWithRedirect(
    Links.login,
    extLinkEnv,
    toAccountLink(location.pathname + (location.search ?? '')),
  );
  const actionReporter = browserProvider.getActionReporter();

  actionReporter('Redirecting to Legacy Login', {});

  yield call(redirect, loginHref);
};

export const notFoundRedirectSaga = function* (): SagaIterator {
  yield call(redirect, '/');
};
