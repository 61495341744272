import { always, any, both, cond, propOr } from 'ramda';
import type { Currency } from '@peloton/internationalize';
import type { Address, ID, Money, Name } from '@ecomm/models';
import { ProductLine } from '@ecomm/shop/models/Product';
import type { Discount } from './Discount';
import type { Item, SingleItem, TradeIn } from './Item';
import {
  isAccessory,
  isSingleItem,
  hasOrIsAccessory,
  hasOrIsBike,
  hasOrIsDevice,
  hasOrIsBikePlus,
  hasOrIsTread,
  hasOrIsTreadPlus,
  hasOrIsGuide,
  isSubscription,
  hasOrIsEquipmentLease,
  hasOrIsRow,
  hasOrIsSubscriptionPrepaidCredit,
} from './Item';

export enum ShippingMethod {
  Peloton = 'fieldops',
  Xpo = 'xpo',
  Shipwire = 'shipwire',
}

export type ShipmentQuote = {
  amount: Money;
  estimated?: boolean;
  method?: ShippingMethod;
};

export const toShipmentTotal = ({ shipmentQuote = { amount: 0 } }: Cart) =>
  shipmentQuote.amount;

export type CartContactInfo = {
  name: Partial<Name>;
  phone?: string;
  address: Partial<Address>;
};

export type GiftCardPayment = {
  amountInCents: number;
  paymentType: string;
  paymentMethodToken: string;
  lastFour: string;
  balance: number;
};

export type GiftCardPaymentPayload = Omit<GiftCardPayment, 'lastFour'> & {
  label: string;
};

export type GiftCardUpdatePaymentsPayload = {
  total: number;
  giftCardPayments: GiftCardPayment[];
};

export type Cart = {
  id: ID;
  currency: Currency;
  discounts: Discount[];
  items: Item[];
  shipmentQuote?: ShipmentQuote;
  subtotal: Money;
  tax: Money;
  total: Money;
  tradeIn?: TradeIn;
  email?: string;
  shipping: CartContactInfo;
  isGift: boolean;
  payments?: GiftCardPayment[];
};

type BillingPartner = {
  accountNumber: string | null;
  name: string;
};

export type Payment = {
  token?: string;
  partner: BillingPartner;
  amount: Money;
};

export const getShippingFromCart = (cart: Cart): CartContactInfo | undefined =>
  cart.shipping;

export const getItemsFromCart = (cart: Cart): Item[] => cart.items;

export const getNumberOfItemsInCart = (cart: Cart): number => {
  // WEB-7189: Cart items undefined with defined cart bug
  // Unsure how this bug is able to occur but this should stop it from ever bubbling up
  // Just figured it out with sakib,
  // So basically if aq `update email in cart` call resolves before the cart summary,
  // Then there is a cart with just an email and everything else undefined
  // Stay tuned for resolution
  if (!cart.items) {
    return 0;
  }

  return cart.items.reduce((acc, curr) => acc + curr.quantity, 0);
};

export const getNumberOfDevicesInCart = (cart: Cart): number =>
  getItemsByTypeInCart(hasOrIsDevice)(cart).reduce((acc, curr) => acc + curr.quantity, 0);

export const getNumberOfSubscriptionsInCart = (cart: Cart): number =>
  getItemsByTypeInCart(isSubscription)(cart).reduce(
    (acc, curr) => acc + curr.quantity,
    0,
  );

export const getSingleItemsFromCart = (cart: Cart): SingleItem[] =>
  cart.items &&
  cart.items.reduce(
    (items, item) => [...(isSingleItem(item) ? [item] : item.items), ...items],
    [],
  );

export const getShipmentQuoteFromCart = (cart: Cart): ShipmentQuote | undefined =>
  cart.shipmentQuote;

export const hasShipmentQuote = (cart: Cart): boolean => Boolean(cart.shipmentQuote);

export const hasPostalCode = (cart: Cart): boolean =>
  Boolean(cart.shipping && cart.shipping.address && cart.shipping.address.postalCode);

export const hasDeviceInCart = (cart: Cart): boolean =>
  getItemsByTypeInCart(hasOrIsDevice)(cart).length !== 0;

export const hasBikeInCart = (cart: Cart): boolean =>
  getItemsByTypeInCart(hasOrIsBike)(cart).length !== 0;

export const hasBikePlusInCart = (cart: Cart): boolean =>
  getItemsByTypeInCart(hasOrIsBikePlus)(cart).length !== 0;

export const hasTreadPlusInCart = (cart: Cart): boolean =>
  getItemsByTypeInCart(hasOrIsTreadPlus)(cart).length !== 0;

export const hasTreadInCart = (cart: Cart): boolean =>
  getItemsByTypeInCart(hasOrIsTread)(cart).length !== 0;

export const hasTreadAndBikeInCart = (cart: Cart): boolean =>
  both(hasTreadPlusInCart, hasBikeInCart)(cart);

export const hasGuideInCart = (cart: Cart): boolean =>
  getItemsByTypeInCart(hasOrIsGuide)(cart).length !== 0;

export const hasRowInCart = (cart: Cart): boolean =>
  getItemsByTypeInCart(hasOrIsRow)(cart).length !== 0;

export const hasOnlyRowInCart = (cart: Cart): boolean => {
  const productsWithSetup = new Set([
    ProductLine.BikePlus,
    ProductLine.Bike,
    ProductLine.Tread,
    ProductLine.TreadPlus,
  ]);
  return (
    hasRowInCart(cart) &&
    getSingleItemsFromCart(cart).every(item => !productsWithSetup.has(item.productLine))
  );
};

export const hasOnlyRowDevicesInCart = (cart: Cart): boolean =>
  hasRowInCart(cart) &&
  getItemsByTypeInCart(hasOrIsRow)(cart).length === getNumberOfDevicesInCart(cart);

export const hasOnlyBuyoutInCart = (cart: Cart) => {
  const buyoutItems = cart.items.filter((i: Item) => i.slug && i.slug.includes('opc-b-'));
  return buyoutItems.length === 1 && cart.items.length === 2;
};

export const whichDeviceInCart = (cart: Cart) =>
  cond([
    [hasTreadAndBikeInCart, always('both')],
    [hasBikeInCart, always('bike')],
    [hasTreadPlusInCart, always('tread')],
    [() => true, always('none')],
  ])(cart);

export const hasAccessoriesInCart = (cart: Cart): boolean =>
  getItemsByTypeInCart(hasOrIsAccessory)(cart).length !== 0;

export const hasEquipmentLeaseInCart = (cart: Cart): boolean =>
  getItemsByTypeInCart(hasOrIsEquipmentLease)(cart).length !== 0;

export const hasSubscriptionPrepaidCreditInCart = (cart: Cart): boolean =>
  getItemsByTypeInCart(hasOrIsSubscriptionPrepaidCredit)(cart).length !== 0;

export const getItemsByTypeInCart = (selector: (item: Item) => boolean) => (
  cart: Cart,
): Item[] => propOr<[], Cart, Item[]>([], 'items', cart).filter(selector);

const hasCorrectNumOfSubscriptionsForDevices = (cart: Cart): boolean =>
  cart.items.length > 0
    ? getNumberOfDevicesInCart(cart) !== getNumberOfSubscriptionsInCart(cart)
    : true;

export const getSubtotalFromCart = (cart: Cart): Money => cart.subtotal;

export const getTaxFromCart = (cart: Cart): Money => cart.tax;

export const getTotalFromCart = (cart: Cart): Money => cart.total;

export const getDiscountsFromCart = (cart: Cart): Discount[] => cart.discounts;

export const isEmpty = (cart?: Cart): boolean =>
  cart && cart.items ? cart.items.length === 0 : true;

export const doesCartNeedSubscription = (cart?: Cart): boolean =>
  cart && cart.items
    ? hasDeviceInCart(cart) && hasCorrectNumOfSubscriptionsForDevices(cart)
    : false;

export const getDevicePackagesWithAccs = (cart: Cart): Item[] => {
  return propOr<[], Cart, Item[]>([], 'items', cart)
    .filter(item => hasOrIsDevice(item))
    .filter(item => any(isAccessory, propOr([], 'items', item)));
};

export const sumGCToRedeem = (payments: GiftCardPayment[]) =>
  payments.reduce((acc, data) => {
    if (data.balance === 0) {
      return acc;
    }

    return acc + data.amountInCents;
  }, 0);

// Check if possible to add one more gift card
export const recalculateRemainingTotal = (
  total: number = 0,
  payments: GiftCardPayment[] = [],
): number => {
  const sumToRedeem = sumGCToRedeem(payments);
  if (total >= sumToRedeem) {
    return total - sumToRedeem;
  }

  return total;
};

// Calculate remaining total for checkout
export const getRemainingTotal = (
  total: number = 0,
  payments: GiftCardPayment[] = [],
): number => Math.max(total - sumGCToRedeem(payments), 0);
