import { adjust, append, findIndex, propEq, contains, filter } from 'ramda';
import type { Reducer } from 'redux';
import type { TypedAction } from '@peloton/redux';
import type { PendingResult } from '@engage/result';
import { error as engageError, ok, pending } from '@engage/result';
import type { UserKey } from '../api/manageSharedUsers';
import type ExtendPauseSubscription from '../models/ExtendPauseSubscription';
import type PauseSubscription from '../models/PauseSubscription';
import type UnpauseSubscription from '../models/UnpauseSubscription';
import type {
  Subscription,
  Device,
  DeviceSubscription,
  SubscriptionKind,
  SubUser,
} from '../Subscription';
import NS from './namespace';
import type { UpdatesState } from './updates';
import { updatesReducer, defaultUpdatesState, pruneUnknownStateProps } from './updates';

export const subscriptionReducer: Reducer<State> = (
  state = defaultState,
  action: Action,
) => {
  switch (action.type) {
    case SubscriptionsActionType.SubDetailsRequest:
      return {
        ...state,
        isLoading: true,
      };

    case SubscriptionsActionType.SubsRequest:
      return {
        ...state,
        isLoading: true,
      };

    case SubscriptionsActionType.SubsRequestSuccess:
      return {
        ...state,
        entities: action.payload.subscriptions,
        isLoading: false,
        error: false,
      };

    case SubscriptionsActionType.SubsRequestFailure:
      return {
        ...state,
        isLoading: false,
        error: true,
      };

    case SubscriptionsActionType.DetachSubRequestFailure:
      return {
        ...state,
        isLoading: false,
        error: true,
      };

    case SubscriptionsActionType.DetachSubRequest:
      return {
        ...state,
        isLoading: true,
        error: false,
      };

    case SubscriptionsActionType.AddSharedUserSuccess:
      return {
        ...state,
        entities: state.entities
          ? toUpdatedSubs(
              state.entities,
              action.payload.subId,
              toUpdatedSubWithAddedUser(action.payload.addedUser),
            )
          : undefined,
      };

    case SubscriptionsActionType.RemoveSharedUserSuccess:
      return {
        ...state,
        entities: state.entities
          ? toUpdatedSubs(
              state.entities,
              action.payload.subId,
              toUpdatedSubWithRemovedUser(action.payload.userId),
            )
          : undefined,
      };

    case SubscriptionsActionType.UpdateSubscription:
      return {
        ...state,
        entities: state.entities
          ? toUpdatedSubs(state.entities, action.payload.id, () => action.payload)
          : undefined,
      };

    default: {
      const nextCancellationsState = updatesReducer(
        pruneUnknownStateProps(state),
        action,
      );
      if (nextCancellationsState.cancellation === state.cancellation) {
        return state;
      }

      return {
        ...state,
        ...nextCancellationsState,
      };
    }
  }
};

const toUpdatedSubs = (
  subs: Subscription[],
  subId: string,
  updateSubFn: (sub: Subscription) => Subscription,
) => {
  const idx = findIndex(propEq('id', subId))(subs);

  return idx === -1 ? subs : adjust(updateSubFn, idx, subs);
};

const hasSharedUser = (user: SubUser, sub: Subscription) =>
  contains(
    user.id,
    sub.sharedUserSet.map(u => u.id),
  );

const toUpdatedSubWithAddedUser = (user: SubUser) => (sub: Subscription) =>
  hasSharedUser(user, sub)
    ? sub
    : { ...sub, sharedUserSet: append(user, sub.sharedUserSet) };

const toUpdatedSubWithRemovedUser = (userId: string) => (sub: Subscription) => ({
  ...sub,
  sharedUserSet: filter((user: SubUser) => user.id !== userId)(sub.sharedUserSet),
});

export const defaultState: State = {
  ...defaultUpdatesState,
  entities: undefined,
  isLoading: false,
  error: false,
};

type State = {
  entities?: Subscription[];
  isLoading: boolean;
  error: boolean;
} & UpdatesState;

// Actions

export enum SubscriptionsActionType {
  SubsRequest = 'pelo/preferences/subscriptions/REQUEST',
  SubsRequestSuccess = 'pelo/preferences/subscriptions/REQUEST_SUCCESS',
  SubsRequestFailure = 'pelo/preferences/subscriptions/REQUEST_FAILURE',
  SubDetailsRequest = 'pelo/preferences/subscriptions/details/REQUEST',
  DetachSubRequest = 'pelo/preferences/subscription/details/DETACH_REQUEST',
  DetachSubRequestFailure = 'pelo/preferences/subscription/details/DETACH_REQUEST_FAILURE',
  AddSharedUserRequest = 'pelo/subscriptions/ADD_SHARED_USER_REQUEST',
  AddSharedUserSuccess = 'pelo/subscriptions/ADD_SHARED_USER_SUCCESS',
  RemoveSharedUserRequest = 'pelo/subscriptions/REMOVE_SHARED_USER_REQUEST',
  RemoveSharedUserSuccess = 'pelo/subscriptions/REMOVE_SHARED_USER_SUCCESS',
  UpdateSubscription = 'pelo/subscriptions/UPDATE_SUBSCRIPTION',
  PauseSubscriptionRequest = 'pelo/preferences/subscriptions/PAUSE',
  ExtendPauseSubscriptionRequest = 'pelo/preferences/subscriptions/EXTEND_PAUSE',
  UnpauseSubscriptionRequest = 'pelo/preferences/subscriptions/UNPAUSE',
}

export type LoadSubDetailsAction = TypedAction<
  ReturnType<typeof loadSubscription>,
  SubscriptionsActionType.SubDetailsRequest
>;
export type DetachSubAction = TypedAction<
  ReturnType<typeof detachSubscription>,
  SubscriptionsActionType.DetachSubRequest
>;
export type PauseSubscriptionAction = TypedAction<
  ReturnType<typeof pauseSubscription>,
  SubscriptionsActionType.PauseSubscriptionRequest
>;
export type ExtendPauseSubscriptionAction = TypedAction<
  ReturnType<typeof extendPauseSubscription>,
  SubscriptionsActionType.ExtendPauseSubscriptionRequest
>;
export type UnpauseSubscriptionAction = TypedAction<
  ReturnType<typeof unpauseSubscription>,
  SubscriptionsActionType.UnpauseSubscriptionRequest
>;
type Action =
  | TypedAction<ReturnType<typeof loadSubscriptions>, SubscriptionsActionType.SubsRequest>
  | TypedAction<
      ReturnType<typeof loadSubscriptionsSuccess>,
      SubscriptionsActionType.SubsRequestSuccess
    >
  | TypedAction<
      ReturnType<typeof loadSubscriptionsFailure>,
      SubscriptionsActionType.SubsRequestFailure
    >
  | TypedAction<
      ReturnType<typeof detachSubscriptionFailure>,
      SubscriptionsActionType.DetachSubRequestFailure
    >
  | TypedAction<
      ReturnType<typeof addSharedUserSuccess>,
      SubscriptionsActionType.AddSharedUserSuccess
    >
  | TypedAction<
      ReturnType<typeof removeSharedUserSuccess>,
      SubscriptionsActionType.RemoveSharedUserSuccess
    >
  | TypedAction<
      ReturnType<typeof updateSubscription>,
      SubscriptionsActionType.UpdateSubscription
    >
  | PauseSubscriptionAction
  | UnpauseSubscriptionAction
  | LoadSubDetailsAction
  | DetachSubAction;

export const loadSubscriptions = (id?: string) => ({
  type: SubscriptionsActionType.SubsRequest,
  payload: {
    id,
  },
});

export const loadSubscriptionsSuccess = (subs: Subscription[], id?: string) => ({
  type: SubscriptionsActionType.SubsRequestSuccess,
  payload: {
    id,
    subscriptions: subs,
  },
});

export const loadSubscriptionsFailure = (id?: string) => ({
  type: SubscriptionsActionType.SubsRequestFailure,
  payload: {
    id,
  },
});

export const loadSubscription = (id: string) => ({
  type: SubscriptionsActionType.SubDetailsRequest,
  payload: id,
});

export const detachSubscription = (sub: DeviceSubscription, device: Device) => ({
  type: SubscriptionsActionType.DetachSubRequest,
  payload: {
    subId: sub.id,
    deviceId: device.id,
    deviceType: device.kind,
  },
});

export const detachSubscriptionFailure = () => ({
  type: SubscriptionsActionType.DetachSubRequestFailure,
  payload: {},
});

export const addSharedUser = (
  subKind: SubscriptionKind,
  subId: string,
  user: UserKey,
  callbacks: SubscriptionCallbacks,
) => ({
  type: SubscriptionsActionType.AddSharedUserRequest,
  payload: {
    subKind,
    subId,
    user,
    callbacks,
  },
});

export const addSharedUserSuccess = (subId: string, addedUser: SubUser) => ({
  type: SubscriptionsActionType.AddSharedUserSuccess,
  payload: {
    subId,
    addedUser,
  },
});

export const removeSharedUser = (
  subKind: SubscriptionKind,
  subId: string,
  userId: string,
  callbacks: SubscriptionCallbacks,
) => ({
  type: SubscriptionsActionType.RemoveSharedUserRequest,
  payload: {
    subKind,
    subId,
    userId,
    callbacks,
  },
});

export const removeSharedUserSuccess = (subId: string, userId: string) => ({
  type: SubscriptionsActionType.RemoveSharedUserSuccess,
  payload: {
    subId,
    userId,
  },
});

export const updateSubscription = (subscription: Subscription) => ({
  type: SubscriptionsActionType.UpdateSubscription,
  payload: subscription,
});

export type SubscriptionCallbacks = {
  onSuccess: () => void;
  onError: (error: Error) => void;
};

export const pauseSubscription = (
  values: PauseSubscription,
  callbacks: SubscriptionCallbacks,
) => ({
  type: SubscriptionsActionType.PauseSubscriptionRequest,
  payload: values,
  ...callbacks,
});

export const extendPauseSubscription = (
  values: ExtendPauseSubscription,
  callbacks: SubscriptionCallbacks,
) => ({
  type: SubscriptionsActionType.ExtendPauseSubscriptionRequest,
  payload: values,
  ...callbacks,
});

export const unpauseSubscription = (
  values: UnpauseSubscription,
  callbacks: SubscriptionCallbacks,
) => ({
  type: SubscriptionsActionType.UnpauseSubscriptionRequest,
  payload: values,
  ...callbacks,
});

// Selectors

export type SubscriptionState = { [NS]: State };

/**
 * @deprecated use {@link getSubscriptionsResult} instead
 */
export const getSubscriptions = (state: SubscriptionState) => state[NS].entities;

export const getSubscriptionsResult = (
  state: SubscriptionState,
): PendingResult<Subscription[], undefined> => {
  const reducerState = state[NS];
  if (reducerState.error && !reducerState.entities) {
    return engageError(undefined);
  }

  if (reducerState.isLoading || !reducerState.entities) {
    return pending;
  }

  return ok(reducerState.entities);
};
