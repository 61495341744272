const isDigitRegex = /^\d+$/;

const isUniversalId = (id: string) => isDigitRegex.test(id) && id.length == 16;

const createUuid = (id: string) => {
  const uuidPt1 = id.substring(0, 8);
  const uuidPt2 = id.substring(8, 12);
  const uuidPt3 = id.substring(12, 16);
  const uuidPt4 = id.substring(16, 20);
  const uuidPt5 = id.substring(20);
  const formattedId = [uuidPt1, uuidPt2, uuidPt3, uuidPt4, uuidPt5].join('-');
  return formattedId;
};

// defining the regex expression isUuidRegex in each conditional block because regex with the global flag (/g)
// keeps internally the state of the search and doesn't reset correctly
// this means that multiple .test() calls alternate between true and false as subsequent calls start from after the original match.
const isUuid = (id: string) => {
  if (id.length >= 36) {
    const isUuidRegex = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
    return isUuidRegex.test(id);
  } else if (id.length >= 32) {
    const isUuidRegex = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
    const formattedId = createUuid(id);
    return isUuidRegex.test(formattedId);
  } else {
    return false;
  }
};

export const isValidOrderId = (id: string) => {
  if (isUniversalId(id)) {
    return true;
  } else if (isUuid(id)) {
    return true;
  } else {
    return false;
  }
};
