import { Headline, spacing, red, white } from '@pelotoncycle/design-system';
import { Spinner } from '@pelotoncycle/design-system/icons/loading';
import React from 'react';
import styled from 'styled-components';

type Props = {
  headline?: string;
};

export const Loading: React.FC<Props> = ({ headline }) => {
  return (
    <Container>
      <SpinnerStyled primaryColor={red[50]} height={40} />
      {headline && <Headline size="medium">{headline}</Headline>}
    </Container>
  );
};

const Container = styled.div`
  color: ${white};
  text-align: center;
`;

const SpinnerStyled = styled(Spinner)`
  margin-bottom: ${spacing[16]};
`;
