import React from 'react';
import { Helmet } from 'react-helmet';
import { SWRConfig } from 'swr';
import type { Locale } from '@peloton/internationalize';
import { toLocale, toISOLang } from '@peloton/internationalize';
import { toFetcher } from '@content/client/craGlobalEntriesFetcher';
import { Context } from './context';
import isPreview from './helpers/isPreview';
import { useCopyClient } from './useCopyClient';

type Props = {
  locale?: Locale;
};
const DomainBasedCopyProvider: React.FC<React.PropsWithChildren<Props>> = ({
  locale = toLocale(),
  children,
}) => {
  const lang = toISOLang(locale);
  const client = useCopyClient(locale, isPreview);

  if (!client) {
    return null;
  } else {
    return (
      <>
        <Helmet htmlAttributes={{ lang }} />
        <SWRConfig value={{ fetcher: toFetcher(isPreview) }}>
          <Context.Provider children={children} value={client} />
        </SWRConfig>
      </>
    );
  }
};

export default DomainBasedCopyProvider;
