import { path } from 'ramda';
import type { CopyValues } from '@peloton/copy-generic';
import MissingKeyError from '@peloton/copy/MissingKeyError';
import type { ReportError } from '@peloton/error-reporting';
import type { QueryHookResultV2 as QueryHookResult } from '@peloton/graphql/apolloV2';
import toFormattedText from './toFormattedText';

export const unwrapValueWithFormatting = (
  query: (arg: { variables: { id: string } }) => QueryHookResult<any, any>,
  key: string,
  id: string | undefined,
  values: CopyValues | undefined,
  reportError: ReportError,
  defaultValue?: string,
) => toFormatUnwrappedCopy(query, key, id || '', reportError, defaultValue)(values);

export const toFormatUnwrappedCopy = (
  query: (arg: { variables: { id: string } }) => QueryHookResult<any, any>,
  key: string,
  id: string,
  reportError: ReportError,
  defaultValue?: string,
) => {
  const queryResult = query({ variables: { id } });
  const unformattedText: string = path(['data', key, 'value'], queryResult) || '';
  if (Boolean(unformattedText)) {
    return (values?: CopyValues) => toFormattedText(unformattedText, values) as string;
  } else {
    if (id === '') {
      if (defaultValue !== undefined) {
        return () => defaultValue;
      } else {
        reportError(new Error(`Improper usage: ${key} called with undefined id`));
      }
    } else {
      reportError(new MissingKeyError(key, id));
    }
    return () => '';
  }
};
