import { brand, white } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { media } from '@peloton/styles';
import { AccountMarkdown } from '@account/copy';
import { spaces } from '@account/styles';
import { b3 } from '@acme-ui/typography';
import type { Props } from '../HeaderViewProps';
import StyledContainer from '../StyledContainer';
import StyledLink from '../StyledLink';
import LogoMark from './LogoMark';

const HeaderViewMobile: React.FC<React.PropsWithChildren<Props>> = ({
  className,
  homeLink,
  hasHelpBlock = false,
}) => {
  const logoLink = toLogoLink(homeLink);
  return (
    <StyledContainer className={className}>
      {hasHelpBlock ? (
        <FlexContainer>
          <HelpBlock>
            <AccountMarkdown id="header.helpBlock.mobile" />
          </HelpBlock>
          <LogoContainer>{logoLink}</LogoContainer>
        </FlexContainer>
      ) : (
        <MaxWidthContainer>{logoLink}</MaxWidthContainer>
      )}
    </StyledContainer>
  );
};

export default HeaderViewMobile;

const toLogoLink = (href: string): React.ReactElement => (
  <StyledLink href={href}>
    <LogoMark />
  </StyledLink>
);

const MaxWidthContainer = styled.div`
  align-items: center;
  display: flex;
  height: 40px;
  justify-content: space-between;
  margin: auto;
  padding: 0 15px;

  ${media.tablet`
    padding: 0 20px;
  `}

  svg {
    height: 22px;
    width: 22px;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const HelpBlock = styled.div`
  ${b3}
  text-align: center;
  background-color: ${brand.darkest};
  color: ${white};
  padding: ${spaces.medium}px 0;
`;

const LogoContainer = styled.div`
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    height: 32px;
    width: 32px;
  }
`;
