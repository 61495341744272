import { call, put } from 'redux-saga/effects';
import type { Client } from '@peloton/api';
import { track } from '@engage/analytics';
import { isDigitalSubTrial } from '@engage/subscriptions';
import { cancelDigitalSubscription } from '@engage/subscriptions/api';
import type { SubsDigCancelRequestAction } from '@engage/subscriptions/redux';
import { cancelDigFailure, cancelDigSuccess } from '@engage/subscriptions/redux';

export const trackCanceledSubscription = (reason: string, isFreeTrial: boolean) => ({
  event: 'Canceled Subscription',
  reason,
  isFreeTrial,
});

const cancelSubscription = function* (
  client: Client,
  action: SubsDigCancelRequestAction,
) {
  try {
    const { reason, sub } = action.payload;

    yield call(cancelDigitalSubscription, client, sub.id);
    yield put(track(trackCanceledSubscription(reason, isDigitalSubTrial(sub))));
    yield put(cancelDigSuccess());
  } catch (e) {
    yield put(cancelDigFailure());
  }
};

export default cancelSubscription;
