import { useTrackCallback } from '@account/analytics';
import { HyattConnect } from '@account/analytics/events';
import useTrackPageLoaded from '@account/analytics/hooks/useTrackPageLoaded';

export const useTrackStartPageLoaded = () =>
  useTrackPageLoaded({ pageName: 'Hyatt Connect Start' });

export const useTrackConfirmPageLoaded = () =>
  useTrackPageLoaded({ pageName: 'Hyatt Connect Confirm' });

export const useTracking = () => {
  const { track } = useTrackCallback();

  return {
    trackContinueClick: () =>
      track({
        event: HyattConnect.ClickedContinue,
      }),
    trackAcceptClick: () =>
      track({
        event: HyattConnect.ClickedAccept,
      }),
    trackCancelClick: () =>
      track({
        event: HyattConnect.ClickedCancel,
      }),
    trackTryAgainClick: () =>
      track({
        event: HyattConnect.ClickedTryAgain,
      }),
    trackExploreClick: () =>
      track({
        event: HyattConnect.ClickedExplore,
      }),
    trackConnectSucceeded: () =>
      track({
        event: HyattConnect.ConnectSucceeded,
      }),
    trackConnectFailed: (error: string) =>
      track({
        event: HyattConnect.ConnectFailed,
        instanceProperties: { error },
      }),
  };
};
