import { find, pipe, propEq, filter, anyPass, pathSatisfies, allPass } from 'ramda';
import type { Reducer } from 'redux';
import type { TypedAction } from '@peloton/redux';
import type { Card } from '@account/api/models/Card';
import { PaymentType } from '@account/api/models/PaymentMethod';

const cardsReducer: Reducer<State> = (state = defaultState, action: Action) => {
  switch (action.type) {
    case Type.Request:
      return {
        ...state,
        isLoading: true,
      };
    case Type.RequestSuccess:
      return {
        entities: action.payload,
        isLoading: false,
        error: undefined,
      };
    case Type.RequestFailure:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

const defaultState: State = {
  entities: undefined,
  error: undefined,
  isLoading: false,
};

type State = {
  entities?: Card[];
  error?: unknown;
  isLoading: boolean;
};

// Actions

type Action =
  | TypedAction<ReturnType<typeof loadCards>, Type.Request>
  | TypedAction<ReturnType<typeof loadCardsSuccess>, Type.RequestSuccess>
  | TypedAction<ReturnType<typeof loadCardsFailure>, Type.RequestFailure>;

enum Type {
  Request = 'pelo/preferences/cards/REQUEST',
  RequestSuccess = 'pelo/preferences/cards/REQUEST_SUCCESS',
  RequestFailure = 'pelo/preferences/cards/REQUEST_FAILURE',
}

const loadCards = (isGiftCardMyMembershipEnabled: boolean = false) => ({
  type: Type.Request,
  payload: {
    isGiftCardMyMembershipEnabled,
  },
});

const loadCardsSuccess = (cards: Card[]) => ({
  type: Type.RequestSuccess,
  payload: cards,
});

const loadCardsFailure = (error: unknown) => ({
  type: Type.RequestFailure,
  payload: error,
});

// Selectors

export type CardsState = { cards: State };

const getCards = (state: CardsState) => state.cards.entities ?? [];

const isLoadingCards = (state: CardsState) => state.cards.isLoading;

export const getGiftCards = pipe<CardsState, Card[], Card[]>(
  getCards,
  filter(
    allPass([
      propEq('paymentType', PaymentType.GiftCard),
      pathSatisfies((amount: number) => amount > 0, ['balance', 'amount']),
    ]),
  ),
);

const getDefaultCard = pipe<CardsState, Card[], Card | undefined>(
  getCards,
  find(propEq('paymentType', PaymentType.CreditCard)),
);

const getBenefitHubPaymentMethod = pipe<CardsState, Card[], Card | undefined>(
  getCards,
  find(propEq('paymentType', PaymentType.CorporateBenefit)),
);

const getMembershipDetailsCards = pipe<CardsState, Card[], Card[]>(
  getCards,
  filter(
    anyPass([
      propEq('paymentType', PaymentType.CorporateBenefit),
      propEq('isDefault', true),
    ]),
  ),
);

const getCardsError = (state: CardsState) => state.cards.error;

export {
  cardsReducer,
  getCards,
  getDefaultCard,
  getBenefitHubPaymentMethod,
  getMembershipDetailsCards,
  getCardsError,
  isLoadingCards,
  loadCards,
  loadCardsSuccess,
  loadCardsFailure,
  Type as CardsActionType,
};
