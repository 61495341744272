import type { ReactSDKClient } from '@optimizely/react-sdk';
import { OptimizelyProvider, OptimizelyContext } from '@optimizely/react-sdk';
import React, { useContext, useState } from 'react';
import type { Locale } from '@peloton/internationalize';
import { useAsyncEffect } from '@ecomm/hooks';
import { PageSpinner } from '@ecomm/spinner';

type Props = {
  optimizelyClient: ReactSDKClient;
  locale: Locale;
};

const AsyncClient: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const { optimizely } = useContext(OptimizelyContext);
  const [clientReady, setClientReady] = useState(false);

  useAsyncEffect(async () => {
    await optimizely?.onReady();
    setClientReady(true);
  }, []);

  if (!clientReady) {
    return <PageSpinner />;
  }

  return <>{children}</>;
};

const optimizelyCookieName = 'optimizelyEndUserId';
const accountCookieName = 'accountOptimizelyEndUserId';

const getCookieValue = (cookieName: string): string | null => {
  const maybeCookieMatch = document.cookie.match(
    new RegExp(`(^| )${cookieName}=([^;]+)`),
  );
  return maybeCookieMatch ? maybeCookieMatch[2] : null;
};

const toUserId = (): string => {
  try {
    const oneWeek = 60 * 60 * 24 * 7;
    const value = getCookieValue(accountCookieName);
    if (value) {
      return value;
    }
    const randomUUID = window.crypto.randomUUID();
    document.cookie = `${accountCookieName}=${randomUUID};max-age=${oneWeek}`;
    return randomUUID;
  } catch (_) {
    return 'userId';
  }
};

export const getUserIdFromCookie = () =>
  getCookieValue(optimizelyCookieName) ?? toUserId();

const Provider: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  optimizelyClient,
  locale,
}) => {
  const id = React.useMemo(() => getUserIdFromCookie(), []);
  return (
    <OptimizelyProvider
      optimizely={optimizelyClient}
      user={{
        id,
        attributes: {
          locale,
        },
      }}
    >
      <AsyncClient>{children}</AsyncClient>
    </OptimizelyProvider>
  );
};
export default Provider;
