import { toProspectReferralPath } from '@account/urls';
import { ReferralTypes } from '../constants';

export const getProspectCFUReferralPath = (
  userId: string,
  queryParams: Record<string, string>,
) => {
  const queryString = Object.keys(queryParams)
    .map(param => `${param}=${queryParams[param]}`)
    .join('&');
  return `${toProspectReferralPath(userId)}${queryString ? `?${queryString}` : ''}`;
};

export const GUEST_PASS_CODE_ENDPOINT = '/api/user/guestPass';
export const GUEST_PASS_ELIGIBILITY_ENDPOINT = '/api/user/guestPassEligibility';
export const getGuestPassReferralHistoryPath = (userId: string) =>
  `/api/user/${userId}/digital_guest_pass_referral_history`;

export const BASE_REFERRALS_URL = '/settings/referrals';
export const APP_REFERRALS_URL = `${BASE_REFERRALS_URL}/${ReferralTypes.App}`;
export const CFU_REFERRALS_URL = `${BASE_REFERRALS_URL}/${ReferralTypes.Equipment}`;
