export const REMOTE_ACTIVATION_ROUTE = '/activate';
export const ADD_ACCOUNT_ROUTE = '/add-account';
export const SUCCESS_ROUTE = '/success';
export const REMOTE_ACTIVATION_PROFILE_ROUTE = '/activate-profile';
export const REMOTE_ACTIVATION_CHECKOUT_ROUTE = 'checkout';

export const toRemoteActivationPath = (activationId: string = ':activationId') =>
  '/remote-activate/' + activationId;

export const remoteIdQueryParam = 'remoteId';
export const toRemoteIdQueryParam = (remoteId: string) =>
  `?${remoteIdQueryParam}=${remoteId}`;
