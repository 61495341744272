import type { Country } from '@peloton/internationalize';
import { Status } from '@ecomm/models';
import type { PaymentGateway } from '@ecomm/payment';
import type { SetupIntentOptions } from '@ecomm/payment/sagas';

export enum UpdatePaymentActionTypes {
  UpdatePaymentRequested = 'account/payment/UPDATE_PAYMENT_REQUESTED',
  UpdatePaymentSuccess = 'account/payment/UPDATE_PAYMENT_SUCCESS',
  UpdatePaymentFailure = 'account/payment/UPDATE_PAYMENT_FAILURE',
  UpdatePayment = 'account/payment/UPDATE_PAYMENT',
}

export type UpdatePaymentState = {
  updatePaymentStatus: UpdatePaymentReducerState;
};

export const UPDATE_PAYMENT_NAMESPACE = 'updatePaymentStatus';

export type UpdatePaymentAction = {
  type: UpdatePaymentActionTypes.UpdatePayment;
  payload: SetupIntentOptions & {
    country: Country;
    capture: boolean;
    source: string;
    orderId?: string;
    onSuccess?: () => void;
  };
};

type UpdatePaymentSuccessAction = {
  type: UpdatePaymentActionTypes.UpdatePaymentSuccess;
};

type UpdatePaymentFailureAction = {
  type: UpdatePaymentActionTypes.UpdatePaymentFailure;
  payload: string;
};

type UpdatePaymentRequestedAction = {
  type: UpdatePaymentActionTypes.UpdatePaymentRequested;
};

export const updateAndCapturePayment = (
  paymentGateway: PaymentGateway,
  postalCode: string,
  country: Country,
  source: string,
  recaptchaToken?: string,
  orderId?: string,
  onSuccess?: () => void,
): UpdatePaymentAction => ({
  type: UpdatePaymentActionTypes.UpdatePayment,
  payload: {
    paymentGateway,
    postalCode,
    capture: true,
    country,
    source,
    orderId,
    recaptchaToken,
    onSuccess,
  },
});

export const updatePayment = (
  paymentGateway: PaymentGateway,
  postalCode: string,
  country: Country,
  source: string,
  onSuccess?: () => void,
  paymentsOverride?: SetupIntentOptions['paymentsOverride'],
): UpdatePaymentAction => ({
  type: UpdatePaymentActionTypes.UpdatePayment,
  payload: {
    paymentGateway,
    postalCode,
    capture: false,
    country,
    source,
    onSuccess,
    paymentsOverride,
  },
});

export const updatePaymentWithCaptcha = (
  paymentGateway: PaymentGateway,
  postalCode: string,
  country: Country,
  source: string,
  recaptchaToken: string,
  onSuccess?: () => void,
  paymentsOverride?: SetupIntentOptions['paymentsOverride'],
): UpdatePaymentAction => ({
  type: UpdatePaymentActionTypes.UpdatePayment,
  payload: {
    paymentGateway,
    postalCode,
    country,
    capture: false,
    source,
    recaptchaToken,
    onSuccess,
    paymentsOverride,
  },
});

export const updatePaymentSuccess = (): UpdatePaymentSuccessAction => ({
  type: UpdatePaymentActionTypes.UpdatePaymentSuccess,
});

export const updatePaymentFailure = (payload: any): UpdatePaymentFailureAction => ({
  type: UpdatePaymentActionTypes.UpdatePaymentFailure,
  payload,
});

export const updatePaymentRequested = (): UpdatePaymentRequestedAction => ({
  type: UpdatePaymentActionTypes.UpdatePaymentRequested,
});

export const getUpdatePaymentError = (state: UpdatePaymentState) =>
  state.updatePaymentStatus.error;

export const getUpdatePaymentStatus = (state: UpdatePaymentState) =>
  state.updatePaymentStatus.status;

type UpdatePaymentReducerState = {
  status: Status;
  error: any;
};

const initialState = {
  status: Status.Init,
  error: null,
};

type Action =
  | UpdatePaymentSuccessAction
  | UpdatePaymentFailureAction
  | UpdatePaymentRequestedAction;

export default (state: UpdatePaymentReducerState = initialState, action: Action) => {
  switch (action.type) {
    case UpdatePaymentActionTypes.UpdatePaymentFailure:
      return { status: Status.Failed, error: action.payload };
    case UpdatePaymentActionTypes.UpdatePaymentSuccess:
      return { status: Status.Loaded, error: null };
    case UpdatePaymentActionTypes.UpdatePaymentRequested:
      return { status: Status.Loading, error: null };
    default:
      return state;
  }
};
