import type { PaymentGateway } from '@ecomm/payment';
import type { SetupIntentOptions } from '@ecomm/payment/sagas';
import type { NextStep } from '../../navigation/useNextStepUrl';
import type { MutationCustomerSetRefundPaymentMethodMutationFn } from '../SetPaymentMethod.generated';

type AddRefundPaymentMethodPayload = SetupIntentOptions & {
  setPaymentMethod: MutationCustomerSetRefundPaymentMethodMutationFn;
  orderId: string;
  nextStep: NextStep | undefined;
};

export enum ActionTypes {
  AddRefundPaymentMethod = 'account/refund_payment/ADD_REFUND_PAYMENT_METHOD',
  AddRefundPaymentError = 'account/refund_payment/ADD_REFUND_PAYMENT_ERROR',
  AddRefundPaymentLoading = 'account/refund_payment/ADD_REFUND_PAYMENT_LOADING',
}

export type AddRefundPaymentMethodAction = {
  type: ActionTypes.AddRefundPaymentMethod;
  payload: AddRefundPaymentMethodPayload;
};

export type AddRefundPaymentErrorAction = {
  type: ActionTypes.AddRefundPaymentError;
  payload: any;
};

export type AddRefundPaymentLoadingAction = {
  type: ActionTypes.AddRefundPaymentLoading;
  payload: boolean;
};

export const addRefundPaymentMethod = (
  paymentGateway: PaymentGateway,
  orderId: string,
  postalCode: string,
  setPaymentMethod: MutationCustomerSetRefundPaymentMethodMutationFn,
  nextStep: NextStep | undefined,
): AddRefundPaymentMethodAction => ({
  type: ActionTypes.AddRefundPaymentMethod,
  payload: {
    paymentGateway,
    postalCode,
    setPaymentMethod,
    orderId,
    nextStep,
  },
});

export const addRefundPaymentMethodWithCaptcha = (
  paymentGateway: PaymentGateway,
  orderId: string,
  postalCode: string,
  setPaymentMethod: MutationCustomerSetRefundPaymentMethodMutationFn,
  nextStep: NextStep | undefined,
  recaptchaToken: string,
): AddRefundPaymentMethodAction => ({
  type: ActionTypes.AddRefundPaymentMethod,
  payload: {
    paymentGateway,
    postalCode,
    setPaymentMethod,
    orderId,
    nextStep,
    recaptchaToken,
  },
});

export const addRefundPaymentError = (payload: any): AddRefundPaymentErrorAction => ({
  type: ActionTypes.AddRefundPaymentError,
  payload,
});

export const addRefundPaymentLoading = (
  payload: boolean,
): AddRefundPaymentLoadingAction => ({
  type: ActionTypes.AddRefundPaymentLoading,
  payload,
});

export type Action =
  | AddRefundPaymentMethodAction
  | AddRefundPaymentErrorAction
  | AddRefundPaymentLoadingAction;
