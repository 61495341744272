import { useClient } from '@peloton/api';
import { toClient } from '@account/api';
import { toAccountApiEnv } from '@account/env';

const AUTH_SESSION = '/auth/session';

const useLegacySession = () => {
  const client = useClient();
  const clientNoAuthHeader = toClient(toAccountApiEnv());
  const getSession = () => clientNoAuthHeader.get<{ isAuthed: boolean }>(AUTH_SESSION);
  const postSession = () => client.post(AUTH_SESSION);

  return {
    isAuthedSession: async () => {
      const session = await getSession();
      return session.data.isAuthed;
    },
    postSession,
  };
};

export default useLegacySession;
