import { all, fork } from 'redux-saga/effects';
import { sagas as claimBenefitSagas } from '../pg-account-benefit-onboarding';
import plansSaga from './getPlansSaga';
import lookupSaga from './lookupSaga';
import partnerLookupSaga from './partnerLookupSaga';
import trackOrderSaga from './trackOrderSaga';

export default function* sagas() {
  yield all([
    fork(plansSaga),
    fork(lookupSaga),
    fork(partnerLookupSaga),
    fork(claimBenefitSagas),
    fork(trackOrderSaga),
  ]);
}
