import type { SagaIterator } from 'redux-saga';
import { getContext, takeEvery, takeLeading } from 'redux-saga/effects';
import { CLIENT_CONTEXT } from '@peloton/api';
import { UserReducerActionType } from '@peloton/auth';
import { NavigationActionType } from '@peloton/navigation';
import { SubscriptionsActionType } from '@engage/subscriptions/redux';
import extendPauseSubscription from '@engage/subscriptions/sagas/extendPauseSubscription';
import pauseSubscription from '@engage/subscriptions/sagas/pauseSubscription';
import { loginRedirectSaga, notFoundRedirectSaga } from './redirects';

export const appSaga = function* (): SagaIterator {
  const client = yield getContext(CLIENT_CONTEXT);

  yield takeEvery(UserReducerActionType.LOGIN_REDIRECT, loginRedirectSaga);

  yield takeLeading(
    SubscriptionsActionType.PauseSubscriptionRequest,
    pauseSubscription,
    client,
  );

  yield takeLeading(
    SubscriptionsActionType.ExtendPauseSubscriptionRequest,
    extendPauseSubscription,
    client,
  );

  yield takeEvery(NavigationActionType.NotFound, notFoundRedirectSaga);
};
