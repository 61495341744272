import React from 'react';
import { asyncComponent } from '@peloton/code-splitting';
import { FEATURES, Route } from '@account/optimizely';
import { toServicePlanPath } from './urls';

const Routes = [
  <Route
    feature={FEATURES.IsServicePlanPageActive}
    key={toServicePlanPath()}
    path={toServicePlanPath()}
    component={asyncComponent(
      () => import('./Page' /* webpackChunkName: "ServicePlanPage" */),
    )}
    shouldTrack={true}
  />,
];

export default Routes;
