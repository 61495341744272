import React from 'react';
import { Route } from 'react-router-dom';
import scriptjs from 'scriptjs';
import { asyncComponent } from '@peloton/code-splitting';
import { Route as AccountRoute } from '@account/router';
import { isEligibleForAppCheckout } from '@account/subscription-mobility/guards';
import { APP_MEMBERSHIP_CHECKOUT_ROUTE, DOWNLOAD_APP_ROUTE } from './urls';

const noOp = () => {};

const Routes = isEligibleForAppCheckout()
  ? [
      <AccountRoute
        key={APP_MEMBERSHIP_CHECKOUT_ROUTE}
        path={APP_MEMBERSHIP_CHECKOUT_ROUTE}
        component={asyncComponent(
          () => import('./Page' /* webpackChunkName: "AppMembershipCheckoutPage" */),
        )}
      />,
      <Route
        key={DOWNLOAD_APP_ROUTE}
        path={DOWNLOAD_APP_ROUTE}
        component={asyncComponent(() => {
          scriptjs('/account-scripts/appsFlyerSmartScript.js', noOp);
          return import('./AppDownloadPage' /* webpackChunkName: "DownloadAppPage" */);
        })}
      />,
    ]
  : [];

export default Routes;
