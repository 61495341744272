import { css, createGlobalStyle } from 'styled-components';
import { reset } from '@peloton/css-reset';
import { oneTrustReset } from '@peloton/onetrust/styles';
import { zopimStyles } from '@peloton/styles';

const hideUntilFontLoad = css`
  .wf-loading * {
    opacity: 0;
    animation-play-state: paused !important;
  }
`;

const disableTextSizeAdjustment = css`
  html {
    -webkit-text-size-adjust: 100%;
  }
`;

const pointerStyles = css`
  a,
  button,
  [role='button'],
  [type='button'],
  [type='submit'] {
    cursor: pointer;
  }
`;

export const bodyStyles = css`
  font-family: 'Inter', sans-serif;
  font-weight: 300;
  line-height: 27px;
  color: #181a1d;
  -webkit-font-smoothing: antialiased;
`;

const body = css`
  body {
    ${bodyStyles}
    ${pointerStyles}
  }
`;

const root = css`
  #root {
    float: left;
    width: 100%;
  }
`;

export const globalStylesNoReset = css`
  html,
  body,
  #root {
    height: 100%;
  }
  ${hideUntilFontLoad}
  ${disableTextSizeAdjustment}
  ${body}
  ${root}
  ${zopimStyles}
`;

export const globalStyles = css`
  ${reset}
  ${globalStylesNoReset}
  ${oneTrustReset}
`;

/**
 * NOTE: When used, GlobalStyles must be the first styled component defined,
 * so be sure to import it before any other styled components.
 *
 * This is due to the styled-components library adding css to the page in the
 * order that their components are defined.
 *
 * https://github.com/styled-components/styled-components/issues/382#issuecomment-273330002
 */
export default createGlobalStyle`
  ${globalStyles}
`;

export const GlobalStylesNoReset = createGlobalStyle`
  ${globalStylesNoReset}
`;
