import React from 'react';
import { asyncComponent } from '@peloton/code-splitting';
import { Route } from '@account/router';
import { PLAN_CHECKOUT, SWITCH_PLAN } from '@onewellness/routes';

const route = [
  <Route
    key={PLAN_CHECKOUT}
    path={PLAN_CHECKOUT}
    component={asyncComponent(() => import('./Handler'))}
  />,
  <Route
    key={SWITCH_PLAN}
    path={SWITCH_PLAN}
    component={asyncComponent(() => import('./Handler'))}
  />,
];

export default route;
