import { white } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { PelotonText } from '@peloton/logos';
import { media } from '@peloton/styles';
import { useImageData } from '@ecomm/copy';
import { Audience } from '@onewellness/routes';
import { contentKeyForAudience } from '@onewellness/utils/copy';
import { HyattLogo, LululemonLogo, YMCALogo } from './logos';

type Props = {
  audience: Audience;
  fill: string;
  logo?: string;
};

const Logo: React.FC<Pick<Props, 'audience' | 'logo'>> = ({ audience, logo }) => {
  const { image } = useImageData(
    contentKeyForAudience('onewellness.{audience}.header.logo', audience),
  );

  if (logo) {
    return <PartnershipLogo src={logo} alt="Partner Logo" data-test-id="1wHeaderLogo" />;
  } else if (audience === Audience.Lululemon || audience === Audience.LululemonHardware) {
    return <LululemonLogo data-test-id="lululemon-logo" />;
  } else if (audience === Audience.YMCA) {
    return <YMCALogo data-test-id="ymca-logo" />;
  } else if (audience === Audience.Hyatt) {
    return <HyattLogo data-test-id="hyatt-logo" />;
  } else {
    return (
      <PartnershipLogo
        src={image.mobile.url}
        alt={image.mobile.description}
        data-test-id="1wHeaderLogo"
      />
    );
  }
};

export const AudienceLogo: React.FC<Props> = ({ audience, fill, logo }) => {
  return (
    <Container>
      <PelotonTextStyled data-test-id="1wPeloton-Logo" fill={fill} />
      <Divider />
      <Logo audience={audience} logo={logo} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PelotonTextStyled = styled(PelotonText)`
  height: 11px;

  ${media.desktop`
    height: 15px;
  `}
`;

const PartnershipLogo = styled.img`
  height: 28px;

  ${media.desktop`
    height: 34px;
  `}
`;

const Divider = styled.div`
  display: inline-block;
  width: 1px;
  height: 18px;
  margin: 0 20px;
  background: ${white};

  ${media.desktop`
    height: 24px;
  `}
`;
