import type { ToggleDefinitions } from '@account/feature-toggles';
import { ToggleStatus } from '@ecomm/feature-toggle';

const features: Partial<ToggleDefinitions> = {
  postPurchaseUpsell: {
    status: ToggleStatus.Enabled,
  },
  stripeSetupIntentsCaptcha: {
    status: ToggleStatus.Enabled,
  },
  marketingOptIn: {
    status: ToggleStatus.Enabled,
  },
  marketingOptOut: {
    status: ToggleStatus.Disabled,
  },
  copyXray: {
    status: ToggleStatus.Disabled,
  },
  showFinancingPartner: {
    status: ToggleStatus.DateTrigger,
    start: '2023-04-12T13:00:00-04:00',
    end: '2024-04-12T13:00:00-04:00',
  },
  freeTrialForSixtyDays: {
    status: ToggleStatus.DateTrigger,
    start: '2023-10-02T14:00:00.000Z',
    end: '2023-10-31T16:00:00.000Z',
  },
  holidaysNY2023: {
    status: ToggleStatus.DateTrigger,
    start: '2023-12-21T10:00:00+01:00',
    end: '2024-01-31T10:59:00-05:00',
  },
  stripeCreateIntentsCaptcha: {
    status: ToggleStatus.Enabled,
  },
};

export default features;
