import { Locale, useLocale } from '@peloton/internationalize';
import { useAccountMetadata } from '@account/copy/helpers/useAccountMetadata';

export const useHyattFaqCopy = () => {
  const locale = useLocale();
  const englishFaq = [
    useAccountMetadata('onewellness.hyatt.connect.faqs.1'),
    useAccountMetadata('onewellness.hyatt.connect.faqs.2'),
    useAccountMetadata('onewellness.hyatt.connect.faqs.3'),
    useAccountMetadata('onewellness.hyatt.connect.faqs.4'),
    useAccountMetadata('onewellness.hyatt.connect.faqs.5'),
    useAccountMetadata('onewellness.hyatt.connect.faqs.6'),
    useAccountMetadata('onewellness.hyatt.connect.faqs.7'),
    useAccountMetadata('onewellness.hyatt.connect.faqs.8'),
    useAccountMetadata('onewellness.hyatt.connect.faqs.9'),
    useAccountMetadata('onewellness.hyatt.connect.faqs.10'),
    useAccountMetadata('onewellness.hyatt.connect.faqs.11'),
  ];
  const usFaq = [
    useAccountMetadata('onewellness.hyatt.connect.faqs.us.1'),
    useAccountMetadata('onewellness.hyatt.connect.faqs.us.2'),
    useAccountMetadata('onewellness.hyatt.connect.faqs.us.3'),
    useAccountMetadata('onewellness.hyatt.connect.faqs.us.4'),
  ];
  const deFaq = [
    useAccountMetadata('onewellness.hyatt.connect.faqs.de.1'),
    useAccountMetadata('onewellness.hyatt.connect.faqs.de.2'),
    useAccountMetadata('onewellness.hyatt.connect.faqs.de.3'),
    useAccountMetadata('onewellness.hyatt.connect.faqs.de.4'),
    useAccountMetadata('onewellness.hyatt.connect.faqs.de.5'),
    useAccountMetadata('onewellness.hyatt.connect.faqs.de.6'),
    useAccountMetadata('onewellness.hyatt.connect.faqs.de.7'),
    useAccountMetadata('onewellness.hyatt.connect.faqs.de.8'),
    useAccountMetadata('onewellness.hyatt.connect.faqs.de.9'),
  ];

  // must match page builder's "hyatt connect landing page" faq
  let faqs = englishFaq.slice(0, 5);
  switch (locale) {
    case Locale.EnglishCanada:
    case Locale.EnglishUnitedKingdom:
    case Locale.EnglishAustralia:
      faqs = [...faqs, ...usFaq.slice(1), ...englishFaq.slice(5)];
      break;
    case Locale.GermanGermany:
      faqs = [...faqs, ...deFaq];
      break;
    default:
      faqs = [...faqs, ...usFaq, ...englishFaq.slice(5)];
  }

  return faqs.map((copy, i) => {
    return {
      key: copy.keywords[0] + i,
      question: copy.title,
      answer: copy.description,
    };
  });
};
