import type { OrderStatusGuestQuery } from 'packages/@account/pg-post-purchase-flow/OrderStatus/OrderStatusGuest.generated';
import { ProductLine } from '@account/graphql/types.generated';
import type { DeliveryPartnersFragment } from '@account/order/DeliveryDetails/ScheduledDelivery/DeliveryPartners.generated';
import { isFaasBuyout } from '@account/order/Helpers';
import type { TrackShopPaymentInfoFragment } from '@account/pg-order-confirmation/graphql/fragments.generated';
import type { OrderStatusQuery } from '@account/pg-post-purchase-flow/OrderStatus/OrderStatus.generated';
import type { OrderDeliveryQuery } from '@account/pg-post-purchase-flow/Scheduler/ReserveDeliveryPage/OrderDelivery.generated';
import type { OrderTrackingSalesOrder } from '../../pg-post-purchase-flow/OrderTracking/orderTrackingTypes';
import type { ParcelTrackingFragment } from '../../pg-post-purchase-flow/OrderTracking/ParcelTracking.generated';
import type { AnalyticsBasePropertiesQuery } from '../queries/AnalyticsBaseProperties.generated';
import type { OrderAnalyticsQuery } from '../queries/OrderAnalytics.generated';

type QueryItem = NonNullable<OrderAnalyticsQuery['salesOrder']['items']>[number] & {
  productLine: string | null | undefined;
};

type QueryBundle = NonNullable<OrderAnalyticsQuery['salesOrder']['bundles']>[number];

enum DeliveryPartner {
  Fsl = 'Fsl',
  JbHunt = 'JbHunt',
  XpoClm = 'XPO',
  XpoUk = 'XpoUk',
  Rhenus = 'Rhenus',
  Winnings = 'Winnings',
  Amj = 'Amj',
}
export const PARTNER_KEY = 'ecomm/referralPartner';
const BIKE_REFURB_PREFIX = 'BA01-RF';
const BIKEPLUS_REFURB_PREFIX = 'BA02-RF';
// const BIKE_FAAS_PREFIX = 'BA01-FS'; // Unused but accurate
const BIKEPLUS_FAAS_PREFIX = 'BA02-FS';
const HAULAWAY_SKU = 'cfu-haul-away';

export const toFlattenedItems = (
  data:
    | OrderAnalyticsQuery
    | OrderStatusQuery
    | OrderStatusGuestQuery
    | OrderDeliveryQuery
    | AnalyticsBasePropertiesQuery,
) =>
  (data?.salesOrder.bundles ?? [])
    .map((bundle: QueryBundle) => bundle.items)
    .concat(data?.salesOrder.items ?? [])
    .flat()
    .map((item: QueryItem) => ({
      ...item,
      productLine: item.productLine?.toLowerCase() || '',
    })) as QueryItem[];

const toName = (items: QueryItem[]) =>
  items
    .map(item => item.title)
    .sort()
    .join(',');

const toBundleName = (bundles: QueryBundle[]) =>
  bundles
    .map(bundle => bundle.title)
    .sort()
    .join(',');

export const toTrackingParcels = (
  parcels: NonNullable<ParcelTrackingFragment['logisticsOrder']>['parcels'][number][],
) =>
  parcels.map(parcel => {
    const mostRecentEvent =
      parcel.events && parcel.events.length > 0 ? parcel.events![0] : null;
    const estimatedDeliveryDate = mostRecentEvent?.estimatedDeliveryDate;
    const status = mostRecentEvent?.status;
    return {
      estimatedDeliveryDate,
      carrier: parcel.carrier,
      trackingNumber: parcel.trackingNumber,
      status,
    };
  });

const hasBikePlus = (skus: (string | undefined | null)[]) => {
  const bikePlusSkus = [
    'BA02-0011',
    'BA02-0012',
    'BA02-0003',
    'BA02-0020',
    'BA02-0023',
    'BA02-0021',
    'BA02-0027',
    'BA02-0031', // Colored Bike (Candy)
    'BA02-0034', // Colored Bike (Ocean)
    'BA02-0032', // Colored Bike (Galaxy)
  ];
  return skus.some(sku => sku && bikePlusSkus.includes(sku));
};

const hasTreadPlus = (skus: (string | undefined | null)[]) => {
  const treadPlusSkus = ['TA01-0001', 'TA01-0002', 'TA01-0003'];
  return skus.some(sku => sku && treadPlusSkus.includes(sku));
};

const hasOPCBikePlus = (skus: (string | undefined | null)[]) => {
  const opcBikePlusSkus = ['OPC-BIKE-PLUS', 'FAAS-BIKE-PLUS', 'FAAS-BIKE-PLUS-R-2'];
  return skus.some(
    sku => sku && (opcBikePlusSkus.includes(sku) || sku.startsWith(BIKEPLUS_FAAS_PREFIX)),
  );
};

export const hasRefurbishedBike = (skus: (string | undefined | null)[]) => {
  const refurbBikeSkus = [
    'bike-refurbished-consumer',
    'bike-refurbished-v4',
    'bike-refurbished-v3',
    'bike-refurbished-pl-11-r-a',
    'bike_refurb_b_ruby_v4',
  ];
  return skus.some(
    sku =>
      sku &&
      (refurbBikeSkus.includes(sku.toLowerCase()) || sku.startsWith(BIKE_REFURB_PREFIX)),
  );
};

export const hasRefurbishedBikePlus = (skus: (string | undefined | null)[]) => {
  const refurbBikePlusSkus = [
    'bikeplus-refurbished-consumer-na',
    'bikeplus-refurbished-consumer-na-v2',
    'bikeplus-refurbished-consumer-na-v3',
  ];
  return skus.some(
    sku =>
      sku &&
      (refurbBikePlusSkus.includes(sku.toLowerCase()) ||
        sku.startsWith(BIKEPLUS_REFURB_PREFIX)),
  );
};

export const OPC_DELIVERY_FEE_SKU = 'OPC-DELIVERY';
export const TREAD_PLUS_SERVICE_TECHNICIAN = 'TREAD-PLUS-SERVICE-TECHNICIAN';

export const SERVICE_TECHNICIAN = 'SERVICE_TECHNICIAN';

export const hasServiceTechnician = (salesOrder: OrderTrackingSalesOrder): boolean => {
  const items = salesOrder.items || [];
  return items.some(item => item.productLine === SERVICE_TECHNICIAN);
};

export const hasRearGuard = (skus: (string | undefined | null)[]) => {
  const rearGuardSkus = ['st01-0001', 'st01-0002'];
  return skus.some(sku => sku && rearGuardSkus.includes(sku.toLowerCase()));
};

export const toTrackingDeliveryPartner = (order: DeliveryPartnersFragment | null) => {
  const noPartner = 'No delivery partner specified';
  if (!order) {
    return noPartner;
  }

  if (order.isFsl) return DeliveryPartner.Fsl;
  else if (order.isJbHunt) return DeliveryPartner.JbHunt;
  else if (order.isXpoClm) return DeliveryPartner.XpoClm;
  else if (order.isXpoUk) return DeliveryPartner.XpoUk;
  else if (order.isRhenus) return DeliveryPartner.Rhenus;
  else if (order.isWinnings) return DeliveryPartner.Winnings;
  else if (order.isAmj) return DeliveryPartner.Amj;
  else return noPartner;
};

export const toTrackingCategory = (
  productLines: (string | undefined | null)[],
  skuNames: (string | undefined | null)[],
) =>
  [
    productLines.includes('consumer_bike') &&
      !hasBikePlus(skuNames) &&
      !hasOPCBikePlus(skuNames) &&
      !hasRefurbishedBikePlus(skuNames) &&
      'bike',
    productLines.includes('tread') && !hasTreadPlus(skuNames) && 'tread',
    productLines.includes('guide') && 'guide',
    productLines.includes('consumer_bike') &&
      (hasOPCBikePlus(skuNames) || hasRefurbishedBikePlus(skuNames)) &&
      'bike-plus',
    (productLines.includes('bike-plus') || hasBikePlus(skuNames)) && 'bike-plus',
    (productLines.includes('tread-plus') || hasTreadPlus(skuNames)) && 'tread-plus',
    productLines.includes('row') && 'row',
    productLines.includes('digital_subscription') && 'digital',
    productLines.some(
      productLine => productLine && isAccessory(productLine as ProductLine),
    ) && 'accessory',
    productLines.includes('subscription_prepaid_credit') &&
      'Peloton All Access Membership',
  ]
    .filter(Boolean)
    .join(',');

export const toTrackingProducts = ({
  productLine,
  title,
  price,
  productOptionId,
  quantity,
  skuName,
}: QueryItem) => ({
  brand: 'Peloton',
  category: productLine,
  name: title,
  price: toDollarsValue(price?.amount) || 0,
  productId: productOptionId,
  quantity,
  sku: skuName,
});

const referringPartnerFromStorage = () => {
  const partner = localStorage.getItem(PARTNER_KEY);

  if (Boolean(partner)) {
    localStorage.removeItem(PARTNER_KEY);
  }

  return partner;
};

const cleanPaymentObject = (shopPayment: TrackShopPaymentInfoFragment) => {
  const { __typename, ...rest } = shopPayment;
  const { __typename: totalTypename, ...totalAmount } = rest.totalAmount;
  let creditCardMetadata = rest.creditCardMetadata;
  if (creditCardMetadata) {
    const { __typename: ccTypename, ...restCreditCardMetadata } = creditCardMetadata;
    creditCardMetadata = restCreditCardMetadata;
  }

  return {
    ...rest,
    totalAmount,
    creditCardMetadata,
  };
};

const toTrackingProperties = (
  data: OrderAnalyticsQuery,
  shopPayments?: TrackShopPaymentInfoFragment[],
) => {
  // OPC delivery fee product line is 'OTHER', which the app will mistake as an
  // accessory, so we need to crosscheck the sku too
  const items = toFlattenedItems(data).filter(
    item => !item.skuName?.includes(OPC_DELIVERY_FEE_SKU),
  );
  const skuNames = items.map(item => item.skuName);
  const bundles = data?.salesOrder.bundles;
  const referringPartner = referringPartnerFromStorage();

  const billingPartner = data?.paymentInformation?.payments
    ?.map(payment => payment.billingPartner)
    .join(', ');

  return {
    shopPayments: shopPayments?.map(cleanPaymentObject),
    billingPartner,
    category: toTrackingCategory(
      items.map(item => item.productLine),
      skuNames,
    ),
    hasGift: data?.salesOrder.isGift,
    currency: data?.salesOrder.subtotal?.currency,
    email: data?.salesOrder.email,
    hasAccessory: hasAccessory(items),
    hasAccessoryBundle: bundles && hasAccessoryBundle(bundles),
    bundleName: toBundleName(bundles ?? []),
    name: toName(items),
    paymentMethod: data?.paymentInformation?.payments?.[0]?.paymentLabel || '',
    products: items.map(toTrackingProducts),
    orderId: data?.salesOrder.id,
    hasBeenTracked: data?.salesOrder.hasBeenTracked,
    hasBuyout: hasFaasBuyout(items),
    hasCPO: hasRefurbishedBike(skuNames) || hasRefurbishedBikePlus(skuNames),
    hasOPC: items.some(item => {
      const productLine = item.productLine;

      return productLine && productLine.includes('equipment_lease');
    }),
    hasSubscriptionGift: items.some(item => {
      return item.productLine?.toUpperCase() == ProductLine.SubscriptionPrepaidCredit;
    }),
    giftSubscriptionDuration: toGiftSubscriptionDuration(items),
    hasTradeIn: items.some(item => item.hasTradeIn),
    value: toDollarsValue(data?.salesOrder.subtotal?.amount),
    deliveryPartner: toTrackingDeliveryPartner(data?.salesOrder.logisticsOrder ?? null),
    orderStatus: data?.salesOrder.logisticsOrder?.deliveryStatus?.state ?? '',
    deliveryDate: data?.salesOrder.logisticsOrder?.deliveryPreference?.date ?? '',
    warrantySelected: getWarrantyOption(items),
    accessories: getAccessories(items),
    referringPartner,
    hasHaulaway: hasHaulaway(items),
  };
};

const isAccessory = (productLine: ProductLine): boolean =>
  // These are all the accessories ProductLines:
  // - spare_part for Cleats and other spare parts
  // - tread_accessory for JBLs and others
  // - other for the majority of accessories
  // - tread_weight for Dumbbells and others
  // - parcel_only for Hestia and others
  [
    ProductLine.BikeAccessory,
    ProductLine.TreadAccessory,
    ProductLine.TreadWeight,
    ProductLine.Other,
    ProductLine.ParcelOnly,
    ProductLine.SparePart,
  ].includes(productLine.toUpperCase() as ProductLine);

const getAccessories = (items: QueryItem[]): QueryItem[] =>
  items.filter(item => item.productLine && isAccessory(item.productLine));

const hasAccessoryBundle = (bundles: QueryBundle[]) =>
  bundles.some(bundle =>
    bundle.items.every(item => item.productLine && isAccessory(item.productLine)),
  );

const hasAccessory = (items: QueryItem[]): boolean =>
  items.some(item => item.productLine && isAccessory(item.productLine));

const hasFaasBuyout = (items: QueryItem[]): boolean =>
  items.some(item => isFaasBuyout(item.skuName));

const getWarrantyOption = (items: QueryItem[]): string =>
  items.filter(
    item =>
      item.productLine?.toUpperCase() == ProductLine.Warranty && item.price?.amount != 0,
  )[0]?.title ?? '';

const toDollarsValue = (value: any) => {
  if (isNaN(value)) return undefined;
  return value / 100;
};

const toGiftSubscriptionDuration = (items: QueryItem[]): string | null => {
  const prepaidCreditItem = items.find(
    item => item.productLine?.toUpperCase() == ProductLine.SubscriptionPrepaidCredit,
  );

  if (!prepaidCreditItem || !prepaidCreditItem.skuName) {
    return null;
  }

  const SUBSCRIPTION_DURATION_MAP: { [key: string]: string } = {
    'PL-SUB-03-MTH-PREPAID': '3',
    'PL-SUB-06-MTH-PREPAID': '6',
    'PL-SUB-12-MTH-PREPAID': '12',
  };

  return SUBSCRIPTION_DURATION_MAP[prepaidCreditItem.skuName]
    ? `${SUBSCRIPTION_DURATION_MAP[prepaidCreditItem.skuName]} months`
    : null;
};

export const hasHaulaway = (items: QueryItem[]) =>
  items.some(item => item.skuName === HAULAWAY_SKU);

export default toTrackingProperties;
