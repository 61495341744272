import { curry, reduce, isNil } from 'ramda';

export enum Kind {
  Ok = 'RESULT_TAG_OK',
  TError = 'RESULT_TAG_ERROR',
  Pending = 'PENDING_KIND',
}

export type Ok<T> = {
  type: Kind.Ok;
  value: T;
};

export type TError<T> = {
  type: Kind.TError;
  value: T;
};

export type Pending = {
  type: Kind.Pending;
  value?: [];
};

export type Result<T, E> = Ok<T> | TError<E>;
export type PendingResult<T, E> = Pending | Result<T, E>;

// interned `ok` for undefined values to always return the same object
const undefinedOk = {
  type: Kind.Ok,
  value: undefined,
};

/**
 * Constructors
 */
export const ok = <T>(value: T): Ok<T> => {
  if (value === undefined) {
    return undefinedOk as any;
  } else {
    return {
      type: Kind.Ok,
      value,
    };
  }
};

export const error = <E>(value: E): TError<E> => ({
  type: Kind.TError,
  value,
});

export const pending = {
  type: Kind.Pending as const,
};

export type PendingResultMappingOptions<R, T, E> = {
  getValue: (fromValue: R) => T;
  isPending: (fromValue: R) => boolean;
  getError: (fromValue: R) => E;
};
/**
 * This is intended to be a temporary adapter from older representations of pending data to PendingResult.
 * @param getValue extracts the ok value from the input (e.g. fromValue.entity)
 * @param isPending extracts the pending value from the input (e.g. fromValue.isLoading)
 * @param getError extracts the error value from the input (e.g. fromValue.error). When this returns undefined
 *  we assume there is no error.
 * @returns a PendingResult type that represents the same data.
 */
export const toPendingResult = <R, T, E>(
  options: PendingResultMappingOptions<R, T, E>,
  fromValue: R,
): PendingResult<T, E> => {
  if (options.isPending(fromValue)) {
    return pending;
  } else if (typeof options.getError(fromValue) !== 'undefined') {
    return error(options.getError(fromValue));
  } else {
    return ok(options.getValue(fromValue));
  }
};

/**
 * Typeguards
 */
export const isOk = <T, U>(state: PendingResult<T, U>): state is Ok<T> =>
  state.type === Kind.Ok;

export const isError = <T, U>(state: PendingResult<T, U>): state is TError<U> =>
  state.type === Kind.TError;

export const isPending = <T, E>(state: PendingResult<T, E>): state is Pending =>
  state === pending;

/**
 * mapResult will map an ok value and short circuit on pending and error values. It returns a new PendingResult with
 * the mapped ok values
 */
export const mapResult = <T, E, NextT>(
  mapper: (value: T) => NextT,
  result: PendingResult<T, E>,
): PendingResult<NextT, E> => {
  if (isOk(result)) {
    return ok(mapper(result.value));
  }
  return result;
};

/**
 * ap allows you apply pending results of callable functions to other pending results. It will return
 * a new pending result with thisResult's value applied to otherResult's value.
 */
const ap = <T, E, NextT>(
  thisResult: PendingResult<(value: T) => NextT, E>,
  otherResult: PendingResult<T, E>,
) => {
  if (isOk(thisResult)) {
    return mapResult(thisResult.value, otherResult);
  }
  return thisResult;
};

export type ValueOf<P extends PendingResult<any, any>> = P extends Ok<infer V>
  ? V
  : never;

/**
 * mapAllResults takes an array of n pending results and a mapper that takes the same number (n) of arguments.
 * It returns a single pending result of the return value of the mapper.
 */

type AnyPE = PendingResult<any, any>;
function mapAllResults<T1 extends AnyPE, T2 extends AnyPE, R>(
  mapper: (...results: [ValueOf<T1>, ValueOf<T2>]) => R,
  results: [T1, T2],
): PendingResult<R, any>;
function mapAllResults<T1 extends AnyPE, T2 extends AnyPE, T3 extends AnyPE, R>(
  mapper: (...results: [ValueOf<T1>, ValueOf<T2>, ValueOf<T3>]) => R,
  results: [T1, T2, T3],
): PendingResult<R, any>;
function mapAllResults<
  T1 extends AnyPE,
  T2 extends AnyPE,
  T3 extends AnyPE,
  T4 extends AnyPE,
  R
>(
  mapper: (...results: [ValueOf<T1>, ValueOf<T2>, ValueOf<T3>, ValueOf<T4>]) => R,
  results: [T1, T2, T3, T4],
): PendingResult<R, any>;
function mapAllResults<T extends AnyPE, R>(
  mapper: (...results: ValueOf<T>[]) => R,
  results: T[],
): PendingResult<R, any> {
  if (mapper.length !== results.length) {
    throw new Error('Mapper function must have same number of arguments as result array');
  }
  return reduce<any, any>(ap, ok(curry(mapper) as any), results) as PendingResult<R, any>;
}

export { mapAllResults };

/**
 * Returns the ok value of a pending result or a default. If the value is not ok and a default is not provided,
 * the default behavior will be followed
 * * Pending state will return undefined
 * * Errored state will throw the error.
 */
export const getOkValue = <T, E>(
  pendingResult: PendingResult<T, E>,
  defaultValue?: T,
) => {
  if (isOk(pendingResult)) {
    return pendingResult.value;
  } else if (!isNil(defaultValue)) {
    return defaultValue;
  } else if (isPending(pendingResult)) {
    return undefined;
  } else {
    throw new Error(
      `Attempted to retrieve value from error type, ${pendingResult.value}`,
    );
  }
};
