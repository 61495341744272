/* eslint-disable no-restricted-imports */
import {
  isEmpty,
  find,
  compose,
  equals,
  prop,
  filter,
  both,
  complement,
  length,
  __,
  descend,
  sortWith,
} from 'ramda';
/* eslint-enable no-restricted-imports */
import type { Currency, Money } from '@peloton/internationalize/models/currency';
import { formatMoney, toMoney } from '@peloton/internationalize/models/currency';
import type { Time } from '@peloton/time';
import { formatDurationBetween, toNowTime } from '@peloton/time';

export type Subscription = DeviceSubscription | DigitalSubscription;

export type DeviceSubscription = CommonProps & {
  activationCode: string;
  attachedDevices: Device[];
  canPause: boolean;
  canUnpause: boolean;
  cancelledOn: Time;
  commitment: MonthsCount;
  costInCents: number;
  currency: string;
  deviceLastAttachedAt: Time;
  firstActivatedAt: Time;
  hasEquipmentLease: boolean;
  kind: SubscriptionKind.Device;
  maxSharedUsers?: number;
  order: Order;
  pausedOn: Time;
  prepaidCredits: MonthsCount;
  type: SubscriptionType;
  unpausedOn: Time;
  waivers: MonthsCount;
};

// TODO: move values to shared/digital as they are added to the api contract
export type DigitalSubscription = CommonProps & {
  billingType: BillingType;
  costInCents?: number;
  currency?: string;
  doesRollover: boolean;
  kind: SubscriptionKind.Digital | SubscriptionKind.LegacyiOS;
  requireAccessCode: boolean;
  tierId: string | null;
  tierType: TierType | null;
  billingFrequency: BillingFrequency | null;
  platform: Platform | null;
  rolloverCostInCents: number;
  lastSuccessfulCharge?: Time;
};

export type CommonProps = {
  billingType?: BillingType;
  costInCents: number;
  id: string;
  isActive: boolean;
  isTrial?: boolean;
  isOwner: boolean;
  name: string;
  email?: string;
  nextBillingAt: Time;
  owner: SubUser;
  purchasedAt: Time;
  sharedUserSet: SubUser[];
  status: Status;
};

export enum SubscriptionKind {
  Digital = 'Digital',
  LegacyiOS = 'iOS',
  Device = 'Device',
}

export enum SubscriptionType {
  Commercial = 'commercial',
  Consumer = 'consumer',
  Guide = 'guide',
}

export enum DeviceType {
  Bike = 'bike',
  Tread = 'tread',
  Guide = 'guide',
}

export enum TierType {
  Free = 'no_membership',
  AppOne = 'digital_basic',
  Legacy = 'digital',
  AppPlus = 'digital_plus',
  Guide = 'guide',
  AllAccess = 'all-access',
}

export enum BillingFrequency {
  Monthly = 'monthly',
  Annual = 'annual',
}

export type AttachedDevice = {
  deviceGroup: DeviceType;
  deviceId: string;
  deviceName: string | null;
  lastAttachedAt: number;
};

export type Device = {
  kind: DeviceType;
  name: string;
  id: string;
};

type Order = {
  id: string;
  confirmationCode: string;
};

export type SubUser = {
  id: string;
  username?: string;
  imageUrl?: string;
};

export enum StatusType {
  Unused = 'unused',
  Paused = 'paused',
  ActiveCancelScheduled = 'active_scheduled_for_cancellation',
  ActiveGracePeriod = 'active_in_grace_period',
  ActiveNormal = 'active_normal',
  OverdueDelinquent = 'overdue_delinquent',
  OverdueNoPayment = 'overdue_no_payment',
  Cancelled = 'cancelled',
  Inactive = 'inactive',
  ActiveAutoRenewOff = 'active_auto_renew_off',
  Expired = 'expired',
}

export type Status = {
  type: StatusType;
  text: string;
};

export enum BillingType {
  Trial = 'trial',
  AppleMonthly = 'apple_monthly',
  AmazonMonthly = 'amazon_monthly',
  GoogleMonthly = 'google_monthly',
  BangoMonthly = 'bango_monthly',
  AppleOneWeek = 'apple_one_week',
  PelotonInternal = 'peloton_internal',
  PelotonYearly = 'peloton_yearly',
  PreDelivery = 'pre_delivery',
  RokuMonthly = 'roku_monthly',
}

export enum Platform {
  Amazon = 'amazon',
  Apple = 'apple',
  Bango = 'bango',
  Google = 'google',
  Peloton = 'peloton',
  Roku = 'roku',
}

export type MonthsCount = {
  totalMonths: number;
  usedMonths: number;
};

export const getRemainingMonths = (count: MonthsCount) =>
  Math.max(count.totalMonths - count.usedMonths, 0);

export const canCancel = (sub: Subscription) =>
  sub.isActive && (canCancelDeviceSub(sub) || canCancelDigitalSub(sub));

export const paymentDue = (sub: Subscription) =>
  sub.status.type === StatusType.ActiveGracePeriod ||
  sub.status.type === StatusType.OverdueDelinquent ||
  sub.status.type === StatusType.OverdueNoPayment;

export const canManage = (sub: Subscription) =>
  isDeviceSub(sub) &&
  (canCancel(sub) || sub.canPause || sub.canUnpause || paymentDue(sub));

const canCancelDeviceSub = (sub: Subscription) =>
  isDeviceSub(sub) &&
  getRemainingMonths(sub.commitment) === 0 &&
  (sub.status.type === StatusType.ActiveNormal ||
    sub.status.type === StatusType.OverdueNoPayment);

const canCancelDigitalSub = (sub: Subscription) =>
  isDigitalSub(sub) && sub.isActive && sub.status.type !== StatusType.ActiveAutoRenewOff;

export const isCancelled = (sub: Subscription) =>
  sub.status.type === StatusType.Cancelled;

export const isExpired = (sub: Subscription) => sub.status.type === StatusType.Expired;

export const isActiveAutoRenewOff = (sub: Subscription) =>
  sub.status.type === StatusType.ActiveAutoRenewOff;

export const canReactivate = (sub: Subscription) =>
  !isLegacyDigitalSub(sub) &&
  (isExpired(sub) ||
    isActiveAutoRenewOff(sub) ||
    isCancelled(sub) ||
    sub.status.type === StatusType.ActiveCancelScheduled);

export const canRejoin = (sub: Subscription) =>
  isLegacyDigitalSub(sub) &&
  (isExpired(sub) ||
    isActiveAutoRenewOff(sub) ||
    isCancelled(sub) ||
    sub.status.type === StatusType.ActiveCancelScheduled);

const activeBillingStatuses = new Set<StatusType>([
  StatusType.ActiveGracePeriod,
  StatusType.ActiveNormal,
  StatusType.OverdueDelinquent,
  StatusType.OverdueNoPayment,
]);

export const willRenew = (sub: Subscription) => {
  const isScheduledToPause = isDeviceSub(sub) && sub.canUnpause;
  return activeBillingStatuses.has(sub.status.type) && !isScheduledToPause;
};

export const isDeviceSub = (sub: Subscription): sub is DeviceSubscription =>
  sub.kind === SubscriptionKind.Device;

const isLegacyiOS = (sub: Subscription) => sub.kind === SubscriptionKind.LegacyiOS;

export const isDigitalSub = (sub: Subscription): sub is DigitalSubscription =>
  sub.kind === SubscriptionKind.Digital || isLegacyiOS(sub);

export const isPelotonDigitalSub = (sub: Subscription): sub is DigitalSubscription =>
  isDigitalSub(sub) && sub.platform === Platform.Peloton;

export const isThirdPartyDigitalSub = (sub: Subscription): sub is DigitalSubscription =>
  isDigitalSub(sub) && sub.platform !== Platform.Peloton;

export const isCommercialSub = (sub: Subscription): sub is DeviceSubscription =>
  (sub as DeviceSubscription).type === SubscriptionType.Commercial;

export const isAllAccessSub = (sub: Subscription): sub is DeviceSubscription =>
  isDeviceSub(sub) && (sub as DeviceSubscription).type === SubscriptionType.Consumer;

export const isGuideSub = (sub: Subscription): sub is DeviceSubscription =>
  isDeviceSub(sub) && (sub as DeviceSubscription).type === SubscriptionType.Guide;

const activeOrPausedStatuses = new Set<StatusType>([
  StatusType.ActiveNormal,
  StatusType.ActiveAutoRenewOff,
  StatusType.ActiveCancelScheduled,
  StatusType.ActiveGracePeriod,
  StatusType.Paused,
]);

export const isActiveOrPausedSub = (sub: Subscription) =>
  activeOrPausedStatuses.has(sub.status.type);

export const isOverdueSub = (sub: Subscription) =>
  sub.status.type === StatusType.OverdueDelinquent ||
  sub.status.type === StatusType.OverdueNoPayment;

export const isUnusedSub = (sub: Subscription) => sub.status.type === StatusType.Unused;

export const isCancelledSub = (sub: Subscription) =>
  sub.status.type === StatusType.Cancelled;

export const subHasEquipmentLease = (sub: Subscription) =>
  isDeviceSub(sub) && sub.hasEquipmentLease;

export const shouldManageThroughPeloton = (sub: Subscription) =>
  isDeviceSub(sub) || isPelotonDigitalSub(sub);

export const shouldManageThroughAmazon = (sub: Subscription) =>
  isDigitalSub(sub) && sub.platform === Platform.Amazon;

export const shouldManageThroughApple = (sub: Subscription) =>
  isDigitalSub(sub) && (isLegacyiOS(sub) || sub.platform === Platform.Apple);

export const shouldManageThroughVerizon = (sub: Subscription) =>
  isDigitalSub(sub) && sub.platform === Platform.Bango;

export const isDigitalSubTrial = (sub: Subscription): sub is DigitalSubscription =>
  isDigitalSub(sub) && sub.billingType === BillingType.Trial;

export const isPelotonDigitalSubTrial = (sub: Subscription): sub is DigitalSubscription =>
  isDigitalSub(sub) &&
  (sub.billingType === BillingType.PelotonInternal ||
    sub.billingType === BillingType.PelotonYearly) &&
  sub.costInCents === 0;

export const isDigitalPreDeliverySub = (sub: Subscription): sub is DigitalSubscription =>
  isDigitalSub(sub) && sub.billingType === BillingType.PreDelivery;

const isActive = prop('isActive');

export const getActiveDigitalSubTrial = find<Subscription>(
  both(isActive, isDigitalSubTrial),
);

export const getDaysUntilNextBilling = (sub: Subscription) =>
  Math.max(0, Math.floor(formatDurationBetween(toNowTime(), sub.nextBillingAt, 'day')));

const hasExactlyNActiveSubscriptions = (n: number) =>
  compose<Subscription[], Subscription[], number, boolean>(
    equals(n),
    length,
    filter<Subscription>(isActive),
  );

const notActive = complement(isActive);

const getInactiveDigitalTrial = find<Subscription>(both(notActive, isDigitalSubTrial));

export const getActivePrimaryDigitalTrial = (
  subs: Subscription[],
): Subscription | null => {
  const activeTrial = getActiveDigitalSubTrial(subs);
  return hasExactlyNActiveSubscriptions(1)(subs) && activeTrial ? activeTrial : null;
};

export const getLapsedDigitalTrial = (subs: Subscription[]): Subscription | null => {
  const lapsedTrial = getInactiveDigitalTrial(subs);
  return hasExactlyNActiveSubscriptions(0)(subs) && lapsedTrial ? lapsedTrial : null;
};

export const hasTrials = (subs: Subscription[]): boolean => {
  const digitalSubs = find(isDigitalSubTrial)(subs);
  return Boolean(digitalSubs) && !isEmpty(digitalSubs);
};

export const isLegacyDigitalSub = (sub: Subscription): boolean =>
  isDigitalSub(sub) && sub.tierType === TierType.Legacy;

export const hasActiveLegacySubs = (subs: Subscription[]): boolean =>
  subs.some(sub => isLegacyDigitalSub(sub) && isActiveOrPausedSub(sub));

export const toPrimarySub = (subs?: Subscription[]): Subscription | undefined =>
  subs && subs.length > 0
    ? sortWith<Subscription>([
        sortFirst(isActive),
        sortFirst(prop('isOwner')),
        sortFirst((sub: Subscription) => !isDigitalSubTrial(sub)),
        sortFirst(isDeviceSub),
        descend(s => s.costInCents),
      ])(subs)[0]
    : undefined;

export const to1wSubs = (subs?: Subscription[] | undefined): Subscription[] => {
  return (
    subs?.filter(
      sub =>
        sub.isOwner && !isDigitalPreDeliverySub(sub) && !isPelotonDigitalSubTrial(sub),
    ) ?? []
  );
};

const sortFirst = (condition: (sub: Subscription) => boolean) => (
  a: Subscription,
  b: Subscription,
) => (condition(a) === condition(b) ? 0 : condition(a) ? -1 : 1);

export type MoneyProps = Pick<Subscription, 'costInCents' | 'currency'>;
export const parseMoney = ({ costInCents, currency }: MoneyProps): Money =>
  toMoney(costInCents, currency as Currency);

const printMoney = (money: Money): string => `${formatMoney(money)}/mo`;

export const getFormattedMoney = (costInCents: number, currency?: string): string =>
  formatMoney(toMoney(costInCents, currency as Currency));

export const toFormattedMoney = compose(printMoney, parseMoney);

export const toSubscriptionDomainType = (subscription: Subscription) => {
  if (isGuideSub(subscription)) {
    return subscription.type;
  }
  return subscription.kind.toLowerCase();
};

export const isAnnualBillingFrequency = (
  subscription: Subscription,
): subscription is DigitalSubscription =>
  isDigitalSub(subscription) && subscription.billingFrequency === BillingFrequency.Annual;

export const getRolloverPrice = (subscription: Subscription) => {
  if (isDigitalSub(subscription)) {
    return subscription.rolloverCostInCents;
  }
  return subscription.costInCents;
};
