// @ts-nocheck
// NOTE: This file is for packages/apps that are ready to be turned on for Apollo 3 in staging
import type {
  TypePolicy,
  NormalizedCacheObject,
  DataProxy,
  ApolloQueryResult,
} from '@apollo/client';
import {
  HttpLink as HttpLinkV3,
  from,
  ApolloClient as ApolloClientV3,
  InMemoryCache as InMemoryCacheV3,
  ApolloProvider as ApolloProviderV3,
  useApolloClient as useApolloClientV3,
  ApolloError as ApolloErrorV3,
  useMutation as useMutationV3,
  useQuery as useQueryV3,
  useSuspenseQuery,
  ApolloLink,
} from '@apollo/client';
import { SchemaLink as SchemaLinkV3 } from '@apollo/client/link/schema';
import type { DataProxy as DataProxyV2 } from 'apollo-cache';
import {
  InMemoryCache as InMemoryCacheV2,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory';
import {
  ApolloClient as ApolloClientV2,
  ApolloError as ApolloErrorV2,
} from 'apollo-client';
import { HttpLink as HttpLinkV2 } from 'apollo-link-http';
import { SchemaLink as SchemaLinkV2 } from 'apollo-link-schema';
import type { DocumentNode as DocumentNodeV2 } from 'graphql';
import { OperationVariables as OperationVariablesV2 } from 'react-apollo';
import type {
  MutationHookOptions as MutationHookOptionsV2,
  QueryHookOptions as QueryHookOptionsV2,
  QueryHookResult as QueryHookResultV2,
  MutationFn as MutationFnV2,
  MutationResult as MutationResultV2,
} from 'react-apollo-hooks';
import {
  useApolloClient as useApolloClientV2,
  ApolloProvider as ApolloProviderV2,
  useMutation as useMutationV2,
  useQuery as useQueryV2,
} from 'react-apollo-hooks';
import { USE_APOLLO_V3_ACCOUNT } from '@peloton/app-config';

const OperationVariables = OperationVariablesV2;

const useApolloClient = useApolloClientV3;
const ApolloProvider = ApolloProviderV3;
const HttpLink = HttpLinkV3;
const InMemoryCache = InMemoryCacheV3;
const ApolloClient = ApolloClientV3;
const SchemaLink = SchemaLinkV3;
const ApolloError = ApolloErrorV3;
const useMutation = useMutationV3;
const useQuery = useQueryV3;

// TODO: these V2 exports are to consolidate all usage of outdated Apollo packages into this @peloton/graphql.
// NOTE: These are exported as V2 since the base functionality is still using Apollo V2
export {
  // toggled exports
  useApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  ApolloClient,
  SchemaLink,
  useQuery,
  useMutation,
  // Apollo 2 exports
  IntrospectionFragmentMatcher,
  // Apollo 3 exports
  useSuspenseQuery,
  from,
  ApolloError,
  ApolloLink,
};

export type {
  ApolloQueryResult,
  DataProxy,
  TypePolicy,
  NormalizedCacheObject,
  OperationVariables,
};
