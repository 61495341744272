import React from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { asyncComponent } from '@peloton/code-splitting';
import { DomainBasedCopyProvider, useAccountKeyValue } from '@account/copy';
import { getIsProdEnv } from '@account/env';
import type { ReducerState as ToggleState } from '@ecomm/feature-toggle/redux';
import { switchToggle } from '@ecomm/feature-toggle/redux';
import { _getIsToggleActive, getToggles } from '@ecomm/feature-toggle/selectors';

const PanelComponent = asyncComponent(
  () =>
    import(
      '@ecomm/feature-toggle/panel/View' /* webpackChunkName: "FeatureTogglePanel" */
    ),
);

const BaseFeatureTogglePanel: React.FC<React.PropsWithChildren<unknown>> = () => {
  const flyoutAriaLabel = useAccountKeyValue('toggleViewer.ariaLabel');
  const isProd = useSelector(getIsProdEnv);
  const toggles = useSelector(getToggles);
  const dispatch = useDispatch();
  const isToggleActive = useToggleGetter();

  return isProd ? null : (
    <PanelComponent
      toggles={toggles}
      isToggleActive={isToggleActive}
      setToggleValue={(toggleId, value) => {
        dispatch(switchToggle(toggleId, value));
      }}
      flyoutAriaLabel={flyoutAriaLabel}
      shouldCreateWindowHandler={isProd}
    />
  );
};

const FeatureTogglePanel: React.FC<React.PropsWithChildren<unknown>> = () => (
  <DomainBasedCopyProvider>
    <BaseFeatureTogglePanel />
  </DomainBasedCopyProvider>
);

export default FeatureTogglePanel;

const useToggleGetter = () => {
  const state: ToggleState = useStore().getState() as ToggleState;
  return (t: string) => _getIsToggleActive(t)(state);
};
