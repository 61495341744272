'use client';
import React from 'react';
import scriptjs from 'scriptjs';
import { asyncComponent } from '@peloton/code-splitting';
import { FEATURES, Route } from '@account/optimizely';
import { googleMapsCommonProps } from '@ecomm/google-maps';
import { toRemoteActivationPath } from './urls';

const noOp = () => {};

const Routes = [
  <Route
    key={toRemoteActivationPath()}
    path={toRemoteActivationPath()}
    component={asyncComponent(() => {
      // here, we initialize fetch for google maps for address autocomplete.
      // we don't await the script's 'onload' event, as it should not block render of the checkout page,
      // nor is it actually required for the user to complete checkout.
      scriptjs(googleMapsCommonProps.googleMapURL, noOp);

      return import('./Page'); /* webpackChunkName: "RemoteActivationPage" */
    })}
    feature={FEATURES.RemoteActivationPage}
  />,
];

export default Routes;
