export enum PaymentType {
  CorporateBenefit = 'benefit_hub',
  DirectDebit = 'direct_debit',
  CreditCard = 'credit_card',
  GiftCard = 'gift_card',
}

export type PaymentMethod = {
  maskedNumber?: string;
  expirationDate?: string;
  isExpired: boolean;
  label: string;
  paymentType: PaymentType;
};
