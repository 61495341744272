import { AuthLinkEnvs } from '@peloton/external-links';
import type { EnvironmentFlags } from './models';

const toAuthLinkEnv = ({
  isProd,
  isProdTest,
  isLocal,
  isUatProd,
  isStageN,
}: EnvironmentFlags): typeof AuthLinkEnvs[keyof typeof AuthLinkEnvs] =>
  (isProd && AuthLinkEnvs.Prod) ||
  (isProdTest && AuthLinkEnvs.ProdTest) ||
  (isLocal && AuthLinkEnvs.Local) ||
  (isUatProd && AuthLinkEnvs.UatProd) ||
  (isStageN && AuthLinkEnvs.StageN) ||
  AuthLinkEnvs.UatQa;

export default toAuthLinkEnv;
