import type { PaymentDetailsFragment } from './PaymentDetails.generated';

export type PaymentMethodDetails = NonNullable<
  PaymentDetailsFragment['payments']
>[number];

export enum BillingPartner {
  Stripe = 'stripe',
  Fiserv = 'fiserv',
  Truemed = 'truemed',
  NullPayment = 'null_payment',
}
