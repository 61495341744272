import * as React from 'react';
import { asyncComponent } from '@peloton/code-splitting';
import { Route } from '@account/router';
import { toReturnsStatusPath } from '@account/urls';

const Routes = [
  <Route
    key="returns-page"
    path={toReturnsStatusPath()}
    component={asyncComponent(() => import('./Page'))}
    shouldTrack={true}
  />,
];

export default Routes;
