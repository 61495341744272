import { gql } from '@apollo/client';
import type { Country } from '@peloton/internationalize/models';
import type { OneWellnessClient } from './client';
import { toData } from './utils';

export enum ErrorType {
  MultipleAccounts = 1316,
}

type QueryProps = {
  token: string | null;
  country: Country;
};

export const entitlementByToken = (
  client: OneWellnessClient,
  { token, country }: QueryProps,
) =>
  client
    .query({
      query: ENTITLEMENT_BY_TOKEN,
      variables: { token, country },
    })
    .then(toData);

const ENTITLEMENT_BY_TOKEN = gql`
  query EntitlementByToken($token: String!, $country: String) {
    entitlementByToken(token: $token) {
      token
      isClaimed
      program {
        slug
        onboardingPathSegment
        programBenefits(country: $country) {
          formattedRetailRate
          formattedUserPays
          retailRate
          subscriptionType
          userPays
        }
        partner {
          name
          logo
        }
      }
    }
  }
`;
