import { css } from 'styled-components';
import { defaultTransition } from './animation';

export const zopimStyles = css`
  .zopim {
    ${defaultTransition()}
  }

  [data-test-id='ChatWidgetWindow'],
  [data-test-id='ChatWidgetMobileWindow'] {
    z-index: 90003 !important;
  }

  [data-test-id='ChatWidgetButton'],
  [data-test-id='ChatWidgetMobileButton'] {
    z-index: 90003 !important;
  }

  [data-test-id='ChatWidgetUnreadCountBadge'],
  [data-test-id='ChatWidgetMobileUnreadCountBadge'] {
    z-index: 90004 !important;
  }

  [data-test-id='ChatWidgetNotification'],
  [data-test-id='ChatWidgetMobileNotification'] {
    z-index: 90005 !important;
  }
`;

export default zopimStyles;
