import { replace } from 'ramda';
import type { Client } from '@peloton/api';
import { PaymentMethod } from '@ecomm/models';

const UPDATE_PAYMENT_METHOD_ENDPOINT = '/ecomm/customer/payment';

const BillingPartnerMap = {
  [PaymentMethod.CreditCard]: 'stripe',
  [PaymentMethod.KlarnaDirectDebit]: 'klarna_direct',
};

export const updatePaymentMethod = (
  api: Client,
  paymentMethod: string,
  paymentType: PaymentMethod.CreditCard | PaymentMethod.KlarnaDirectDebit,
) =>
  api.post(UPDATE_PAYMENT_METHOD_ENDPOINT, {
    paymentMethod,
    partner: BillingPartnerMap[paymentType],
  });

const updateAndCapturePaymentMethodEndpoint = (orderId: String) =>
  replace(/-/g, '', `/ecomm/orders/${orderId}/update_payment`);

export const updateAndCapturePaymentMethod = (
  api: Client,
  paymentMethod: string,
  paymentType: PaymentMethod.CreditCard | PaymentMethod.KlarnaDirectDebit,
  orderId: string,
) =>
  api.post(updateAndCapturePaymentMethodEndpoint(orderId), {
    paymentMethod,
    paymentPartner: BillingPartnerMap[paymentType],
  });
