const isApiMeError = (error: any) =>
  error?.response?.config?.url.indexOf('/api/me') !== -1;
const isLoginError = (error: any) =>
  error?.response?.config?.url.indexOf('/login') !== -1;
const isLogoutError = (error: any) =>
  error?.response?.config?.url.indexOf('/logout') !== -1;
const isStreamLimitError = (error: any) =>
  error?.response?.config?.url.indexOf('/subscription/stream') !== -1;
const isPrimarySubscriptionError = (error: any) =>
  error?.response?.config?.url.indexOf('/primary_subscription') !== -1;

// Content previews can be expected to 404 occasionally
const isContentPreviewError = (error: any) =>
  error?.response?.data?.errorCode === 110 &&
  error?.response?.data?.message === 'Content Preview is Not Available';

const isApiUserError = (error: any) =>
  error?.response?.config?.url.indexOf('api/user') !== -1;

const doesResourceNotExist = (err: any) =>
  err?.response?.data?.errorCode === 110 ||
  (err?.response?.data?.errorCode === 150 &&
    err?.response?.data?.message !== 'User is already a participant of this challenge') || // Temp fix for novalidate error leaking out of API
  err?.response?.data?.message === 'Resource does not exist.';

const errorHandler = (
  redirectToLogin: () => void,
  redirectToNotFound: () => void,
  deAuthenticate?: () => void,
) => (error: any) => {
  const skipErrorHandlers = error?.response?.config?.meta?.skipErrorHandlers ?? {};
  if (skipErrorHandlers?.skipAll || skipErrorHandlers[error?.response?.status]) {
    return Promise.reject(error);
  }

  if (error?.response?.status === 401 || error?.response?.status === 403) {
    deAuthenticate?.();
    if (
      !isLoginError(error) &&
      !isLogoutError(error) &&
      !isApiMeError(error) &&
      !isStreamLimitError(error) &&
      !isPrimarySubscriptionError(error)
    ) {
      redirectToLogin();
    }
  }

  if (
    error?.response?.status === 404 ||
    (doesResourceNotExist(error) && !isApiUserError(error))
  ) {
    !isContentPreviewError(error) && redirectToNotFound();
  }

  return Promise.reject(error);
};

export default errorHandler;
