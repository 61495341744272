import { createMatchSelector } from 'connected-react-router';
import type { GlobalState } from 'packages/@peloton/split-testing/redux/optimizely';
import { any, isEmpty, not, pathOr, pipe, prop, propOr, values } from 'ramda';
import { createSelector } from 'reselect';
import { getSignedInUserEmail } from '@peloton/auth/redux';
import type { UserSelectorState } from '@peloton/auth/redux';
import { localeUrlRegexMatcher } from '@peloton/internationalize/models/path';
import { selectCartPayments } from '@ecomm/cart/redux/selectors/base';
import type { Attribution, Verification } from '@ecomm/checkout/models';
import type { ReducerState as ToggleState } from '@ecomm/feature-toggle';
import { getIsToggleActive } from '@ecomm/feature-toggle';
import type { Email, UIState } from '@ecomm/models';
import { PaymentMethod, isLoaded } from '@ecomm/models';
import type { TokenOpts } from '@ecomm/payment';
import type { SubmitOrderPayment } from '../api/types';
import type { Address, CheckoutBillingInfo, CreditCard, User } from '../models';
import { AddressType } from '../models';
import { CHECKOUT, CHECKOUT_REVIEW, FAAS_BUYOUT_CHECKOUT } from '../routes';
import type { ReducerState as RootState, State as CheckoutState } from './rootReducer';
import type { State as UserReducerState, UserState } from './user';

export const selectCheckout = (state: RootState): CheckoutState => state.checkout;

export const selectCheckoutReviewOrder = (state: RootState) =>
  selectCheckout(state).reviewOrder;

export const selectCheckoutSubmitOrder = (state: RootState) =>
  selectCheckout(state).submitOrder;

export const getUser = pipe<RootState, CheckoutState, UserReducerState>(
  selectCheckout,
  prop('user'),
);

export const getUserEmail = createSelector<
  RootState | UserSelectorState,
  UserState,
  Email | undefined,
  Email
>(
  getUser,
  getSignedInUserEmail,
  (user, signedInUserEmail) => signedInUserEmail || user.email,
);

export const getUserField = (state: RootState, props: { name: keyof UserState }) =>
  prop(props.name, getUser(state));

export const getUserErrors = pipe(getUser, pathOr({}, ['errors']));
export const getUserEmailErrors = pipe(getUser, pathOr('', ['errors', 'email']));

export const getAcceptedTerms = (state: RootState): boolean =>
  getUser(state).hasAcceptedPolicy;

export const getUserFieldError = (
  state: RootState,
  props: { name: keyof UserState },
): string => prop(props.name, getUserErrors(state));

export const getDoesUserExist = (state: RootState): boolean | undefined =>
  selectCheckout(state).user.exists;

export const getMarketingPreference = (state: RootState): boolean =>
  selectCheckout(state).user.allowMarketing;

export const getAcceptedLeaseAgreement = (state: RootState): boolean =>
  selectCheckout(state).user.hasAcceptedLeaseAgreement;

export const getShippingUIState = (state: RootState): UIState =>
  selectCheckout(state).shipping.ui;

export const selectCheckoutBilling = (state: RootState): CheckoutBillingInfo =>
  selectCheckout(state).billing;

export const selectCheckoutToken = (state: RootState) =>
  selectCheckout(state).billing.token;

export const selectCheckoutPromo = (state: RootState) => selectCheckout(state).promo;

export const selectCodeReferrer = (state: RootState) => selectCheckout(state).referrer;

export const getCheckoutUIState = (state: RootState): UIState => selectCheckout(state).ui;

export const getCheckoutAddress = (
  state: RootState,
  props: { addressType: AddressType },
): Address => prop(props.addressType, selectCheckout(state).address);

export const getCheckoutPayment = (state: RootState) => selectCheckout(state).payment;

export const getCheckoutCardName = (state: RootState) =>
  getCheckoutPayment(state)['Credit Card']?.name;

export const getCheckoutAddressField = (
  state: RootState,
  props: { addressType: AddressType; name: keyof Address },
): string => prop(props.name, getCheckoutAddress(state, props));

export const getPaymentField = (
  state: RootState,
  props: { paymentMethod: PaymentMethod; name: keyof CreditCard },
): string => pathOr('', [props.paymentMethod, props.name], getCheckoutPayment(state));

export const getPromoField = (state: RootState): string =>
  selectCheckoutPromo(state).value;

export const getCheckoutAddressErrors = (
  state: RootState,
  props: { addressType: AddressType },
): Partial<Record<keyof Address, string>> =>
  pathOr({}, ['errors', props.addressType], selectCheckout(state).address);

export const getPaymentErrors = (
  state: RootState,
  props: { paymentMethod: PaymentMethod },
): Partial<Record<keyof CreditCard, string>> =>
  pathOr({}, ['errors', props.paymentMethod], selectCheckout(state).payment);

export const getPromoError = (state: RootState) => selectCheckoutPromo(state).error;

export const getPromoUI = (state: RootState) => selectCheckoutPromo(state).ui;

export const getIsPromoApplied = createSelector(getPromoUI, isLoaded);

export const getReferrerData = (state: RootState) => selectCodeReferrer(state).value;

export const getSelectedPaymentMethod = (state: RootState) =>
  getCheckoutPayment(state).selectedMethod;

export const getSelectedFinancingPartner = (state: RootState) =>
  getCheckoutPayment(state).financingPartner;

export const getAddressFieldError = (
  state: RootState,
  props: { addressType: AddressType; name: keyof Address },
): string => propOr('', props.name, getCheckoutAddressErrors(state, props));

export const getPaymentFieldError = (
  state: RootState,
  props: { paymentMethod: PaymentMethod; name: keyof CreditCard },
): string => propOr('', props.name, getPaymentErrors(state, props));

// TODO: Add User info errors
export const hasCheckoutErrors = createSelector<
  RootState,
  { addressType: AddressType; paymentMethod: PaymentMethod },
  Partial<Record<keyof Address, string>>,
  Partial<Record<keyof CreditCard, string>>,
  Partial<Record<keyof User, string>>,
  boolean
>(
  getCheckoutAddressErrors,
  getPaymentErrors,
  getUserErrors,
  (addressErrors = {}, paymentErrors = {}, userErrors = {}) => {
    const errorMsgs = [
      ...values(addressErrors),
      ...values(paymentErrors),
      ...values(userErrors),
    ];
    return any(pipe(isEmpty, not), errorMsgs);
  },
);

export const hasCheckoutWithNoPaymentErrors = createSelector<
  RootState,
  Partial<Record<keyof User, string>>,
  boolean
>(getUserErrors, (userErrors = {}) => {
  const errorMsgs = [...values(userErrors)];
  return any(pipe(isEmpty, not), errorMsgs);
});

export const hasUserErrors = createSelector<
  RootState,
  Partial<Record<keyof User, string>>,
  boolean
>(getUserErrors, (userErrors = {}) => {
  const errorMsgs = [...values(userErrors)];
  return any(pipe(isEmpty, not), errorMsgs);
});

export const hasUserEmailErrors = createSelector<
  RootState,
  Partial<Record<keyof User, string>>,
  boolean
>(getUserEmailErrors, userEmailErrors => {
  return Boolean(userEmailErrors);
});

export const hasAcceptedTermsError = createSelector<RootState, boolean, boolean>(
  getAcceptedTerms,
  acceptedTerms => {
    return !acceptedTerms;
  },
);

const getBillingAddress = (state: RootState) =>
  getCheckoutAddress(state, { addressType: AddressType.Billing });

const getCreditCardName = (state: RootState) =>
  getPaymentField(state, { paymentMethod: PaymentMethod.CreditCard, name: 'name' });

export const getPaymentTokenData = createSelector<RootState, Address, string, TokenOpts>(
  getBillingAddress,
  getCreditCardName,
  (address, creditCardName) => ({ creditCardName, address }),
);

const matchCheckoutRouteSelector = createMatchSelector({
  path: CHECKOUT,
  exact: true,
} as any);

const matchCheckoutRouteI18nSelector = createMatchSelector({
  path: `/(${localeUrlRegexMatcher})${CHECKOUT}`,
  exact: true,
} as any);

export const matchCheckoutRoute = createSelector(
  [matchCheckoutRouteSelector, matchCheckoutRouteI18nSelector],
  (us, i18n) => {
    return us || i18n;
  },
);

const matchBuyoutRouteDefault = createMatchSelector({
  path: FAAS_BUYOUT_CHECKOUT,
  exact: true,
} as any);

const matchBuyoutRouteI18n = createMatchSelector({
  path: `/(${localeUrlRegexMatcher})${FAAS_BUYOUT_CHECKOUT}`,
  exact: true,
} as any);

export const matchBuyoutRoute = createSelector(
  [matchBuyoutRouteDefault, matchBuyoutRouteI18n],
  (us, i18n) => {
    return us || i18n;
  },
);
const matchCheckoutReviewRouteUs = createMatchSelector(CHECKOUT_REVIEW);
const matchCheckoutReviewRouteI18n = createMatchSelector(
  `/(${localeUrlRegexMatcher})${CHECKOUT_REVIEW}`,
);

export const matchCheckoutReviewRoute = createSelector(
  [matchCheckoutReviewRouteUs, matchCheckoutReviewRouteI18n],
  (us, i18n) => {
    return us || i18n;
  },
);

export type MarketingOptionsState = GlobalState & ToggleState;

export const getShowOptIn = (state: MarketingOptionsState): boolean =>
  getIsToggleActive('needsConsentForMarketing')(state);

export const getShowOptOut = (state: MarketingOptionsState): boolean =>
  getIsToggleActive('optOutMarketing')(state);

export const getAttribution = (state: RootState): Attribution =>
  selectCheckout(state).attribution;

export const getSkipFreeTrialPath = (state: RootState): string =>
  selectCheckout(state).digital.skipFreeTrialPath;

export const getVerification = (state: RootState): Verification =>
  selectCheckout(state).verification;

export const getFreeTrialEligibility = (state: RootState): boolean =>
  selectCheckout(state).freeTrialEligibility;

export const getGiftCardOrderPayments = createSelector(
  selectCartPayments,
  giftCardPayments =>
    giftCardPayments?.map(
      (giftCardPayment): SubmitOrderPayment => ({
        token: {
          kind: 'gift_card',
          id: giftCardPayment.paymentMethodToken,
        },
        amount: giftCardPayment.amountInCents,
        method: PaymentMethod.GiftCard,
        lastFour: giftCardPayment.lastFour,
      }),
    ),
);
