import React from 'react';
import { ErrorBoundary, ErrorReporterContext } from '@peloton/error-reporting';

// TODO: fix this prettier auto-formatting
export type Props = {
  LoadingFallback: JSX.Element;
  ErrorFallback: JSX.Element;
} & Partial<React.ComponentProps<typeof React.Suspense>> &
  Partial<React.ComponentProps<typeof ErrorBoundary>>;

const AsyncFallback: React.FC<React.PropsWithChildren<Props>> = ({
  LoadingFallback,
  ErrorFallback,
  fallback = LoadingFallback,
  renderError = () => ErrorFallback,
  ...props
}) => (
  <React.Suspense fallback={fallback}>
    <ErrorReporterContext.Consumer>
      {({ errorReporter }) => (
        <ErrorBoundary
          renderError={renderError}
          reportError={errorReporter.reportError}
          // use key to bind the error to a particular page, allowing
          // client-side routing from within an error component
          // https://pelotoncycle.atlassian.net/browse/LOX-3213
          key={typeof window !== 'undefined' ? window.location.pathname : 'ssr'}
        >
          <React.Fragment {...props} />
        </ErrorBoundary>
      )}
    </ErrorReporterContext.Consumer>
  </React.Suspense>
);

export default AsyncFallback;
