export interface ConcessionaryPricing {
  bikeDiscount: string;
  refurbBikeDiscount: string;
  bikePlusDiscount: string;
  refurbBikePlusDiscount: string;
  treadDiscount: string;
  treadPlusDiscount: string;
  rowDiscount: string;
}
const mapToView = (content: any): ConcessionaryPricing => {
  return {
    bikeDiscount: content['ConcessionaryPricing']['concessionaryPricing.bike']['value'],
    refurbBikeDiscount:
      content['ConcessionaryPricing']['concessionaryPricing.refurb.bike']['value'],
    bikePlusDiscount:
      content['ConcessionaryPricing']['concessionaryPricing.bike.plus']['value'],
    refurbBikePlusDiscount:
      content['ConcessionaryPricing']['concessionaryPricing.refurb.bike.plus']['value'],
    treadDiscount: content['ConcessionaryPricing']['concessionaryPricing.tread']['value'],
    treadPlusDiscount:
      content['ConcessionaryPricing']['concessionaryPricing.tread.plus']['value'],
    rowDiscount: content['ConcessionaryPricing']['concessionaryPricing.row']['value'],
  };
};

export const CONCESSIONARY_PRICING_ID = '3LowKJ2XQSchdq1BAa9Bp9';

export default {
  entryId: CONCESSIONARY_PRICING_ID,
  contentType: 'contentAggregate',
  mapToView,
};
