import { T, allPass } from 'ramda';
import type { ToggleId } from '@account/feature-toggles';
import { getIsToggleActive } from '@account/feature-toggles';
import { getIsDark } from '@ecomm/feature-toggle';

export type Toggle = 'dark' | ToggleId;

const toAreTogglesActive = <S>(toggles?: Toggle | Toggle[]): ToggleSelector<S> => {
  if (!toggles) {
    return T as ToggleSelector<S>;
  }

  toggles = Array.isArray(toggles) ? toggles : [toggles];

  return allPass(
    toggles.map((toggle: Toggle) =>
      toggle === 'dark' ? getIsDark : getIsToggleActive(toggle),
    ),
  );
};

export default toAreTogglesActive;

type ToggleSelector<S> = (state: S) => boolean;
