import React from 'react';
import { asyncComponent } from '@peloton/code-splitting';
import { Route } from '@account/router';
import { SUBSCRIPTION_ERROR_PATH } from '../routes';

const route = [
  <Route
    key={SUBSCRIPTION_ERROR_PATH}
    path={SUBSCRIPTION_ERROR_PATH}
    component={asyncComponent(
      () => import('./Page' /* webpackChunkName: "ExistingSubscriptionsErrorPage" */),
    )}
  />,
];

export default route;
