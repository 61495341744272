/* map, used below, is used to map over a key/value object, rather than the native array map */
/* eslint-disable-next-line no-restricted-imports */
import { map, merge, pipe } from 'ramda';
import React from 'react';
import type { Options } from '@peloton/images-ui';
import type { Breakpoint } from '@peloton/responsive';
import { BreakpointSwitch } from '@peloton/responsive';
import { toCloudinaryOrLocalSrc } from './toCloudinarySrc';

export type BreakpointOptionsMap = Record<Breakpoint, Partial<Options>>;

type Src = { src: string };

export type BreakpointOptions = Partial<BreakpointOptionsMap> & { mobile: Options };

export type Props = {
  /**
   * Use this to describe the width or height of the image at different breakpoints,
   * as well as to set other options such as compression quality and image format.
   *
   * In general, you should set the width or height for a given breakpoint to the
   * maximum width or height the image could reach at that breakpoint.
   *
   * If an image maintains a single width/height at each breakpoint, you can set
   * it once in the lowest breakpoint.
   *
   * If an image maintains the same percent width throughout all breakpoints,
   * then best practice would be to set options for the mobile, tablet, and desktop
   * breakpoints, calculating the maximum width the image would reach at each of
   * those breakpoints.
   */
  breakpointOptions: BreakpointOptions;
  isLocal: boolean;
  render: (props: Src & { children?: React.ReactNode }, options: Options) => JSX.Element;
  src: string;
};

/**
 * Takes a full source URL for an image, a map of breakpoints to options that
 * apply to images at each breakpoint, and a component to pass the `src` to.
 *
 * Returns a `BreakpointSwitch` that passes the src with the matching options
 * from `breakpointOptions` to the render component.
 *
 * A single component at the `mobile` breakpoint will always be returned,
 * using the passed `src`.
 */
export const BreakpointTransforms: React.FC<React.PropsWithChildren<Props>> = ({
  isLocal,
  src: topLevelSource,
  breakpointOptions,
  render,
  ...props
}) => (
  <BreakpointSwitch
    breakpoints={map<any, any>(
      pipe<Options, Options & Src, Src & { options: Options }, JSX.Element>(
        // coalesce the src with the breakpoint-specific options
        merge({ src: topLevelSource }),
        // run the source through cloudinary config
        withConfiguredSource(isLocal),
        // spread any rest props into the render component
        ({ src, options }) => render({ src, ...props }, options),
      ),
      {
        ...breakpointOptions,
      },
    )}
  />
);

const withConfiguredSource = (isLocal: boolean) => (options: Options & Src) => ({
  options,
  src: toCloudinaryOrLocalSrc(isLocal, options),
});
