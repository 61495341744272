import * as React from 'react';
import { Route } from 'react-router-dom';
import { asyncComponent } from '@peloton/code-splitting';
import { toReturnPath } from '@account/urls';

const LoadablePage = asyncComponent(() => import('./Page'));
const Routes = [
  <Route key="returns-page" path={toReturnPath()}>
    <LoadablePage />
  </Route>,
];

export default Routes;
