import { any, chain } from 'ramda';
import type { Image, Money } from '@ecomm/models';
import { ProductLine } from '@ecomm/shop/models/Product';
import type { Discount } from './Discount';

export enum ItemType {
  Item = 'item',
  Subscription = 'subscription',
  Bundle = 'bundle',
  Device = 'device',
}

export type BaseItem = {
  id: string;
  description: string;
  discount?: Discount;
  images: Image[];
  name: string;
  price: Money;
  slug: string;
};

export const accessoryProductLines = {
  [ProductLine.Other]: 'accessory',
  [ProductLine.ParcelOnly]: 'accessory',
  [ProductLine.SparePart]: 'accessory',
  [ProductLine.TreadAccessory]: 'accessory',
  [ProductLine.TreadWeight]: 'accessory',
};

export const onePelotonClubProductLines = {
  [ProductLine.EquipmentLease]: 'opc',
};

export const toPrimaryImage = (item: BaseItem) => item.images && item.images[0];

type BaseBundle = BaseItem & {
  quantity: 1;
  items: SingleItem[];
  productLine?: ProductLine;
};

export type SingleItem = BaseItem & {
  productId: string;
  productLine: ProductLine;
  quantity: number;
  sku: string;
  type: 'item' | 'subscription';
};

export type Device = BaseBundle & {
  type: 'device';
};

export type Bundle = BaseBundle & {
  type: 'bundle';
};

export type TradeIn = {
  id: string;
  quantity: number;
};

export type Item = Device | SingleItem | Bundle;

export type FlattenedItem = SingleItem & { bundleSlug?: string };

const isBundle = (item: Item): item is Bundle => item.type === 'bundle';

export const isSingleItem = (item: Item): item is SingleItem =>
  item.type === 'item' || item.type === 'subscription';

export const isDevice = (item: Item): item is Device => item.type === 'device';

export const isBike = (item: Item) =>
  isSingleItem(item) && item.productLine === ProductLine.Bike;

export const isBikePlus = (item: Item) =>
  isSingleItem(item) && item.productLine === ProductLine.BikePlus;

export const isTreadPlus = (item: Item) =>
  isSingleItem(item) && item.productLine === ProductLine.TreadPlus;

export const isTread = (item: Item) =>
  isSingleItem(item) && item.productLine === ProductLine.Tread;

export const isSubscription = (item: Item): item is SingleItem =>
  item.type === 'subscription';

export const isWarranty = (item: Item): item is SingleItem =>
  isSingleItem(item) && item.productLine === ProductLine.Warranty;

export const isGuide = (item: Item) =>
  isSingleItem(item) && item.productLine === ProductLine.RainforestCafe;

export const isRow = (item: Item) =>
  isSingleItem(item) && item.productLine === ProductLine.Row;

export const isAccessory = (item: Item): item is SingleItem =>
  isSingleItem(item) &&
  (item.productLine === ProductLine.Other ||
    item.productLine === ProductLine.TreadWeight ||
    item.productLine === ProductLine.TreadAccessory ||
    item.productLine === ProductLine.ParcelOnly ||
    item.productLine === ProductLine.SparePart);

export const isEquipmentLease = (item: Item) =>
  isSingleItem(item) && item.productLine === ProductLine.EquipmentLease;

export const isRefurbishedBike = (item: Item) =>
  isSingleItem(item) && item.productLine === ProductLine.RefurbBike;

export const isRefurbishedBikePlus = (item: Item) =>
  isSingleItem(item) && item.productLine === ProductLine.RefurbBikePlus;

export const isSubscriptionPrepaidCredit = (item: Item) =>
  isSingleItem(item) && item.productLine === ProductLine.SubscriptionPrepaidCredit;

export const isItemByProductLine = (productLine: ProductLine) => (item: Item) =>
  isSingleItem(item) && item.productLine === productLine;

export const hasOrIsDevice = (item: Item): boolean =>
  isBundle(item) ? any(isDevice, item.items) : isDevice(item);

export const hasOrIsBike = (item: Item): boolean =>
  isDevice(item) ? any(isBike, item.items) : isBike(item);

export const hasOrIsBikePlus = (item: Item): boolean =>
  isDevice(item) ? any(isBikePlus, item.items) : isBikePlus(item);

export const hasOrIsTreadPlus = (item: Item): boolean =>
  isDevice(item) ? any(isTreadPlus, item.items) : isTreadPlus(item);

export const hasOrIsTread = (item: Item): boolean =>
  isDevice(item) ? any(isTread, item.items) : isTread(item);

export const hasOrIsAccessory = (item: Item): boolean =>
  isBundle(item) ? any(isAccessory, item.items) : isAccessory(item);

export const hasOrIsGuide = (item: Item): boolean =>
  isBundle(item) ? any(isGuide, item.items) : isGuide(item);

export const hasOrIsRow = (item: Item): boolean =>
  isDevice(item) ? any(isRow, item.items) : isRow(item);

export const hasOrIsEquipmentLease = (item: Item): boolean =>
  isDevice(item) ? any(isEquipmentLease, item.items) : isEquipmentLease(item);

export const hasOrIsRefurbishedBike = (item: Item): boolean =>
  isDevice(item) ? any(isRefurbishedBike, item.items) : isRefurbishedBike(item);

export const hasOrIsRefurbishedBikePlus = (item: Item): boolean =>
  isDevice(item) ? any(isRefurbishedBikePlus, item.items) : isRefurbishedBikePlus(item);

export const hasOrIsRefurbishedBikeOrBikePlus = (item: Item): boolean =>
  hasOrIsRefurbishedBike(item) || hasOrIsRefurbishedBikePlus(item);

export const hasOrIsSubscriptionPrepaidCredit = (item: Item): boolean =>
  isDevice(item)
    ? any(isSubscriptionPrepaidCredit, item.items)
    : isSubscriptionPrepaidCredit(item);

export const hasOrIsItemByProductLine = (productLine: ProductLine) => (
  item: Item,
): boolean =>
  isBundle(item) || isDevice(item)
    ? any(isItemByProductLine(productLine), item.items)
    : isItemByProductLine(productLine)(item);

export const toSingleItems = chain<Item, FlattenedItem>(item => {
  if (item.type === 'bundle' || item.type === 'device') {
    return item.items.map(i => ({ ...i, bundleSlug: item.slug }));
  } else {
    return [item];
  }
});
