import type { Dayjs } from 'dayjs';
import { duration, utc } from 'moment';
import type { unitOfTime } from 'moment';
import type { Duration } from 'moment-timezone';
// eslint-disable-next-line no-restricted-imports
import { theUserIsInGermany } from '@peloton/internationalize';
import { precisionRound } from '@peloton/numbers';
import { nbsp } from '@peloton/text';
import type { Locale } from '../internationalize/models';
import type { Time } from './time';
import { toNowTime } from './time';

export const Format = {
  DayFirstSlashDate: 'D/M/YY',
  DayFirstDotDate: 'D.M.YY',
  MonthFirstSlashDate: 'M/D/YY',
  DayFirstSlashDateLongYear: 'D/M/YYYY',
  DayFirstDotDateLongYear: 'D.M.YYYY',
  MonthFirstSlashDateLongYear: 'M/D/YYYY',
  TwoDigitMonthFirstSlashDate: 'MM/DD/YY',
  DayFirstMonthNameShortDate: 'DD MMM YYYY',
  DayFirstMonthNameShortDotDate: 'DD. MMM YYYY',
  MonthNameShortDate: 'MMM DD, YYYY',
  MonthNameMinimalDate: 'MMM D, YYYY',
  MonthNameMinimalDateWithoutYear: 'MMM D',
  Time12Hour: `h:mm${nbsp}A`,
  Time12HourShort: `h${nbsp}A`,
  Time24Hour: 'HH:mm',
  Time24HourShort: 'HH',
  DayNameShort: 'ddd',
  DayNameLong: 'dddd',
  DayOfMonth: 'D',
  MonthName: 'MMMM',
  MonthNameShort: 'MMM',
  MonthNumberWithLeadingZero: 'MM',
  MonthNumberWithoutLeadingZero: 'M',
  TwoDigitYear: 'YY',
  FourDigitYear: 'YYYY',
};

export const formatDate = (date: Time | Dayjs, formatString: string) =>
  date.format(formatString);

export const formatTimestamp = (date: Time) => date.format('ddd M/D/YY @ h:mm A');

export const maybeDay = (date: Time) =>
  date.isSame(toNowTime(), 'day') ? '' : date.format('ddd ');

export const enforceTwoDigits = (num: number) =>
  num.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });

// It turns out that moment does not have a solution for formatting durations that works everywhere.
export const formatDuration = (totalSeconds: number) => {
  if (totalSeconds < 0) {
    totalSeconds = 0;
  } else {
    totalSeconds = Math.round(totalSeconds);
  }
  const hours = Math.floor(totalSeconds / 3600);
  const minutes =
    hours > 0
      ? enforceTwoDigits(Math.floor(totalSeconds / 60) % 60)
      : Math.floor(totalSeconds / 60) % 60;
  const seconds = enforceTwoDigits(totalSeconds % 60); // This will enforce 2 digits of seconds
  return `${hours > 0 ? `${hours}:` : ''}${minutes}:${seconds}`;
};

export const formatDurationHourAndMinutes = (totalSeconds: number) => {
  if (totalSeconds < 0) {
    totalSeconds = 0;
  } else {
    totalSeconds = Math.round(totalSeconds);
  }
  const hours = Math.floor(totalSeconds / 3600);
  const minutes =
    hours > 0
      ? enforceTwoDigits(Math.floor(totalSeconds / 60) % 60)
      : Math.floor(totalSeconds / 60) % 60;
  return `${hours}:${minutes}`;
};

export const formatDurationMinutesAndSeconds = (totalTime: number, unit: string) => {
  if (unit === 'min') {
    totalTime = totalTime * 60;
  }
  if (totalTime < 0) {
    totalTime = 0;
  } else {
    totalTime = Math.round(totalTime);
  }
  const totalHours = Math.floor(totalTime / 3600);
  const totalMinutes =
    totalHours > 0 || totalTime < 600
      ? enforceTwoDigits(Math.floor(totalTime / 60) % 60)
      : Math.floor(totalTime / 60) % 60;
  const totalSeconds = enforceTwoDigits(totalTime % 60);
  return `${totalHours > 0 ? `${totalHours}:` : ''}${totalMinutes}:${totalSeconds}`;
};

export const formatDurationFromMinutes = (minutes: number) =>
  formatDuration(minutes * 60);

export const formatDurationMinutes = (seconds: number) =>
  duration(seconds, 'seconds').asMinutes();

export const formatDurationMinutesRound = (seconds: number) =>
  Math.max(1, Math.round(seconds / 60));

export const formatDurationBetween = (
  startTime: Time,
  endTime: Time,
  unit: unitOfTime.Base,
) => precisionRound(duration(endTime.diff(startTime)).as(unit));

export const formatDurationByUnit = (dur: Duration, unit: unitOfTime.Base): number =>
  dur.get(unit);

export const formatDateTime = (
  date: Time,
  options: { trimLeadingZero: boolean } = { trimLeadingZero: false },
) => {
  const format = options.trimLeadingZero ? 'h:mm a' : 'hh:mm a';
  return date.format(format);
};

export const formatTimestampDashes = (date: Time) => date.format('YYYY-MM-DD h:mm A');

export const formatDateDashes = (date: Time) => date.format('YYYY-MM-DD');

/** Yahoo wants duration formatted as HH:MM, i.e. 20 minutes = 00:20 */
export const toHHMMDuration = (durationInSeconds: number) => {
  const d = duration(durationInSeconds, 'seconds');
  return utc(d.as('milliseconds')).format('HH:mm');
};

export const toMMSSDuration = (durationInSeconds: number) => {
  const d = duration(durationInSeconds, 'seconds');
  return utc(d.as('milliseconds')).format('m:ss');
};

export const toIsoString = (date: Time) => date.toISOString();

export const toTimerTime = (durOrSec: Duration | number, displayHoursThreshold = 100) => {
  const dur = toDuration(durOrSec);
  const seconds = formatDurationByUnit(dur, 'seconds');
  const totalMinutesDuration = Math.round(
    dur.clone().subtract(seconds, 'seconds').asMinutes(),
  );
  if (totalMinutesDuration >= displayHoursThreshold) {
    const minutes = Math.floor(formatDurationByUnit(dur, 'minutes'));
    const hours = Math.floor(formatDurationByUnit(dur, 'hours'));
    return `${hours}:${toLeadingZero(minutes)}:${toLeadingZero(seconds)}`;
  }
  return `${toLeadingZero(totalMinutesDuration)}:${toLeadingZero(seconds)}`;
};

export const toAbsoluteTimerTime = (
  durOrSec: Duration | number,
  displayHoursThreshold = 100,
  leadingZeroMinutes = true,
) => {
  const dur = toDuration(durOrSec);
  const seconds = formatDurationByUnit(dur, 'seconds');
  const totalMinutesDuration = Math.round(
    dur.clone().subtract(seconds, 'seconds').asMinutes(),
  );
  if (totalMinutesDuration >= displayHoursThreshold) {
    const minutes = Math.floor(formatDurationByUnit(dur, 'minutes'));
    const hours = Math.floor(formatDurationByUnit(dur, 'hours'));
    return `${Math.abs(hours)}:${toLeadingZero(Math.abs(minutes))}:${toLeadingZero(
      Math.abs(seconds),
    )}`;
  }
  return `${
    leadingZeroMinutes
      ? toLeadingZero(Math.abs(totalMinutesDuration))
      : Math.abs(totalMinutesDuration)
  }:${toLeadingZero(Math.abs(seconds))}`;
};

export const toDuration = (durOrSec: Duration | number): Duration => {
  const result = typeof durOrSec === 'number' ? duration(durOrSec, 'seconds') : durOrSec;
  return result as Duration;
};

export const toMonth = (date: Time) => date.clone().format('MM');
export const toYear = (date: Time) => date.clone().format('YYYY');
export const toDay = (date: Time) => date.clone().format('DD');

const toLeadingZero = (s: number) => s.toString().padStart(2, '0');

export const formatTimeForLocale = (
  date: Time,
  locale?: Locale,
  short: Boolean = false,
) => {
  if (theUserIsInGermany(locale!)) {
    return date.format(short ? Format.Time24HourShort : Format.Time24Hour);
  }
  return date.format(short ? Format.Time12HourShort : Format.Time12Hour);
};
