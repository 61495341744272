import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import type { State as EnvState } from '@peloton/env';
import { getLocale } from '@peloton/env';
import type { ExtLinkEnvState } from '@peloton/external-links';
import type { Locale } from '@peloton/internationalize';
import { toISOLang } from '@peloton/internationalize';
import type { AccountMetadata } from '@account/copy';
import { useAccountMetadata } from '@account/copy/helpers/useAccountMetadata';

type OwnProps = {
  id: AccountMetadata;
};

type StateProps = {
  locale: Locale;
};

type MetadataProps = OwnProps & StateProps;

const MetadataTags: React.FC<React.PropsWithChildren<MetadataProps>> = ({
  id,
  locale,
}) => {
  const { title, description, image, ogtype } = useAccountMetadata(id);

  return (
    <Helmet htmlAttributes={{ lang: toISOLang(locale) }}>
      <meta property="og:type" content={ogtype} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={window.location.href} />
      <meta property="og:image" content={image.url} />
      <meta property="og:image:height" content={'630'} />
      <meta property="og:image:width" content={'1200'} />
    </Helmet>
  );
};

const mapStateToProps = (state: ExtLinkEnvState & EnvState): StateProps => ({
  locale: getLocale(state),
});

export default connect<StateProps, OwnProps>(mapStateToProps)(MetadataTags);
