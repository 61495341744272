import { authSaga } from '@peloton/auth';
import { sagas as driftSagas } from '@peloton/drift';
import { errorReportingSaga } from '@peloton/error-reporting';
import { fetcherSaga } from '@peloton/redux-fetch';
import accountCheckoutSagas from '@account/checkout/sagas';
import { paymentInfoSagas } from '@account/payment';
import { cancellationAnalyticsSagas } from '@account/pg-cancellation/analytics';
import myMembershipSaga from '@account/pg-my-membership/sagas';
import {
  refundPaymentMethodSagas,
  orderPaymentMethodSagas,
} from '@account/pg-post-purchase-flow/PaymentMethod/sagas';
import subscriptionMobilitySagas from '@account/subscription-mobility/sagas';
import { analyticsSaga } from '@ecomm/analytics';
import oneWellnessSagas from '@onewellness/sagas/account';
import { appSaga } from './appSaga';

export const toStoreSagas = () => [
  analyticsSaga,
  authSaga,
  driftSagas,
  errorReportingSaga,
  paymentInfoSagas,
  refundPaymentMethodSagas,
  orderPaymentMethodSagas,
  appSaga,
  myMembershipSaga,
  cancellationAnalyticsSagas,
  fetcherSaga,
  oneWellnessSagas,
  accountCheckoutSagas,
  subscriptionMobilitySagas,
];
