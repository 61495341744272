import React from 'react';
import { asyncComponent } from '@peloton/code-splitting';
import { Route } from '@account/router';
import { APPLY_BENEFIT_PATH } from '@onewellness/routes';

const route = [
  <Route
    key={APPLY_BENEFIT_PATH}
    path={APPLY_BENEFIT_PATH}
    component={asyncComponent(
      () => import('./Page' /* webpackChunkName: "BenefitsApplyBenefit" */),
    )}
  />,
];

export default route;
