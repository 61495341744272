import { combineReducers } from 'redux';
import type { ReducerState as AddressState } from './address';
import address from './address';
import type { ReducerState as AttributionState } from './attribution';
import attribution from './attribution';
import type { ReducerState as BillingState } from './billing';
import billing from './billing';
import type { ReducerState as DigitalState } from './digital';
import digital from './digital';
import type { ReducerState as FreeTrialEligibilityState } from './freeTrialEligibility';
import freeTrialEligibility from './freeTrialEligibility';
import type { ReducerState as PaymentState } from './payment';
import payment from './payment';
import type { ReducerState as PromoState } from './promo';
import promo from './promo';
import referrer from './referrer';
import type { ReducerState as ReferrerState } from './referrer';
import type { ReducerState as ReviewOrderState } from './reviewOrder';
import { default as reviewOrder } from './reviewOrder';
import type { ReducerState as ShippingState } from './shipping';
import shipping from './shipping';
import type { ReducerState as SubmitOrderState } from './submitOrder';
import { default as submitOrder } from './submitOrder';
import type { ReducerState as UIState } from './ui';
import ui from './ui';
import type { ReducerState as UserState } from './user';
import user from './user';
import type { ReducerState as VerificationState } from './verification';
import verification from './verification';

export type State = AddressState &
  AttributionState &
  BillingState &
  DigitalState &
  FreeTrialEligibilityState &
  PaymentState &
  PromoState &
  ReferrerState &
  ReviewOrderState &
  ShippingState &
  SubmitOrderState &
  UIState &
  UserState &
  VerificationState;

export default combineReducers({
  address,
  attribution,
  billing,
  digital,
  freeTrialEligibility,
  payment,
  promo,
  reviewOrder,
  shipping,
  submitOrder,
  ui,
  user,
  verification,
  referrer,
});

export type ReducerState = {
  checkout: State;
};
