import React from 'react';
import { useTracking } from 'react-tracking';
import { track as peloTrack } from '@peloton/analytics';
import { accountEnvironmentFlags } from '@account/env';
import type { AnalyticsEvent } from './events';
import { HappyPathEvents } from './events';
import { monitorTrackingEvent, monitorExperimentDistribution } from './monitor';

export type TrackingProperties = {
  event: AnalyticsEvent;
  instanceProperties?: object; // used for specifying instance properties when using react-tracking
};

export const useTrackCallback = () => {
  const { getTrackingData } = useTracking();

  const track = React.useCallback(
    ({ event, instanceProperties }: TrackingProperties) => {
      const contextProperties = getTrackingData();
      const segmentProperties = { ...contextProperties, ...instanceProperties };

      const trackingEvent = {
        event,
        properties: segmentProperties,
      };
      try {
        peloTrack(trackingEvent);
        if (!accountEnvironmentFlags().isProd) {
          // @ts-expect-error
          if (window.trackingData) {
            // @ts-expect-error
            window.trackingData.push(event);
          } else {
            // @ts-expect-error
            window.trackingData = [event];
          }
        }
      } catch (e) {
        console.error(`Error sending Segment event: ${event}`);
      }
      monitorTrackingEvent(event);
      if (event === HappyPathEvents.ExperimentReceived && segmentProperties) {
        monitorExperimentDistribution({
          experimentName: segmentProperties['experimentName'],
          variationName: segmentProperties['variationName'],
        });
      }
    },
    [getTrackingData],
  );

  return { track };
};
