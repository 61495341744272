import type { Reducer } from 'redux';
import type { TypedAction } from '@peloton/redux';
import type { Transaction } from '../models/Transaction';

const transactionsReducer: Reducer<State> = (state = defaultState, action: Action) => {
  switch (action.type) {
    case ActionType.Request:
      return {
        ...defaultState,
        isLoading: true,
      };

    case ActionType.RequestSuccess:
      return {
        entities: action.payload,
        isLoading: false,
        error: false,
      };

    case ActionType.RequestFailure:
      return {
        ...state,
        isLoading: false,
        error: true,
      };

    default:
      return state;
  }
};

const defaultState: State = {
  entities: undefined,
  isLoading: false,
  error: false,
};

type State = {
  entities?: Transaction[];
  isLoading: boolean;
  error: boolean;
};

// Actions

enum ActionType {
  Request = 'pelo/preferences/subscriptions/details/transactions/REQUEST',
  RequestSuccess = 'pelo/preferences/subscriptions/transactions/REQUEST_SUCCESS',
  RequestFailure = 'pelo/preferences/subscriptions/transactions/REQUEST_FAILURE',
}

export type LoadTransactionAction = TypedAction<
  ReturnType<typeof loadTransactions>,
  ActionType.Request
>;

type Action =
  | LoadTransactionAction
  | TypedAction<ReturnType<typeof loadTransactionsSuccess>, ActionType.RequestSuccess>
  | TypedAction<ReturnType<typeof loadTransactionsFailure>, ActionType.RequestFailure>;

const loadTransactions = (subId: string) => ({
  type: ActionType.Request,
  payload: subId,
});

const loadTransactionsSuccess = (transactions: Transaction[]) => ({
  type: ActionType.RequestSuccess,
  payload: transactions,
});

const loadTransactionsFailure = () => ({
  type: ActionType.RequestFailure,
});

// Selectors

const NS = 'subscriptionTransactions';

export type TransactionsState = { [NS]: State };

const getTransactions = (state: TransactionsState): Transaction[] | undefined =>
  state[NS].entities;

const getTransaction = (state: TransactionsState, id: string): Transaction | undefined =>
  state[NS].entities && findTransaction(id, state[NS].entities!);

const findTransaction = (id: string, transactions: Transaction[]) =>
  transactions.find(transaction => transaction.id === id);

export {
  NS as SUB_TRANSACTION_NAMESPACE,
  getTransactions,
  getTransaction,
  findTransaction,
  transactionsReducer,
  ActionType as TransactionsActionType,
  loadTransactions,
  loadTransactionsSuccess,
  loadTransactionsFailure,
};
