import webfonts from './webfonts';

/**
 * @deprecated prefer \@peloton/typography/families
 * @see https://pelotoncycle.atlassian.net/browse/TAKO-1675
 * */
export default () => {
  const WebFont = require('webfontloader');
  WebFont.load(webfonts);
};
