import React from 'react';
import { asyncComponent } from '@peloton/code-splitting';
import { Route } from '@account/router';
import { toCancellationFlowPath } from './urls';

const Routes = [
  <Route
    key={toCancellationFlowPath()}
    path={toCancellationFlowPath()}
    component={asyncComponent(
      () => import('./Page' /* webpackChunkName: "CancellationPage" */),
    )}
    shouldTrack={true}
  />,
];

export default Routes;
