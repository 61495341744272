import { useSelector } from 'react-redux';
import { getIsProdEnv } from '@peloton/env';
import { toAccountLink, getExtLinkEnv, toHref } from '@peloton/external-links';
import { useReduxState } from '@engage/redux';
import { HYATT_OFFER_CONNECT_CONFIRM } from '../routes';

const HYATT_CONNECT_PROD = {
  URL: 'https://www.hyatt.com/loyalty/member/en-US/authorize',
  CLIENT_ID: 'tLVV4ALJGYpgYUib73Wy29WI664I0Tnz',
};

const HYATT_CONNECT_STAGING = {
  URL: 'https://www.hyattstg.com/loyalty/member/en-US/authorize',
  CLIENT_ID: 'pVl9Fir82kGnFTysQlsIsMmYmctdW52A',
};

export const useHyattConnectUrl = () => {
  const isProd = useReduxState(getIsProdEnv);
  const config = isProd ? HYATT_CONNECT_PROD : HYATT_CONNECT_STAGING;

  /*
    The following are the only callback hosts whitelisted by Hyatt:
      - account.onepeloton.com
      - account.test.onepeloton.com
  */
  const extLinkEnv = useSelector(getExtLinkEnv);
  const callbackUrl = toHref(toAccountLink(HYATT_OFFER_CONNECT_CONFIRM), extLinkEnv);

  const authUrl = new URL(config.URL);
  authUrl.search = new URLSearchParams({
    response_type: 'code',
    client_id: config.CLIENT_ID,
    redirect_uri: callbackUrl,
  }).toString();

  return authUrl.toString();
};
