import React from 'react';
import { defaultProps } from 'recompose';
import type { ErrorBoundary } from '@peloton/error-reporting';
import { AsyncFallback } from '@peloton/graphql';
import { ErrorComponent } from '@ecomm/layout';
import { PageSpinner, Spinner } from '@ecomm/spinner';

// TODO: fix this prettier auto-formatting
export type Props = {
  LoadingFallback?: JSX.Element;
  ErrorFallback?: JSX.Element;
} & Partial<React.ComponentProps<typeof React.Suspense>> &
  Partial<React.ComponentProps<typeof ErrorBoundary>>;

const AsyncFallbackWithDefaults: React.FC<React.PropsWithChildren<Props>> = ({
  LoadingFallback = <PageSpinner />,
  ErrorFallback = <ErrorComponent />,
  fallback = LoadingFallback,
  renderError = () => ErrorFallback,
  ...props
}) => (
  <AsyncFallback
    LoadingFallback={LoadingFallback}
    ErrorFallback={ErrorFallback}
    fallback={fallback}
    renderError={renderError}
    {...props}
  />
);

export default AsyncFallbackWithDefaults;

export const ComponentAsyncFallback = defaultProps<Partial<Props>>({
  LoadingFallback: <Spinner area={30} />,
})(AsyncFallbackWithDefaults);
