import React from 'react';

const LogoMark: React.FC<
  React.PropsWithChildren<React.SVGProps<SVGSVGElement>>
> = props => (
  <svg role="img" viewBox="0 0 16 22" {...props}>
    <path d="M12.98 5.287l1.589-2.758a1.687 1.687 0 0 0-.616-2.303 1.682 1.682 0 0 0-2.3.617L10.066 3.6a7.739 7.739 0 0 0-9.027 3.522 7.75 7.75 0 0 0-.602 6.44l2.934-5.09V8.47a5.019 5.019 0 0 1 3.066-2.357 5.01 5.01 0 0 1 3.832.506 5.068 5.068 0 0 1 1.848 6.908A5.05 5.05 0 0 1 5.22 15.38l1.683-2.92c.805.465 1.834.189 2.299-.617a1.688 1.688 0 0 0-.616-2.303 1.682 1.682 0 0 0-2.3.617L.917 19.47a1.687 1.687 0 0 0 .616 2.303c.805.466 1.835.189 2.3-.617L5.421 18.4a7.74 7.74 0 0 0 9.026-3.523 7.766 7.766 0 0 0-1.468-9.59" />
  </svg>
);

export default LogoMark;
