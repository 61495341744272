import type { SagaIterator } from 'redux-saga';
import { fork, all, call, put, select, getContext, take } from 'redux-saga/effects';
import { CLIENT_CONTEXT } from '@peloton/api';
import { reportError } from '@peloton/error-reporting';
import type { Country } from '@peloton/internationalize';
import {
  UpdatePaymentActionTypes,
  updatePaymentWithCaptcha,
  updatePayment,
} from '@account/payment';
import updatePaymentSaga from '@account/payment/creditCard/sagas/updatePaymentSaga';
import { AddressType } from '@ecomm/checkout/models/Address';
import { updateBillingAddress } from '@ecomm/checkout/redux/address';
import {
  hasAcceptedTermsError,
  hasCheckoutWithNoPaymentErrors,
} from '@ecomm/checkout/redux/selectors';
import type { PaymentGateway } from '@ecomm/payment';
import { getQuickCheckoutData } from '@ecomm/quick-checkout/redux';
import {
  checkoutLoading,
  failCheckout,
  checkoutSuccess,
} from '@ecomm/quick-checkout/redux/ui';
import { responseToErrorCode, subscriptionTierCheckout } from '../api';
import type { SubscriptionTierCheckoutRequestAction } from '../redux/ui';

export const EVENT_SOURCE = 'account';

export const subscriptionTierCheckoutSaga = function* ({
  payload: {
    product: productOptionId,
    paymentGateway,
    recaptchaToken,
    tierData,
    successCallbackFn,
    existingPayment,
  },
}: SubscriptionTierCheckoutRequestAction): SagaIterator {
  try {
    if (existingPayment) {
      yield put(updateBillingAddress('postalCode', existingPayment.postalCode));
    }
    const client = yield getContext(CLIENT_CONTEXT);
    const data = yield select(getQuickCheckoutData, { addressType: AddressType.Billing });
    const { shippingPostalCode, shippingCountry, hasAcceptedPolicy } = data;

    if (!existingPayment) {
      const updatePaymentArgs: [PaymentGateway, string, Country, string] = [
        paymentGateway,
        shippingPostalCode,
        shippingCountry,
        EVENT_SOURCE,
      ];
      yield fork(
        updatePaymentSaga,
        recaptchaToken
          ? updatePaymentWithCaptcha(...updatePaymentArgs, recaptchaToken)
          : updatePayment(...updatePaymentArgs),
      );
      const action = yield take([
        UpdatePaymentActionTypes.UpdatePaymentFailure,
        UpdatePaymentActionTypes.UpdatePaymentSuccess,
      ]);
      if (action.type === UpdatePaymentActionTypes.UpdatePaymentFailure) {
        throw new Error(responseToErrorCode(action.payload));
      }
    }

    const { expiresOn, formattedTotalCharged } = yield call(
      subscriptionTierCheckout,
      client,
      {
        productOptionId,
        shippingPostalCode,
        shippingCountry,
        hasAcceptedPolicy,
        attribution: {
          type: 'peloton',
        },
        ...tierData,
      },
    );

    yield put(checkoutSuccess());
    if (successCallbackFn) {
      yield call(successCallbackFn, undefined, undefined, {
        expiresOn,
        formattedTotalCharged,
      });
    }
  } catch (error) {
    const errorId = `errors.${error.message}`;
    yield all([put(failCheckout(errorId)), put(reportError({ error }))]);
  }
};

export const validateSubscriptionTierCheckoutSaga = function* (
  action: SubscriptionTierCheckoutRequestAction,
): SagaIterator {
  yield put(checkoutLoading());

  const hasErrors = yield select(hasCheckoutWithNoPaymentErrors);
  const hasNotAcceptedTerms = yield select(hasAcceptedTermsError);

  if (hasErrors || hasNotAcceptedTerms) {
    yield put(failCheckout('errors.incomplete'));
  } else {
    yield call(subscriptionTierCheckoutSaga, action);
  }
};
