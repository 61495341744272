import { useEffect, useState } from 'react';

/**
 * Accepts an async function that contains asynchronous, possibly effectful code.
 *
 * Behaves like `React.useEffect()`, except that `useAsyncEffect()` allows errors
 * that happen asynchronously to be caught in an `ErrorBoundary`.
 *
 * @see https://github.com/facebook/react/issues/14981#issuecomment-468460187
 *
 * @param fn Async function whose resolved value will be ignored.
 * @param deps If present, effect will only activate if the values in the list change.
 */
const useAsyncEffect = <T extends Promise<void>>(fn: () => T, deps?: any[]) => {
  const [, setError] = useState();

  useEffect(() => {
    (async () => {
      try {
        await fn();
      } catch (e) {
        setError(() => {
          throw e;
        });
      }
    })();
  }, deps);
};

export default useAsyncEffect;
