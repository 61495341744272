import React from 'react';
import { Route } from 'react-router-dom';
import { asyncComponent } from '@peloton/code-splitting';

const Routes = [
  <Route
    key={'/account/payment'}
    path={'/account/payment'}
    component={asyncComponent(
      () => import('./Handler' /* webpackChunkName: "UpdatePaymentPage" */),
    )}
  />,
];

export default Routes;
