import type { PasswordChange } from '../models/PasswordChange';

const CHANGE_PASSWORD_FORM_NAME = 'change-password';

enum PasswordChangeActionType {
  PasswordChange = 'pelo/preferences/subscriptions/PASSWORD_CHANGE',
}

export type PasswordChangeAction = PasswordChangeCallbacks & {
  type: PasswordChangeActionType.PasswordChange;
  payload: Partial<PasswordChange>;
};

type PasswordChangeCallbacks = {
  onSuccess: EmptyHandler;
  onInvalidPasswordError: EmptyHandler;
  onFormError: ValueHandler;
};

const submitPasswordChange = (
  values: Partial<PasswordChange>,
  { onSuccess, onInvalidPasswordError, onFormError }: PasswordChangeCallbacks,
): PasswordChangeAction => ({
  type: PasswordChangeActionType.PasswordChange,
  payload: values,
  onSuccess,
  onInvalidPasswordError,
  onFormError,
});

type EmptyHandler = () => void;
type ValueHandler = (value: string) => void;

export { CHANGE_PASSWORD_FORM_NAME, PasswordChangeActionType, submitPasswordChange };
