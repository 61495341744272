import type { NextStep } from '../../navigation/useNextStepUrl';
import type { MutationCustomerSetRefundPaymentMethodMutationFn } from '../SetPaymentMethod.generated';

type AddOrderPaymentMethodPayload = {
  setPaymentMethod: MutationCustomerSetRefundPaymentMethodMutationFn;
  orderId: string;
  nextStep: NextStep | undefined;
  paymentMethodId: string;
};

export enum ActionTypes {
  AddOrderPaymentMethod = 'account/refund_payment/ADD_ORDER_PAYMENT_METHOD',
  AddOrderPaymentError = 'account/refund_payment/ADD_ORDER_PAYMENT_ERROR',
  AddOrderPaymentLoading = 'account/refund_payment/ADD_ORDER_PAYMENT_LOADING',
}

export type AddOrderPaymentMethodAction = {
  type: ActionTypes.AddOrderPaymentMethod;
  payload: AddOrderPaymentMethodPayload;
};

export type AddOrderPaymentErrorAction = {
  type: ActionTypes.AddOrderPaymentError;
  payload: any;
};

export type AddOrderPaymentLoadingAction = {
  type: ActionTypes.AddOrderPaymentLoading;
  payload: boolean;
};

export const addOrderPaymentMethod = (
  orderId: string,
  paymentMethodId: string,
  setPaymentMethod: MutationCustomerSetRefundPaymentMethodMutationFn,
  nextStep: NextStep | undefined,
): AddOrderPaymentMethodAction => ({
  type: ActionTypes.AddOrderPaymentMethod,
  payload: {
    setPaymentMethod,
    orderId,
    nextStep,
    paymentMethodId,
  },
});

export const addOrderPaymentError = (payload: any): AddOrderPaymentErrorAction => ({
  type: ActionTypes.AddOrderPaymentError,
  payload,
});

export const addOrderPaymentLoading = (
  payload: boolean,
): AddOrderPaymentLoadingAction => ({
  type: ActionTypes.AddOrderPaymentLoading,
  payload,
});

export type Action =
  | AddOrderPaymentMethodAction
  | AddOrderPaymentErrorAction
  | AddOrderPaymentLoadingAction;
