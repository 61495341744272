import React from 'react';
import { toExtLinkEnv, toHref } from '@peloton/external-links/models';
import { Links } from '@peloton/links';
import { useAddCurrentLocaleToUrl } from '@account/utils/hooks/useAddCurrentLocaleToUrl';
import type { Props } from './HeaderProps';
import { ResponsiveHeader } from './HeaderView/HeaderView';

export const Header: React.FC<React.PropsWithChildren<Props>> = ({ ...props }) => {
  const link = useAddCurrentLocaleToUrl(toHref(Links.peloton, toExtLinkEnv()));

  return <ResponsiveHeader homeLink={link} {...props} />;
};

export default Header;
