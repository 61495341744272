import { BillingFrequency, Platform } from '@membership/models/Subscription';
import { MONTHS_IN_YEAR } from '@membership/models/Time';
import type { CheckoutOrderId } from '@account/checkout/models';
import type {
  PaymentMethod as PaymentMethodType,
  ProratedMembershipAmount,
  TierSubscriptionPlan,
} from '@account/graphql/types.generated';
// eslint-disable-next-line custom/no-restricted-imports
import type { DigitalCheckout } from '@ecomm/quick-checkout/models';
import type { DigitalSubscription, TierType } from '@engage/subscriptions/Subscription';
import type { SwitchPlanQuery } from './grapqhl/SwitchPlanQuery.generated';

export { BillingFrequency, MONTHS_IN_YEAR };

export enum TierDirection {
  Upgrade = 'upgrade',
  Downgrade = 'downgrade',
  New = 'new',
}

export enum TierDirectionIntent {
  Upgrade = 'upgrade-plan',
}

export enum CheckoutMethod {
  OneStepCheckout = 'one_step_checkout',
  TierCheckout = 'tier_checkout',
}

export { Platform };

export type PaymentMethod = Pick<
  PaymentMethodType,
  | 'label'
  | 'postalCode'
  | 'paymentType'
  | 'maskedNumber'
  | 'expirationDate'
  | 'isExpired'
  | 'paymentMethodId'
>;

export type SwitchPlanSubscriptionTier = SwitchPlanQuery['subscriptionTiers']['nodes'][0];

export type TierLevel = number;

export interface TierPlan {
  tierId: string;
  tierLabel: string;
  tierLevel: TierLevel;
}

export interface CurrentPlan extends TierPlan {
  billingFrequency: BillingFrequency;
  platform: Platform;
  subscriptionId: string;
  subscriptionCurrency: DigitalSubscription['currency'];
  subscriptionType: DigitalSubscription['kind'];
  isActivePelotonDigitalSubTrial: boolean;
  subscriptionRenewalDate: string;
  tierType: TierType;
  requiresPayment: boolean;
}

export type CustomerFlags = {
  isTrialEligible: boolean;
  hasThirdPartyCancellationAgreement: boolean;
};

export type SkuOption = TierSubscriptionPlan & {
  sku: string;
  proratedAmount?: ProratedMembershipAmount;
};

export type NextPlanSkuOptions = {
  [BillingFrequency.Monthly]?: SkuOption;
  [BillingFrequency.Annual]?: SkuOption;
};

export interface NextPlan extends TierPlan {
  skuOptions: NextPlanSkuOptions;
  orderId?: CheckoutOrderId;
  expiresOn?: number;
  formattedTotalCharge?: string;
  checkoutMethod?: CheckoutMethod;
}

export enum SwitchPlanStep {
  Loading,
  SelectPlan,
  Checkout,
  CancelConfirmation,
  CheckoutConfirmation,
  CreateUserConfirmation,
}

export const SubscriptionBillingChange = 'billing_change';

export type SubscriptionTierChangeType =
  | Exclude<TierDirection, TierDirection.New>
  | typeof SubscriptionBillingChange;

export type SubscriptionChangeData = {
  fromSubscriptionId: string;
  pretaxAmountInCents: number;
  changeType: SubscriptionTierChangeType;
};

export type SubscriptionChangeCheckoutData = Pick<
  DigitalCheckout,
  'productOptionId' | 'shippingPostalCode' | 'shippingCountry' | 'hasAcceptedPolicy'
> &
  SubscriptionChangeData & {
    attribution: Record<'type', string>;
  };
