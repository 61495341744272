// TODO: MIGRATE THESE CHANGES INTO hooks.ts AFTER APOLLO V3 STAGING TEST PERIOD IS OVER
// The bulk of these changes are removing the old imports from the V2 Apollo packages
// and replacing them with the new imports from the V3 Apollo packages. The only other
// main change is that suspend is no longer an option in V3 and has been set to optional in the
import type {
  MutationHookOptions as BaseMutationHookOptions,
  QueryHookOptions as BaseQueryHookOptions,
  QueryResult as QueryHookResult,
  MutationTuple as MutationFn,
  MutationResult as TMutationResult,
  OperationVariables,
} from '@apollo/client';
import { useMutation, useQuery as useBasicQuery, useSuspenseQuery } from '@apollo/client';
import type { DocumentNode } from 'graphql';

export type QueryResult<TData, TVariables> = QueryHookResult<TData> & {
  loading?: boolean;
};
export type MutationFunction<TData, TVariables> = MutationFn<TData, TVariables>;
export type BaseMutationOptions<TData, TVariables> = BaseMutationHookOptions<
  TData,
  TVariables
>;
export type MutationResult<TData> = TMutationResult<TData>;

export type QueryHookOptions<
  TData,
  TVariables,
  TCache = object
> = BaseQueryHookOptions<TVariables> & {
  throwError?: boolean;
  useApq?: boolean;
  suspend?: boolean;
  skip?: boolean;
  fetchPolicy?: string;
};

export type MutationHookOptions<
  TData,
  TVariables,
  TCache = object
> = BaseMutationHookOptions<TData, TVariables, TCache>;

export const useQuery = <TData = any, TVariables = OperationVariables, TCache = object>(
  query: DocumentNode,
  { throwError = true, useApq, ...options }: QueryHookOptions<TData, TVariables> = {},
) => {
  let baseUseQuery = useSuspenseQuery;
  if (options.suspend === false) {
    // @ts-expect-error
    baseUseQuery = useBasicQuery;
  }
  const response = baseUseQuery<TData, OperationVariables>(query, {
    skip: options.skip,
    errorPolicy: throwError === false ? 'ignore' : 'all',
    context: { useApq, ...options.context },
    // @ts-expect-error
    fetchPolicy: options.fetchPolicy,
    // TODO: how to report swallowed errors
    variables: (options.variables as unknown) as OperationVariables,
    ...options,
  });
  // since useSuspenseQuery does not have loading we need to add it for when
  // 'suspend' is set to false
  const responseWithLoading = {
    ...response,
    // @ts-expect-error
    loading: response.loading === undefined ? undefined : response.loading,
  };

  if (throwError && response.error) {
    throw response.error;
  }

  return responseWithLoading;
};

export { useMutation };

// These hooks are available in @apollo/react-hooks (and the upcoming @apollo/client 3.0)

export const useLazyQuery = <TData, TVariables>(_: DocumentNode, __?: any) => {
  console.error(
    '@ecomm/copy needs to upgrade to @apollo/react-hooks in order to use this feature',
  );
};

export type LazyQueryHookOptions<TData, TVariables> = BaseQueryHookOptions<TVariables> & {
  throwError?: boolean;
};
