import type { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';
import type { Client } from '@peloton/api';
import { getSignedInUserId } from '@peloton/auth';
import { fetchSubscriptionTransactions } from '@account/api/transactions';
import type { LoadTransactionAction } from '../redux';
import { loadTransactionsSuccess, loadTransactionsFailure } from '../redux';

const loadTransactions = function* (
  client: Client,
  { payload: subId }: LoadTransactionAction,
): SagaIterator {
  try {
    const userId = yield select(getSignedInUserId);
    const transactions = yield call(fetchSubscriptionTransactions, client, userId, subId);
    yield put(loadTransactionsSuccess(transactions));
  } catch (e) {
    yield put(loadTransactionsFailure());
  }
};

export default loadTransactions;
