import type { SagaIterator } from 'redux-saga';
import { put, call } from 'redux-saga/effects';
import { reportError } from '@peloton/error-reporting';
import { history } from '@peloton/redux';
import { createSetupIntentSaga } from '@ecomm/payment/sagas';
import type { AddRefundPaymentMethodAction } from '../redux/addRefundPaymentMethod';
import {
  addRefundPaymentError,
  addRefundPaymentLoading,
} from '../redux/addRefundPaymentMethod';

export default function* (action: AddRefundPaymentMethodAction): SagaIterator {
  yield put(addRefundPaymentLoading(true));
  try {
    const setupIntentOptions = {
      paymentGateway: action.payload.paymentGateway,
      postalCode: action.payload.postalCode,
      recaptchaToken: action.payload.recaptchaToken,
    };
    const result = yield call(createSetupIntentSaga, setupIntentOptions);
    const {
      payload: { setPaymentMethod, orderId, nextStep },
    } = action;
    const mutationOptions = {
      variables: {
        billingPartnerToken: result.payment_method,
        orderId: orderId,
      },
    };
    // This ts-ignore is due to Apollo 3 upgrades
    // eslint-disable-next-line @typescript-eslint/prefer-ts-expect-error
    // @ts-ignore
    yield call(setPaymentMethod, mutationOptions);
    yield put(addRefundPaymentLoading(false));
    yield history.push(nextStep!.url) as any; // SagaIterators can't yield `void`, but this seems to be working 🤷‍♂️
  } catch (error) {
    yield put(addRefundPaymentError(error));
    yield put(addRefundPaymentLoading(false));
    yield put(reportError({ error }));
  }
}
