import { useContext } from 'react';
import { useSelector } from 'react-redux';
import type {
  QueryHookOptionsV2 as TQueryHookOptions,
  DocumentNodeV2 as DocumentNode,
} from '@peloton/graphql';
import { useQuery as useBaseQuery } from '@peloton/graphql/apolloToggle';
import type { OperationVariablesV2 } from '@peloton/graphql/apolloV2';
import { getIsToggleActive } from '@account/feature-toggles';
import processContentfulData from '@ecomm/copy-xray/processContentfulData';
import { Context } from './context';

export type { QueryHookResultV2 as QueryResult } from '@peloton/graphql';

export type QueryHookOptions<TData, TVariables, TCache = object> = TQueryHookOptions<
  TVariables,
  TCache
>;

export const useQuery = <TData, TVariables>(query: DocumentNode, options?: any) => {
  const client = useContext(Context);
  const { data, ...rest } = useBaseQuery<TData, TVariables & OperationVariablesV2>(
    query,
    {
      ...options,
      client,
      overrideClient: client,
      fetchPolicy: 'cache-only',
    },
  );

  const copyXrayEnabled = useSelector(getIsToggleActive('copyXray'));
  return {
    data: copyXrayEnabled ? processContentfulData(data as object) : data,
    ...rest,
  };
};

// These hooks are available in @apollo/react-hooks (and the upcoming @apollo/client 3.0)

export const useLazyQuery = <TData, TVariables>(_: DocumentNode, __?: any) => {
  console.error(
    '@ecomm/copy needs to upgrade to @apollo/react-hooks in order to use this feature',
  );
};

export type LazyQueryHookOptions<TVariables, TCache = object> = TQueryHookOptions<
  TVariables,
  TCache
>;
