import { all, takeLeading, fork, takeLatest } from 'redux-saga/effects';
import { UserActions } from '@ecomm/checkout/redux';
import { filterSaga } from '@ecomm/checkout/sagas/user';
import { default as validateSaga } from '@ecomm/checkout/sagas/validate';
import { ActionTypes as EcommActionTypes } from '@ecomm/quick-checkout/redux';
import { default as quickCheckoutSaga } from '@ecomm/quick-checkout/sagas/checkoutSaga';
import promoCodeSaga from '@ecomm/quick-checkout/sagas/promoCodeSaga';
import registerUserSaga from '@ecomm/quick-checkout/sagas/registerUserSaga';
import checkoutWithExistingPaymentSaga from './checkoutWithExistingPaymentSaga';

const checkoutSagas = function* () {
  yield takeLeading(
    EcommActionTypes.ExistingPaymentCheckoutRequested,
    checkoutWithExistingPaymentSaga,
  );
  yield takeLatest(UserActions.UserFieldBlurred, filterSaga);
};

export default function* () {
  yield all([
    fork(quickCheckoutSaga),
    fork(checkoutSagas),
    fork(promoCodeSaga),
    fork(validateSaga),
    fork(registerUserSaga),
  ]);
}
