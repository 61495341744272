import React, { lazy } from 'react';
import type { RouteProps } from 'react-router-dom';
import { Route } from 'react-router-dom';
import { NotFoundErrorPage } from '@account/layout';
import { FEATURES } from '@account/optimizely/enums';
import { useFeatureWithOverrides } from '@account/optimizely/hooks/useFeatureWithOverrides';
import { toOrderConfirmationPath } from '@account/urls';

type ToggledRouteWithOverrideProps = RouteProps & {
  feature: FEATURES;
  fallbackComponent: React.ComponentType<React.PropsWithChildren<unknown>>;
};

export const ToggledRouteWithOverride: React.FC<
  React.PropsWithChildren<ToggledRouteWithOverrideProps>
> = ({ feature, fallbackComponent: FallbackComponent, ...rest }) => {
  const isFeatureEnabled = useFeatureWithOverrides(feature);
  return isFeatureEnabled ? <Route {...rest} /> : <FallbackComponent />;
};

const Page = lazy(
  () => import(/* webpackChunkName: "order-confirmation-page" */ './Page'),
);

export const orderConfirmationRoute = (
  <ToggledRouteWithOverride
    feature={FEATURES.IsOrderConfirmationPageActive}
    key={toOrderConfirmationPath()}
    path={toOrderConfirmationPath()}
    component={Page}
    fallbackComponent={NotFoundErrorPage}
    data-test-id="order-confirmation-route"
  />
);
