import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getIsUserLoading, isUserSignedIn, useOauth } from '@peloton/auth';
import { useErrorReporter } from '@peloton/error-reporting';
import { redirect } from '@peloton/navigation';
import { useClientErrorHandler } from '@account/api';
import { pending, ok } from '@engage/result';
import type { RequiresAuthOptions } from './models';
import { useLoginUrl } from './OauthProvider';
import useCheckSession from './useCheckSession';
import useLogout from './useLogout';

const useRequiresAuth = (options: RequiresAuthOptions = {}) => {
  const { getIsAuthenticated, isLoading: isAuth0Loading } = useOauth();
  const logout = useLogout();
  const isSignedIn = useSelector(isUserSignedIn);
  const isLoading = useSelector(getIsUserLoading);
  const loginUrl = useLoginUrl();
  const { errorReporter } = useErrorReporter();
  const redirectToLogin = useCallback(() => {
    const redirectUrlParam = options.redirectUrlParam ?? window.location.href;
    redirect(loginUrl(redirectUrlParam));
  }, [options]);
  const [isCheckingSession] = useCheckSession(redirectToLogin);

  useClientErrorHandler();

  useEffect(() => {
    (async () => {
      if (!isSignedIn && !isLoading && !isAuth0Loading) {
        const isAuthenticated = await getIsAuthenticated();
        if (isAuthenticated) {
          errorReporter.reportError(
            new Error(`Requires Auth | member was not authenticated | Logging user out`),
          );
          logout();
        } else {
          redirectToLogin();
        }
      }
    })();
  }, [isLoading, isAuth0Loading, isSignedIn]);

  if (isLoading || isAuth0Loading || isCheckingSession) {
    return pending;
  } else {
    return ok(isSignedIn);
  }
};

export default useRequiresAuth;
