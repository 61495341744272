import { AnalyticsBrowser } from '@segment/analytics-next';
import { USE_APOLLO_V3_ECOPY, MONITORING_ENV } from '@peloton/app-config';
import type { AnalyticsInterface } from './analytics';
import type { AnalyticsJS } from './segment-analytics';
import type { EventType, GroupTrackTraits, Identity, Load, Config } from './types';

// analytics is referenced by window.analytics at each instance below.
// Segment initially creates stub versions of analytics methods,
// queueing calls to these methods until the stubs are updated.

const hasAnalyticsFn = (name: string): boolean =>
  (<any>window).analytics &&
  (<any>window).analytics[name] &&
  typeof (<any>window).analytics[name] === 'function';

export const loadSegment = ({ key }: Load) => {
  // ensure this only loads Segment Analytics 2 on Stage in WWW
  if (MONITORING_ENV === 'uat' && USE_APOLLO_V3_ECOPY) {
    console.log('Segment Analytics 2 loading on Stage CRA-WWW');
    const analytics = new AnalyticsBrowser().load({ writeKey: key });
    // AnalyticsJS declares some funcs that do not seem to be in use in the codebase which AnayticsBrowser does not support.
    window.analytics = (analytics as unknown) as AnalyticsJS;
  } else if (hasAnalyticsFn('load')) {
    (<any>window).analytics.load(key);
    console.log('window', window.analytics);
  }
};

export type SegmentPage<T extends object> = { name?: string } & Config<T>;

export const pageSegment = <T extends object>({
  name,
  properties,
  options,
  callback,
}: SegmentPage<T>) => {
  if (hasAnalyticsFn('page')) {
    (<any>window).analytics.page(name, properties, options, callback);
  }
};

type Track<T extends object> = { event: string } & Config<T>;

export const trackSegment = <T extends object>({
  event,
  properties,
  options,
  callback,
}: Track<T>) => {
  if (hasAnalyticsFn('track')) {
    (<any>window).analytics.track(event, properties, options, callback);
  }
};

type Identify<T extends object> = { userId?: string } & Config<T>;

export const identifySegment = <T extends object>({
  userId,
  properties,
  options,
  callback,
}: Identify<T>) => {
  if (hasAnalyticsFn('identify')) {
    if (MONITORING_ENV === 'uat' && USE_APOLLO_V3_ECOPY && properties) {
      // @ts-expect-error
      properties.firstName = 'Segment/Analytics-Next';
    }
    (<any>window).analytics.identify(userId, properties, options, callback);
  }
};

type Group<T extends object> = { groupId: string } & Config<T>;

export const segmentGroup = <T extends object>({
  groupId,
  properties,
  options,
  callback,
}: Group<T>) => {
  if (hasAnalyticsFn('group')) {
    (<any>window).analytics.group(groupId, properties, options, callback);
  }
};

export const resetSegment = () => {
  if (hasAnalyticsFn('reset')) {
    (<any>window).analytics.reset();
  }
};

export const readySegment = (callback: () => void) => {
  if (hasAnalyticsFn('ready')) {
    (<any>window).analytics.ready(callback);
  }
};

export const anonymousIdSegment = (callback: (anonymousId: string) => void) => {
  readySegment(() => {
    callback((<any>window).analytics.user().anonymousId());
  });
};

export const Analytics = {
  Make: (): AnalyticsInterface => {
    class Internal {
      track<T extends object>(mixedEvent: EventType<T>) {
        if (typeof mixedEvent === 'string') {
          console.warn(`Unhandled track event ${mixedEvent}`);
        } else {
          const { event, ...properties } = mixedEvent;
          trackSegment({ event, ...properties });
        }
      }

      group<T extends object>({ event, ...properties }: GroupTrackTraits<T>) {
        segmentGroup({ event, ...properties });
      }

      identify<T extends object>(identity: Identity<T>) {
        identifySegment(identity);
      }

      reset(): void {
        resetSegment();
      }

      load(load: Load): void {
        loadSegment(load);
      }

      page<T extends object>(page: SegmentPage<T>): void {
        pageSegment(page);
      }

      ready(callback: () => void): void {
        readySegment(callback);
      }

      anonymousId(callback: (anonymousId: string) => void): void {
        anonymousIdSegment(callback);
      }
    }
    return new Internal();
  },
};

export const makeAnalytics = () => Analytics.Make();

export const isSegmentInitialized = () => Boolean((<any>window).analytics?.initialized);
