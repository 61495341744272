import type { Client } from '@peloton/api';
import type { ApiMeUser } from '@peloton/auth';
import { fetchUser } from '@peloton/auth';
import { toUtcTime } from '@peloton/time';
import type { BasicInfo } from '@account/pg-my-membership/models/BasicInfo';

export const toBasicInfo = (apiMe: ApiMeUser): BasicInfo => ({
  id: apiMe.id,
  birthday: apiMe.birthday ? toUtcTime(apiMe.birthday) : undefined,
  email: apiMe.email,
  firstName: apiMe.firstName,
  lastName: apiMe.lastName,
  gender: apiMe.gender,
  location: apiMe.location,
  phoneNumber: apiMe.phoneNumber,
  username: apiMe.username,
  height: apiMe.height,
  weight: apiMe.weight,
});

export const toApiMeUserChange = ({ id, ...info }: BasicInfo): Partial<ApiMeUser> => ({
  ...info,
  birthday: info.birthday && info.birthday.unix(),
});

export const fetchBasicInfo = (api: Client): Promise<BasicInfo> =>
  fetchUser(api).then(toBasicInfo);

export const updateBasicInfo = (api: Client, basicInfo: BasicInfo) =>
  api.put(toUpdateUrl(basicInfo.id), toApiMeUserChange(basicInfo));

const toUpdateUrl = (id: string) => `api/user/${id}`;
