import type { ToggleDefinitions } from '@account/feature-toggles';
import { ToggleStatus } from '@ecomm/feature-toggle';

const features: ToggleDefinitions = {
  checkoutWithPaymentIntents: {
    status: ToggleStatus.Enabled,
  },
  checkoutWithSetupIntents: {
    status: ToggleStatus.Enabled,
  },
  vatInvoice: {
    status: ToggleStatus.Dark,
  },
  updateOrderPayment: {
    status: ToggleStatus.Dark,
  },
  updateServiceOrderPayment: {
    status: ToggleStatus.Dark,
  },
  stripeSetupIntentsCaptcha: {
    status: ToggleStatus.Enabled,
  },
  stripeCreateIntentsCaptcha: {
    status: ToggleStatus.Enabled,
  },
  postPurchaseUpsell: {
    status: ToggleStatus.Enabled,
  },
  marketingOptIn: {
    status: ToggleStatus.Enabled,
  },
  marketingOptOut: {
    status: ToggleStatus.Disabled,
  },
  copyXray: {
    status: ToggleStatus.Disabled,
  },
  showFinancingPartner: {
    status: ToggleStatus.Enabled,
  },
  freeTrialForSixtyDays: {
    status: ToggleStatus.DateTrigger,
    start: '2023-10-03T04:00:00.000Z',
    end: '2023-11-01T14:00:00.000Z',
  },
  holidaysNY2023: {
    status: ToggleStatus.DateTrigger,
    start: '2023-12-21T10:00:00+01:00',
    end: '2024-02-01T02:59:00-05:00',
  },
};

export default features;
