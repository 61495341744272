import { takeLeading } from 'redux-saga/effects';
import { ActionTypes } from '../redux/ui';
import { validateSubscriptionTierCheckoutSaga } from './subscriptionTierCheckoutSaga';

export default function* () {
  yield takeLeading(
    ActionTypes.SubscriptionTierCheckoutRequested,
    validateSubscriptionTierCheckoutSaga,
  );
}
