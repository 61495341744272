import { useEffect } from 'react';
import { AnalyticsEvents } from '../events';
import { useTrackCallback } from '../track';

const useTrackPageLoaded = (instanceProperties = {}) => {
  const { track } = useTrackCallback();
  useEffect(() => {
    track({
      event: AnalyticsEvents.LoadedPage,
      instanceProperties,
    });
  }, []);
};

export default useTrackPageLoaded;
