export enum CancelReason {
  BillingChange = 'BILLING_CHANGE',
  LifeEvent = 'LIFE_EVENT',
  Pregnancy = 'PREGNANCY',
  SeasonalUse = 'SEASONAL_USE',
  NotExercising = 'NOT_EXERCISING',
  NotEnjoying = 'NOT_ENJOYING',
  Expensive = 'EXPENSIVE',
  SoftwareIssues = 'SOFTWARE_ISSUES',
  Other = 'OTHER',
  Unspecified = '',
  TierChange = 'tier_change',
  Downgrade = 'downgrade',
}
