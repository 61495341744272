import type { UserAttributes } from '@optimizely/optimizely-sdk';
import React from 'react';
import { getSignedInUserId } from '@peloton/auth';
import { useReduxState } from '@engage/redux';
import type { Props } from './Route';
import Route from './Route';

type OwnProps = Props & {
  overrideAttributes?: UserAttributes;
};

const ToggledRouteScopedToUser: React.FC<React.PropsWithChildren<OwnProps>> = ({
  overrideAttributes = {},
  ...props
}) => {
  const userId = useReduxState(getSignedInUserId);
  return (
    <Route
      overrideUserId={userId}
      overrideAttributes={{
        userId: userId ?? null,
        ...overrideAttributes,
      }}
      {...props}
    />
  );
};

export default ToggledRouteScopedToUser;
