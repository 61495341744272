import React from 'react';
import { unwrapValueWithFormatting, toFormatUnwrappedCopy } from '@peloton/copy';
import type { CopyValues, CopyValues as Values } from '@peloton/copy-generic';
import { useErrorReporter } from '@peloton/error-reporting/useErrorReporter';
import { useAccountKeyValueQuery, useFormsQuery } from './hooks.generated';
import type { AccountKeyValue, Forms } from './models.generated';
// WILL IT BUILD
export type CopyHook<ContentType extends string> = (
  id: ContentType,
  values?: Values,
) => string;
type CopyComponent<ContentType extends string> = React.FC<
  React.PropsWithChildren<{
    id: ContentType;
    values?: Values;
  }>
>;

const toCopyComponent = <ContentType extends string>(
  hook: CopyHook<ContentType>,
  // eslint-disable-next-line react/display-name
): CopyComponent<ContentType> => ({ id, values }) => <>{hook(id, values)}</>;

export const useAccountKeyValue: CopyHook<AccountKeyValue> = (id, values) =>
  unwrapValueWithFormatting(
    // This ts-ignore is due to Apollo 3 upgrades
    // eslint-disable-next-line @typescript-eslint/prefer-ts-expect-error
    // @ts-ignore
    useAccountKeyValueQuery,
    'accountKeyValue',
    id,
    values,
    useErrorReporter().errorReporter.reportError,
  );
export const AccountCopy = toCopyComponent(useAccountKeyValue);
AccountCopy.displayName = 'AccountCopy';

export const useUnformattedAccountKeyValue = (
  id: AccountKeyValue,
): { toFormattedAccountKeyValue: (values?: CopyValues) => string } => {
  const toFormattedAccountKeyValue = toFormatUnwrappedCopy(
    // This ts-ignore is due to Apollo 3 upgrades
    // eslint-disable-next-line @typescript-eslint/prefer-ts-expect-error
    // @ts-ignore
    useAccountKeyValueQuery,
    'accountKeyValue',
    id || '',
    useErrorReporter().errorReporter.reportError,
  );
  return { toFormattedAccountKeyValue };
};

export const useAccountFormsKeyValue: CopyHook<Forms> = (id, values) =>
  unwrapValueWithFormatting(
    // This ts-ignore is due to Apollo 3 upgrades
    // eslint-disable-next-line @typescript-eslint/prefer-ts-expect-error
    // @ts-ignore
    useFormsQuery,
    'forms',
    id,
    values,
    useErrorReporter().errorReporter.reportError,
  );
