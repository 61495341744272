import type { Action } from './addRefundPaymentMethod';
import { ActionTypes } from './addRefundPaymentMethod';

export type AddRefundPaymentMethodReducerState = {
  error: any;
  loading: boolean;
};

const initialState = {
  error: null,
  loading: false,
};

export default (
  state: AddRefundPaymentMethodReducerState = initialState,
  action: Action,
) => {
  switch (action.type) {
    case ActionTypes.AddRefundPaymentError:
      return { ...state, error: action.payload };
    case ActionTypes.AddRefundPaymentLoading:
      return { ...state, loading: action.payload };
    default:
      return state;
  }
};
