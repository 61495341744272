import { spacing, Eyebrow } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { getUserAvatarUrl, getUserEmail, getUserFullName } from '@peloton/auth';
import { Avatar as AvatarImg } from '@ecomm/image';
import { useReduxState } from '@engage/redux';

const useAvatar = () => {
  const name = useReduxState(getUserFullName);
  const email = useReduxState(getUserEmail);
  const url = useReduxState(getUserAvatarUrl);

  return { name: name || email, url };
};

type AvatarProps = {
  className?: string;
  showName?: boolean;
  size?: number;
};

export const Avatar: React.FC<AvatarProps> = ({
  className,
  showName = false,
  size = 32,
}) => {
  const { name, url } = useAvatar();

  return (
    <Container className={className}>
      <AvatarStyled size={size} url={url} alt={name} />
      {showName && <Name>{name}</Name>}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Name = styled(Eyebrow)`
  color: white;
  margin-left: ${spacing[8]};
`;

const AvatarStyled = styled(AvatarImg)`
  border: 0;
`;
