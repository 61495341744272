export const toCancellationFlowPath = (subscriptionId: string = ':subscriptionId') =>
  `/cancellation/${subscriptionId}`;

export const CANCELLATION_PAUSE_SUFFIX = `/pause`;
export const CANCELLATION_PAUSE_SUCCESS_SUFFIX = `${CANCELLATION_PAUSE_SUFFIX}/success`;

export const toPausePath = (subscriptionId: string = ':id') =>
  `${toCancellationFlowPath(subscriptionId)}${CANCELLATION_PAUSE_SUFFIX}`;

export const toPauseSuccessPath = (subscriptionId: string = ':id') =>
  `${toCancellationFlowPath(subscriptionId)}${CANCELLATION_PAUSE_SUCCESS_SUFFIX}`;

export const CANCELLATION_OFFER_SUFFIX = '/offer';

export const toOffersPath = (
  subscriptionId: string = ':subscriptionId',
  offerPageId: string = ':offerPageId',
) =>
  `${toCancellationFlowPath(subscriptionId)}/${offerPageId}${CANCELLATION_OFFER_SUFFIX}`;

export const CANCELLATION_REFINEMENT_SUFFIX = '/refinement';

export const toOtherPath = (subscriptionId: string = ':subscriptionId') =>
  `${toCancellationFlowPath(subscriptionId)}${CANCELLATION_OTHER_SUFFIX}`;

export const CANCELLATION_OTHER_SUFFIX = '/other';

export const toRefinementsPath = (
  subscriptionId: string = ':subscriptionId',
  refinementQuestionId: string = ':refinementQuestionId',
) =>
  `${toCancellationFlowPath(
    subscriptionId,
  )}/${refinementQuestionId}${CANCELLATION_REFINEMENT_SUFFIX}`;

export const CANCELLATION_SUCCESS_SUFFIX = `/success`;

export const toSuccessPath = (subscriptionId: string = ':subscriptionId') =>
  `${toCancellationFlowPath(subscriptionId)}${CANCELLATION_SUCCESS_SUFFIX}`;
