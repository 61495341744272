import { slate } from '@pelotoncycle/design-system';
import React from 'react';
import styled, { css } from 'styled-components';
import { white } from '@ecomm/colors';

import type { BreakpointOptions } from './BreakpointTransforms';
import SimpleImage from './Image';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  size: number;
  alt: string;
  url?: string;
};

const Avatar: React.FC<React.PropsWithChildren<Props>> = ({ url: src, size, ...props }) =>
  src ? (
    <StyledImage
      size={size}
      breakpointOptions={toAvatarTransforms(size)}
      src={src}
      className="fs-exclude"
      {...props}
    />
  ) : (
    <NoImage size={size} />
  );

const placeholderStyles = css`
  background-color: ${slate[50]};
  border-radius: 50%;
  border: solid 2px ${white};
`;

const NoImage = styled.div<{ size: number }>`
  ${placeholderStyles}
  width: ${props => `${props.size}px`};
  height: ${props => `${props.size}px`};
`;

const StyledImage = styled(SimpleImage)<{ size: number }>`
  ${placeholderStyles}
  overflow: hidden;
  width: ${props => `${props.size}px`};
  height: ${props => `${props.size}px`};
`;

const toAvatarTransforms = (size: number): BreakpointOptions => ({
  mobile: {
    aspectRatio: 1,
    gravity: 'face',
    height: size,
    width: size,
    crop: 'fill',
  },
});

export default Avatar;
