import React from 'react';
import { Route } from 'react-router-dom';
import { asyncComponent } from '@peloton/code-splitting';
import { toPostPurchaseFlowPath, toPostPurchaseFlowPathV2 } from './urls';

const Routes = [
  <Route
    key={toPostPurchaseFlowPathV2()}
    path={toPostPurchaseFlowPathV2()}
    component={asyncComponent(
      () => import('./PageV2' /* webpackChunkName: "PostPurchaseFlowPageV2" */),
    )}
  />,
  <Route
    key={toPostPurchaseFlowPath()}
    path={toPostPurchaseFlowPath()}
    component={asyncComponent(
      () => import('./Page' /* webpackChunkName: "PostPurchaseFlowPages" */),
    )}
  />,
];

export default Routes;
