import { Status } from '@ecomm/models';

export enum FetchStripeAccountActionTypes {
  FetchStripeAccountRequested = 'account/payment_info/FETCH_STRIPE_ACCOUNT_REQUESTED',
  FetchStripeAccountSuccess = 'account/payment_info/FETCH_STRIPE_ACCOUNT_SUCCESS',
  FetchStripeAccountFailure = 'account/payment_info/FETCH_STRIPE_ACCOUNT_FAILURE',
  GetStripeAccount = 'account/payment_info/FETCH_STRIPE_ACCOUNT',
}

type FetchStripeAccountRequestedAction = {
  type: FetchStripeAccountActionTypes.FetchStripeAccountRequested;
};

type FetchStripeAccountSuccessAction = {
  type: FetchStripeAccountActionTypes.FetchStripeAccountSuccess;
};

type FetchStripeAccountFailureAction = {
  type: FetchStripeAccountActionTypes.FetchStripeAccountFailure;
  payload: any;
};

type GetStripeAccountAction = {
  type: FetchStripeAccountActionTypes.GetStripeAccount;
};

export const fetchStripeAccountRequested = (): FetchStripeAccountRequestedAction => ({
  type: FetchStripeAccountActionTypes.FetchStripeAccountRequested,
});

export const fetchStripeAccountSuccess = (): FetchStripeAccountSuccessAction => ({
  type: FetchStripeAccountActionTypes.FetchStripeAccountSuccess,
});

export const fetchStripeAccountFailure = (
  payload: any,
): FetchStripeAccountFailureAction => ({
  type: FetchStripeAccountActionTypes.FetchStripeAccountFailure,
  payload,
});

export const getStripeAccount = (): GetStripeAccountAction => ({
  type: FetchStripeAccountActionTypes.GetStripeAccount,
});

type Action = FetchStripeAccountRequestedAction | FetchStripeAccountSuccessAction;

export type InitialLoadReducerState = Status;

export type LoadingReducerState = { initialLoadStatus: InitialLoadReducerState };

export const LOADING_REDUCER_NAMESPACE = 'initialLoadStatus';

export const getLoading = (state: LoadingReducerState) => state.initialLoadStatus;

export default (state: Status = Status.Loading, action: Action) => {
  switch (action.type) {
    case FetchStripeAccountActionTypes.FetchStripeAccountRequested:
      return Status.Loading;
    case FetchStripeAccountActionTypes.FetchStripeAccountSuccess:
      return Status.Loaded;
    default:
      return state;
  }
};
