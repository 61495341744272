import type { PendingResult } from '@engage/result';
import { ok, isOk, error as engageError } from '@engage/result';
import type { SubscriptionState } from './redux';
import { getSubscriptions, getSubscriptionsResult } from './redux';
import type {
  BillingType,
  Subscription,
  DigitalSubscription,
  DeviceSubscription,
} from './Subscription';
import {
  toPrimarySub,
  to1wSubs,
  isAllAccessSub,
  isDigitalSub,
  isActiveOrPausedSub,
} from './Subscription';

export const isLoadingSubscriptions = (state: SubscriptionState) =>
  state.subscriptions.isLoading;

export const getSubscriptionHasError = (state: SubscriptionState) =>
  state.subscriptions.error;

export const getOwnSubscriptions = (state: SubscriptionState) => {
  const subs = getSubscriptions(state);
  return subs?.filter(sub => sub.isOwner);
};

export const getOwnedAllAccessSubscriptions = (state: SubscriptionState) => {
  const subs = getSubscriptions(state);
  return subs?.filter(isAllAccessSub).filter(sub => sub.isOwner) as DeviceSubscription[];
};

export const getActiveOrPausedOwnedAllAccessSubscriptions = (
  state: SubscriptionState,
) => {
  const subs = getSubscriptions(state);
  return subs?.filter(
    sub => sub.isOwner && isAllAccessSub(sub) && isActiveOrPausedSub(sub),
  ) as DeviceSubscription[];
};

export const getActiveOrPausedOwnedDigitalSubscriptions = (state: SubscriptionState) => {
  const subs = getSubscriptions(state);
  return (
    (subs?.filter(
      sub => sub.isOwner && isDigitalSub(sub) && isActiveOrPausedSub(sub),
    ) as DigitalSubscription[]) ?? []
  );
};

export const getSharedSubscriptions = (state: SubscriptionState) => {
  const subs = getSubscriptions(state) ?? [];
  return subs.filter(sub => !sub.isOwner);
};

export const getSubscriptionsWithBillingType = (
  state: SubscriptionState,
  props: { billingType: BillingType },
) => {
  const subs = getSubscriptions(state) ?? [];
  return subs.filter(sub => sub.billingType === props.billingType);
};

/**
 * @deprecated use {@link getSubscriptionResult} instead
 */
export const getSubscription = (state: SubscriptionState, id: string) => {
  const subs = getSubscriptions(state);
  return subs && findSub(id, subs);
};

export const getSubscriptionResult = (
  state: SubscriptionState,
  id: string,
): PendingResult<Subscription, undefined> => {
  const result = getSubscriptionsResult(state);
  if (isOk(result)) {
    const sub = findSub(id, result.value);
    return sub ? ok(sub) : engageError(undefined);
  }
  return result;
};

const findSub = (id: string, subs: Subscription[]) => subs.find(sub => sub.id === id);

export const getPrimarySub = (state: SubscriptionState) => {
  const subs = getSubscriptions(state);
  return toPrimarySub(subs);
};

export const get1wSubs = (state: SubscriptionState) => {
  const subs = getSubscriptions(state);
  return to1wSubs(subs);
};

export const getActiveSubs = (subs: Subscription[] = []) => {
  return subs.filter(s => s.isActive);
};
