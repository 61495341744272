/* Don't import any files from another package here. That will cause unsupported dependencies */

import { route as applyBenefitRoute } from '@onewellness/pg-account-apply-benefit';
import { route as backupPaymentRoute } from '@onewellness/pg-account-backup-payment';
import { route as benefitPricingRoute } from '@onewellness/pg-account-benefit-onboarding';
import { route as hyattConnectRoute } from '@onewellness/pg-account-hyatt-connect';
import { route as liverpoolfcContinueRoute } from '@onewellness/pg-account-liverpoolfc-continue';
import { route as migrationGuideRoute } from '@onewellness/pg-account-migration-guide';
import { route as planCheckoutRoute } from '@onewellness/pg-account-plan-checkout';
import { route as subscriptionsErrorRoute } from '@onewellness/pg-account-subscription-error';

const accountRoutes = [
  ...applyBenefitRoute,
  ...backupPaymentRoute,
  ...benefitPricingRoute,
  ...subscriptionsErrorRoute,
  ...migrationGuideRoute,
  ...liverpoolfcContinueRoute,
  ...planCheckoutRoute,
  ...hyattConnectRoute,
];

export { accountRoutes };
