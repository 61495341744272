import { toCountryFromLocale, Country, toCountry } from './country';
import { toCurrencySymbol } from './currencySymbol';
import { Locale } from './locale';

export enum Currency {
  UnitedStatesDollars = 'USD',
  PoundSterling = 'GBP',
  CanadianDollar = 'CAD',
  Euro = 'EUR',
  AustralianDollars = 'AUD',
  Default = 'USD',
}

const countryCurrenciesMap: Record<Country, Currency> = {
  [Country.UnitedStates]: Currency.UnitedStatesDollars,
  [Country.UnitedKingdom]: Currency.PoundSterling,
  [Country.Canada]: Currency.CanadianDollar,
  [Country.Germany]: Currency.Euro,
  [Country.Australia]: Currency.AustralianDollars,
  [Country.Austria]: Currency.Euro,
};

export const toCurrency = (country: Country = toCountry()) =>
  countryCurrenciesMap[country] || Currency.Default;

export const toLocaleCurrency = (locale: Locale) =>
  `${toCurrencySymbol(locale)} ${toCurrency(toCountryFromLocale(locale))}`;

export type Money = {
  currency: Currency;
  amount: number;
};

export const formatMoney = (money: Money) =>
  new Intl.NumberFormat(toLocaleFromCurrency(money.currency), {
    style: 'currency',
    currency: money.currency,
  }).format(money.amount / 100);

export const formatMoneyNoZeroDecimals = (money: Money) =>
  new Intl.NumberFormat(toLocaleFromCurrency(money.currency), {
    style: 'currency',
    currency: money.currency,
    minimumFractionDigits: 0,
  }).format(money.amount / 100);

export const toLocaleFromCurrency = (currency: Currency): Locale => {
  switch (currency) {
    case Currency.UnitedStatesDollars:
      return Locale.EnglishUnitedStates;
    case Currency.CanadianDollar:
      return Locale.EnglishCanada;
    case Currency.PoundSterling:
      return Locale.EnglishUnitedKingdom;
    // MEMS-878: Refactor for currencies that don't have a direct relation to locale
    case Currency.Euro:
      return Locale.GermanGermany;
    case Currency.AustralianDollars:
      return Locale.EnglishAustralia;
    default:
      return Locale.Default;
  }
};

export const toMoney = (cents: number, currency?: Currency) => ({
  currency: currency ?? Currency.UnitedStatesDollars,
  amount: cents,
});

export const addMoney = (a: Money, b: Money): Money => {
  if (a.currency !== b.currency) {
    throw new Error('Cannot add different currencies');
  } else {
    return {
      currency: a.currency,
      amount: a.amount + b.amount,
    };
  }
};

export const toCurrencyFromLocale = (locale: Locale): Currency =>
  ({
    [Locale.EnglishUnitedStates]: Currency.UnitedStatesDollars,
    [Locale.EnglishUnitedKingdom]: Currency.PoundSterling,
    [Locale.EnglishCanada]: Currency.CanadianDollar,
    [Locale.GermanGermany]: Currency.Euro,
    [Locale.GermanAustria]: Currency.Euro,
    [Locale.EnglishAustralia]: Currency.AustralianDollars,
    [Locale.EnglishBeta]: Currency.UnitedStatesDollars,
    [Locale.SpanishMexico]: Currency.UnitedStatesDollars,
    [Locale.FrenchCanada]: Currency.CanadianDollar,
  }[locale]);
