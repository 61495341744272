import React from 'react';

export const HyattAvatar: React.FC = props => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 65 66"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="Group 2609855">
      <circle id="Ellipse 52" cx="32.5788" cy="32.9999" r="32.4211" fill="#2A71B9" />
      <g id="Group">
        <g id="WoH">
          <path
            id="Background"
            d="M54.6847 10.88H10.4595V55.1053H54.6847V10.88Z"
            fill="#2A71B9"
          />
        </g>
        <g id="WoH_2">
          <g id="WoH_3">
            <path
              id="Vector"
              d="M38.1992 43.6726H39.8271V50.188H41.3138V43.6726H42.9455V43.0259H38.1992V43.6726Z"
              fill="white"
            />
            <path
              id="Vector_2"
              d="M45.2739 43.6726H46.9019V50.188H48.3923V43.6726H50.0202V43.0259H45.2739V43.6726Z"
              fill="white"
            />
            <path
              id="Vector_3"
              d="M34.5099 50.188H36.0524L33.7629 43.0259H32.8151L30.377 50.188H31.3173L31.9566 48.3111H33.9116L34.5137 50.188H34.5099ZM32.1684 47.6718L32.9601 45.3488L33.7034 47.6718H32.1684Z"
              fill="white"
            />
            <path
              id="Vector_4"
              d="M45.4854 15.7977V22.9599H47.7451C49.4845 22.9599 50.4918 21.6256 50.4918 19.3807C50.4918 17.1357 49.4808 15.7977 47.7451 15.7977H45.4854ZM47.4515 16.4407C48.4067 16.4407 49.0014 17.6933 49.0051 19.3881C49.0051 21.0457 48.4364 22.2685 47.5184 22.3169H46.972V16.4407H47.4515Z"
              fill="white"
            />
            <path
              id="Vector_5"
              d="M36.3937 22.9599L34.8364 19.7932C35.5909 19.4847 35.944 18.8789 35.944 17.8902C35.944 16.4853 35.13 15.7977 33.3274 15.7977H31.417V22.9599H32.9037V20.0311C32.9037 20.0311 33.3497 20.0311 33.3646 20.0311L34.8066 22.9599H36.3937ZM32.9074 19.3918V16.4407H33.3051C34.1153 16.4407 34.4461 17.0057 34.4461 17.894C34.4461 18.9012 34.0447 19.3918 33.3051 19.3918H32.9074Z"
              fill="white"
            />
            <path
              id="Vector_6"
              d="M20.4752 15.7977L19.5646 19.7783L18.6354 15.7977H17.8884L16.9741 19.7783L16.0449 15.7977H14.5693L16.2456 22.9599H16.9926L17.9218 18.9049L18.8361 22.9599H19.5832L21.2743 15.7977H20.4752Z"
              fill="white"
            />
            <path
              id="Vector_7"
              d="M18.8112 46.2966H16.8674V43.0259H15.377V50.188H16.8674V46.9433H18.8112V50.188H20.2979V43.0259H18.8112V46.2966Z"
              fill="white"
            />
            <path
              id="Vector_8"
              d="M27.9728 43.0259L26.3115 46.0922L24.6352 43.0259H23.0259L25.137 46.8653V50.188H26.6385V46.9136L28.7348 43.0259H27.9728Z"
              fill="white"
            />
            <path
              id="Vector_9"
              d="M43.2585 22.3206H40.6457V15.7977H39.1553V22.9599H43.2585V22.3206Z"
              fill="white"
            />
            <path
              id="Vector_10"
              d="M28.7307 19.3727C28.7307 18.1983 28.4445 17.2431 27.913 16.6038C27.4187 16.0091 26.7794 15.6969 25.9654 15.6969C25.1515 15.6969 24.5122 16.0091 24.0179 16.6038C23.4864 17.2431 23.2039 18.1983 23.2002 19.3727C23.2002 19.3727 23.2002 19.3727 23.2002 19.3765C23.2002 19.3802 23.2002 19.3765 23.2002 19.3802C23.2002 20.5547 23.4864 21.5099 24.0179 22.1491C24.5122 22.7438 25.1515 23.056 25.9654 23.056C26.7794 23.056 27.4187 22.7438 27.913 22.1491C28.4445 21.5099 28.727 20.5547 28.7307 19.3802C28.7307 19.3802 28.7307 19.3802 28.7307 19.3765C28.7307 19.3727 28.7307 19.3765 28.7307 19.3727ZM25.9654 22.4204C25.2927 22.4204 24.8318 21.8183 24.8318 19.3765C24.8318 16.9346 25.289 16.3325 25.9654 16.3325C26.6419 16.3325 27.099 16.9346 27.099 19.3765C27.099 21.8183 26.6419 22.4204 25.9654 22.4204Z"
              fill="white"
            />
            <g id="Group_2">
              <path
                id="Vector_11"
                d="M34.4756 36.5705H35.9623V33.0247H38.4339V32.3817H35.9623V30.0476H38.8502V29.4083H34.4756V36.5705Z"
                fill="white"
              />
              <path
                id="Vector_12"
                d="M31.8112 32.9837C31.8112 31.8092 31.5251 30.854 30.9936 30.2147C30.4992 29.6201 29.86 29.3079 29.046 29.3079C28.232 29.3079 27.5928 29.6201 27.0984 30.2147C26.5669 30.854 26.2845 31.8092 26.2808 32.9837C26.2808 32.9837 26.2808 32.9837 26.2808 32.9874C26.2808 32.9911 26.2808 32.9874 26.2808 32.9911C26.2808 34.1656 26.5669 35.1208 27.0984 35.7601C27.5928 36.3548 28.232 36.667 29.046 36.667C29.86 36.667 30.4992 36.3548 30.9936 35.7601C31.5251 35.1208 31.8075 34.1656 31.8112 32.9911C31.8112 32.9911 31.8112 32.9911 31.8112 32.9874C31.8112 32.9837 31.8112 32.9874 31.8112 32.9837ZM29.046 36.0314C28.3733 36.0314 27.9124 35.4293 27.9124 32.9874C27.9124 30.5455 28.3696 29.9434 29.046 29.9434C29.7224 29.9434 30.1796 30.5455 30.1796 32.9874C30.1796 35.4293 29.7224 36.0314 29.046 36.0314Z"
                fill="white"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
