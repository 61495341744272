export enum BillingFrequency {
  Monthly = 'monthly',
  Annual = 'annual',
}

export enum Platform {
  Amazon = 'amazon',
  Apple = 'apple',
  Bango = 'bango',
  Google = 'google',
  Peloton = 'peloton',
  Roku = 'roku',
}

export enum TierLabel {
  DIGITAL_BASIC = 'digital_basic',
  DIGITAL_PLUS = 'digital_plus',
  LEGACY = 'legacy',
  GUIDE = 'guide',
  ALL_ACCESS = 'all_access',
}

export enum MembershipType {
  Device = 'device',
  Digital = 'digital',
  Guide = 'guide',
}

export enum ProductSlugs {
  AppFree = 'app-free',
  AppOne = 'app-one',
  AppPlus = 'app-plus',
  StrengthPlus = 'strength-plus',
}
