export const OTD_TREAD_PLUS_PREORDER = 'otd_tread_plus_preorder';

const mapToView = (content: any) => ({
  INITIAL: content['cart.otd']['cartOTDLookup.initial'],
  DYNAMIC_INITIAL: content['cart.otd']['OTDLookup.initial'],
  OK: content['cart.otd']['OTDLookup.ok'],
  INSUFFICIENT_DATA: content['cart.otd']['OTDLookup.staticFallBack'],
  POSTAL_CODE_CANT_SELF_SCHEDULE: content['cart.otd']['OTDLookup.staticFallBack'],
  ERROR: content['cart.otd']['OTDLookup.staticFallBack'],
  staticOTD: content['cart.otd']['cartOtdLookup.static'],
  INVALID_POSTAL_CODE: content['cart.otd']['OTDLookup.invalidPostalCode'],
  INELIGIBLE_POSTAL_CODE: content['cart.otd']['OTDLookup.ineligiblePostalCode'],
  DISABLED: content['cart.otd']['OTDLookup.staticFallBack'],
  otd_estimate_date_range: content['cart.otd']['otd.estimate.dateRange'],
  otd_estimate_single_date: content['cart.otd']['otd.estimate.singleDate'],
  otd_bike_estimates: content['cart.otd']['otd.bikeEstimates'],
  otd_bike_plus_estimates: content['cart.otd']['otd.bikePlusEstimates'],
  otd_guide_estimates: content['cart.otd']['otd.guideEstimates'],
  otd_tread_estimates: content['cart.otd']['otd.treadEstimates'],
  otd_tread_plus_estimates: content['cart.otd']['otd.treadPlusEstimates'],
  otd_row_estimates: content['cart.otd']['otd.rowEstimates'],
  caesar_preorder_otd: content['cart.otd']['caesar.preorder.otd'],
  [OTD_TREAD_PLUS_PREORDER]: content['cart.otd']['tread_plus.preorder.otd'],
});

export default mapToView;
