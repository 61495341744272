import type { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';
import type { Client } from '@peloton/api';
import { getSignedInUserId } from '@peloton/auth';
import { fetchSubscriptions } from '../api';
import type { loadSubscriptions as loadSubscriptionsAction } from '../redux';
import { loadSubscriptionsSuccess, loadSubscriptionsFailure } from '../redux';

const loadSubscriptions = function* (
  client: Client,
  action: ReturnType<typeof loadSubscriptionsAction>,
): SagaIterator {
  try {
    const userId = yield select(getSignedInUserId);
    const subs = yield call(fetchSubscriptions, client, userId);
    yield put(loadSubscriptionsSuccess(subs, action.payload.id));
  } catch (e) {
    yield put(loadSubscriptionsFailure(action.payload.id));
  }
};

export default loadSubscriptions;
