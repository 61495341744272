import type { AxiosResponse } from 'axios';
import type { Client } from '@peloton/api';
import { pipeData } from '@peloton/api';
import type { Locale } from '@peloton/internationalize';
import { toLocale } from '@peloton/internationalize';
import type { Omit } from '@peloton/types/Omit';
import type { FeedPrivacy } from '@engage/graphql';
import type {
  FeaturesSettings,
  NotificationSettings,
  ProfilePrivacySettings,
  MeasurementUnit,
  DisplayPreferences,
  ClosedCaptionsSettings,
  SettingsModel,
  UserMeasurementPreferences,
  BlockExplicitSettings,
  BlockPrenatalSettings,
  ClassLanguage,
  ClassLanguagePreferences,
  ContentPreferences,
  CorporateWellnessGroup,
  CorporateWellnessSettings,
} from './models';
import { ChatRestriction } from './models';
import type { ApiClassLanguagePUT } from './toApiClassLanguagePUT';
import { toApiClassLanguagePUT } from './toApiClassLanguagePUT';

// We can get rid of this when displayLanguage comes from the API.
export type SettingsFromApi = Pick<
  SettingsModel,
  Exclude<keyof SettingsModel, 'displayLanguage'>
> & { disableExplicitWarning: boolean };

export const fetchUserSettings = (
  api: Client,
  userId: string,
  toggle = false,
): Promise<SettingsFromApi> =>
  api.get(toSettingsUrl(userId)).then(pipeData(toggle ? toSettings : toLegacySettings));

export const fetchCaptionsSettings = (
  api: Client,
  userId: string,
): Promise<ClosedCaptionsSettings> =>
  api.get(toSettingsUrl(userId)).then(pipeData(toClosedCaptionsSettings));

export const fetchUserMeasurementPreferences = (api: Client, userId: string) =>
  api.get(toSettingsUrl(userId)).then(pipeData(toUserMeasurementPreferences));

export const updateCaptionsSettings = (
  api: Client,
  userId: string,
  settings: ClosedCaptionsSettings,
) =>
  api
    .put(toSettingsUrl(userId), closedCaptionsSettingsToApi(settings))
    .then(pipeData(toClosedCaptionsSettings));

export const updateBlockExplicitSettings = (
  api: Client,
  userId: string,
  settings: BlockExplicitSettings,
) => updateSettings(api, userId, settings, pipeData(toBlockExplicitSettings));

export const updateHidePrenatalSettings = (
  api: Client,
  userId: string,
  settings: BlockPrenatalSettings,
) => updateSettings(api, userId, settings, pipeData(toHidePrenatalSettings));

export const updatePrivacySettings = (
  api: Client,
  userId: string,
  settings: ProfilePrivacySettings & CorporateWellnessSettings,
) => updateSettings(api, userId, profilePrivacyToApi(settings));

export const updateShareChallengeCompletion = (
  api: Client,
  userId: string,
  corporateWellnessGroup: CorporateWellnessGroup | null,
) => updateSettings(api, userId, { corporateWellnessGroup });

export const updateNotificationSettings = (
  api: Client,
  userId: string,
  settings: NotificationSettings,
) => updateSettings(api, userId, notificationsToApi(settings));

export const updateFeaturesSettings = (
  api: Client,
  userId: string,
  settings: FeaturesSettings,
) => updateSettings(api, userId, featuresToApi(settings));

export const updateClassLanguagePreferences = (
  api: Client,
  userId: string,
  settings: ClassLanguagePreferences,
) => updateSettings(api, userId, classLanguagePreferencesToApi(settings));

export const updateDisplayPreferencesApi = (
  api: Client,
  userId: string,
  settings: DisplayPreferences,
) => updateSettings(api, userId, displayPreferencesToApi(settings));

export const updateContentPreferencesApi = (
  api: Client,
  userId: string,
  settings: ContentPreferences,
) => updateSettings(api, userId, contentPreferencesToApi(settings));

export const updateSettings = (
  api: Client,
  userId: string,
  settings: DeepPartial<ApiUserSettingsPUT>,
  processResult?: (results: AxiosResponse<ApiUserSettings>) => any,
) =>
  api
    .put(toSettingsUrl(userId), settings)
    .then(result =>
      processResult ? processResult(result as AxiosResponse<ApiUserSettings>) : result,
    );

const toSettingsUrl = (userId: string) => `api/user/${userId}/settings`;

type ApiUserPrivacySettings = {
  classmatesCanChatMe: boolean;
  friendsCanChatMe: boolean;
  newFacebookFriendEmailNotification: boolean;
  newFollowerEmailNotification: boolean;
  isProfilePrivate: boolean;
  workoutMapsPrivate: boolean;
  age: boolean;
  gender: boolean;
  effortZonesPrivate: boolean;
  feedPrivacy: FeedPrivacy;
  allowMarketingGrowth: boolean;
  allowPersonalizedExperience: boolean;
  hideFromContactSearch: boolean;
  hideFromUserSearch: boolean;
};

export type ApiUserSettings = {
  privacy: ApiUserPrivacySettings;
  distanceUnit: MeasurementUnit;
  heightUnit: MeasurementUnit;
  weightUnit: MeasurementUnit;
  classLanguagePreferences: ClassLanguage[];
  closedCaptions: { enabled: boolean; languagePreference: Locale | null };
  blockExplicit: boolean;
  blockPrenatal: boolean;
  isEffortScoreEnabled: boolean;
  disableExplicitWarning: boolean;
  corporateWellnessGroup: {
    id: string;
    name: string;
    shareCorporateWellnessCompletion: boolean | null;
  } | null;
  syncContacts: boolean;
};

export type ApiUserSettingsPUT = Omit<ApiUserSettings, 'classLanguagePreferences'> & {
  classLanguagePreferences: ApiClassLanguagePUT[];
  translationLanguage: Locale;
};

const closedCaptionsSettingsToApi = (
  settings: ClosedCaptionsSettings,
): DeepPartial<ApiUserSettingsPUT> => ({
  closedCaptions: {
    enabled: settings.isClosedCaptionsOn,
    languagePreference: settings.closedCaptionsLanguagePreference,
  },
});

export const profilePrivacyToApi = (
  settings: ProfilePrivacySettings & CorporateWellnessSettings,
): DeepPartial<ApiUserSettingsPUT> => ({
  privacy: {
    isProfilePrivate: settings.isProfilePrivate,
    classmatesCanChatMe: settings.chatRestriction === ChatRestriction.all,
    workoutMapsPrivate: settings.workoutMapsPrivate,
    friendsCanChatMe:
      settings.chatRestriction === ChatRestriction.following ||
      settings.chatRestriction === ChatRestriction.all,
    age: settings.age,
    gender: settings.gender,
    effortZonesPrivate: settings.isStriveScorePrivate,
    feedPrivacy: settings.feedPrivacy,
    allowMarketingGrowth: settings.allowMarketingGrowth,
    allowPersonalizedExperience: settings.allowPersonalizedExperience,
    hideFromContactSearch: settings.hideFromContactSearch,
    hideFromUserSearch: settings.hideFromUserSearch,
  },
  corporateWellnessGroup: settings.corporateWellnessGroup,
  syncContacts: settings.syncContacts,
});

export const notificationsToApi = (
  settings: NotificationSettings,
): DeepPartial<ApiUserSettingsPUT> => ({
  privacy: {
    newFacebookFriendEmailNotification: settings.shouldSendFacebookEmailNotification,
    newFollowerEmailNotification: settings.shouldSendFollowerEmailNotification,
  },
});

export const featuresToApi = (
  settings: FeaturesSettings,
): DeepPartial<ApiUserSettingsPUT> => ({
  privacy: { effortZonesPrivate: settings.isStriveScorePrivate },
  isEffortScoreEnabled: settings.isStriveScoreEnabled,
});

export const classLanguagePreferencesToApi = (
  settings: ClassLanguagePreferences,
): Partial<ApiUserSettingsPUT> => ({
  classLanguagePreferences: settings.classLanguagePreferences.map(toApiClassLanguagePUT),
});

export const displayPreferencesToApi = (
  settings: DisplayPreferences,
): Partial<ApiUserSettingsPUT> => ({
  distanceUnit: settings.distanceUnit,
  translationLanguage: toLocale(settings.displayLanguage),
});

export const contentPreferencesToApi = (
  settings: ContentPreferences,
): Partial<ApiUserSettingsPUT> => ({
  blockExplicit: settings.blockExplicit,
  blockPrenatal: settings.blockPrenatal,
});

export const userMeasurementPreferencesToApi = (
  settings: UserMeasurementPreferences,
): Partial<ApiUserSettingsPUT> => ({
  heightUnit: settings.heightUnit,
  weightUnit: settings.weightUnit,
});

export const toLegacySettings = (apiSettings: ApiUserSettings): SettingsFromApi => ({
  chatRestriction: apiSettings.privacy.classmatesCanChatMe
    ? ChatRestriction.all
    : apiSettings.privacy.friendsCanChatMe
    ? ChatRestriction.following
    : ChatRestriction.none,
  shouldSendFacebookEmailNotification:
    apiSettings.privacy.newFacebookFriendEmailNotification,
  shouldSendFollowerEmailNotification: apiSettings.privacy.newFollowerEmailNotification,
  isProfilePrivate: apiSettings.privacy.isProfilePrivate,
  workoutMapsPrivate: apiSettings.privacy.workoutMapsPrivate,
  distanceUnit: apiSettings.distanceUnit,
  heightUnit: apiSettings.heightUnit,
  weightUnit: apiSettings.weightUnit,
  blockExplicit: apiSettings.blockExplicit,
  blockPrenatal: apiSettings.blockPrenatal,
  classLanguagePreferences: apiSettings.classLanguagePreferences,
  age: apiSettings.privacy.age,
  gender: apiSettings.privacy.gender,
  isStriveScoreEnabled: apiSettings.isEffortScoreEnabled,
  isStriveScorePrivate: apiSettings.privacy.effortZonesPrivate,
  disableExplicitWarning: apiSettings.disableExplicitWarning,
  feedPrivacy: apiSettings.privacy.feedPrivacy,
  corporateWellnessGroup: apiSettings.corporateWellnessGroup,
  allowMarketingGrowth: apiSettings.privacy.allowMarketingGrowth,
  allowPersonalizedExperience: apiSettings.privacy.allowPersonalizedExperience,
  hideFromUserSearch: apiSettings.privacy.hideFromUserSearch,
  hideFromContactSearch: apiSettings.privacy.hideFromContactSearch,
  syncContacts: apiSettings.syncContacts,
});

export const toSettings = (apiSettings: ApiUserSettings): SettingsFromApi => ({
  chatRestriction: apiSettings.privacy.classmatesCanChatMe
    ? ChatRestriction.following
    : apiSettings.privacy.friendsCanChatMe
    ? ChatRestriction.following
    : ChatRestriction.none,
  shouldSendFacebookEmailNotification:
    apiSettings.privacy.newFacebookFriendEmailNotification,
  shouldSendFollowerEmailNotification: apiSettings.privacy.newFollowerEmailNotification,
  isProfilePrivate: apiSettings.privacy.isProfilePrivate,
  workoutMapsPrivate: apiSettings.privacy.workoutMapsPrivate,
  distanceUnit: apiSettings.distanceUnit,
  heightUnit: apiSettings.heightUnit,
  weightUnit: apiSettings.weightUnit,
  blockExplicit: apiSettings.blockExplicit,
  blockPrenatal: apiSettings.blockPrenatal,
  classLanguagePreferences: apiSettings.classLanguagePreferences,
  age: apiSettings.privacy.age,
  gender: apiSettings.privacy.gender,
  isStriveScoreEnabled: apiSettings.isEffortScoreEnabled,
  isStriveScorePrivate: apiSettings.privacy.effortZonesPrivate,
  disableExplicitWarning: apiSettings.disableExplicitWarning,
  feedPrivacy: apiSettings.privacy.feedPrivacy,
  corporateWellnessGroup: apiSettings.corporateWellnessGroup,
  allowMarketingGrowth: apiSettings.privacy.allowMarketingGrowth,
  allowPersonalizedExperience: apiSettings.privacy.allowPersonalizedExperience,
  hideFromUserSearch: apiSettings.privacy.hideFromUserSearch,
  hideFromContactSearch: apiSettings.privacy.hideFromContactSearch,
  syncContacts: apiSettings.syncContacts,
});

const toClosedCaptionsSettings = (
  apiSettings: ApiUserSettings,
): ClosedCaptionsSettings => {
  return {
    isClosedCaptionsOn: apiSettings.closedCaptions.enabled,
    closedCaptionsLanguagePreference: apiSettings.closedCaptions.languagePreference,
  };
};

const toBlockExplicitSettings = (
  apiSettings: ApiUserSettings,
): BlockExplicitSettings => ({
  blockExplicit: apiSettings.blockExplicit,
});

const toHidePrenatalSettings = (apiSettings: ApiUserSettings): BlockPrenatalSettings => ({
  blockPrenatal: apiSettings.blockPrenatal,
});

const toUserMeasurementPreferences = (
  apiSettings: ApiUserSettings,
): UserMeasurementPreferences => ({
  heightUnit: apiSettings.heightUnit,
  weightUnit: apiSettings.weightUnit,
});

export type DeepPartial<T> = { [P in keyof T]?: DeepPartial<T[P]> };
