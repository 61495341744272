import { call, put } from 'redux-saga/effects';
import type { Client } from '@peloton/api';
import { changePassword, redirectToLogin } from '@peloton/auth';
import type { PasswordChangeAction } from '../redux/passwordChange';

const updatePassword = function* (client: Client, action: PasswordChangeAction) {
  try {
    yield call(changePassword, client, {
      oldPassword: action.payload.currentPassword!,
      newPassword: action.payload.newPassword!,
    });
    yield call(action.onSuccess);
    yield put(redirectToLogin());
  } catch (e) {
    // Show error in redux form.
    if (e.response.status === 401) {
      yield call(action.onInvalidPasswordError);
    } else {
      yield call(action.onFormError, 'Unknown Error');
    }
  }
};

export default updatePassword;
