import type { SagaIterator } from 'redux-saga';
import { put, call, getContext, spawn, select } from 'redux-saga/effects';
import { CLIENT_CONTEXT } from '@peloton/api';
import { getSignedInUserId } from '@peloton/auth';
import { DomainError } from '@peloton/domain-error';
import type { ReportError } from '@peloton/error-reporting';
import { ERROR_REPORTING_CLIENT_CONTEXT } from '@peloton/error-reporting';
import { PaymentMethod } from '@ecomm/models';
import { createSetupIntentSaga } from '@ecomm/payment/sagas';
import { updatePaymentMethod, updateAndCapturePaymentMethod } from '../../api';
import { reportUpdatePayment, UpdatePaymentStage } from '../../update';
import type { UpdatePaymentAction } from '../redux';
import {
  updatePaymentFailure,
  updatePaymentRequested,
  updatePaymentSuccess,
} from '../redux';

export default function* (action: UpdatePaymentAction): SagaIterator {
  const {
    country,
    source,
    onSuccess,
    capture,
    orderId,
    ...setupIntentPayload
  } = action.payload;
  yield spawn(reportUpdatePayment, {
    source,
    stage: UpdatePaymentStage.Attempt,
    country,
  });
  yield put(updatePaymentRequested());

  try {
    const result = yield call(createSetupIntentSaga, setupIntentPayload);
    const client = yield getContext(CLIENT_CONTEXT);
    if (capture && orderId) {
      yield call(
        updateAndCapturePaymentMethod,
        client,
        result.payment_method,
        PaymentMethod.CreditCard,
        orderId,
      );
    } else {
      yield call(
        updatePaymentMethod,
        client,
        result.payment_method,
        PaymentMethod.CreditCard,
      );
    }

    yield spawn(reportUpdatePayment, {
      source,
      stage: UpdatePaymentStage.Success,
      country,
    });
    if (onSuccess) {
      yield spawn(onSuccess);
    }
    yield put(updatePaymentSuccess());
  } catch (error) {
    yield put(updatePaymentFailure(error));
    const userId = yield select(getSignedInUserId);
    const setupIntentId = error.setup_intent?.id;
    const errorMessage =
      error instanceof DomainError ? error.originalError.message : error.message;
    yield spawn(reportUpdatePayment, {
      source,
      stage: UpdatePaymentStage.Failure,
      country,
      errorMessage: errorMessage,
      userId: userId,
      setupIntentId: setupIntentId,
    });
    const reporter: ReportError = yield getContext(ERROR_REPORTING_CLIENT_CONTEXT);
    const message = `Update payment error on ${source}: ${country}, ${window.location.hostname}, ${errorMessage}`;
    const domainError = new DomainError(message, {
      user_id: userId,
      setup_intent_id: setupIntentId,
      error: error,
    });
    yield call(reporter, domainError);
  }
}
