import { curry } from 'ramda';
import type { SagaIterator } from 'redux-saga';
import { takeEvery, call, getContext, put, select } from 'redux-saga/effects';
import type { Client } from '@peloton/api';
import { CLIENT_CONTEXT } from '@peloton/api';
import type {
  RequestAction,
  SuccessActionCreator,
  FailActionCreator,
  Namespace,
} from './actionCreators';
import type { FetcherSelectorState } from './redux';
import { FetcherActionTypes, successAction, failAction, requestAction } from './redux';

export const toLoaderSaga = curry(function* <P, Q, R, S, T>(
  success: SuccessActionCreator<P, Q>,
  fail: FailActionCreator<R>,
  client: Client,
  action: RequestAction<S, T>,
): SagaIterator {
  try {
    // TODO: remove the `as any` here once TypeScript implements variadic types
    const payload = yield (call as any)(
      action.payload.gateway,
      client,
      ...action.payload.gatewayParams,
    );
    yield put(success(action.payload.namespace, payload));
  } catch (e) {
    yield put(fail(action.payload.namespace, e));
  }
});

export const toCacheSaga = curry(function* <P, Q, R, S, T>(
  success: SuccessActionCreator<P, Q>,
  fail: FailActionCreator<R>,
  client: Client,
  action: RequestAction<S, T>,
): SagaIterator {
  const state = yield select(getState, action.payload.namespace);

  if (state) {
    if (state.isLoading) {
      return;
    }
    if (state.entity) {
      yield put(success(action.payload.namespace, state.entity));
      return;
    }
  }
  yield put(
    requestAction(
      action.payload.namespace,
      action.payload.gateway,
      action.payload.gatewayParams,
    ),
  );
});

export const getState = (state: FetcherSelectorState, namespace: Namespace) =>
  state.fetched[namespace];

export const loadRequestSaga = toLoaderSaga(successAction, failAction);
export const cacheSaga = toCacheSaga(successAction, failAction);

export const fetcherSaga = function* (): SagaIterator {
  const client = yield getContext(CLIENT_CONTEXT);
  // FIXME: type the curried action
  yield takeEvery(FetcherActionTypes.Request as any, loadRequestSaga, client);
  yield takeEvery(FetcherActionTypes.Cache as any, cacheSaga, client);
};
