import { useEffect, useState } from 'react';
import { toCountry } from '@peloton/internationalize';
import { useOneWellnessClient } from '@onewellness/api/client';
import type { OneWellnessClient } from '@onewellness/api/client';
import { entitlementByToken } from '@onewellness/api/entitlement';
import { useAccessToken } from '@onewellness/utils/hooks';

export const usePartnerLogo = () => {
  const token = useAccessToken();
  const country = toCountry();
  const client: OneWellnessClient = useOneWellnessClient();
  const [logo, setLogo] = useState('');

  useEffect(() => {
    if (token) {
      entitlementByToken(client, { token, country }).then(data => {
        setLogo((data?.entitlementByToken?.program?.partner?.logo as string) || '');
      });
    }
  }, [token, country, client, setLogo]);

  return logo;
};
