import type { SagaIterator } from 'redux-saga';
import { call, select, put } from 'redux-saga/effects';
import type { Client } from '@peloton/api';
import { getSignedInUserId } from '@peloton/auth';
import { reactivateSubscription as reactivate } from '@engage/subscriptions/api';
import type { reactivateSubscription as recativateActionCreator } from '../redux';
import { loadSubscriptions, reactivateFailure, reactivateSuccess } from '../redux';
import { getSubscription } from '../selectors';
import updateSubscriptions from './updateSubscriptions';

type ReactivateSubAction = ReturnType<typeof recativateActionCreator>;
const reactivateSubscription = function* (
  client: Client,
  { payload: subId }: ReactivateSubAction,
): SagaIterator {
  try {
    const userId = yield select(getSignedInUserId);
    if (!userId) {
      throw new Error();
    }
    const sub = yield select(getSubscription, subId);

    const updatedSub = yield call(reactivate, client, userId, sub);
    yield put(reactivateSuccess());
    yield put(loadSubscriptions());
    yield call(updateSubscriptions, updatedSub);
  } catch (e) {
    yield put(reactivateFailure());
  }
};

export default reactivateSubscription;
