import { track } from '@peloton/analytics';
import { getCurrentRelativePathname } from '@peloton/internationalize/models/path';
import type { getUserTrackingProperties } from '@ecomm/auth';

type TrackFaqOpenStateToggledProps = {
  faqGroupKey: string;
  faqItemKey: string;
  userTrackingProperties: ReturnType<typeof getUserTrackingProperties>;
};

export const trackFaqOpenStateToggled = ({
  faqGroupKey,
  faqItemKey,
  userTrackingProperties,
}: TrackFaqOpenStateToggledProps) =>
  track({
    event: 'Clicked FAQs',
    properties: {
      page: getCurrentRelativePathname(),
      parent: faqGroupKey,
      unitName: faqItemKey,
      ...userTrackingProperties,
    },
  });
