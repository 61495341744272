import { COMMIT_REF } from '@peloton/app-config';

/**
 * @param {string} name - name of package or app Apollo Client is being initialized in
 * @param {string} [version] - override COMMIT_REF as version of Apollo Client
 * @returns {object} - object with name and version for Apollo Client initialization
 */

export const toClientNameAndVersion = (name: string, version?: string) => ({
  name,
  version: version || COMMIT_REF || 'local',
});
