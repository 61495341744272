import type { Partner } from './partner';
import type { ProgramBenefit } from './programBenefit';
import { programBenefitCoversAam } from './programBenefit';

export type Program = {
  programBenefits: ProgramBenefit[];
  onboardingPathSegment: string;
  partner: Partner;
};

export const programHasAamBenefit = (program: Program): boolean => {
  return (
    program.programBenefits.filter(programBenefit =>
      programBenefitCoversAam(programBenefit),
    ).length > 0
  );
};
