import { Body as P, spacing, white, List, Link } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { Markdown } from '@peloton/copy';
import { useAccountKeyValue } from '@account/copy';
import { HYATT_OFFER_CONNECT } from '@onewellness/routes';
import { useTracking } from '../segment';
import { Body } from '../shared';
import type { Register } from './useHyattConnect';

type Props = {
  register: Register;
};

export const Confirm: React.FC<Props> = ({ register }) => {
  const { trackAcceptClick, trackCancelClick } = useTracking();

  const headline = useAccountKeyValue('hyatt.offer.confirm.headline');
  const body = useAccountKeyValue('hyatt.offer.confirm.body');
  const accept = useAccountKeyValue('hyatt.offer.confirm.accept');
  const cancel = useAccountKeyValue('hyatt.offer.confirm.cancel');

  return (
    <>
      <Body
        width="480px"
        headlineText={headline}
        bodyText={
          <Markdown
            content={body}
            markdown={{
              renderers: {
                paragraph: ({ children }: { children: React.ReactNode[] }) => (
                  <BodyText size="extraSmall">{children}</BodyText>
                ),
                list: ({ children }: { children: React.ReactNode[] }) => (
                  <ListStyled items={children} size="small" />
                ),
              },
            }}
          />
        }
        cta={{
          onClick: () => {
            trackAcceptClick();
            register();
          },
          text: accept,
        }}
      />
      <LinkStyled href={HYATT_OFFER_CONNECT} size="small" onClick={trackCancelClick}>
        {cancel}
      </LinkStyled>
    </>
  );
};

const ListStyled = styled(List)`
  padding-top: ${spacing[16]};
  text-align: left;
`;

const BodyText = styled(P)`
  text-align: left;
`;

const LinkStyled = styled(Link)`
  padding-bottom: ${spacing[4]};
  align-self: center;
  color: ${white};

  &:hover {
    color: ${white};
    opacity: 0.8;
  }
`;
