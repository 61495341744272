import type { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import type { Client } from '@peloton/api';
import { fetchUserExists, successfulProfileUpdate, toUser } from '@peloton/auth';
import type { BasicInfo } from '@account/pg-my-membership/models/BasicInfo';
import { updateBasicInfo as update } from '../../api/basicInfo';
import type { BasicInfoActionType } from '../redux/basicInfo';

type ChangesKey = ('email' | 'username')[];

type UpdatedBasicInfoPresenter = {
  addErrors(prop: ChangesKey[0], errors: string[]): void;
  redirectToLoginPage(): void;
  displaySuccessMessage(): void;
  displayErrorMessage(): void;
  displayPolicyViolationMessage(): void;
};

type BasicInfoUpdateAction = {
  type: BasicInfoActionType.BasicInfoUpdate;
  presenter: UpdatedBasicInfoPresenter;
  info: BasicInfo;
  changes: ChangesKey;
};

const updateBasicInfo = function* (
  client: Client,
  { presenter, info, changes }: BasicInfoUpdateAction,
): SagaIterator {
  const usernameExists = yield call(
    checkUsername,
    client,
    info,
    changes.includes('username'),
  );

  const emailExists = yield call(checkEmail, client, info, changes.includes('email'));
  const policyViolationCode = 3330;

  if (usernameExists) {
    presenter.addErrors('username', ['Username is taken']);
  }
  if (emailExists) {
    presenter.addErrors('email', ['Email is taken']);
  }

  if (!usernameExists && !emailExists) {
    try {
      const updatedUser = yield call(update, client, info);
      yield put(successfulProfileUpdate(toUser(updatedUser.data)));
      presenter.displaySuccessMessage();
    } catch (error) {
      if (error.response?.data?.errorCode === policyViolationCode) {
        presenter.displayPolicyViolationMessage();
      } else {
        presenter.displayErrorMessage();
      }
    }
  }
};

const checkUsername = function* (
  client: Client,
  info: BasicInfo,
  changedUsernameOrEmail: boolean,
): SagaIterator {
  if (changedUsernameOrEmail) {
    return yield call(fetchUserExists, client, { username: info.username });
  } else {
    return false;
  }
};

const checkEmail = function* (
  client: Client,
  info: BasicInfo,
  changedUsernameOrEmail: boolean,
): SagaIterator {
  if (changedUsernameOrEmail) {
    return yield call(fetchUserExists, client, { email: info.email });
  } else {
    return false;
  }
};

export default updateBasicInfo;
