import type { AxiosInstance } from 'axios';
import { useEffect } from 'react';
import { useErrorReporter } from '@peloton/error-reporting';
import useLogout from '@account/auth/useLogout';

const useTokenMismatchHandler = (client: AxiosInstance) => {
  const { errorReporter } = useErrorReporter();
  const logout = useLogout();

  useEffect(() => {
    const responseInterceptor = client.interceptors.response.use(config => {
      if (config.headers['invalidate-token'] === 'True') {
        errorReporter.reportError(new Error('Token mismatch detected.'));
        logout();
      }
      return config;
    });

    return () => {
      client.interceptors.response.eject(responseInterceptor);
    };
  }, []);
};

export default useTokenMismatchHandler;
