import { black, white } from '@pelotoncycle/design-system';
import { rgba } from 'polished';
import styled from 'styled-components';

const StyledContainer = styled.header`
  background-color: ${white};
  box-shadow: 0 3px 5px 0 ${rgba(black, 0.1)};
  z-index: 2;
  /* Need in IE11+ to retain shadow on mobile/tablet */
  position: relative;
`;

export default StyledContainer;
