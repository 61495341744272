import type { Subscription, DigitalSubscription, TierType } from './Subscription';
import { BillingType, isDeviceSub, isDigitalSub, SubscriptionType } from './Subscription';

export enum SubscriptionEventNames {
  AddedUserToSubscription = 'Added User to Subscription',
  RemovedUserFromSubscription = 'Removed User From Subscription',
}

const toManageSharedUserEvent = (event: string) => (
  userId: string,
  sub: Subscription,
) => ({
  event: event,
  ...toSubscriptionAnalytics(sub),
  '[User ID]': userId,
  subscriptionStatus: sub.isActive ? 'active' : 'inactive',
});

export const toRemoveSharedUserEvent = toManageSharedUserEvent(
  SubscriptionEventNames.RemovedUserFromSubscription,
);
export const toAddSharedUserEvent = toManageSharedUserEvent(
  SubscriptionEventNames.AddedUserToSubscription,
);

export const toReactivateEvent = (sub: Subscription) => ({
  event: 'Clicked Reactivate',
  subscriptionStatus: sub.status.text,
  subscriptionActivatedDate: isDeviceSub(sub)
    ? sub.firstActivatedAt.format('MM/DD/YYYY')
    : sub.purchasedAt.format('MM/DD/YYYY'),
  subscriptionDeactivatedDate: sub.nextBillingAt.format('MM/DD/YYYY'),
  numberOfUsers: sub.sharedUserSet.length + 1, // includes self
});

export const toSubscriptionAnalytics = (
  sub: Subscription,
): SubscriptionAnalyticsProps => ({
  subscriptionType: toSubscriptionType(sub),
  subscriptionOwner: sub.owner.id,
  '[Subscription ID]': sub.id,
  subscriptionPaymentProvider: toPaymentProvider(
    isDigitalSub(sub) ? sub.billingType : BillingType.PelotonInternal,
  ),
  subscriptionBillingFrequency: isDigitalSub(sub)
    ? sub.billingFrequency ?? 'null'
    : BillingType.PelotonInternal,
});

const isFreeTrial = (sub: DigitalSubscription) =>
  sub.billingType === BillingType.Trial || sub.costInCents === 0;

const toReadableTierType = (tierType: TierType) => {
  switch (tierType) {
    case 'no_membership':
      return 'Freemium';
    case 'digital_basic':
      return 'App One';
    case 'digital':
      return 'Digital Membership Monthly';
    case 'digital_plus':
      return 'App Plus';
    case 'guide':
      return 'Guide';
    case 'all-access':
      return 'All Access';
    default:
      return 'No Tier Type Found';
  }
};

const toSubscriptionType = (sub: Subscription) => {
  if (isDeviceSub(sub) && sub.type === SubscriptionType.Commercial) {
    return 'Commercial Membership';
  }
  if (isDeviceSub(sub) && sub.type === SubscriptionType.Consumer) {
    return 'Consumer Membership';
  }
  if (isDigitalSub(sub)) {
    const readableTierType = sub.tierType
      ? toReadableTierType(sub.tierType)
      : 'No Tier Type Found';
    const appendMaybeFreeTrial = isFreeTrial(sub) ? ' Free Trial' : '';
    return readableTierType + appendMaybeFreeTrial;
  }
  return '';
};

const toPaymentProvider = (billingType: BillingType) => {
  switch (billingType) {
    case BillingType.Trial:
    case BillingType.AppleOneWeek:
      return 'None';
    case BillingType.AppleMonthly:
      return 'iTunes';
    case BillingType.AmazonMonthly:
      return 'Amazon';
    case BillingType.GoogleMonthly:
      return 'Google Play';
    case BillingType.PelotonInternal:
    default:
      return 'Credit Card';
  }
};

export type SubscriptionAnalyticsProps = {
  '[Subscription ID]': string;
  subscriptionOwner: string;
  subscriptionPaymentProvider: string;
  subscriptionType: string;
  subscriptionBillingFrequency: string;
};
