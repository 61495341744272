import { pick } from 'ramda';
import { combineReducers } from 'redux';
import type { TypedAction } from '@peloton/redux';
import type { PendingResult } from '@engage/result';
import { pending } from '@engage/result';
import { CancelReason } from '../../models';
import type { Subscription } from '../../Subscription';
import STATE_NAMESPACE from '../namespace';
import updateReducer from './updateReducer';

export enum SubscriptionUpdatesActionType {
  SubsDigCancelRequest = 'pelo/preferences/subscriptions/CANCEL_DIGITAL_SUB',
  SubsDigCancelSuccess = 'pelo/preferences/subscriptions/CANCEL_SUCCESS',
  SubsDigCancelFailure = 'pelo/preferences/subscriptions/CANCEL_FAILURE',

  ReactivateRequest = 'pelo/preferences/subscription/details/REACTIVATE_REQUEST',
  ReactivateSuccess = 'pelo/preferences/subscription/details/REACTIVATE_SUCCESS',
  ReactivateFailure = 'pelo/preferences/subscription/details/REACTIVATE_FAILURE',
  SubsDigUpdatesClear = 'pelo/preferences/subscriptions/UPDATES_CLEAR',
}

// cancel

export const cancelDigitalSub = (
  sub: Subscription,
  reason: CancelReason = CancelReason.Unspecified,
) => ({
  type: SubscriptionUpdatesActionType.SubsDigCancelRequest,
  payload: {
    sub,
    reason,
  },
});

export const cancelDigSuccess = () => ({
  type: SubscriptionUpdatesActionType.SubsDigCancelSuccess,
});

export const cancelDigFailure = () => ({
  type: SubscriptionUpdatesActionType.SubsDigCancelFailure,
});

export const clearUpdateStatus = () => ({
  type: SubscriptionUpdatesActionType.SubsDigUpdatesClear,
});

// reactivate

export const reactivateSubscription = (sub: Subscription) => ({
  type: SubscriptionUpdatesActionType.ReactivateRequest,
  payload: sub.id,
});

export const reactivateSuccess = () => ({
  type: SubscriptionUpdatesActionType.ReactivateSuccess,
});

export const reactivateFailure = () => ({
  type: SubscriptionUpdatesActionType.ReactivateFailure,
});

export type ReactivateSubAction = TypedAction<
  ReturnType<typeof reactivateSubscription>,
  SubscriptionUpdatesActionType.ReactivateRequest
>;
export type ReactivateSubFailureAction = TypedAction<
  ReturnType<typeof reactivateFailure>,
  SubscriptionUpdatesActionType.ReactivateFailure
>;
export type ReactivateSubSuccessAction = TypedAction<
  ReturnType<typeof reactivateSuccess>,
  SubscriptionUpdatesActionType.ReactivateSuccess
>;
export type SubsDigCancelFailureAction = TypedAction<
  ReturnType<typeof cancelDigFailure>,
  SubscriptionUpdatesActionType.SubsDigCancelFailure
>;
export type SubsDigCancelRequestAction = TypedAction<
  ReturnType<typeof cancelDigitalSub>,
  SubscriptionUpdatesActionType.SubsDigCancelRequest
>;
export type SubsDigCancelSuccessAction = TypedAction<
  ReturnType<typeof cancelDigSuccess>,
  SubscriptionUpdatesActionType.SubsDigCancelSuccess
>;
export type SubsDigUpdatesClearAction = TypedAction<
  ReturnType<typeof clearUpdateStatus>,
  SubscriptionUpdatesActionType.SubsDigUpdatesClear
>;

export type Action =
  | ReactivateSubAction
  | ReactivateSubFailureAction
  | ReactivateSubSuccessAction
  | SubsDigCancelFailureAction
  | SubsDigCancelRequestAction
  | SubsDigCancelSuccessAction
  | SubsDigUpdatesClearAction;

export type UpdatesState = {
  cancellation: PendingResult<null, null>;
};

export type UpdatesSelectorState = {
  [STATE_NAMESPACE]: UpdatesState;
};

export const defaultUpdatesState: UpdatesState = {
  cancellation: pending,
};

export const getCancellationStatus = (state: UpdatesSelectorState) =>
  state[STATE_NAMESPACE].cancellation;
export const pruneUnknownStateProps = pick(Object.keys(defaultUpdatesState));

export const updatesReducer = combineReducers<UpdatesState>({
  cancellation: updateReducer(
    SubscriptionUpdatesActionType.SubsDigCancelRequest,
    SubscriptionUpdatesActionType.SubsDigCancelSuccess,
    SubscriptionUpdatesActionType.SubsDigCancelFailure,
    SubscriptionUpdatesActionType.SubsDigUpdatesClear,
  ),
});
