import React from 'react';
import { Prerender404Tags } from '@ecomm/metadata';
import ErrorComponent from './ErrorComponent';

const NotFoundError: React.FC<React.PropsWithChildren<unknown>> = () => (
  <>
    <Prerender404Tags />
    <ErrorComponent titleId="errors_default_title" subtitleId="errors_default_subtitle" />
  </>
);

export default NotFoundError;
