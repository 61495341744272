import { toV2ConfigSchema } from '@peloton/auth/oauthConfigSchema';
import { OauthEnvironment } from '@peloton/env/oauthEnv';

const oauthConfigMap = {
  [OauthEnvironment.Dev]: {
    domain: 'auth-dev.onepeloton.com',
    audience: 'https://api-dev.onepeloton.com/',
    api: 'api-api-core.dev.k8s.pelotime.com',
    client_id: 'qxcbOKkLSUwN8i8oHfNjhkPoD16YmyP9',
  },
  [OauthEnvironment.Stage]: {
    domain: 'auth-stage.onepeloton.com',
    audience: 'https://api-stage.onepeloton.com/',
    api: 'qa1.onepeloton.com',
    client_id: 'jsad22FPq4sMlEQZdhgWALwR9Q2A5yHJ',
  },
  [OauthEnvironment.Test]: {
    domain: 'auth-test.onepeloton.com',
    audience: 'https://api-test.onepeloton.com/',
    api: 'qa2.onepeloton.com',
    client_id: '<client-id>',
  },
  [OauthEnvironment.Prod]: {
    domain: 'auth.onepeloton.com',
    audience: 'https://api.onepeloton.com/',
    api: 'api.onepeloton.com',
    client_id: 'zzLr78cqba7DdtBgRAXFQrlR7xP0FAb4',
  },
};

export const toOauthConfig = (env: OauthEnvironment) => {
  const oauthConfigEnv = oauthConfigMap[env] ?? oauthConfigMap[OauthEnvironment.Dev];
  const oauthConfig = toV2ConfigSchema(oauthConfigEnv);
  return oauthConfig;
};
