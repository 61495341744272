import { black, white, spacing } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { Links } from '@peloton/links';
import { PelotonText } from '@peloton/logos';
import { media } from '@peloton/styles';
import { useKeyValueData } from '@ecomm/copy';
import { Link } from '@ecomm/internationalize-ui';
import { Audience } from '@onewellness/routes';
import { useAudience } from '@onewellness/utils/hooks';
import { AudienceLogo } from './AudienceLogo';
import { DESKTOP_HEIGHT, MOBILE_HEIGHT } from './constants';
import { usePartnerLogo } from './usePartnerLogo';

type Props = {
  audience?: Audience;
  theme?: 'light' | 'dark';
};

const audiencesWithLogos = [
  Audience.DicksSportingGoods,
  Audience.Hyatt,
  Audience.Lululemon,
  Audience.LululemonHardware,
  Audience.YMCA,
];

export const HeaderView: React.FC<Props> = ({ audience, theme = 'dark' }) => {
  const routeAudience = useAudience();
  const resolvedAudience = audience || routeAudience || Audience.Corporate;
  const hasAudienceLogo = audiencesWithLogos.includes(resolvedAudience);
  const homeLinkLabel = useKeyValueData('header.homeLinkLabel');
  const background = theme === 'dark' ? black : white;
  const fill = theme === 'dark' ? white : black;
  const partnerLogo = usePartnerLogo();
  let logo;

  if (hasAudienceLogo || partnerLogo) {
    logo = <AudienceLogo audience={resolvedAudience} fill={fill} logo={partnerLogo} />;
  } else {
    logo = <PelotonTextStyled fill={fill} />;
  }

  return (
    <header style={{ background }}>
      <Inner horizontalCenter={Boolean(hasAudienceLogo || partnerLogo)}>
        <Link
          aria-label={homeLinkLabel}
          href={Links.peloton.path}
          hasUnderline={false}
          data-test-id="link"
        >
          {logo}
        </Link>
      </Inner>
    </header>
  );
};

const Inner = styled.div<{ horizontalCenter: boolean }>`
  display: flex;
  height: ${MOBILE_HEIGHT}px;
  align-items: center;
  justify-content: ${({ horizontalCenter }) => (horizontalCenter ? 'center' : 'unset')};

  ${media.desktop`
    height: ${DESKTOP_HEIGHT}px;
  `}
`;

const PelotonTextStyled = styled(PelotonText)`
  height: 11px;
  margin-left: ${spacing[16]};
  vertical-align: middle;

  ${media.desktop`
    height: 15px;
    margin-left: ${spacing[32]};
  `}
`;
