import React, { useContext } from 'react';
import type { ToggleNames, ToggleValues } from './queryToggles';
import { getQueryToggles, getToggleOverrideValue } from './queryToggles';

export const useQueryToggleValue = (toggleName: ToggleNames) => {
  const toggleValues = useContext(QueryToggleContext);
  return getToggleOverrideValue(toggleName, toggleValues);
};

const QueryToggleContext = React.createContext<ToggleValues>({} as ToggleValues);

type Props = {
  children: React.ReactNode;
  isLit: boolean;
  isProd: boolean;
  browserLocationSearch: string;
};

export const QueryToggleProvider = ({
  isLit,
  isProd,
  browserLocationSearch,
  children,
}: Props) => {
  const search = browserLocationSearch;
  const toggleValues = getQueryToggles(isLit, isProd, search);

  return (
    <QueryToggleContext.Provider value={toggleValues}>
      {children}
    </QueryToggleContext.Provider>
  );
};
