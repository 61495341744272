import { pipe } from 'ramda';
import { TLD } from '@peloton/app-config';
import { isBrowser } from '@peloton/browser';
import { isRouteSegmentLocale } from '@peloton/internationalize/models/path';
import { LOCALE_TOGGLE } from '@peloton/internationalize/models/toggle';

export enum Store {
  UnitedStates = 'dotcom',
  UnitedKingdom = 'webstore_gb',
  Canada = 'ca_webstore',
  Germany = 'de_webstore',
  Default = UnitedStates,
  Australia = 'au_webstore',
  Austria = 'at_webstore',
}

const tldStoresMap: Record<string, Store> = {
  '.com': Store.UnitedStates,
  '.co.uk': Store.UnitedKingdom,
  '.ca': Store.Canada,
  '.de': Store.Germany,
  'com.au': Store.Australia,
  '.at': Store.Austria,
};

export const localeStoresMap: Record<string, Store> = {
  us: Store.UnitedStates,
  gb: Store.UnitedKingdom,
  ca: Store.Canada,
  de: Store.Germany,
  au: Store.Australia,
  at: Store.Austria,
};

export const toStore = (hostname: string = TLD ?? window.location.hostname): Store => {
  if (
    isBrowser &&
    LOCALE_TOGGLE &&
    isRouteSegmentLocale(window.location.pathname.split('/')[1])
  ) {
    const locale = window.location.pathname.split('/')[1].split('-')[1].toLowerCase();
    return localeStoresMap[locale] || Store.Default;
  }
  const key = Object.keys(tldStoresMap).find(tld => hostname.endsWith(tld));
  return (key && tldStoresMap[key]) || Store.Default;
};
export type WebStore = {
  kind: 'web';
  slug: Store;
};

const toWebStore = pipe(
  toStore,
  (slug: Store): WebStore => ({
    kind: 'web',
    slug,
  }),
);

export { toWebStore };
