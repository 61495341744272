import type { ClientConfig, QueryMap } from '@peloton/copy/config';
import type { TypePolicy } from '@peloton/graphql/apolloToggle';
import { ApolloClient, HttpLink, InMemoryCache } from '@peloton/graphql/apolloToggle';
import 'whatwg-fetch';

export type CopyFile = {
  data: Record<string, { items: Record<string, KeyValueType>[] }>;
};
type KeyValueType = { key: string; value: string; __typename: string };
const toTypePolicy = (field: string, map: QueryMap): TypePolicy['fields'] => ({
  [field]: {
    read: (_, { args, toReference }) =>
      toReference({ __typename: map[field], key: args?.id, url: args?.url }),
  },
});
const toTypePolicies = (map: QueryMap) =>
  Object.values(map).reduce(
    (acc, curr) => ({
      ...acc,
      [curr]: {
        // @ts-expect-error
        keyFields: obj => (obj.key ? ['key'] : []),
      },
    }),
    {},
  );

const toQueryTypePolicies = (map: QueryMap) =>
  Object.keys(map).reduce(
    (acc, curr) => ({
      ...acc,
      ...toTypePolicy(curr, map),
    }),
    {},
  );
/**
 * returns a new Apollo Client, hydrated with localized strings
 */
export const toClientV3 = (config: ClientConfig) => ({ data }: CopyFile) => {
  const link = new HttpLink({
    uri: config.url,
    headers: {
      Authorization: `Bearer ${config.token}`,
    },
  });

  const cache = new InMemoryCache({
    // This ts-ignore is due to Apollo 3 upgrades
    // eslint-disable-next-line @typescript-eslint/prefer-ts-expect-error
    // @ts-ignore
    typePolicies: {
      Query: {
        fields: toQueryTypePolicies(config.queryMap),
      },
      ...toTypePolicies(config.queryMap),
    },
  });

  const client = new ApolloClient({
    cache,
    link,
  });

  client.writeQuery({
    query: config.queryDocument,
    data,
  });

  if (config.hydrateOnLoad) {
    client.query({
      query: config.queryDocument,
      variables: config.queryVariables,
    });
  }
  if (process.env.NOTE_ENV !== 'production') {
    (window as any).__printCopy = () => client.extract();
  }
  return client;
};
