import React from 'react';
import { DomainBasedCopyProvider } from '@account/copy';
import NotFoundError from './NotFoundError';
import PageContainer from './PageContainer';

const NotFoundErrorPage = () => (
  <DomainBasedCopyProvider>
    <PageContainer>
      <NotFoundError />
    </PageContainer>
  </DomainBasedCopyProvider>
);

export default NotFoundErrorPage;
