'use client';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useClient } from '@peloton/api';
import { deAuthenticate, useOauthErrorHandler, errorHandler } from '@peloton/auth';
import { redirectToNotFound } from '@peloton/navigation';
import useLogout from '@account/auth/useLogout';

const useClientErrorHandler = () => {
  const dispatch = useDispatch();
  const deAuth = useCallback(() => dispatch(deAuthenticate()), [dispatch]);
  const notFoundRedirect = useCallback(() => dispatch(redirectToNotFound()), [dispatch]);
  const logout = useLogout();
  const client = useClient();

  const oauthErrorHandler = useOauthErrorHandler(
    errorHandler(logout, notFoundRedirect, deAuth),
    client,
    true,
  );

  useEffect(() => {
    const responseInterceptor = client.interceptors.response.use(
      config => config,
      oauthErrorHandler,
    );

    return () => {
      client.interceptors.response.eject(responseInterceptor);
    };
  }, []);
};

export default useClientErrorHandler;
