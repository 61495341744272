export type AnalyticsEvent =
  | HappyPathEvents
  | ErrorEvents
  | ExperimentEvents
  | CancellationEvent
  | CancellationPageEvent
  | RemoteDeviceActivationEvent
  | PostPurchaseEvent
  | ExtendedServicePlanEvent
  | DownloadAppEvents
  | AppCheckoutEvents
  | CheckoutEvent
  | GiftCardEvent
  | QuickPayCheckoutEvents
  | PersonalizedWinbackEvents
  | MyMembershipEvents
  | AnalyticsEvents
  | HyattConnect;

export type CTAEventProps = {
  parentType: string;
  linkTo: string;
  unitName: string;
  linkName: string;
  parent?: string;
};

// https://pelotoncycle.atlassian.net/wiki/spaces/EO/pages/40921661584/OX+Analytics
// TODO: Refactor the naming of events

const CLICKED_LINK = 'Clicked Link';

export enum HappyPathEvents {
  ClickedReimagineTheWaitCTA = 'Clicked Reimagine the Wait CTA button',
  OrderHasSubscription = 'Purchased Subscription',
  OrderPlacedTreadDeposit = 'Placed Tread Deposit',
  OrderHasTread = 'Purchased Tread',
  OrderHasBike = 'Purchased Bike',
  OrderHasBikePlus = 'Purchased Bike-plus',
  OrderTransactionCompleted = 'Completed Transaction',
  PurchaserSubmittedPPS = 'Purchaser Submitted PPS',
  GuestSubmittedPPS = 'Guest Submitted PPS',
  PurchaserViewedPPS = 'Purchaser Viewed PPS',
  ScheduledDelivery = 'Scheduled Delivery',
  GuestViewedPPS = 'Guest Viewed PPS',
  OrderPlaced = 'Order Placed',
  ViewedPPFPaymentMethod = 'Account app PPF payment method page rendered',
  AccountAppSkipScheduling = 'Account app skip scheduling',
  AccessedHomepageFromReferralShare = 'Accessed homepage from referral share',
  SharedReferralCode = 'Shared Referral Code',
  ClickedPastReferrals = 'Clicked Past Referrals',
  OrderHistoryListPageViewed = 'Order History Page Viewed',
  OrderHistoryDetailsPageViewed = 'Order History Details Page Viewed',
  OrderHistoryDetailsButtonClicked = 'Clicked Link on OX',
  OrderSummaryButtonClicked = 'Clicked Link on OX',
  OrderTrackingPageViewed = 'Track Your Order Page Viewed',
  OrderStatusAndTrackingToggleClicked = 'Order Status/Tracking Toggle Clicked',
  ScheduleOrRescheduleDeliveryLinkClicked = 'Schedule or Reschedule link Clicked',
  SeeOrCloseTrackingHistory = 'See or Close Tracking History link clicked',
  TrackingUrlLinkClicked = 'Tracking URL Link Clicked',
  ExperimentReceived = 'Experiment Received',
  AccountUpsellBannerViewed = 'Post Order Banner Viewed',
  AccountUpsellBannerClicked = 'Clicked Link on OX',
  AccountUpsellProductClicked = 'Clicked Link on OX',
  ReturnsStatusPageViewed = 'Return Status Page Viewed',
  ReturnsStatusButtonClicked = 'Clicked Link on OX',
  ReturnCfuButtonClicked = 'Return Step Completed',
  ReturnItemsButtonClicked = 'Clicked Link on OX',
  ReturnConfirmMyReturnButtonClicked = 'Return Step Completed',
  SelectReasonContinueButton = 'Return Step Completed',
  ViewedReferralPage = 'Viewed Referral Page',
  ViewedProspectLandingPage = 'Viewed Prospect Landing Page',
  ReferralLinkClicked = 'Clicked Link on Referral',
  ContinueToWebClicked = 'Clicked Continue to Web',
  RejoinClicked = 'Clicked Rejoin',
}

export enum ErrorEvents {
  AccountAppErrorComponentRendered = 'Account app error component rendered',
  AccountAppNoSlotsErrorComponentRendered = 'Account app no slots error component rendered',
  AccountAppPPFErrorComponentRendered = 'Account app PPF error component rendered',
  AccountAppSubmitDeliveryPreferenceError = 'Account app submit delivery preference error',
}

export enum ExperimentEvents {
  CancellationExperimentTreatmentEnabled = 'Cancellation Experiment Treatment',
  CancellationExperimentControlEnabled = 'Cancellation Experiment Control',
}

export enum CancellationEvent {
  CancelOrderCTAClick = CLICKED_LINK,
  ConfirmFormOpen = 'Cancellation Step Viewed',
  ConfirmFormSubmit = 'Cancellation Step Completed',
  ConfirmFormError = 'Cancellation Step Viewed',
  ConfirmFormSuccess = 'Cancellation Step Viewed',
  ModalClose = 'Cancellation Modal Closed',
  ModalOpen = 'Cancellation Step Viewed',
  OrderStatusViewed = 'Order Status Page Viewed',
  ReasonFormSubmit = 'Cancellation Step Completed',
  CancellationOpenTextReasonSent = 'Open Text Reason Response',
}

export enum CancellationPageEvent {
  RefinementClick = 'REFINEMENT_CLICK',
  OfferClick = 'OFFER_CLICK',
}

export enum RemoteDeviceActivationEvent {
  ActivationExpiration = 'Activation Expiration',
  MembershipPurchased = 'Membership Purchased',
  ReEnteredActivationCode = 'Re-Entered Activation Code',
  SubscriptionActivated = 'Subscription Activated',
  TappedPurchaseANewMembership = 'Tapped Purchase A New Membership',
  UseDifferentMembershipClicked = 'Tapped Use Different Membership',
  ViewedCancelExistingMembership = 'Viewed Cancel Existing Membership',
  ViewedLocateYourMembership = 'Viewed Locate Your Membership',
  ViewedMembershipAttached = 'Viewed Membership Attached',
  ViewedMembershipSelection = 'Viewed Membership Selection',
  ViewedPaymentMethod = 'Viewed Payment Method',
  ViewedPurchaseMembership = 'Viewed Purchase Membership',
  ViewedSwitchMembership = 'Viewed Switch Membership',
  ViewedCancelSubscriptionModal = 'Viewed Cancel Subscription Modal',
  ClickedAddedPaymentMethod = 'Clicked Added Payment Method',
  AddedPaymentMethod = 'Added Payment Method',
  ViewedConfirmMembership = 'Viewed Confirm Membership',
  RemoteActivationCompleted = 'Remote Activation Completed',
  RemoteActivationCreatedAccount = 'Created Account',
  TooltipViewed = 'Tooltip Viewed',
  StartedMembershipFlow = 'Started Membership Flow',
  ViewedGiftingInterstitial = 'Viewed Gifting Interstitial',
  GiftingEmailEntered = 'Gifting Email Entered',
  GiftingClickedContinue = 'Gifting Clicked Continue',
  ExperimentViewed = 'Experiment Viewed',
  ViewedEducationInterstitial = 'Viewed Education Interstitial',
  ClickedLink = CLICKED_LINK,
}

export enum PostPurchaseEvent {
  PostPurchaseLinkClick = 'Clicked Post Purchase Link',
}

export enum ExtendedServicePlanEvent {
  PlanCheckoutInitiated = 'Extended Service Plan Checkout Initiated',
  PlanCheckoutFailed = 'Extended Service Plan Checkout Failed',
  PlanCheckoutSuccess = 'Extended Service Plan Checkout Success',
  Eligible = 'Extended Service Plan Eligible',
  InEligible = 'Extended Service Plan Ineligible',
}

export enum DownloadAppEvents {
  GooglePlay = 'Google Play download link clicked',
  AppleBadge = 'Apple download badge link clicked',
}

export enum AppCheckoutEvents {
  ClickedLink = CLICKED_LINK,
  ClickedFaqs = 'Clicked FAQs',
  ViewedInterstitial = 'Viewed Digital Interstitial Page',
  ViewedMyMembership = 'Viewed My Membership Page',
  CreatedDigitalAccount = 'Created Digital Account',
  MarketingOptInChecked = 'Checked marketing consent box',
}

export enum CheckoutEvent {
  ClickedButton = CLICKED_LINK,
  CreatedPassword = 'Created Password',
  EnteredEmail = 'Moved away from email field on Digital Checkout',
  CheckoutStepCompleted = 'Checkout Step Completed',
  CheckoutStepViewed = 'Checkout Step Viewed',
  ViewedDigitalCheckoutScreen = 'Viewed Digital Checkout Screen',
  ViewedFreeAccountScreen = 'Viewed Free Account Screen',
  BillingCycleSelected = 'Billing Cycle Selected',
  ClickedStartMembership = 'Clicked Start Membership',
  ProductAttributeSelected = 'Product Attribute Selected',
  StartedDigitalMembership = 'Started Digital Membership',
}

export enum CheckoutStepNames {
  UserSelectedBillingCycle = 'User Selected Billing Cycle',
  ViewedCheckoutPage = 'Viewed Checkout Page',
  ViewedCheckout = 'Viewed Checkout Payment Screen',
  CompletedCheckout = 'Entered Payment Details or clicked Klarna/Affirm/Paybright',
}

export enum SubscriptionTypes {
  FreeTrial = 'Free Trial',
  MTM = 'Mtm',
}

export enum GiftCardEvent {
  TooltipViewed = 'Tooltip Viewed',
  PaymentInfoEntered = 'Payment Info Entered',
}

export enum QuickPayCheckoutEvents {
  HasQuickpayCheckout = 'Has Quickpay Checkout',
  ClickedExpressCheckout = 'Clicked Express Checkout',
  ExpressCheckoutModalOpened = 'Express Checkout Modal Opened',
  ExpressCheckoutModalClosed = 'Express Checkout Modal Closed',
  CheckoutStepCompleted = 'Checkout Step Completed',
}

export enum PersonalizedWinbackEvents {
  ViewedReactivationPage = 'Viewed Reactivation Page',
  SelectPaymentMethod = 'Selected Payment Method',
  AddedPaymentMethod = 'Added Payment Method',
  ClickedStartMembership = 'Clicked Start Membership',
  MembershipPurchased = 'Membership Purchased',
  SelectedPaymentMethod = 'Selected Payment Method',
  SubscriptionActivated = 'Subscription Activated',
  FailedReactivation = 'Failed Reactivation',
  OfferApplied = 'Offer Applied',
}

export enum MyMembershipEvents {
  ClickedPauseMembership = 'Clicked Pause Membership',
  MembershipReactivated = 'Membership Reactivated',
  CancelledMembership = 'Cancelled Membership',
  ResumedMembership = 'Resumed Membership',
}

export enum AnalyticsEvents {
  ClickedLink = CLICKED_LINK,
  LoadedPage = 'Loaded a Page',
  ExperimentViewed = 'Experiment Viewed',
}

export enum HyattConnect {
  ClickedContinue = 'Hyatt Connect Continue CTA Clicked',
  ClickedAccept = 'Hyatt Connect Accept CTA Clicked',
  ClickedCancel = 'Hyatt Connect Cancel CTA Clicked',
  ClickedTryAgain = 'Hyatt Connect Try Again CTA Clicked',
  ClickedExplore = 'Hyatt Connect Explore CTA Clicked',
  ConnectSucceeded = 'Hyatt Connect Succeeded',
  ConnectFailed = 'Hyatt Connect Failed',
}
