import type { EnvironmentFlags as PEnvironmentFlags } from '@peloton/env';
import { environmentFlags } from '@peloton/env';
import { maybeHostname } from '@peloton/internationalize/models/locale';

export type EnvironmentFlags = PEnvironmentFlags & {
  isDeployPreview: boolean;
};

const accountEnvironmentFlags = (hostname = maybeHostname()): EnvironmentFlags => {
  const baseEnvFlags = environmentFlags('account')(hostname);
  const isNonProdEnv =
    !baseEnvFlags.isUat &&
    !baseEnvFlags.isStaging &&
    !baseEnvFlags.isStageN &&
    !baseEnvFlags.isLocal &&
    !baseEnvFlags.isUatProd;
  const isWWWProdTestUrl =
    (hostname.includes('prod-test') || hostname.includes('prod-www')) && isNonProdEnv;
  const isWWWProdUrl = hostname.startsWith('www.') && isNonProdEnv;
  const isDeployPreview = hostname.startsWith('deploy-preview');
  return {
    ...baseEnvFlags,
    isProd: baseEnvFlags.isProd || isWWWProdUrl,
    isProdTest: baseEnvFlags.isProdTest || isWWWProdTestUrl,
    isDeployPreview,
  };
};
export default accountEnvironmentFlags;
