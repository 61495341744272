import type { UserAttributes } from '@optimizely/optimizely-sdk';
import { useFeature } from '@optimizely/react-sdk';
import React from 'react';
import { NotFoundErrorPage } from '@account/layout';
import { Route } from '@account/router';
import type { OwnProps as RouteProps } from '@account/router/Route';
import type { FEATURES } from './enums';

export type Props = RouteProps & {
  feature: FEATURES;
};

type ToggledRouteProps = Props & {
  overrideAttributes?: UserAttributes;
  overrideUserId?: string;
};

const ToggledRoute: React.FC<React.PropsWithChildren<ToggledRouteProps>> = ({
  feature,
  overrideAttributes,
  overrideUserId,
  ...props
}) => {
  const [isFeatureEnabled] = useFeature(
    feature,
    {},
    { overrideUserId, overrideAttributes },
  );

  return isFeatureEnabled ? <Route {...props} /> : <NotFoundErrorPage />;
};

export default ToggledRoute;
