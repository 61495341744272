import type WebFont from 'webfontloader';

export default {
  google: {
    families: ['Roboto:100,300,400,500,700'],
  },
  typekit: {
    id: 'sxy3vgl',
  },
} as WebFont.Config;
