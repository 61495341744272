import React from 'react';
import { useSelector } from 'react-redux';
import type { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { useTracking } from 'react-tracking';
import { useCookies } from '@peloton/cookies';
import { ORDER_HISTORY } from '@account/pg-order-history/urls';
import { POST_PURCHASE_FLOW_PREFIX } from '@account/urls';
import { getUserTrackingProperties, getUserTransactionState } from '@ecomm/auth';
import { Status } from '@ecomm/models';
import BaseTrackingPropertiesProvider from './BaseTrackingPropertiesProvider';
import { isValidOrderId } from './utils/isValidOrderId';

const useUserProperties = () => {
  const [userData, setUserData] = React.useState({});
  const {
    hasDigitalSubscription,
    hasDeviceSubscription,
    isLoggedIn,
    email,
    userId,
  } = useSelector(getUserTrackingProperties);

  const { status } = useSelector(getUserTransactionState);

  React.useEffect(() => {
    if (status !== Status.Loaded && status !== Status.Failed) return;

    setUserData({
      email,
      userId,
      hasDigitalSubscription,
      hasDeviceSubscription,
      isLoggedIn,
    });
  }, [email, userId, hasDigitalSubscription, hasDeviceSubscription, isLoggedIn, status]);
  return {
    userData,
  };
};

type Props = RouteComponentProps;

const AppTrackingProvider: React.FC<React.PropsWithChildren<Props>> = ({
  location,
  children,
}) => {
  const { userData } = useUserProperties();
  const { getCookie } = useCookies();
  const baseTrackingPathnameAllowList = new Set<string>([
    POST_PURCHASE_FLOW_PREFIX,
    ORDER_HISTORY,
  ]);

  const getOrderIdFromPathname = (): string => {
    const pathnames = location.pathname.substring(1).split('/');
    const pathnamePrefix = pathnames[0] ?? '';

    if (
      pathnames.length >= 2 &&
      baseTrackingPathnameAllowList.has('/' + pathnamePrefix)
    ) {
      return pathnames[1];
    } else {
      return '';
    }
  };

  const orderId = getOrderIdFromPathname();
  const validOrderId = React.useMemo(() => isValidOrderId(orderId), [orderId]);

  const { Track } = useTracking({
    appName: 'Account',
    propertyType: 'Web',
    page: window.location.pathname,
    url: window.location.href,
    ttclid: sessionStorage.getItem('ttclid'),
    ttp: getCookie('_ttp'),
    ...userData,
  });

  if (validOrderId && Object.keys(userData).length > 0) {
    return (
      <Track>
        <BaseTrackingPropertiesProvider
          orderId={orderId}
          isLoggedIn={
            'isLoggedIn' in userData ? (userData['isLoggedIn'] as boolean) : false
          }
        >
          {children}
        </BaseTrackingPropertiesProvider>
      </Track>
    );
  }

  return <Track>{children}</Track>;
};

export default withRouter(AppTrackingProvider);
