import { gql } from '@apollo/client';
import type { ApolloClient } from 'apollo-client';
import { identity } from 'ramda';
import { pipeData, throwError } from '@peloton/api';
import { ErrorCode } from './utils';

export const benefitActivate = (
  client: ApolloClient<any>,
  email: string,
  hasAccount: Boolean,
  locale: string,
) =>
  client
    .mutate({
      mutation: ELIGIBLE_PERSON_ACTIVATE,
      variables: { email, hasAccount, locale },
    })
    .then(pipeData(identity))
    .catch(throwError<ErrorCode>(() => ErrorCode.BenefitHubError));

const ELIGIBLE_PERSON_ACTIVATE = gql`
  mutation SendActivationEmail($email: String!, $hasAccount: Boolean!, $locale: String!) {
    eligiblePersonActivate(email: $email, hasAccount: $hasAccount, locale: $locale) {
      requestOk
      requestErrors {
        errorCode
      }
    }
  }
`;
