import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import type { ActionCreator } from 'redux';

export const useReduxAction = <AC extends ActionCreator<any>>(ac: AC) => {
  const dispatch = useDispatch();
  return useCallback((...params: Parameters<AC>) => dispatch(ac(...params)), [
    ac,
    dispatch,
  ]);
};

type Selector = (state: any, ...args: any[]) => any;
type SelectorState<TSelector extends Selector> = Parameters<TSelector>[0];
type SelectorParameters<TSelector extends Selector> = TSelector extends (
  _: any,
  ...args: infer P
) => any
  ? P
  : never;

export const useReduxState = <S extends Selector>(
  selector: S,
  ...selectorParams: SelectorParameters<S>
): ReturnType<S> => {
  return useReduxStateWithEquals(selector, shallowEqual, ...selectorParams);
};

export const useReduxStateWithEquals = <S extends Selector>(
  selector: S,
  equals: (a: ReturnType<S>, b: ReturnType<S>) => boolean,
  ...selectorParams: SelectorParameters<S>
): ReturnType<S> => {
  // NOTE: Generate a wrapped selector function that first checks if the state actually
  //       changed. This prevents the selector logic from executing when it is not needed.
  //       The downside of this is keeping two extra references per hook usage
  //       (one for last known state, one for last known result) in addition to the
  //       last known result being stored by useSelector itself. Usually this does not
  //       have a large benefit, unless there are hundreds of mounted components
  //       each using more than one useReduxState/useReduxStateWithEquals hook.
  //       This technique relies on the state object not being recreated when no
  //       actual data changed (e.g. via {...state} in a nested reducer).
  const wrappedSelector = useMemo(() => {
    let lastState: SelectorState<S>;
    let lastResult: ReturnType<S>;

    const wrapped = (state: SelectorState<S>) => {
      if (lastState !== undefined && lastState === state) {
        return lastResult;
      }

      lastState = state;
      lastResult = selector(state, ...selectorParams);

      return lastResult;
    };

    return wrapped;
  }, [selector, ...selectorParams]);

  Object.defineProperty(wrappedSelector, 'name', { value: selector.name });

  return useSelector(wrappedSelector, equals);
};
