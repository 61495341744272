import { path, pickAll } from 'ramda';
import { useAccountMetadataQuery } from '../hooks.generated';
import type { AccountMetadata } from '../models.generated';

export type AccountMetadataContent = {
  title: string;
  description: string;
  image: { url: string };
  ogtype: string;
  keywords: string[];
};

export const useAccountMetadata = (id: AccountMetadata): AccountMetadataContent => {
  const res = useAccountMetadataQuery({ variables: { id } });

  const {
    title = '',
    description = '',
    keywords = [],
    ogtype = 'website',
    image = { url: '' },
  } = pickAll<any, AccountMetadataContent>(
    ['title', 'description', 'keywords', 'ogtype', 'image'],
    path(['data', 'accountMetadata'], res) || {},
  );

  return {
    title,
    description,
    keywords: (keywords || []) as string[],
    ogtype,
    image,
  };
};
