import { equals } from 'ramda';
import type { Action, Reducer } from 'redux';
import type {
  FailAction,
  Gateway,
  Namespace,
  RequestAction,
  ResponseAction,
} from './actionCreators';

const defaultState: FetcherState = {};

export const fetchReducer: Reducer<FetcherState> = (
  state = defaultState,
  action: FetchAction,
) => {
  switch (action.type) {
    case FetcherActionTypes.Request:
      return {
        ...state,
        [action.payload.namespace]: {
          ...state[action.payload.namespace],
          isLoading: true,
        },
      };
    case FetcherActionTypes.RequestSuccess: {
      const {
        meta: { namespace },
        payload,
      } = action;

      const newNamespaceState = {
        entity: payload,
        isLoading: false,
        error: undefined,
      };

      if (namespace in state && equals(state[namespace], newNamespaceState)) {
        return state;
      }

      return {
        ...state,
        [action.meta.namespace]: newNamespaceState,
      };
    }
    case FetcherActionTypes.RequestFailure:
      return {
        ...state,
        [action.meta.namespace]: {
          isLoading: false,
          error: action.payload,
        },
      };
    case FetcherActionTypes.Cache:
    default:
      return state;
  }
};

export const getEntity = <T>(state: FetcherSelectorState, namespace: Namespace): T =>
  state.fetched[namespace] && state.fetched[namespace].entity;

export const getIsLoading = (
  state: FetcherSelectorState,
  namespace: Namespace,
): boolean => state.fetched[namespace] && state.fetched[namespace].isLoading;

export const getIsError = (state: FetcherSelectorState, namespace: Namespace): boolean =>
  state.fetched[namespace] && !!state.fetched[namespace].error;

export const getError = (state: FetcherSelectorState, namespace: Namespace) =>
  state.fetched[namespace] && state.fetched[namespace].error;

export const requestAction = <T>(
  namespace: Namespace,
  gateway: Gateway<T>,
  ...gatewayParams: any[]
): FetchRequestAction<T> => ({
  type: FetcherActionTypes.Request,
  payload: {
    namespace,
    gateway,
    gatewayParams,
  },
});

export const cacheAction = <T>(
  namespace: Namespace,
  gateway: Gateway<T>,
  ...gatewayParams: any[]
): FetchCacheAction<T> => ({
  type: FetcherActionTypes.Cache,
  payload: {
    namespace,
    gateway,
    gatewayParams,
  },
});

export const successAction = <T>(
  namespace: Namespace,
  payload: T,
): FetchSuccessAction<T> => ({
  type: FetcherActionTypes.RequestSuccess,
  meta: {
    namespace,
  },
  payload,
});

export const failAction = (namespace: Namespace, payload: Error): FetchFailureAction => ({
  type: FetcherActionTypes.RequestFailure,
  meta: {
    namespace,
  },
  payload,
});

export const isFetchSuccessAction = (action: Action): action is FetchSuccessAction<any> =>
  action.type === FetcherActionTypes.RequestSuccess;

export type FetchAction =
  | FetchCacheAction<any>
  | FetchRequestAction<any>
  | FetchSuccessAction<any>
  | FetchFailureAction;

export type FetchResultAction = FetchSuccessAction<any> | FetchFailureAction;

export type FetchCacheAction<T> = RequestAction<FetcherActionTypes.Cache, T>;
export type FetchRequestAction<T> = RequestAction<FetcherActionTypes.Request, T>;
export type FetchSuccessAction<T> = ResponseAction<FetcherActionTypes.RequestSuccess, T>;
export type FetchFailureAction = FailAction<FetcherActionTypes.RequestFailure>;

export enum FetcherActionTypes {
  Cache = 'pelo/fetch/Cache',
  Request = 'pelo/fetch/Request',
  RequestSuccess = 'pelo/fetch/RequestSuccess',
  RequestFailure = 'pelo/fetch/RequestFailure',
}

type FetcherState = Record<Namespace, DataState<any>>;

export type FetcherSelectorState = {
  fetched: FetcherState;
};

export type DataState<T> = {
  isLoading: boolean;
  entity?: T;
  error?: Error;
};
