import type {
  BasicInfo,
  MeasurementPreferences,
} from '@account/pg-my-membership/models/BasicInfo';

export type BasicInfoPresenter = {
  displayBasicInfo(info: BasicInfo & MeasurementPreferences): void;
  displayCompleteProfileMessage(): void;
  redirectToLoginPage(): void;
};

export enum BasicInfoActionType {
  BasicInfoRequest = 'pelo/preferences/basic-info/REQUEST',
  BasicInfoUpdate = 'pelo/preferences/basic-info/UPDATE',
}

export type BasicInfoRequestAction = {
  type: BasicInfoActionType.BasicInfoRequest;
  presenter: BasicInfoPresenter;
  url: string;
};
