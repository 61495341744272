import { gql } from '@apollo/client';
import { toData } from '@onewellness/api';
import type { OneWellnessClient } from '@onewellness/api/client';

export const isRegisteredForRewards = (
  client: OneWellnessClient,
  memberId: string,
  partnerName: string,
) =>
  client
    .query({
      query: IS_REGISTERED_FOR_REWARDS,
      variables: { memberId, partnerName },
    })
    .then(toData);

const IS_REGISTERED_FOR_REWARDS = gql`
  query isRegisteredForRewards($memberId: String!, $partnerName: String!) {
    isRegisteredForRewards(memberId: $memberId, partnerName: $partnerName) {
      isRegistered
    }
  }
`;
