import { none } from 'ramda';
import { call, select, put } from 'redux-saga/effects';
import type { Client } from '@peloton/api';
import type { LoadSubDetailsAction } from '../redux';
import {
  getSubscriptions,
  loadSubscriptionsSuccess,
  loadSubscriptions as loadSubscriptionsAction,
} from '../redux';
import type { Subscription } from '../Subscription';
import loadSubscriptions from './loadSubscriptions';

// Ensures subscription is loaded by making sure we've loaded ALL subs
export const loadSubscription = function* (client: Client, action: LoadSubDetailsAction) {
  const subs: Subscription[] | undefined = yield select(getSubscriptions);

  if (subs === undefined || none(sub => sub.id === action.payload, subs)) {
    yield call(loadSubscriptions, client, loadSubscriptionsAction(action.payload));
  } else {
    yield put(loadSubscriptionsSuccess(subs, action.payload));
  }
};

export default loadSubscription;
