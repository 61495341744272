import type { Locale } from '@peloton/internationalize';
import type { Money, Currency } from '@peloton/internationalize/models/currency';
import { addMoney, toMoney } from '@peloton/internationalize/models/currency';
import type { Time } from '@peloton/time';

export type Subsidy = {
  total: Money;
  billingPartner: string;
  createdAt: number;
  currency: Currency;
  label: string;
};

export type StandardTransaction = {
  subTotal: Money;
  billingPartner: string;
  tax: Money;
};

export type Transaction = {
  id: string;
  card: SimpleCard;
  createdAt: Time;
  subscriptionId?: string;
  subTotal: Money;
  tax: Money;
  monthsPaid: number;
  locale: Locale;
  billingPartner?: string;
  amountInCents?: number;
  currency: Currency;
  subsidy?: Subsidy;
  standardTransactions: StandardTransaction[];
};

export type SimpleCard = {
  last4: string;
  brand: string;
};

const getTotal = (transaction: Transaction | StandardTransaction) =>
  addMoney(transaction.subTotal, transaction.tax);

const getTotalBilled = (
  standardTransactions: StandardTransaction[],
  currency: Currency,
) =>
  addMoney(
    subscriptionTransSubTotal(standardTransactions, currency),
    subscriptionTransTaxTotal(standardTransactions, currency),
  );

const subscriptionTransSubTotal = (
  standardTransactions: StandardTransaction[],
  currency: Currency,
) => {
  const total = standardTransactions.reduce((a, standardTransaction) => {
    if (!isBenefitHub(standardTransaction.billingPartner)) {
      return a + standardTransaction.subTotal.amount;
    } else {
      return a;
    }
  }, 0);
  return toMoney(total, currency);
};

const subscriptionTransTaxTotal = (
  standardTransactions: StandardTransaction[],
  currency: Currency,
) => {
  const taxTotal = standardTransactions.reduce((a, standardTransaction) => {
    if (!isBenefitHub(standardTransaction.billingPartner)) {
      return a + standardTransaction.tax.amount;
    } else {
      return a;
    }
  }, 0);
  return toMoney(taxTotal, currency);
};

const formatBillingPartner = (billingPartner: string) => {
  if (isBenefitHub(billingPartner)) {
    return 'pgMyMembership.standardTransaction.billingPartner.corporateWellness';
  } else if (['totus', 'fiserv'].includes(billingPartner)) {
    return 'pgMyMembership.giftCard.name';
  } else {
    return 'pgMyMembership.standardTransaction.billingPartner.credit';
  }
};

const isBenefitHub = (billingPartner: string) => billingPartner === 'benefit_hub';

export {
  getTotalBilled,
  getTotal,
  subscriptionTransSubTotal,
  subscriptionTransTaxTotal,
  formatBillingPartner,
  isBenefitHub,
};
