import { useFeature } from '@optimizely/react-sdk';
import { brand, grey, white } from '@pelotoncycle/design-system';
import { transparentize } from 'polished';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import styled, { css } from 'styled-components';
import type { ExtLinkEnvState, ExtLinkEnv } from '@peloton/external-links';
import { toHref, getExtLinkEnv } from '@peloton/external-links';
import { addCurrentLocaleToUrl } from '@peloton/internationalize/addCurrentLocaleToUrl';
import { useOneTrust } from '@peloton/onetrust/useOneTrust';
import type { RoutingState } from '@peloton/redux';
import { getLocation } from '@peloton/redux';
import { BreakpointEither } from '@peloton/responsive';
import { hoverTransition, media } from '@peloton/styles';
import type { AccountKeyValue } from '@account/copy';
import { AccountCopy, useAccountKeyValue } from '@account/copy';
import { FEATURES } from '@account/optimizely/enums';
import { getBenefitHubPaymentMethod } from '@account/pg-my-membership/redux';
import { b4, b6, hb6, reg } from '@acme-ui/typography';
import type { ReducerState as ToggleState } from '@ecomm/feature-toggle';
import { ChatIcon } from '@ecomm/icons';
import { L4 } from '@ecomm/links';
import { useReduxState } from '@engage/redux';
import { FooterData, CorporateWellnessFooterData } from './static';

export type Props = Pick<React.HTMLAttributes<HTMLElement>, 'className'> & {
  extLinkEnv: ExtLinkEnv;
  pathname: string;
};

const FooterView: React.FC<React.PropsWithChildren<Props>> = ({
  className,
  extLinkEnv,
  pathname,
}) => {
  const [isI18NEnabled] = useFeature(FEATURES.i18NLocaleToggle);
  const supportChatLink = useAccountKeyValue('support.chat-url.delivery');
  const benefitHubPaymentMethod = useReduxState(getBenefitHubPaymentMethod);
  const isCorporateWellnessPage = pathname.indexOf('/benefits/corporate') === 0;
  const {
    isReady: isOneTrustReady,
    toggleInfoDisplay,
    isDoNotSellEnabled,
    doNotSellLink,
  } = useOneTrust();

  let links = FooterData.links;
  if (isCorporateWellnessPage || benefitHubPaymentMethod) {
    links = CorporateWellnessFooterData.links;
  }

  const toCookieLink = (id: AccountKeyValue) => (
    <li>
      <StyledL4 onClick={() => toggleInfoDisplay()}>
        <AccountCopy id={id} />
      </StyledL4>
    </li>
  );

  const toDoNotSellLink = (id: AccountKeyValue) => (
    <li>
      <StyledL4 href={doNotSellLink} target="_blank" rel="noopener">
        <AccountCopy id={id} />
      </StyledL4>
    </li>
  );

  return (
    <Background className={className}>
      <Container>
        <FlexContent>
          <LinkColumns>
            {links.map((linkColumn, index) => (
              <LinkColumn key={index}>
                {linkColumn.map((link, linkIndex) => (
                  <li key={linkIndex}>
                    <StyledL4
                      key={linkIndex}
                      href={addCurrentLocaleToUrl(
                        toHref(link.href, extLinkEnv),
                        isI18NEnabled,
                      )}
                      target="_blank"
                      rel="noopener"
                    >
                      <AccountCopy id={link.title} />
                    </StyledL4>
                  </li>
                ))}
                {index === 0 && (
                  <>
                    {isOneTrustReady && toCookieLink('footer_links_cookieSettings')}
                    {isDoNotSellEnabled && toDoNotSellLink('footer_links_doNotSellShare')}
                  </>
                )}
              </LinkColumn>
            ))}
          </LinkColumns>
          <ContactSection>
            <ul>
              <li key="chat">
                <ContactLink href={supportChatLink} rel="noopener" target="_blank">
                  <StyledChatIcon />
                  <BreakpointEither breakpoint="tablet">
                    <span>
                      <AccountCopy id="footer_smallChatText" />
                    </span>
                    <span>
                      <AccountCopy id="footer_chatText" />
                    </span>
                  </BreakpointEither>
                </ContactLink>
              </li>
            </ul>
          </ContactSection>
        </FlexContent>
        <Copyright>
          <AccountCopy id="footer_copyrightText" />
        </Copyright>
      </Container>
    </Background>
  );
};

const mapStateToProps = (state: ExtLinkEnvState & ToggleState & RoutingState) => ({
  extLinkEnv: getExtLinkEnv(state),
  pathname: getLocation(state).pathname,
});

export const Footer = compose(connect(mapStateToProps))(FooterView);

const IconStyle = css`
  height: 16px;
  margin-top: 2px;
`;

const StyledChatIcon = styled(ChatIcon)`
  ${IconStyle}
  margin-right: 10px;
  width: 18px;
`;

const Background = styled.footer`
  background-color: ${brand.darkest};
  color: white;
`;

const Container = styled.section`
  margin: auto;
  max-width: 1000px;
  padding: 30px 20px 38px 20px;

  ${media.tablet`
    padding: 40px 20px;
  `}

  ${media.desktop`
    padding: 40px 30px;
  `}
`;

const FlexContent = styled.section`
  display: flex;
`;

const LinkColumns = styled.section`
  ${b4}
  ${reg}
  display: flex;
  flex-direction: column;

  ${media.tablet`
    flex-direction: row;
  `}
`;

const LinkColumn = styled.ul`
  display: flex;
  flex-direction: column;
  margin-right: 60px;
`;

const StyledL4 = styled(L4)`
  line-height: 24px;
`;

const ContactSection = styled.section`
  ${hb6}
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  min-width: 20%;

  ${media.tablet`
    flex: 0 0 auto;
  `}
`;

const ContactLink = styled(L4)`
  display: flex;
  margin-bottom: 10px;

  ${hoverTransition({
    property: 'fill',
    to: transparentize(0.4, white),
  })}
`;

const Copyright = styled.div`
  ${b6}
  color: ${grey[60]};
  padding-top: 34px;
  width: auto;
`;
